import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { CartSummary } from "../../../components/cart-summary/CartSummary";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import OrderSummaryCompact from "../../../components/cart-summary/OrderSummaryCompact";

const FastCheckoutOrderSummarySegment: React.FC<{
  app_mode: string;
  isCompact?: boolean;
}> = ({ app_mode, isCompact }: any) => {
  const cartData = useSelector((state: RootState) => state.cart);

  const { t } = useTranslation();

  const renderCartSummaryLogic = () => {
    if (isCompact) {
      const grandTotal = `${getSymbolFromCurrency(cartData.shop?.currency)} ${(
        +cartData.total +
        +cartData.shipping -
        +cartData.discount +
        +cartData.convenienceFee
      )?.toFixed(2)}`;
      return (
        <OrderSummaryCompact grandTotal={grandTotal}>
          <CartSummary
            currency={getSymbolFromCurrency(cartData.shop?.currency) ?? ""}
            cartTotal={cartData.total}
            convenienceFee={cartData.convenienceFee}
            discount={cartData.discount}
            shippingFee={cartData.shipping}
            app_mode={app_mode}
          />
        </OrderSummaryCompact>
      );
    } else {
      return (
        <CartSummary
          currency={getSymbolFromCurrency(cartData.shop?.currency) ?? ""}
          cartTotal={cartData.total}
          convenienceFee={cartData.convenienceFee}
          discount={cartData.discount}
          shippingFee={cartData.shipping}
          app_mode={app_mode}
        />
      );
    }
  };

  return (
    <div className="order-summary fast-checkout-page-segment">
      <h2 className="heading-2-regular fast-checkout-segment-heading">
        {app_mode === "fastcheckout" ? t("CartSummary") : t("OrderSummary")}
      </h2>
      {renderCartSummaryLogic()}
    </div>
  );
};

export default FastCheckoutOrderSummarySegment;
