import { setToastMessage } from "../../store/reducers/appSlice";
import store from "../../store/store";
import { CREATE_ADUP_ACCOUNT_URL } from "../constants/urls";
import { toastHandler } from "../utils/toast-handler";

/**
 * @param { string } cartId The cartId of the payment
 */

export async function createAdUpAccount(cartId: string) {
  const key = store.getState().users.currentUser.token;

  const response = await fetch(CREATE_ADUP_ACCOUNT_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "X-API-Key": key,
      Authorization: key ? `Bearer ${key}` : "",
    },
    body: JSON.stringify({
      cart_id: cartId,
    }),
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  return res;
}
