import "../../sass/components/socialCommerce/linkBannerSC.scss";
import arrow from "../../assets/svg/arrowDark.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  title: string;
  profilePage?: boolean;
  onClick: () => void;
}

const LinkBannerSC = ({ title, onClick, profilePage }: Props) => {
  return (
    <div className={`linkBannerSC ${profilePage ? "no-padding" : ""}`}>
      <div className="linkBannerSC__left">
        <LazyLoadImage
          loading="lazy"
          onClick={onClick}
          src={arrow}
          alt="arrow"
        />
        <h1 onClick={onClick}>{title}</h1>
      </div>
      <div className="linkBannerSC__right"></div>
    </div>
  );
};

export default LinkBannerSC;
