const executeScrollOn = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    console.log("Scroll Into View Function Running");
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  } else {
    console.warn(`Element with ID '${elementId}' not found in the DOM.`);
  }
};

/* // Usage with element ID
  executeScrollOn('elementId'); */

export default executeScrollOn;
