import React, { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { clearToastMessage } from "../../store/reducers/appSlice";
import errorIcon from "../../assets/svg/error.svg";
import successIcon from "../../assets/svg/success.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

export interface IToast {
  text: string;
  type: "ERROR" | "SUCCESS" | "INFO" | "";
}

type Props = IToast;

const BasicToastNotification: React.FC<Props> = ({ text, type }) => {
  const [isVisible, setIsVisible] = useState<Boolean>(true);
  const dispatch = useDispatch();
  setTimeout(() => {
    setIsVisible(false);
    dispatch(clearToastMessage(null));
  }, 6000);
  return isVisible ? (
    <motion.div
      animate={{ y: 0 }}
      initial={{ y: -500 }}
      transition={{ duration: 0.2 }}
      exit={{ y: -500 }}
      className={`toast-body-${type} toast-body`}
    >
      <div className="toast-body-left">
        <p>{type}</p>
        <span>{text}</span>
      </div>
      <div className="toast-body-right">
        <LazyLoadImage
          loading="lazy"
          src={type === "SUCCESS" ? successIcon : errorIcon}
          alt="toast icon"
        />
      </div>
    </motion.div>
  ) : null;
};

export default BasicToastNotification;
