import { AnimatePresence } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import BasicToastNotification, { IToast } from "./BasicToastNotification";

type Props = IToast;

const ToastContainer: React.FC<Props> = (toast: IToast) => {
  const { t } = useTranslation();

  return (
    <div className="toast-container">
      <AnimatePresence>
        <BasicToastNotification text={t(toast.text)} type={toast.type} />
      </AnimatePresence>
    </div>
  );
};

export default ToastContainer;
