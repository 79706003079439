import { debounce } from "lodash";
import {
  AdupUserAddress,
  EmptyAdupUserAddress,
  ShippingRate,
} from "../../../interfaces/AdupUserAddress";
import {
  setGrandTotalBeforeConvenienceFee,
  setShippingCost,
} from "../../../store/reducers/cartSlice";
import { SelectedPaymentMethodSavedStatus } from "../../../store/reducers/paymentSlice";
import { verificationStates } from "../../../store/reducers/userSlice";
import store, { RootState } from "../../../store/store";
import { updatePaymentIntentValues } from "../../api/address/update-payment-intent-values";
import { fetchShippingCost } from "../../api/calculate-shipping";

/**
 * Intent should be re-fetched when addresses are edited
 */
function selectShippingAddress(state: RootState) {
  return state.users?.currentUser?.shipping?.shippingAddress;
}
function selectBillingAddress(state: RootState) {
  return state.users?.currentUser?.shipping?.billingAddress;
}
function selectIsBillingShipping(state: RootState) {
  return state.users?.currentUser?.shipping?.isBillingShipping;
}
function getUserVerificationState(state: RootState) {
  return state.users?.currentUser?.verified;
}
function getCartTotal(state: RootState) {
  return state.cart?.total;
}
function getShop(state: RootState) {
  return state.cart?.shop;
}

let currentShippingAddress: AdupUserAddress | undefined = EmptyAdupUserAddress;
let currentBillingAddress: AdupUserAddress | undefined = EmptyAdupUserAddress;
let isBillingShipping: any = true;
let currentCartTotal: any = 0;
async function handleAddressChange() {
  try {
    const isUserVerified = getUserVerificationState(store.getState());
    const shopId: any = getShop(store.getState())?.id;
    let previousShippingAddress = currentShippingAddress;
    let previousBillingAddress = currentBillingAddress;
    let previousIsBillingShipping = isBillingShipping;
    let previousCartTotal = currentCartTotal;
    currentShippingAddress = selectShippingAddress(store.getState());
    currentBillingAddress = selectBillingAddress(store.getState());
    isBillingShipping = selectIsBillingShipping(store.getState());
    currentCartTotal =
      getCartTotal(store.getState()) &&
      !Number.isNaN(+getCartTotal(store.getState()))
        ? +getCartTotal(store.getState())
        : 0;
    const shopSettings: any = getShop(store.getState())?.settings;
    const minFreeShipping =
      shopSettings?.min_free_shipping &&
      !Number.isNaN(+shopSettings?.min_free_shipping)
        ? +shopSettings?.min_free_shipping
        : 0;
    const { selectedPaymentMethodSaveState } = store.getState().payment;
    const discount =
      store.getState().cart?.discount &&
      !Number.isNaN(+store.getState().cart?.discount)
        ? +store.getState().cart?.discount
        : 0;
    let paymentMethodType = "";

    if (
      selectedPaymentMethodSaveState ===
      SelectedPaymentMethodSavedStatus.SAVED_METHOD
    ) {
      paymentMethodType =
        store.getState().payment.savedPaymentMethodSelected.type;
    } else {
      paymentMethodType =
        store.getState().payment.unsavedPaymentMethodSelected.type;
    }
    if (previousIsBillingShipping !== isBillingShipping) {
      console.log(
        "Billing Address is same as Shipping Address",
        isBillingShipping
      );
    }
    if (previousShippingAddress !== currentShippingAddress) {
      console.log(
        "Shipping Addresses changed from",
        previousShippingAddress,
        "to",
        currentShippingAddress
      );
      if (previousBillingAddress !== currentBillingAddress) {
        console.log(
          "Billing Addresses changed from",
          previousBillingAddress,
          "to",
          currentBillingAddress
        );
      }
      if (isUserVerified?.state === verificationStates.verified) {
        console.log("Logged IN User - Shipping Calculator");
        if (
          previousShippingAddress?.shipping_rate !==
          currentShippingAddress?.shipping_rate
        ) {
          console.log(
            "Shipping Rate changed from",
            previousShippingAddress?.shipping_rate,
            "to",
            currentShippingAddress?.shipping_rate
          );
          if (
            minFreeShipping &&
            +minFreeShipping > 0 &&
            +currentCartTotal - +discount >= +minFreeShipping
          ) {
            console.log("FREE Shipping enabled");
            activateFreeShippingRate(currentCartTotal, discount);
          } else {
            if (
              !shopSettings?.fixed_shipping ||
              shopSettings?.fixed_shipping === "false"
            ) {
              console.log("ADDRESS BASED Shipping enabled");
              calculateAddressBasedShippingRate(
                currentCartTotal,
                currentShippingAddress?.shipping_rate,
                shopSettings?.default_shipping_cost,
                discount
              );
            } else {
              console.log("FIXED Shipping enabled");
              calculateFixedShippingRate(
                currentCartTotal,
                shopSettings?.default_shipping_cost,
                discount
              );
            }
          }
        }
      } else {
        console.log("NEW User - Shipping Calculator");
        if (
          previousShippingAddress?.country !== currentShippingAddress?.country
        ) {
          if (
            currentShippingAddress &&
            currentShippingAddress?.country !== ""
          ) {
            console.log(
              "Shipping Address Country changed from",
              previousShippingAddress?.country,
              "to",
              currentShippingAddress?.country
            );
            if (
              minFreeShipping &&
              +minFreeShipping > 0 &&
              +currentCartTotal - +discount >= +minFreeShipping
            ) {
              console.log("FREE Shipping enabled");
              activateFreeShippingRate(currentCartTotal, discount);
            } else {
              if (
                !shopSettings?.fixed_shipping ||
                shopSettings?.fixed_shipping === "false"
              ) {
                console.log("ADDRESS BASED Shipping enabled");
                console.log("NEW User Shipping cost fetching from server...");
                const res = await fetchShippingCost(
                  shopId,
                  currentShippingAddress
                );
                if (res) {
                  const shippingRate = {
                    rate_type: res[0]?.shipping_cost_type,
                    rate: res[0]?.shipping_cost,
                  };
                  console.log(
                    "Shipping Cost fetched from server : ",
                    shippingRate
                  );
                  calculateAddressBasedShippingRate(
                    currentCartTotal,
                    shippingRate,
                    shopSettings?.default_shipping_cost,
                    discount
                  );
                } else {
                  console.log(
                    "Shipping Cost fetching from server Error occurred"
                  );
                  console.log(
                    "Fallback : Re-calculating fixed shipping rate..."
                  );
                  calculateFixedShippingRate(
                    currentCartTotal,
                    shopSettings?.default_shipping_cost,
                    discount
                  );
                }
              } else {
                console.log("FIXED Shipping enabled");
                calculateFixedShippingRate(
                  currentCartTotal,
                  shopSettings?.default_shipping_cost,
                  discount
                );
              }
            }
          } else {
            console.log("No Shipping address country selected");
            console.log("Fallback : Re-calculating fixed shipping rate...");
            calculateFixedShippingRate(
              currentCartTotal,
              shopSettings?.default_shipping_cost,
              discount
            );
          }
        }
      }
      if (paymentMethodType && paymentMethodType !== "") {
        sessionCallDebounce();
      }
    } else if (previousBillingAddress !== currentBillingAddress) {
      console.log(
        "Billing Addresses changed from",
        previousBillingAddress,
        "to",
        currentBillingAddress
      );
      if (paymentMethodType && paymentMethodType !== "") {
        sessionCallDebounce();
      }
    }
    if (previousCartTotal !== currentCartTotal) {
      console.log(
        "Cart Total changed from",
        previousCartTotal,
        "to",
        currentCartTotal
      );
      if (
        minFreeShipping &&
        +minFreeShipping > 0 &&
        +currentCartTotal - +discount >= +minFreeShipping
      ) {
        console.log("FREE Shipping enabled");
        activateFreeShippingRate(currentCartTotal, discount);
      } else {
        if (
          !shopSettings?.fixed_shipping ||
          shopSettings?.fixed_shipping === "false"
        ) {
          console.log("ADDRESS BASED Shipping enabled");
          if (isUserVerified?.state === verificationStates.verified) {
            console.log("Logged IN User - Shipping Calculator");
            calculateAddressBasedShippingRate(
              currentCartTotal,
              currentShippingAddress?.shipping_rate,
              shopSettings?.default_shipping_cost,
              discount
            );
          } else {
            console.log("NEW User - Shipping Calculator");
            if (
              currentShippingAddress &&
              currentShippingAddress?.country !== ""
            ) {
              console.log("NEW User Shipping cost fetching from server...");
              const res = await fetchShippingCost(
                shopId,
                currentShippingAddress
              );
              if (res) {
                const shippingRate = {
                  rate_type: res[0]?.shipping_cost_type,
                  rate: res[0]?.shipping_cost,
                };
                console.log(
                  "Shipping Cost fetched from server : ",
                  shippingRate
                );
                calculateAddressBasedShippingRate(
                  currentCartTotal,
                  shippingRate,
                  shopSettings?.default_shipping_cost,
                  discount
                );
              } else {
                console.log(
                  "Shipping Cost fetching from server Error occurred"
                );
                console.log("Fallback : Re-calculating fixed shipping rate...");
                calculateFixedShippingRate(
                  currentCartTotal,
                  shopSettings?.default_shipping_cost,
                  discount
                );
              }
            } else {
              console.log("No Shipping address country selected");
              console.log("Fallback : Re-calculating fixed shipping rate...");
              calculateFixedShippingRate(
                currentCartTotal,
                shopSettings?.default_shipping_cost,
                discount
              );
            }
          }
        } else {
          console.log("FIXED Shipping enabled");
          calculateFixedShippingRate(
            currentCartTotal,
            shopSettings?.default_shipping_cost,
            discount
          );
        }
      }
    }
  } catch (error) {
    console.log("Error : ", error);
  }
}

function calculateAddressBasedShippingRate(
  cartTotal: number,
  shippingRate: ShippingRate | undefined,
  defaultShippingCost: number,
  discount: number
) {
  let type = shippingRate?.rate_type;
  let rate =
    shippingRate?.rate && !Number.isNaN(+shippingRate?.rate)
      ? +shippingRate?.rate
      : 0;
  let newShippingRate = 0.0;
  let newGrandTotal;

  console.log("Re-calculating address based shipping rate...");

  if (type && rate) {
    if (type === "percentage") {
      newShippingRate = cartTotal * (rate / 100);
    } else if (type === "flat") {
      newShippingRate = rate;
    }
  } else {
    console.log("No shipping rate found for selected address...");
    console.log("Re-calculating fixed shipping rate...");
    newShippingRate =
      defaultShippingCost && !Number.isNaN(+defaultShippingCost)
        ? +defaultShippingCost
        : 0.0;
  }

  console.log("Calculated new ShippingRate : ", newShippingRate);
  store.dispatch(setShippingCost(+newShippingRate));
  console.log("Calculated new cartTotal : ", cartTotal);
  newGrandTotal = (+cartTotal + +newShippingRate - discount).toFixed(2);
  store.dispatch(setGrandTotalBeforeConvenienceFee(+newGrandTotal));
}

function calculateFixedShippingRate(
  cartTotal: number,
  defaultShippingCost: number,
  discount: number
) {
  let newShippingRate = 0.0;
  let newGrandTotal;

  console.log("Re-calculating fixed shipping rate...");

  newShippingRate =
    defaultShippingCost && !Number.isNaN(+defaultShippingCost)
      ? +defaultShippingCost
      : 0.0;

  console.log("Fixed ShippingRate : ", newShippingRate);
  store.dispatch(setShippingCost(+newShippingRate));
  console.log("Calculated new cartTotal : ", cartTotal);
  newGrandTotal = (+cartTotal + +newShippingRate - discount).toFixed(2);
  store.dispatch(setGrandTotalBeforeConvenienceFee(+newGrandTotal));
}

function activateFreeShippingRate(cartTotal: number, discount: number) {
  let newShippingRate = 0.0;
  let newGrandTotal;

  store.dispatch(setShippingCost(+newShippingRate));
  console.log("Calculated new cartTotal : ", cartTotal);
  newGrandTotal = (+cartTotal - discount).toFixed(2);
  store.dispatch(setGrandTotalBeforeConvenienceFee(+newGrandTotal));
}

const sessionCallDebounce = debounce(() => {
  updatePaymentIntentValues();
}, 400);

export const unsubscribeHandleAddressChange =
  store.subscribe(handleAddressChange);
// unsubscribe();
