import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setBillingIsShipping } from "../../store/reducers/userSlice";
import { RootState } from "../../store/store";
import FakeCheckbox from "../checkboxes/FakeCheckbox";

/**
 * toggles "Billing address is the same as shipping address"
 */
const BillingAddressToggle = () => {
  const { t } = useTranslation();

  const isSame = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.isBillingShipping
  );
  const dispatch = useDispatch();

  return (
    <div className="billing-is-shipping">
      <div
        className="billing-is-shipping-content"
        onClick={() => dispatch(setBillingIsShipping(isSame ? !isSame : true))}
        style={{ cursor: "pointer" }}
      >
        <FakeCheckbox
          onClick={() =>
            dispatch(setBillingIsShipping(isSame ? !isSame : true))
          }
          checked={isSame ?? true}
        />
        &nbsp; &nbsp;
        <div className="billing-is-shipping-content-text">
          {t("BillingAddressIsSameAsShipping")}
        </div>
      </div>
    </div>
  );
};

export default BillingAddressToggle;
