import React from "react";

interface Props {
  isLastItemOnCart: boolean;
  currentAmount: number;
  increase: (event: React.MouseEvent) => void;
  decrease: (event: React.MouseEvent) => void;
  isDisabled: boolean;
}

export const InputAmount: React.FC<Props> = ({
  isLastItemOnCart,
  decrease,
  currentAmount,
  increase,
  isDisabled,
}) => {
  return (
    <div className="input-amount-with-controls">
      <button
        disabled={isLastItemOnCart || isDisabled}
        style={isLastItemOnCart ? { backgroundColor: "#D3D3D3" } : {}}
        onClick={decrease}
      >
        -
      </button>
      <span>{currentAmount}</span>
      <button disabled={isDisabled} onClick={increase}>
        +
      </button>
    </div>
  );
};
