import React, { Fragment, useEffect, useState } from "react";
import HeadingWithRightButton from "../../components/heading/HeadingWithRightButton";
import { APP_ENV } from "../../lib/constants/urls";
import AnimatedPayButtonBlack from "../../pages/fast-checkout/subcomponents/AnimatedPayButtonBlack";
import {
  setIsPayButtonLoading,
  setToastMessage,
} from "../../store/reducers/appSlice";
import "./mollie-card-container.scss";
import store from "../../store/store";
import internationalization from "../../internationalization/i18n";
import { handlePaymentRequest } from "../../lib/api/payment-post-request";
import { setEncryptedMollieCardToken } from "../../store/reducers/paymentSlice";
import { verificationStates } from "../../store/reducers/userSlice";
import { useTranslation } from "react-i18next";
import loadMollieScript from "./LoadMollieScript";
import executeScrollOn from "../../lib/utils/executeScrollOn";

declare const window: any;
export interface CardDetails {
  cvc: string;
  expiry: string;
  name: string;
  number: string;
  issuer: any;
  focus: undefined;
}

const MollieContainer: React.FC<any> = ({ MOLLIE_PROFILE_ID }) => {
  const { t } = useTranslation();
  const [isMollieScriptLoaded, setIsMollieScriptLoaded] = useState(false);
  const { profile, accountPhone } = store.getState().users.currentUser;
  const isWalletPaymentMethodInUse =
    store.getState().payment?.isWalletPaymentMethodInUse;
  const verificationState = store.getState().users.currentUser.verified;
  const countryCode = accountPhone?.country?.countryCode;
  const language = internationalization.language;
  const [isErrorOccurred, setIsErrorOccurred] = useState<boolean>(false);
  const [isMollieCardComponentLoaded, setIsMollieCardComponentLoaded] =
    useState(false);

  useEffect(() => {
    loadMollieScript(() => {
      setIsMollieScriptLoaded(true);
    });
  });

  useEffect(() => {
    // executeScrollOnFastCheckoutPaymentMount();
    executeScrollOn("fastCheckoutPaymentMountRef001");
  }, []);

  var form: any;
  var submitButton: any;
  var cardHolder: any;
  var cardNumber: any;
  var expiryDate: any;
  var verificationCode: any;
  var cardHolderError: any;
  var cardNumberError: any;
  var expiryDateError: any;
  var verificationCodeError: any;

  useEffect(() => {
    if (profile?.name?.first) {
      console.log("locale", `${language}_${countryCode}`);
      if (isMollieScriptLoaded && window.Mollie) {
        try {
          var mollie = window.Mollie(MOLLIE_PROFILE_ID, {
            locale: `${language}_${countryCode}`,
            testmode: APP_ENV === "DEV" ? true : false,
          });

          var options = {
            styles: {
              base: {
                color: "rgba(0, 0, 0, 0.8)",
              },
            },
          };

          /**
           * Get elements
           */
          form = document.getElementById("mcForm");

          submitButton = document.getElementById("submit-button");

          /**
           * Create card holder input
           */
          cardHolder = mollie.createComponent("cardHolder", options);
          cardHolder.mount("#card-holder");

          cardHolderError = document.getElementById("card-holder-error");

          cardHolder.addEventListener("change", function (event: any) {
            if (event.error && event.touched) {
              cardHolderError!.textContent = event.error;
            } else {
              cardHolderError!.textContent = "";
            }
          });

          /**
           * Create card number input
           */
          cardNumber = mollie.createComponent("cardNumber", options);
          cardNumber.mount("#card-number");

          cardNumberError = document.getElementById("card-number-error");

          cardNumber.addEventListener("change", function (event: any) {
            if (event.error && event.touched) {
              cardNumberError!.textContent = event.error;
            } else {
              cardNumberError!.textContent = "";
            }
          });

          /**
           * Create expiry date input
           */
          expiryDate = mollie.createComponent("expiryDate", options);
          expiryDate.mount("#expiry-date");

          expiryDateError = document.getElementById("expiry-date-error");

          expiryDate.addEventListener("change", function (event: any) {
            if (event.error && event.touched) {
              expiryDateError!.textContent = event.error;
            } else {
              expiryDateError!.textContent = "";
            }
          });

          /**
           * Create verification code input
           */
          verificationCode = mollie.createComponent(
            "verificationCode",
            options
          );
          verificationCode.mount("#verification-code");

          verificationCodeError = document.getElementById(
            "verification-code-error"
          );

          verificationCode.addEventListener("change", function (event: any) {
            if (event.error && event.touched) {
              verificationCodeError!.textContent = event.error;
            } else {
              verificationCodeError!.textContent = "";
            }
          });

          //* waiting till mollie component is fully loaded to enable Pay Button
          setTimeout(() => {
            setIsMollieCardComponentLoaded(true);
          }, 1500);
        } catch (e: any) {
          console.log("error: ", e);
          setIsErrorOccurred(true);
          store.dispatch(
            setToastMessage({
              text: e ? e.toString() : t("UnexpectedError"),
              type: "ERROR",
            })
          );
        }
      }

      /**
       * Disables the form inputs and submit button
       */
      function disableForm() {
        try {
          submitButton.disabled = true;
        } catch (e: any) {
          console.log("error: ", e);
          store.dispatch(
            setToastMessage({
              text: t("UnexpectedError"),
              type: "ERROR",
            })
          );
        }
      }

      /**
       * Enables the form inputs and submit button
       */
      function enableForm() {
        try {
          submitButton.disabled = false;
        } catch (e: any) {
          console.log("error: ", e);
          store.dispatch(
            setToastMessage({
              text: t("UnexpectedError"),
              type: "ERROR",
            })
          );
        }
      }

      /**
       * Submit handler
       */
      try {
        if (form) {
          form.addEventListener("submit", function (event: any) {
            event.preventDefault();
            disableForm();
            store.dispatch(setIsPayButtonLoading(true));

            // Get a payment token
            mollie.createToken().then(function (result: any) {
              var token = result.token;
              var error = result.error;

              if (token) {
                enableForm();
                const tokenSetToState = store.dispatch(
                  setEncryptedMollieCardToken(token)
                );
                store.dispatch(setIsPayButtonLoading(false));
                if (tokenSetToState) {
                  handlePaymentRequest();
                } else {
                  console.log("Error when passing cardToken to state");
                }
              } else if (error) {
                enableForm();
                store.dispatch(setIsPayButtonLoading(false));
                store.dispatch(
                  setToastMessage({
                    text: error.message
                      ? error.message.toString()
                      : t("UnexpectedError4"),
                    type: "ERROR",
                  })
                );
              }
            });
          });
        }
      } catch (e: any) {
        console.log("error: ", e);
        enableForm();
        store.dispatch(setIsPayButtonLoading(false));
        store.dispatch(
          setToastMessage({
            text: t("UnexpectedError"),
            type: "ERROR",
          })
        );
      }
    }
  }, [profile, window.Mollie, isMollieScriptLoaded]);

  return (
    <>
      {profile?.name?.first &&
      !isErrorOccurred &&
      !isWalletPaymentMethodInUse ? (
        <>
          <HeadingWithRightButton
            headingText={t("PaymentDetailsTitle")}
            button={<Fragment></Fragment>}
          />

          <div id="PaymentForm">
            {!isMollieCardComponentLoaded && (
              <div className="PaymentForm__Spinner">{t("Loading")}</div>
            )}
            <form method="post" className="form" id="mcForm">
              <div className="form-fields">
                <div className="row">
                  <div className="form-group form-group--card-holder">
                    <label className="label" htmlFor="card-holder">
                      {t("NameOnCard")}
                    </label>
                    <div id="card-holder"></div>
                    <div
                      id="card-holder-error"
                      className="field-error"
                      role="alert"
                    ></div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group form-group--card-number">
                    <label className="label" htmlFor="card-number">
                      {t("CardNumber")}
                    </label>
                    <div id="card-number"></div>
                    <div
                      id="card-number-error"
                      className="field-error"
                      role="alert"
                    ></div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group form-group--expiry-date">
                    <label className="label" htmlFor="expiry-date">
                      {t("Expiration")}
                    </label>
                    <div id="expiry-date"></div>
                    <div
                      id="expiry-date-error"
                      className="field-error"
                      role="alert"
                    ></div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group form-group--verification-code">
                    <label className="label" htmlFor="verification-code">
                      CVC
                    </label>
                    <div id="verification-code"></div>
                    <div
                      id="verification-code-error"
                      className="field-error"
                      role="alert"
                    ></div>
                  </div>
                </div>
              </div>

              <button
                id="submit-button"
                className="submit-button"
                type="submit"
              >
                {verificationState?.state !== verificationStates.pending &&
                  isMollieCardComponentLoaded && <AnimatedPayButtonBlack />}
              </button>
            </form>
          </div>
        </>
      ) : null}
    </>
  );
};

export default MollieContainer;
