import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBillingAddress } from "../../store/reducers/userSlice";
import { RootState } from "../../store/store";
import { AddressDetails } from "../address-details-form/AddressDetails";

/**
 * List of all the saved addresses, for selecting a billing address
 */
const BillingAddressList: React.FC = () => {
  const dispatch = useDispatch();

  const addressState = useSelector(
    (state: RootState) => state.users.currentUser.addresses
  );

  const selectedBillingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.billingAddress
  );

  // when the current address is selected
  const changeSelectedAddressIndex = (key: number) => {
    if (addressState?.savedAddresses[key]) {
      dispatch(setBillingAddress(addressState?.savedAddresses[key]));
    }
  };

  return (
    <div className="shipping-address-list fast-checkout-page-segment">
      <div className="address-details-holder">
        {Object.keys(addressState.savedAddresses).map(
          (addressId: any, key: number) => (
            <AddressDetails
              index={key}
              address={addressState.savedAddresses[addressId]}
              retracted={true}
              isSelected={
                addressState.savedAddresses[addressId]?.id ===
                selectedBillingAddress?.id
              }
              key={key}
              onClick={() => changeSelectedAddressIndex(addressId)}
              changeListeners={[]}
            />
          )
        )}
      </div>
    </div>
  );
};

export default BillingAddressList;
