import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { AnimatedLoadingCircle } from "../../assets/svg/animated-svg-components/AnimatedLoadingCircle";
import store from "../../store/store";
import {
  webAuthnRegister,
  webAuthnRegisterVerify,
} from "../../lib/api/webauthn-methods";
import {
  setUserVerified,
  setUserWebAuthn,
  verificationStates,
} from "../../store/reducers/userSlice";
import updateStateFromShopperObject from "../../lib/utils/shopper-object-to-state";
import {
  applicationUserType,
  updateUserType,
} from "../../store/reducers/appSlice";
import { webauthnRegister } from "../../lib/webAuthn/webauthn-register";
import { WebAuthnTypes } from "../../lib/webAuthn/detect-webAuthn-type";
import touchIdLogo from "../../assets/svg/webAuthn/Touch ID.svg";
import faceIdLogo from "../../assets/svg/webAuthn/Face ID.svg";
import androidFingerPrintLogo from "../../assets/svg/webAuthn/Android Fingerprint.svg";
import androidFaceUnlockLogo from "../../assets/svg/webAuthn/Android Face.svg";
import biometricsLogo from "../../assets/svg/webAuthn/Biometrics.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";

interface RegisterWebAuthnProps {
  webAuthType: WebAuthnTypes;
  hideSegment: () => void;
}

const backdropOpacityAnimation = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
    delay: 0.5,
  },
  exit: {
    opacity: 0,
  },
};

const slideUpAnimation = {
  animate: {
    transform: "translateY(0px)",
  },
  initial: {
    transform: "translateY(1000px)",
  },
  exit: {
    transform: "translateY(1000px)",
  },
  transition: {
    delay: 0,
    duration: 0.5,
  },
};

export const RegisterWebAuthn: React.FC<RegisterWebAuthnProps> = ({
  webAuthType,
  hideSegment,
}) => {
  const [verifyingWebAuthn, setVerifyingWebAuthn] = useState(false);
  const [failedWebAuthn, setFailedWebAuthn] = useState(false);

  const { t } = useTranslation();

  const selfRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    selfRef.current.focus();
  }, []);

  async function triggerWebAuthn() {
    setFailedWebAuthn(false);
    setVerifyingWebAuthn(true);
    const result = await webAuthnRegister();
    if (result.challenge) {
      webauthnRegister(
        result.challenge,
        async function (success: any, data: any) {
          if (success) {
            const verifiedResult = await webAuthnRegisterVerify(
              data,
              result.challenge_id
            );
            const { shopper, success } = verifiedResult;
            if (success) {
              // set the state to verified user
              const currentState = store.getState().users.currentUser.verified;
              store.dispatch(
                setUserVerified({
                  ...currentState,
                  state: verificationStates.verified,
                  _: true,
                  loggedInMethod: "webauthn",
                  firstPayment: "pending",
                })
              );

              if (shopper) {
                updateStateFromShopperObject(shopper);
              }

              store.dispatch(
                updateUserType({ user: applicationUserType.OLD_USER })
              );

              store.dispatch(setUserWebAuthn(Boolean(1)));
              console.log("WebAuthn Successful");

              backdropOpacityAnimation.transition = {
                ...backdropOpacityAnimation.transition,
                delay: 0.5,
              };
              slideUpAnimation.transition = {
                ...slideUpAnimation.transition,
                delay: 0,
              };
              hideSegment();
            } else {
              console.log("WebAuthn Error");
              setFailedWebAuthn(true);
            }
          } else {
            console.log("WebAuthn Error");
            setFailedWebAuthn(true);

            backdropOpacityAnimation.transition = {
              ...backdropOpacityAnimation.transition,
              delay: 0.5,
            };
            slideUpAnimation.transition = {
              ...slideUpAnimation.transition,
              delay: 0,
            };
            hideSegment();
          }
        }
      );
    }
    setVerifyingWebAuthn(false);
  }

  return (
    <motion.div ref={selfRef} className="verify-phone-number" tabIndex={0}>
      <motion.div
        key="backdrop"
        className="backdrop"
        {...backdropOpacityAnimation}
      ></motion.div>
      <motion.div
        key="phonenumberprompt"
        {...slideUpAnimation}
        className="verify-phone-number-prompt"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <h1> {webAuthType} Authentication</h1>
        <LazyLoadImage
          loading="lazy"
          src={
            webAuthType === "Face Id"
              ? faceIdLogo
              : webAuthType === "Touch Id"
              ? touchIdLogo
              : webAuthType === "Fingerprint"
              ? androidFingerPrintLogo
              : webAuthType === "Face Unlock"
              ? androidFaceUnlockLogo
              : webAuthType === "Biometrics"
              ? biometricsLogo
              : ""
          }
          width={webAuthType === "Biometrics" ? 200 : 100}
          style={{ margin: 20 }}
        ></LazyLoadImage>
        <p>
          {t("WebAuthnText-1", {
            webAuthType,
          })}
        </p>
        <p>
          {t("WebAuthnText-2")} <br />
          <a href="">{t("LearnMore")}</a>
        </p>
        <div className="verification-methods">
          {failedWebAuthn && (
            <div
              style={{
                textAlign: "center",
                color: "red",
                paddingTop: "10px",
                fontSize: "15px",
              }}
            >
              {t("WebAuthnError")}
            </div>
          )}
          <div
            className="send-again-link"
            onClick={() => {
              if (!verifyingWebAuthn) {
                backdropOpacityAnimation.transition = {
                  ...backdropOpacityAnimation.transition,
                  delay: 0.5,
                };
                slideUpAnimation.transition = {
                  ...slideUpAnimation.transition,
                  delay: 0,
                };

                hideSegment();
              }
            }}
          >
            {t("RegisterLater")}
          </div>
          <button
            className={
              "btn-use-email" + (verifyingWebAuthn ? " lighten-button" : "")
            }
            onClick={() => {
              triggerWebAuthn();
            }}
            disabled={verifyingWebAuthn}
          >
            {verifyingWebAuthn ? (
              <div className="verifying-now-text">
                <AnimatedLoadingCircle />
                Registering &nbsp;
              </div>
            ) : failedWebAuthn ? (
              <div className="webAuthn-text"> {t("TryAgain")}</div>
            ) : (
              <div className="webAuthn-text">
                {t("Register")} {webAuthType}
              </div>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};
