import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import AICheckoutLayout1 from "../../layout/layout1/AICheckoutLayout1";

const SocialCommercePage = () => {
  const app_layout: any = useSelector(
    (state: RootState) => state.cart.shop.theme?.app_layout
  );

  const renderLayout = () => {
    if (app_layout === "1") {
      return <AICheckoutLayout1 />;
    } else {
      return <AICheckoutLayout1 />;
    }
  };

  return renderLayout();
};

export default SocialCommercePage;
