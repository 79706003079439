function getFaviconEl() {
  return document.getElementById("favicon");
}
function getThemeColorEl() {
  return document.getElementById("theme-color");
}

export default function appThemeEngine(
  isInitAPILoading: boolean,
  imageStaticUrl: any,
  settings: any,
  merchantTheme: any
) {
  const root: any = document.documentElement;
  const favicon: any = getFaviconEl(); // Accessing favicon element
  const themeColor: any = getThemeColorEl(); // Accessing header theme-color element
  const defaultFont = `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;`;

  //setting theme color content
  if (themeColor !== null) {
    if (settings?.theme_color !== "" && settings?.theme_color) {
      themeColor.content = settings?.theme_color;
    } else {
      themeColor.content = "#000000";
    }
  }

  //setting theme color
  if (settings?.theme_color !== "" && settings?.theme_color) {
    root?.style.setProperty("--theme-color", settings?.theme_color);
  } else {
    root?.style.setProperty("--theme-color", "#000000");
  }

  //setting font color
  if (settings?.font_color !== "" && settings?.font_color) {
    root?.style.setProperty("--font-color", settings?.font_color);
  } else {
    root?.style.setProperty("--font-color", "#000000");
  }

  //setting font family
  if (
    merchantTheme?.font_family &&
    merchantTheme?.font_family !== "" &&
    merchantTheme?.font_family !== "null"
  ) {
    root?.style.setProperty("--main-font-primary", merchantTheme?.font_family);
  } else {
    root?.style.setProperty("--main-font-primary", defaultFont);
  }
  if (
    merchantTheme?.font_family_secondary &&
    merchantTheme?.font_family_secondary !== "" &&
    merchantTheme?.font_family_secondary !== "null"
  ) {
    root?.style.setProperty(
      "--main-font-secondary",
      merchantTheme?.font_family_secondary
    );
  } else {
    root?.style.setProperty("--main-font-secondary", defaultFont);
  }

  //setting background color
  if (isInitAPILoading) {
    root?.style.setProperty("--back-color", "#f6f6f6");
  } else if (settings?.background_color !== "" && settings?.background_color) {
    root?.style.setProperty("--back-color", settings?.background_color);
  } else {
    root?.style.setProperty("--back-color", "#f6f6f6");
  }

  //setting favicon
  if (favicon !== null) {
    if (
      settings?.company_icon &&
      settings?.company_icon !== "" &&
      settings?.company_icon !== "null"
    ) {
      favicon.href = `${imageStaticUrl}/medium/${settings?.company_icon}`;
    } else if (
      merchantTheme?.merchant_favicon &&
      merchantTheme?.merchant_favicon !== "" &&
      merchantTheme?.merchant_favicon !== "null"
    ) {
      favicon.href = `${imageStaticUrl}/medium/${merchantTheme?.merchant_favicon}`;
    } else {
      favicon.href = ``;
    }
  }

  //setting background image
  if (
    settings?.background_image &&
    settings?.background_image !== "" &&
    settings?.background_image !== "null"
  ) {
    root?.style.setProperty(
      "--back-image",
      isInitAPILoading
        ? "#ffffff"
        : `url('${imageStaticUrl}/medium/${settings?.background_image}')`
    );
    return `${imageStaticUrl}/medium/${settings?.background_image}`;
  } else {
    root?.style.setProperty("--back-image", "#ffffff");
    return undefined;
  }
}
