import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setCreateAccountFlag } from "../../store/reducers/paymentSlice";
import { RootState } from "../../store/store";
import FakeCheckbox from "../checkboxes/FakeCheckbox";
import { useNavigate } from "react-router-dom";

/**
 * toggles "Create Merchant account"
 */
const CreateAccountToggle = () => {
  const { t } = useTranslation();

  const isSame = useSelector(
    (state: RootState) => state.payment?.createAccountFlag
  );
  const merchantTheme = useSelector(
    (state: RootState) => state.users.merchant.config
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div className="createAdUpAccountToggle">
      <div
        className="createAdUpAccountToggle-content"
        onClick={() => dispatch(setCreateAccountFlag(isSame ? !isSame : true))}
        style={{ cursor: "pointer" }}
      >
        <FakeCheckbox
          onClick={() =>
            dispatch(setCreateAccountFlag(isSame ? !isSame : true))
          }
          checked={isSame ?? true}
        />
        &nbsp; &nbsp;
        <div className="createAdUpAccountToggle-content-text">
          {merchantTheme?.create_account_text ? (
            merchantTheme.create_account_text
          ) : (
            <>
              {t("CreateAdUpAccountToggleText1", {
                merchant_name: merchantTheme?.merchant_name
                  ? merchantTheme.merchant_name
                  : "",
              })}
              <a
                style={{ color: "#800080" }}
                onClick={() => {
                  navigate("/profile");
                }}
              >
                {" " + t("CreateAdUpAccountToggleText2")}
              </a>
              {" " + t("CreateAdUpAccountToggleText3")}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateAccountToggle;
