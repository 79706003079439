/*
//* Used to fetch mollie.js only when needed
*/

const loadMollieScript = (callback: () => void) => {
  const existingScript = document.getElementById("mollieJS");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://js.mollie.com/v1/mollie.js";
    script.id = "mollieJS";
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};

export default loadMollieScript;
