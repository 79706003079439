import { useEffect, useState } from "react";
import FastCheckoutShippingAddress from "../../fast-checkout/segments/FastCheckoutShippingAddress";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneForm from "../../../components/phone-form/PhoneForm";
import { AccountProfileForm } from "../../../components/account-profile-input-form/AccountProfileForm";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import ListNavigation from "../../../components/main-profile/ListNavigation";
import i18n from "../../../internationalization/i18n";
import HeaderProfile from "../../../components/header/HeaderProfile";

import { setToastMessage } from "../../../store/reducers/appSlice";
import store from "../../../store/store";
import DeleteButton from "../../../components/button/DeleteButton";
import HeadingWithRightButton from "../../../components/heading/HeadingWithRightButton";
import RoundButton from "../../../components/round-button/RoundButton";
import { deletePaymentAttributes } from "../../../lib/api/delete-payment-attributes";
import { deleteSavedPaymentAttributes } from "../../../store/reducers/paymentSlice";
import OrderList from "../../../components/main-profile/OrderList";
import { patchUserAccount } from "../../../lib/api/patch-user-account";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { editSVG } from "../../../assets/js/allCustomSVGs";
import { submitLog } from "../../../lib/api/log";

const SubViewPage: React.FC<any> = ({ userData, profile, linkName }) => {
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const fName = userData?.profile?.name?.first;
  const lName = userData?.profile?.name?.second;
  const userEmail = userData?.profile?.email;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const route = window.decodeURI(useLocation().pathname);
  const [selectedLanguage, setSelectedLanguage] = useState(
    i18n?.resolvedLanguage
  );
  const [isEditingIconsVisible, setIsEditingIconsVisible] = useState(false);

  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );
  const savedPaymentAttributes = useSelector(
    (state: RootState) => state.payment?.savedPaymentAttributes
  );
  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );

  const languageList = [
    {
      linkTitle: "English",
      key: "en",
      buttonType: "null",
      active: true,
      handlerFunction: () => {
        console.log("Language changed to : English");
        i18n.changeLanguage("en");
        setSelectedLanguage("en");
      },
    },
    {
      linkTitle: "Nederlands",
      key: "nl",
      buttonType: "null",
      active: false,
      handlerFunction: () => {
        console.log("Language changed to : Dutch");
        i18n.changeLanguage("nl");
        setSelectedLanguage("nl");
      },
    },
    {
      linkTitle: "Français",
      key: "fr",
      buttonType: "null",
      active: false,
      handlerFunction: () => {
        console.log("Language changed to : French");
        i18n.changeLanguage("fr");
        setSelectedLanguage("fr");
      },
    },
    {
      linkTitle: "Deutsch",
      key: "de",
      buttonType: "null",
      active: false,
      handlerFunction: () => {
        console.log("Language changed to : German");
        i18n.changeLanguage("de");
        setSelectedLanguage("de");
      },
    },
    {
      linkTitle: "Italiano",
      key: "it",
      buttonType: "null",
      active: false,
      handlerFunction: () => {
        console.log("Language changed to : Italian");
        i18n.changeLanguage("it");
        setSelectedLanguage("it");
      },
    },
    {
      linkTitle: "Española",
      key: "es",
      buttonType: "null",
      active: false,
      handlerFunction: () => {
        console.log("Language changed to : Spanish");
        i18n.changeLanguage("es");
        setSelectedLanguage("es");
      },
    },
    {
      linkTitle: "Português",
      key: "pt",
      buttonType: "null",
      active: false,
      handlerFunction: () => {
        console.log("Language changed to : Portuguese");
        i18n.changeLanguage("pt");
        setSelectedLanguage("pt");
      },
    },
  ];

  useEffect(() => {
    document.title = `Profile - ${fName} ${lName} - Powered by ${merchantTheme?.merchant_name}`;
  }, []);

  useEffect(() => {
    setIsEditingIconsVisible(false);
  }, [route]);

  //* API handlers --- START

  const deleteUserAccountHandler = async (attributes: []) => {
    try {
      for (let i = 0; i < attributes.length; i++) {
        const res = await deletePaymentAttributes(attributes[i]);
        console.log(res);
        store.dispatch(deleteSavedPaymentAttributes(attributes[i]));
      }
    } catch (error) {
      console.log("Delete Payment Attribute API Error :", error);
      await submitLog("error", t("DeletePaymentDetailError"));
      store.dispatch(
        setToastMessage({
          text: t("DeletePaymentDetailError"),
          type: "ERROR",
        })
      );
    }
  };
  const updateAccountDetailsHandler = async () => {
    try {
      const updatedAccountData = {
        first_name: fName ?? undefined,
        last_name: lName ?? undefined,
        email: userEmail ?? undefined,
      };
      await patchUserAccount(updatedAccountData, t("UpdateAccountSuccess"));
    } catch (error) {
      console.log("Update account details API Error :", error);
    }
  };
  const updateLanguageHandler = async () => {
    try {
      const updatedAccountData = {
        language: selectedLanguage ?? undefined,
      };
      patchUserAccount(updatedAccountData, t("UpdateLanguageSuccess"));
    } catch (error) {
      console.log("Update account language API Error :", error);
    }
  };

  //* API handlers --- END

  //* Rendering components --- START

  const renderAccount = () => {
    return (
      <div className="listNavigation-main">
        <div className="listNavigation">
          <PhoneForm hideBtns={true} />
          <div style={{ marginTop: "1.7rem" }}></div>
          <AccountProfileForm />
          <div className="listNavigation__saveUserData">
            <h2 onClick={updateAccountDetailsHandler}>{t("UpdateAccount")}</h2>
          </div>
          {/* <ListNavigation
            list={[
              {
                linkTitle: `${t("UpdateAccount")}`,
                buttonType: "update",
                handlerFunction: updateAccountDetailsHandler,
              },
            ]}
          /> */}
        </div>
      </div>
    );
  };
  const renderAddresses = () => {
    return (
      <div className="listNavigation-main">
        <div className="listNavigation">
          <FastCheckoutShippingAddress profilePageAddress={true} />
        </div>
      </div>
    );
  };
  const renderDeleteButton = (attributes: any) => (
    <div style={{ paddingTop: 5 }}>
      <DeleteButton
        mainTitle={t("DeletePaymentDetails")}
        confirmDeleteMsg={t("RemoveSelectedPaymentDetail")}
        onClick={() => {
          deleteUserAccountHandler(attributes);
        }}
      />
    </div>
  );
  const renderPaymentDetails = () => {
    return (
      <div className="listNavigation-main">
        <div className="listNavigation">
          <HeadingWithRightButton
            headingText={t("SavedPaymentDetails")}
            button={
              <div className="headingWithRightButton__button--container">
                <RoundButton
                  color={"dark"}
                  icon={editSVG}
                  onClick={() =>
                    setIsEditingIconsVisible(!isEditingIconsVisible)
                  }
                  classString="round-button-with-edit"
                />
                {/* <SearchShippingAddressButton onClick={() => null} /> */}
              </div>
            }
          />
          {savedPaymentAttributes && (
            <div className="listNavigation__savedPaymentDetails">
              {/* <div className="payment-methods-list"> */}
              <br />
              {/* //? Need to add all available method attributes here 👇🏼 */}
              {savedPaymentAttributes?.ideal_bank && (
                <div
                  className={"new-payment-method-icon card-details-retracted "}
                >
                  <>
                    <LazyLoadImage
                      loading="lazy"
                      src={`${imageStaticUrl}/small/payment-method/ideal.svg`}
                      alt="ideal"
                    />
                    <span style={{ fontSize: 15 }}>
                      {savedPaymentAttributes?.ideal_bank}
                    </span>
                    {isEditingIconsVisible &&
                      renderDeleteButton(["ideal_bank"])}
                  </>
                </div>
              )}
              {savedPaymentAttributes?.p24_bank && (
                <div
                  className={"new-payment-method-icon card-details-retracted "}
                >
                  <>
                    <LazyLoadImage
                      loading="lazy"
                      src={`${imageStaticUrl}/small/payment-method/p24.svg`}
                      alt="p24"
                    />
                    <span style={{ fontSize: 15 }}>
                      {savedPaymentAttributes?.p24_bank}
                    </span>
                    {isEditingIconsVisible && renderDeleteButton(["p24_bank"])}
                  </>
                </div>
              )}
              {savedPaymentAttributes?.eps_bank && (
                <div
                  className={"new-payment-method-icon card-details-retracted "}
                >
                  <>
                    <LazyLoadImage
                      loading="lazy"
                      src={`${imageStaticUrl}/small/payment-method/eps.svg`}
                      alt="eps"
                    />
                    <span style={{ fontSize: 15 }}>
                      {savedPaymentAttributes?.eps_bank}
                    </span>
                    {isEditingIconsVisible && renderDeleteButton(["eps_bank"])}
                  </>
                </div>
              )}
              {savedPaymentAttributes?.sepa_debit_iban_number && (
                <div
                  className={"new-payment-method-icon card-details-retracted "}
                >
                  <>
                    <LazyLoadImage
                      loading="lazy"
                      src={`${imageStaticUrl}/small/payment-method/sepa.svg`}
                      alt="sepa"
                    />
                    <span style={{ fontSize: 15 }}>
                      IBAN : {savedPaymentAttributes?.sepa_debit_iban_number}
                    </span>
                    {isEditingIconsVisible &&
                      renderDeleteButton(["sepa_debit_iban_number"])}
                  </>
                </div>
              )}
              {(savedPaymentAttributes?.giropay_bic_number ||
                savedPaymentAttributes?.giropay_iban_number) && (
                <div
                  className={"new-payment-method-icon card-details-retracted "}
                >
                  <>
                    <LazyLoadImage
                      loading="lazy"
                      src={`${imageStaticUrl}/small/payment-method/giropay.svg`}
                      alt="giropay"
                    />
                    <span style={{ fontSize: 15 }}>
                      BIC : {savedPaymentAttributes?.giropay_bic_number}
                      <br />
                      IBAN : {savedPaymentAttributes?.giropay_iban_number}
                    </span>
                    {isEditingIconsVisible &&
                      renderDeleteButton([
                        "giropay_bic_number",
                        "giropay_iban_number",
                      ])}
                  </>
                </div>
              )}
            </div>
          )}
          {(!savedPaymentAttributes ||
            Object.keys(savedPaymentAttributes).length === 0) && (
            <h3>
              {t("SavedPaymentDetailsTitle-1")}
              <br />
              {t("SavedPaymentDetailsTitle-2")}
            </h3>
          )}
        </div>
      </div>
    );
  };
  const renderLanguage = () => {
    return (
      <div className="listNavigation-main">
        <div className="listNavigation">
          <ListNavigation
            title={t("Language")}
            list={languageList}
            selectedKey={selectedLanguage}
          />

          <div className="listNavigation__saveUserData">
            <h2 onClick={updateLanguageHandler}>{t("UpdateLanguage")}</h2>
          </div>
        </div>
      </div>
    );
  };

  //* Rendering components --- END

  return (
    <>
      <div className="main-wrapper">
        {/* <HeaderDynamic linkName={"Profile Sub View"} linkRoute={"/profile"} /> */}
        {activeLink !== "profile" && (
          <HeaderProfile backRoute={"/profile"} backRouteTitle={t("Profile")} />
        )}
        {/* <AccountDetails /> */}

        {/* <TermsAndConditions /> */}
        {activeLink === "profile/account" && renderAccount()}
        {activeLink === "profile/addresses" && renderAddresses()}
        {activeLink === "profile/orders" && <OrderList />}
        {activeLink === "profile/payments" && renderPaymentDetails()}
        {activeLink === "profile/language" && renderLanguage()}
      </div>
    </>
  );
};

export default SubViewPage;
