import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { AnimatedLoadingCircle } from "../../assets/svg/animated-svg-components/AnimatedLoadingCircle";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import {
  webAuthnLogin,
  webAuthnLoginVerify,
} from "../../lib/api/webauthn-methods";
import {
  setUserToken,
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import updateStateFromShopperObject from "../../lib/utils/shopper-object-to-state";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  initialHiddenSegments,
  updateUserType,
} from "../../store/reducers/appSlice";
import { webauthnLogin } from "../../lib/webAuthn/webauthn-login";
import { WebAuthnTypes } from "../../lib/webAuthn/detect-webAuthn-type";
import touchIdLogo from "../../assets/svg/webAuthn/Touch ID.gif";
import faceIdLogo from "../../assets/svg/webAuthn/Face ID.gif";
import androidFingerPrintLogo from "../../assets/svg/webAuthn/Android Fingerprint.svg";
import androidFaceUnlockLogo from "../../assets/svg/webAuthn/Android Face.svg";
import biometricsLogo from "../../assets/svg/webAuthn/Biometrics.png";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import updateStateFromPaymentMethodsBlock from "../../lib/utils/payment-methods-to-state";

interface LoginWebAuthnProps {
  webAuthType: WebAuthnTypes;
  hideSegment: () => void;
}

const backdropOpacityAnimation = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
    delay: 0.5,
  },
  exit: {
    opacity: 0,
  },
};

const slideUpAnimation = {
  animate: {
    transform: "translateY(0px)",
  },
  initial: {
    transform: "translateY(1000px)",
  },
  exit: {
    transform: "translateY(1000px)",
  },
  transition: {
    delay: 0,
    duration: 0.5,
  },
};

export const LoginWebAuthn: React.FC<LoginWebAuthnProps> = ({
  webAuthType,
  hideSegment,
}) => {
  const { t } = useTranslation();
  const [verifyingWebAuthn, setVerifyingWebAuthn] = useState(false);

  const phoneNumber = useSelector(
    (state: RootState) => state.users.currentUser.verified.otp.phone_number
  );
  const countryCode = useSelector(
    (state: RootState) => state.users.currentUser.verified.otp.country_code
  );

  const selfRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const currentState = store.getState().users.currentUser.verified;

  useEffect(() => {
    selfRef.current.focus();
  }, []);

  async function triggerWebAuthn() {
    setVerifyingWebAuthn(true);
    try {
      const result = await webAuthnLogin(phoneNumber, countryCode);
      if (result.challenge) {
        webauthnLogin(
          result.challenge,
          async function (success: any, data: any) {
            if (success) {
              const verifiedResult = await webAuthnLoginVerify(
                data,
                result.challenge_id
              );
              const { access_token, shopper, success } = verifiedResult;
              if (success) {
                if (access_token) {
                  // set the state to verified user
                  const currentState =
                    store.getState().users.currentUser.verified;
                  store.dispatch(
                    setUserVerified({
                      ...currentState,
                      state: verificationStates.verified,
                      _: true,
                      loggedInMethod: "webauthn",
                      firstPayment: "pending",
                    })
                  );
                  store.dispatch(setUserToken(access_token));

                  if (shopper) {
                    updateStateFromShopperObject(shopper);
                    updateStateFromPaymentMethodsBlock(shopper);
                    for (const segment of Object.keys(initialHiddenSegments)) {
                      store.dispatch(disableFastCheckoutUISegment(segment));
                    }

                    store.dispatch(
                      updateUserType({ user: applicationUserType.OLD_USER })
                    );
                  }
                }
                console.log("WebAuthn Successful");
              } else {
                hideSegment();
                // alert("error");
              }
            } else {
              hideSegment();
              // alert("error");
            }
          }
        );
      } else {
        setVerifyingWebAuthn(false);
        hideSegment();
      }
    } catch (error) {
      console.log("Error in webauthn Login endpoint", error);
      setVerifyingWebAuthn(false);
      hideSegment();
    }
    setVerifyingWebAuthn(false);
  }

  return (
    <motion.div ref={selfRef} className="verify-phone-number" tabIndex={0}>
      <motion.div
        key="backdrop"
        className="backdrop"
        {...backdropOpacityAnimation}
      ></motion.div>
      <motion.div
        key="phonenumberprompt"
        {...slideUpAnimation}
        className="verify-phone-number-prompt"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <h1>You have saved your details before</h1>
        <p>Verify your device and we will fill all your details for you.</p>

        <div className="verification-methods">
          <div
            className="send-again-link"
            onClick={() => {
              if (!verifyingWebAuthn) {
                backdropOpacityAnimation.transition = {
                  ...backdropOpacityAnimation.transition,
                  delay: 0.5,
                };
                slideUpAnimation.transition = {
                  ...slideUpAnimation.transition,
                  delay: 0,
                };

                hideSegment();
              }
            }}
          >
            Verify using SMS
          </div>
          <button
            className={
              "btn-use-email" + (verifyingWebAuthn ? " lighten-button" : "")
            }
            onClick={() => {
              triggerWebAuthn();
            }}
          >
            {(verifyingWebAuthn && (
              <div className="verifying-now-text">
                {" "}
                <AnimatedLoadingCircle />
                {t("Verifying")} &nbsp;
              </div>
            )) || (
              <div className="webAuthn-text">
                <LazyLoadImage
                  loading="lazy"
                  src={
                    webAuthType === "Face Id"
                      ? faceIdLogo
                      : webAuthType === "Touch Id"
                      ? touchIdLogo
                      : webAuthType === "Fingerprint"
                      ? androidFingerPrintLogo
                      : webAuthType === "Face Unlock"
                      ? androidFaceUnlockLogo
                      : webAuthType === "Biometrics"
                      ? biometricsLogo
                      : ""
                  }
                  width={
                    webAuthType === "Touch Id"
                      ? 75
                      : webAuthType === "Face Id" ||
                        webAuthType === "Biometrics"
                      ? 60
                      : 40
                  }
                  style={
                    webAuthType === "Fingerprint" ||
                    webAuthType === "Biometrics" ||
                    webAuthType === "Face Unlock"
                      ? { marginRight: 8 }
                      : {}
                  }
                ></LazyLoadImage>
                Verify using {webAuthType} &nbsp;
              </div>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};
