import "./switchButton.scss";

const SwitchButton = () => {
  return (
    <div className="switchButton">
      <label className="switch">
        <input type="checkbox" />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default SwitchButton;
