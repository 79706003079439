import React, { Fragment, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { CartView } from "../../../components/cart-view/CartView";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import HeadingWithRightButton from "../../../components/heading/HeadingWithRightButton";
import { useTranslation } from "react-i18next";
import editIcon from "../../../assets/svg/edit-icon.svg";
import deleteIcon from "../../../assets/svg/trash-icon.svg";
import {
  removeItem,
  setFastcheckoutCartItems,
} from "../../../store/reducers/cartSlice";
import { BackArrowBlack } from "../../../assets/svg/svg-components/back-arrow-black";
import DeleteCartItemMsg from "../../../components/delete-cart-item-message/DeleteCartItemMsg";
import { setToastMessage } from "../../../store/reducers/appSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import cartIcon from "../../../assets/svg/empty-cart.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useLocation } from "react-router-dom";

type EditCartButtonProps = {
  onClick: () => void;
};

type DeleteCartButtonProps = {
  onClick: () => void;
};

const EditCartButton: React.FC<EditCartButtonProps> = ({ onClick }) => {
  return (
    <Fragment>
      <button onClick={() => onClick()}>
        <LazyLoadImage loading="lazy" alt="edit cart" src={editIcon} />
      </button>
    </Fragment>
  );
};

const DeleteCartItemButton: React.FC<DeleteCartButtonProps> = ({ onClick }) => {
  return (
    <Fragment>
      <button onClick={() => onClick()}>
        <LazyLoadImage loading="lazy" alt="edit cart" src={deleteIcon} />
      </button>
    </Fragment>
  );
};

interface Props {
  isCompactCart?: boolean;
}

// const FastCheckoutPageCartSegment = React.forwardRef((props, ref) => {
const FastCheckoutPageCartSegment = ({ isCompactCart }: Props) => {
  const cartData = useSelector((state: RootState) => state.cart);
  const { appData } = useSelector((state: RootState) => state.app) as any;
  const imageStaticUrl = appData?.static_base_url;

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [isEditingCart, setIsEditingCart] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const onCancelHandler = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  const variants = {
    spreadDown: { display: "flex" },
  };

  const fastcheckoutCartItems: any =
    useSelector((state: RootState) => state.cart.fastcheckoutCartItems) ?? [];

  const cart: any = useSelector((state: RootState) => state.cart.items) ?? [];

  const deleteSelectedItems = () => {
    dispatch(removeItem({}));
    let tempData = fastcheckoutCartItems ?? [];
    for (let i = 0; i < cart.length; i++) {
      const item = cart[i];
      if (item?.selected) {
        if (
          fastcheckoutCartItems.filter((i: any) =>
            i.includes(`${item?.sku}`)
          )[0]
        ) {
          tempData = tempData.filter(
            (i: any) =>
              i !==
              fastcheckoutCartItems.filter((i: any) =>
                i.includes(`${item?.sku}`)
              )[0]
          );
        }
      }
    }
    dispatch(setFastcheckoutCartItems([...tempData]));
  };

  const [expanded, setIsExpanded] = useState(false);

  const [editOrDeleteButton, setEditOrDeleteButton] = useState(
    <EditCartButton
      onClick={() => {
        setIsEditingCart(!isEditingCart);
      }}
    />
  );

  const route = window.decodeURI(useLocation().pathname);
  const isFastcheckout = route.split("/")[1] === "fastcheckout" ? true : false;

  useEffect(() => {
    if (isEditingCart) {
      const isSelected = cartData?.items.some(function (item) {
        return item.selected === true;
      });
      if (!isFastcheckout) {
        setEditOrDeleteButton(
          <DeleteCartItemButton
            onClick={() =>
              isSelected
                ? setIsDeletePopupOpen(!isDeletePopupOpen)
                : dispatch(
                    setToastMessage({
                      text: t("CartRemoveError"),
                      type: "INFO",
                    })
                  )
            }
          />
        );
      } else {
        setEditOrDeleteButton(
          <EditCartButton
            onClick={() => {
              setIsEditingCart(!isEditingCart);
            }}
          />
        );
      }
    } else {
      setEditOrDeleteButton(
        <EditCartButton
          onClick={() => {
            setIsEditingCart(!isEditingCart);
          }}
        />
      );
    }
  }, [isEditingCart, cartData]);

  const closeCart = () => {
    setIsExpanded(false);
    setIsEditingCart(false);
  };

  return (
    <>
      <div className="fast-checkout-page-top ">
        <AnimatePresence>
          <HeadingWithRightButton
            headingText={t("Cart")}
            button={cartData.items.length > 1 ? editOrDeleteButton : <></>}
            closeCartFunction={isCompactCart ? () => {} : closeCart}
          >
            {expanded && cartData.items.length > 1 && !isCompactCart && (
              <div className="back-button" onClick={closeCart}>
                <BackArrowBlack />
              </div>
            )}
          </HeadingWithRightButton>
        </AnimatePresence>
        {cartData.items.length <= 0 && (
          <div className="order-cart-summary">
            <h3 style={{ margin: 40, textAlign: "center" }}>
              <LazyLoadImage
                loading="lazy"
                style={{ width: 30 }}
                src={cartIcon}
              ></LazyLoadImage>
              <br />
              Cart is empty
            </h3>
          </div>
        )}
        {cartData.items.length > 0 && (
          <motion.div
            variants={variants}
            className="cart-view-holder"
            animate={cartData.items.length > 1 ? "spreadDown" : ""}
            transition={{ duration: 10 }}
          >
            <CartView
              items={
                cartData.items.map &&
                cartData?.items?.map((item: any, key) => ({
                  id: item.id,
                  name: item.name,
                  key,
                  amount: item.amount,
                  price: item.price,
                  src: item.src,
                  currency: getSymbolFromCurrency(item.currency),
                  image: `${imageStaticUrl}/medium/${item.image}`,
                  sku: item?.sku,
                }))
              }
              stacked={cartData.items.length > 1}
              onExpand={
                cartData.items.length > 1
                  ? [(e) => setIsExpanded(e)]
                  : [(e) => closeCart()]
              }
              defaultExpanded={expanded}
              inEditMode={isEditingCart}
            />
          </motion.div>
        )}
      </div>
      {isDeletePopupOpen && (
        <DeleteCartItemMsg
          onDeleteClick={deleteSelectedItems}
          onCancelClick={onCancelHandler}
        />
      )}
    </>
  );
};

export default FastCheckoutPageCartSegment;
