import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { AdupUserProfile } from "../../interfaces/AdupUserProfile";
import {
  setShippingAddress,
  setUserProfile,
} from "../../store/reducers/userSlice";
import AnimatedTextFieldRegular from "../custom-input-field/AnimatedTextFieldRegular";
import { cssRedBorderClass } from "../constants";
import { useTranslation } from "react-i18next";
import { applicationUserType } from "../../store/reducers/appSlice";
import AddressFormWithSearch from "../custom-input-field/AddressFormWithSearch";
import { setIsRequiredFieldsNotFilled } from "../../store/reducers/paymentSlice";
import { EmptyAdupUserAddress } from "../../interfaces/AdupUserAddress";
import executeScrollOn from "../../lib/utils/executeScrollOn";

/**
 * Account (profile data) form containing phone, name and address input fields
 */

interface Props {
  isCompactUserName?: boolean;
}
// export const AccountProfileForm = React.forwardRef((props, ref) => {
export const AccountProfileForm = ({ isCompactUserName }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // username associated with the phone number above
  const currentUserProfile: AdupUserProfile = useSelector(
    (state: RootState) => state.users.currentUser.profile
  );

  const loggedIn = useSelector(
    (state: RootState) => state.users.currentUser.phoneId
  );
  // username associated with the phone number above
  const authState = useSelector((state: RootState) => state.app.auth);

  const updateCurrentUserFirstName = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        name: { ...currentUserProfile.name, first: newName },
      })
    );
  };
  const updateCurrentUserSecondName = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        name: { ...currentUserProfile.name, second: newName },
      })
    );
  };
  const updateCurrentEmail = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        email: newName,
      })
    );
  };

  const paymentStatus = useSelector(
    (state: RootState) => state.payment.paymentProgress
  );

  const hasApiError = useSelector(
    (state: RootState) => state.payment.hasApiError
  );

  useEffect(() => {
    if (hasApiError?.email) setIsEmailInvalid(true);
  }, [hasApiError]);

  // for setting a class if the input field is incomplete
  const [namesIncomplete, setNamesIncomplete] = useState<{
    first: boolean | null;
    second: boolean | null;
    email: boolean | null;
  }>({ first: null, second: null, email: null });

  // for setting a class if the email input is invalid
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);

  // Validate inputs on submitting for payment
  useEffect(() => {
    if (paymentStatus === "PAYMENT_STARTED") {
      if (currentUserProfile.name.first === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: true,
          second: namesIncomplete.second,
          email: namesIncomplete.email,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: false,
          second: namesIncomplete.second,
          email: namesIncomplete.email,
        }));
      }

      if (currentUserProfile.name.second === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: true,
          email: namesIncomplete.email,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: false,
          email: namesIncomplete.email,
        }));
      }

      if (currentUserProfile.email === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: namesIncomplete.second,
          email: true,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: namesIncomplete.second,
          email: false,
        }));
      }
    }
    if (paymentStatus === "PAYMENT_NOT_STARTED") {
      setNamesIncomplete({ first: null, second: null, email: null });
    }
  }, [
    paymentStatus,
    currentUserProfile.name?.first,
    currentUserProfile.name?.second,
    currentUserProfile.email,
  ]);

  useEffect(() => {
    if (
      namesIncomplete.first ||
      namesIncomplete.second ||
      namesIncomplete.email ||
      isEmailInvalid
    ) {
      // executeScrollOnProfileError();
      executeScrollOn("profileDetailsFormRef001");
      dispatch(setIsRequiredFieldsNotFilled(true));
    } else {
      dispatch(setIsRequiredFieldsNotFilled(false));
    }
  }, [namesIncomplete, isEmailInvalid]);

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const renderUserNames = () => {
    if (isCompactUserName && !loggedIn) {
      return (
        <div className="account-form-compact">
          <div className="account-form-compact-left">
            <AnimatedTextFieldRegular
              placeholder={
                t("FirstName") +
                (namesIncomplete.first ? ` (${t("Required")})` : "")
              }
              onChange={(e: any) =>
                updateCurrentUserFirstName((e.target as HTMLInputElement).value)
              }
              key={"fname"}
              name="first_name"
              value={currentUserProfile.name?.first}
              extraClasses={namesIncomplete.first ? cssRedBorderClass : ""}
            />
          </div>
          <div className="account-form-compact-right">
            <AnimatedTextFieldRegular
              placeholder={
                t("LastName") +
                (namesIncomplete.second ? ` (${t("Required")})` : "")
              }
              onChange={(e: any) =>
                updateCurrentUserSecondName(
                  (e.target as HTMLInputElement).value
                )
              }
              key={"sname"}
              name="last_name"
              value={currentUserProfile.name?.second}
              extraClasses={namesIncomplete.second ? cssRedBorderClass : ""}
            />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <AnimatedTextFieldRegular
            placeholder={
              t("FirstName") +
              (namesIncomplete.first ? ` (${t("Required")})` : "")
            }
            onChange={(e: any) =>
              updateCurrentUserFirstName((e.target as HTMLInputElement).value)
            }
            key={"fname"}
            name="first_name"
            value={currentUserProfile.name?.first}
            extraClasses={namesIncomplete.first ? cssRedBorderClass : ""}
          />
          <AnimatedTextFieldRegular
            placeholder={
              t("LastName") +
              (namesIncomplete.second ? ` (${t("Required")})` : "")
            }
            onChange={(e: any) =>
              updateCurrentUserSecondName((e.target as HTMLInputElement).value)
            }
            key={"sname"}
            name="last_name"
            value={currentUserProfile.name?.second}
            extraClasses={namesIncomplete.second ? cssRedBorderClass : ""}
          />
        </>
      );
    }
  };

  return (
    <div className="account-form fast-checkout-page-segment">
      <span>
        {renderUserNames()}
        <AnimatedTextFieldRegular
          isLastInput={true}
          placeholder={
            t("Email") +
            (namesIncomplete.email
              ? ` (${t("Required")})`
              : isEmailInvalid
              ? ` (${t("EmailInvalid")})`
              : "")
          }
          onChange={(e: any) => {
            if (validateEmail((e.target as HTMLInputElement).value)) {
              setIsEmailInvalid(false);
            } else {
              setIsEmailInvalid(true);
            }
            updateCurrentEmail((e.target as HTMLInputElement).value);
          }}
          key={"email"}
          name="email"
          value={currentUserProfile.email}
          extraClasses={
            namesIncomplete.email || isEmailInvalid ? cssRedBorderClass : ""
          }
        />
      </span>

      {authState.user === applicationUserType.NEW_USER && (
        <div style={{ marginBottom: "-0.3rem" }}>
          <AddressFormWithSearch
            addressType="shipping"
            changeListeners={[
              (address) => {
                if (address !== EmptyAdupUserAddress)
                  dispatch(setShippingAddress(address));
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};
