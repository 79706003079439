import { t } from "i18next";
import "../../sass/components/profilePage/footer.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const Footer = () => {
  const year = new Date().getFullYear();
  const merchantTheme = useSelector(
    (state: RootState) => state.users.merchant.config
  );

  return (
    <div className="footer">
      <a
        target={"_blank"}
        href={`${merchantTheme?.how_fastcheckout_works_url}`}
        rel="noreferrer"
      >
        <h2>
          {merchantTheme?.footer_text
            ? merchantTheme.footer_text
            : t("HowAdUpWorks", {
                merchant_name: merchantTheme?.merchant_name
                  ? merchantTheme.merchant_name
                  : "",
              })}
        </h2>
      </a>
      <span>
        {merchantTheme?.merchant_name ? merchantTheme.merchant_name : ""} &copy;{" "}
        {year}
      </span>
    </div>
  );
};

export default Footer;
