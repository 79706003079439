import "../../sass/components/socialCommerce/cartOperatorSC.scss";
import plusSVG from "../../assets/svg/plus-black-SVG.svg";
import minusSVG from "../../assets/svg/minus-black-SVG.svg";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

interface Props {
  onIncrement: (event: React.MouseEvent) => void;
  onDecrement: (event: React.MouseEvent) => void;
  cartItemNumber: number;
  onAddToCartClick: (event: React.MouseEvent) => void;
  isDisabled: boolean;
}

const CartOperatorSC = ({
  onIncrement,
  onDecrement,
  cartItemNumber,
  onAddToCartClick,
  isDisabled,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(setInteractionsPerSessionCount());
      }}
      className="cartOperatorSC"
    >
      <div className="cartOperatorSC--container">
        <div className="cartOperatorSC__left">
          <h2>
            <button onClick={onAddToCartClick}>{t("AddToCart")}</button>
          </h2>
        </div>
        <div className="cartOperatorSC__right">
          <button
            onClick={onDecrement}
            className="cartOperatorSC__right--minusBtn"
            disabled={cartItemNumber <= 1 || isDisabled}
            style={cartItemNumber <= 1 ? { backgroundColor: "#fff" } : {}}
          >
            <LazyLoadImage loading="lazy" src={minusSVG} alt="reduce" />
          </button>
          <p className="cartOperatorSC__right--number">{cartItemNumber}</p>
          <button
            onClick={onIncrement}
            className="cartOperatorSC__right--plusBtn"
            disabled={isDisabled}
          >
            <LazyLoadImage loading="lazy" src={plusSVG} alt="increase" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartOperatorSC;
