import { WEBAUTHN_URL } from "../constants/urls";
import store from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";

export async function webAuthnRegister() {
  const key = store.getState().users.currentUser.token;

  const response = await fetch(WEBAUTHN_URL + "/register", {
    method: "GET",
    headers: {
      Authorization: key ? `Bearer ${key}` : "",
    },
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;

  return result;
}

/**
 * @param { string } challenge_data
 * @param { string } challenge_id
 */
export async function webAuthnRegisterVerify(
  challenge_data: string,
  challenge_id: string
) {
  const key = store.getState().users.currentUser.token;

  const response = await fetch(WEBAUTHN_URL + "/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "X-API-Key": key,
      Authorization: key ? `Bearer ${key}` : "",
    },
    body: JSON.stringify({
      challenge_data,
      challenge_id,
    }),
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;

  return result;
}

export async function webAuthnLogin(
  phone_number: string,
  country_code: string
) {
  const key = store.getState().users.currentUser.token;
  const params = `?phone_number=${phone_number}&country_code=${country_code}`;

  const response = await fetch(WEBAUTHN_URL + "/login" + params, {
    method: "GET",
    headers: {
      Authorization: key ? `Bearer ${key}` : "",
    },
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;

  return result;
}

/**
 * @param { string } challenge_data
 * @param { string } challenge_id
 */
export async function webAuthnLoginVerify(
  challenge_data: string,
  challenge_id: string
) {
  const key = store.getState().users.currentUser.token;

  const response = await fetch(WEBAUTHN_URL + "/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "X-API-Key": key,
      Authorization: key ? `Bearer ${key}` : "",
    },
    body: JSON.stringify({
      challenge_data,
      challenge_id,
    }),
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;

  return result;
}
