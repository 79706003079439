import "../../sass/components/socialCommerce/cartBannerSC.scss";
import arrow from "../../assets/svg/arrowDark.svg";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  cartNumber: number;
  isHeartFilled: boolean;
  onCartClick: () => void;
  onHeartClick: () => void;
}

const CartBannerSC = ({
  cartNumber,
  isHeartFilled,
  onCartClick,
  onHeartClick,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="cartBannerSC">
      <div className="cartBannerSC__left">
        <LazyLoadImage
          loading="lazy"
          onClick={onCartClick}
          src={arrow}
          alt="arrow"
        />
        <h1 onClick={onCartClick}>{t("Cart")}</h1>
        <div className="cartBannerSC__left--number" onClick={onCartClick}>
          <span data-cartBannerSC-number={cartNumber > 9 ? "true" : "false"}>
            {cartNumber}
          </span>
        </div>
      </div>
      {/* // TODO: Add to Favorite feature - under development
      <div className="cartBannerSC__right">
        <div onClick={onHeartClick} className="cartBannerSC__right--heart">
          {!isHeartFilled && <img src={heartOutlinedSVG} alt="heart" />}
          {isHeartFilled && <img src={heartFilledSVG} alt="heart" />}
        </div>
      </div> */}
    </div>
  );
};

export default CartBannerSC;
