import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import OverviewPageLayout1 from "../../layout/layout1/OverviewPageLayout1";

const OverviewPage = () => {
  const app_layout: any = useSelector(
    (state: RootState) => state.cart.shop.theme?.app_layout
  );

  const renderLayout = () => {
    if (app_layout === "1") {
      return <OverviewPageLayout1 />;
    } else {
      return <OverviewPageLayout1 />;
    }
  };

  return renderLayout();
};

export default OverviewPage;
