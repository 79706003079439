/**
 * A saved address of an ADUP user
 */
export interface AdupUserAddress {
  id: string;
  number: string;
  street: string;
  street2: string;
  city: string;
  province: string;
  country: any;
  postcode: string;
  shipping_rate?: ShippingRate;
}

export interface ShippingRate {
  rate_type?: "flat" | "percentage";
  rate?: number;
}

/**
 * Empty Adup User Address
 */
export const EmptyAdupUserAddress: AdupUserAddress = {
  id: "",
  number: "",
  street: "",
  street2: "",
  city: "",
  province: "",
  country: "",
  postcode: "",
  shipping_rate: {},
};
