import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../store/store";
import CompactCart from "../compact-cart/CompactCart";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  hideCompactCart?: boolean;
  forceShowCompactCart?: boolean;
}

const Header = ({ hideCompactCart, forceShowCompactCart }: Props) => {
  const shopData: any = useSelector((state: RootState) => state.cart?.shop);
  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );
  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );

  const isLogo = () => {
    if (
      shopData?.settings?.company_logo &&
      shopData?.settings?.company_logo !== "" &&
      shopData?.settings?.company_logo !== "null"
    ) {
      return true;
    }

    return false;
  };

  const renderCompanyLogo = () => {
    if (isLogo()) {
      return (
        <div className="img__container">
          {!showCookieConsent && (
            <LazyLoadImage
              loading="lazy"
              src={`${imageStaticUrl}/medium/${shopData?.settings?.company_logo}`}
              alt=""
            />
          )}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const renderProfileIcon = () => {
    if (
      !settings?.fascheckout_ui ||
      settings?.fascheckout_ui === "null" ||
      settings?.fascheckout_ui === null
    ) {
      return (
        <Link to="/profile">
          <div className="img__container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 24 24"
            >
              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z" />
            </svg>
          </div>
        </Link>
      );
    }

    return <div />;
  };

  const showCompactCartLogic = () => {
    if (forceShowCompactCart) {
      return true;
    } else if (settings?.fascheckout_ui === "compact" && !hideCompactCart) {
      return true;
    } else {
      return false;
    }
  };

  const renderEmptyDiv = () => {
    if (settings?.fascheckout_ui !== "compact" || !forceShowCompactCart) {
      return <div></div>;
    } else if (settings?.fascheckout_ui === "compact" && hideCompactCart) {
      return <div></div>;
    }
  };

  return (
    <>
      <div data-is-header-fixed={settings?.setting} className="header">
        <div
          data-is-cart={showCompactCartLogic() ? "true" : "false"}
          className="header-max-width-container"
        >
          {renderCompanyLogo()}
          <div
            data-header-logo={isLogo()?.toString()}
            data-is-cart={showCompactCartLogic() ? "true" : "false"}
            className="text__container"
          >
            <h1> {shopData?.name} </h1>
          </div>

          {renderEmptyDiv()}

          {/* {renderProfileIcon()} */}
          {showCompactCartLogic() && <CompactCart />}
        </div>
      </div>
      <div data-is-header-fixed={settings?.setting} className="header-hidden" />
    </>
  );
};

export default Header;
