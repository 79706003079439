import { AnimatePresence } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../../store/store";
import { motion } from "framer-motion";
import { AnimatedWhiteArrowRight } from "../../../assets/svg/animated-svg-components/AnimatedWhiteArrow";
import {
  SelectedPaymentMethodSavedStatus,
  setPaymentProgress,
} from "../../../store/reducers/paymentSlice";
import {
  AdupPaymentMethodSelection,
  AdupSavedPaymentDetails,
} from "../../../interfaces/AdupPaymentMethod";
import { PaymentMethodConfigs } from "../../../lib/config/payment-methods-config";
import { useTranslation } from "react-i18next";
import { AnimatedLoadingCircleColored } from "../../../assets/svg/animated-svg-components/AnimatedLoadingCircle2";
import getSymbolFromCurrency from "currency-symbol-map";
import { setToastMessage } from "../../../store/reducers/appSlice";
import { getKeyByValue } from "../../../lib/utils/helper-functions";
import { useLocation } from "react-router-dom";
import {
  setInteractionsPerSessionCount,
  setPayButtonClickCount,
  setUIStatistics,
} from "../../../store/reducers/statisticsSlice";
import executeScrollOn from "../../../lib/utils/executeScrollOn";

const AnimatedPayButtonBlack: React.FC = () => {
  const isXXL =
    window.location.origin === "https://xxlnutrition.secure-checkout.eu"
      ? true
      : false;

  const cartData = useSelector((state: RootState) => state.cart);
  const route = window.decodeURI(useLocation().pathname);

  const { t } = useTranslation();

  const selectedPaymentMethodSaveState = useSelector(
    (state: RootState) => state.payment.selectedPaymentMethodSaveState
  );

  const unsavedPaymentMethodSelected = useSelector(
    (state: RootState) => state.payment.unsavedPaymentMethodSelected
  );

  const paymentErrorOccurred = useSelector(
    (state: RootState) => state.payment.errorOccurred
  );

  const [bankOptions, setBankOptions] = useState();

  const savedPaymentMethodSelected = useSelector(
    (state: RootState) => state.payment.savedPaymentMethodSelected
  );

  const isPayButtonLoading = useSelector(
    (state: RootState) => state.app.UI.isPayButtonLoading
  );

  const shippingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.shippingAddress
  );

  const phoneNumber = useSelector(
    (state: RootState) => state.users?.currentUser?.accountPhone?.number
  );

  const paymentState = useSelector((state: RootState) => state.payment);

  const [optionTitle, setOptionTitle] = useState("");

  const [paymentMethod, setPaymentMethod] = useState<
    AdupPaymentMethodSelection | AdupSavedPaymentDetails
  >(savedPaymentMethodSelected);

  useEffect(() => {
    if (
      selectedPaymentMethodSaveState ===
      SelectedPaymentMethodSavedStatus.SAVED_METHOD
    ) {
      setPaymentMethod(savedPaymentMethodSelected);
      setBankOptions(savedPaymentMethodSelected.banks);
    } else {
      setPaymentMethod(unsavedPaymentMethodSelected);
      setBankOptions(unsavedPaymentMethodSelected.banks);
    }
  }, [
    selectedPaymentMethodSaveState,
    unsavedPaymentMethodSelected,
    savedPaymentMethodSelected,
  ]);

  useEffect(() => {
    setBankOptions(paymentMethod?.banks);
  }, [paymentMethod]);

  useEffect(() => {
    if (bankOptions) {
      let caption = getKeyByValue(
        bankOptions,
        paymentState?.paymentMethodOptionSelected &&
          paymentState?.paymentMethodOptionSelected !== ""
          ? paymentState?.paymentMethodOptionSelected
          : bankOptions[Object.keys(bankOptions)[0]]
      );
      if (caption) setOptionTitle(`${t("PayButtonCaption")}` + caption);
    } else {
      setOptionTitle("");
    }
  }, [paymentMethod, paymentState.paymentMethodOptionSelected, bankOptions]);

  const dispatch = useDispatch();

  function handleClick() {
    dispatch(setPayButtonClickCount());
    dispatch(setInteractionsPerSessionCount());
    // dispatch(setIsPaymentOnPopUpViaIframe(false));
    if (cartData.items.length > 0) {
      dispatch(setPaymentProgress("PAYMENT_STARTED"));
      if (
        paymentState?.isRequiredFieldsNotFilled ||
        !shippingAddress?.number ||
        shippingAddress?.number === "" ||
        !shippingAddress?.street ||
        shippingAddress?.street === "" ||
        !shippingAddress?.city ||
        shippingAddress?.city === "" ||
        !shippingAddress?.province ||
        shippingAddress?.province === "" ||
        !shippingAddress?.postcode ||
        shippingAddress?.postcode === "" ||
        !shippingAddress?.country ||
        shippingAddress?.country === ""
      ) {
        // executeScrollOnProfileError();
        executeScrollOn("profileDetailsFormRef001");
      } else if (!phoneNumber || phoneNumber === "") {
        store.dispatch(
          setToastMessage({
            text: t("PhoneNumberEmpty"),
            type: "INFO",
          })
        );
        // executeScrollOnPhoneInputError();
        executeScrollOn("phoneInputRef001");
      } else if (!paymentMethod.type || paymentMethod.type === "") {
        store.dispatch(
          setToastMessage({
            text: t("PaymentMethodEmpty"),
            type: "INFO",
          })
        );
        // executeScrollOnPaymentMethodError();
        executeScrollOn("paymentMethodsRef001");
      } else {
        const payfunc = PaymentMethodConfigs[paymentMethod.type]?.payfunction;
        if (payfunc) {
          payfunc({});
        }
      }
    } else {
      store.dispatch(
        setToastMessage({
          text: t("EmptyCartError"),
          type: "INFO",
        })
      );
    }
  }

  //////////////stats/////////////
  const uiStatistics: any = useSelector(
    (state: RootState) => state.statistics.ui
  );
  const buttonRef = useRef<HTMLButtonElement>(null); // Specify the type of the ref

  useEffect(() => {
    if (buttonRef.current) {
      const width = buttonRef.current.offsetWidth;
      const height = buttonRef.current.offsetHeight;

      if (width !== undefined && height !== undefined) {
        console.log("saving Pay Button size stats");
        const updatedUiStats = {
          ...uiStatistics,
          payButtonSize: `width: ${width}px, height: ${height}px`,
        };
        dispatch(setUIStatistics(updatedUiStats));
      }
    }
  }, [dispatch]);

  const renderPayButton = () => {
    if (parseFloat(cartData.grandTotal + "").toFixed(2) === "0.00") {
      return null;
    } else {
      return (
        <AnimatePresence>
          <motion.div
            animate={{ transform: "translateY(20px" }}
            initial={{ transform: "translateY(500px)" }}
            transition={{
              delay: 0.2,
              duration: 0.51,
              type: "spring",
              bounce: 0.2,
            }}
            className="pay-button-holder-fixed"
          >
            <button
              ref={buttonRef}
              className="pay-button-black"
              onClick={() => handleClick()}
              disabled={
                isPayButtonLoading ||
                paymentErrorOccurred ||
                route === "/demo/social-commerce"
              }
              style={
                isPayButtonLoading
                  ? {
                      pointerEvents: "none",
                      borderRadius: isXXL ? "9999px" : "",
                    }
                  : { borderRadius: isXXL ? "9999px" : "" }
              }
            >
              <b
                data-display-title={
                  paymentMethod.displayTitle ? "true" : "false"
                }
              >
                {t("PayAmount", {
                  currency: getSymbolFromCurrency(cartData?.shop?.currency),
                  amount: parseFloat(cartData.grandTotal + "").toFixed(2),
                })}
              </b>
              <p>
                {paymentMethod.displayTitle !== ""
                  ? `${t("Using")} ` + paymentMethod.displayTitle + optionTitle
                  : ""}
              </p>
              {isPayButtonLoading ? (
                <span style={{ width: 45, marginLeft: 250 }}>
                  <AnimatedLoadingCircleColored />
                </span>
              ) : (
                <AnimatedWhiteArrowRight />
              )}
            </button>
          </motion.div>
        </AnimatePresence>
      );
    }
  };

  return renderPayButton();
};

export default AnimatedPayButtonBlack;
