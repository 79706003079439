import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdupCartItem } from "../../interfaces/AdupCartItem";

const initialItems: AdupCartItem[] = [];

export const initialShop = {
  id: -1,
  name: "",
  currency: "EUR",
  domain: "",
  initial: "",
  slug: "",
  subdomain: "",
  totalProducts: 0,
  theme: {
    app_layout: "1",
    header_theme: "default",
    intro_banner_theme: "default",
    product_details_theme: "default",
    user_info_theme: "default",
    payment_methods_theme: "default",
    order_summary_theme: "default",
    pay_button_theme: "default",
    footer_theme: "default",
  },
  settings: {},
};

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: initialItems,
    shipping: 0,
    convenienceFee: 0,
    total: 0,
    discount: 0,
    shop: initialShop,
    allProducts: [],
    attributeSkuMap: [],
    grandTotalBeforeConvenienceFee: 0,
    grandTotal: 0,
    fastcheckoutCartItems: [],
    selectedItems: null,
    gift: "null",
  },
  reducers: {
    incrementItemNumber: (state, action) => {
      state.items = state.items.map((item: AdupCartItem) => {
        if (item.id === action.payload.id)
          return { ...item, amount: item.amount + 1 };
        else return item;
      });

      state.total = state.items
        .map((item) => item.amount * item.price)
        .reduce((previous, current) => current + previous, 0);
    },
    decrementItemNumber: (state, action) => {
      state.items = state.items.map((item: AdupCartItem | any) => {
        if (item.id === action.payload.id) {
          if (item.amount > 1) {
            return { ...item, amount: item.amount - 1 };
          }
        }
        // return { ...item, amount: Math.max(1, item.amount - 1) };
        else return item;
      });
      state.total = state.items
        .map((item) => item.amount * item.price)
        .reduce((previous, current) => current + previous, 0);
    },
    addItem: (state, action) => {
      state.items = state.items.concat(action.payload);
      state.total = state.items
        .map((item) => item.amount * item.price)
        .reduce((previous, current) => current + previous, 0);
    },
    removeItem: (state, action) => {
      if (action.payload?.id) {
        state.items = state.items.filter(
          (item: AdupCartItem) => item.id !== action.payload.id
        );
      } else {
        state.items = state.items.filter(
          (item: AdupCartItem) => !item.selected
        );
      }
      state.total = state.items
        .map((item) => item.amount * item.price)
        .reduce((previous, current) => current + previous, 0);
    },
    resetCartQty: (state, action) => {
      state.items = state.items.map((item) => {
        return {
          ...item,
          amount: action.payload,
        };
      });
      state.total = state.items
        .map((item) => item.amount * item.price)
        .reduce((previous, current) => current + previous, 0);
    },
    emptyCart: (state, action) => {
      state.items = [];
      state.total = 0;
    },
    setCart: (state, action) => {
      state.shop = action.payload.shop;
      state.items = action.payload.cart;
      state.total = action.payload.total;
    },
    setShop: (state, action) => {
      const { currency, domain, name, id, initial, slug, settings, theme } =
        action.payload;
      state.shop.currency = currency;
      state.shop.domain = domain;
      state.shop.id = id;
      state.shop.name = name;
      state.shop.initial = initial;
      state.shop.slug = slug;
      state.shop.settings = settings;
      state.shop.theme = theme;
      // add the other fields here as needed
    },
    setAllProducts: (state, action) => {
      state.allProducts = action.payload;
    },
    setAttributeSkuMap: (state, action) => {
      state.attributeSkuMap = action.payload;
    },
    markItemAsSelected: (
      state,
      action: PayloadAction<{ id: string; mark: boolean }>
    ) => {
      const idx = state.items.findIndex((i) => i.id === action.payload.id);
      if (idx || idx === 0) {
        // state.items = state.items.splice(idx, 0)
        state.items[idx].selected = action.payload.mark;
      }
    },
    setShippingCost: (state, action) => {
      state.shipping = typeof action.payload === "number" ? action.payload : 0;
    },
    setConvenienceFee: (state, action) => {
      state.convenienceFee =
        typeof action.payload === "number" ? action.payload : 0;
    },
    setGrandTotalBeforeConvenienceFee: (state, action) => {
      state.grandTotalBeforeConvenienceFee = action.payload;
    },
    setGrandTotal: (state, action) => {
      state.grandTotal = action.payload;
    },
    setFastcheckoutCartItems: (state, action) => {
      state.fastcheckoutCartItems = action.payload;
    },
    setSelectedItems: (state, action) => {
      state = {
        ...state,
        selectedItems: action.payload,
      };
    },
    setGift: (state, action) => {
      state.gift = action.payload;
    },
  },
});

export const {
  decrementItemNumber,
  incrementItemNumber,
  addItem,
  removeItem,
  resetCartQty,
  emptyCart,
  setCart,
  setShop,
  markItemAsSelected,
  setAllProducts,
  setAttributeSkuMap,
  setShippingCost,
  setConvenienceFee,
  setGrandTotalBeforeConvenienceFee,
  setGrandTotal,
  setFastcheckoutCartItems,
  setSelectedItems,
  setGift,
} = cartSlice.actions;

export default cartSlice.reducer;
