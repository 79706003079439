import { IdealAvailableBanksArray } from "../constants/ideal-banks";
import { AdupPaymentMethodOptionGroup } from "../../interfaces/AdupPaymentMethodOption";
import { idealBankPayFunction } from "../payment-handlers/payment-methods/method-submission/ideal-bank-pay";
import cardPayFunction from "../payment-handlers/payment-methods/method-submission/card-pay";
import { bancontactPayFunction } from "../payment-handlers/payment-methods/method-submission/bancontact-pay";
import { epsPayFunction } from "../payment-handlers/payment-methods/method-submission/eps-pay";
import { giropayPayFunction } from "../payment-handlers/payment-methods/method-submission/giropay-pay";
import { klarnaPayFunction } from "../payment-handlers/payment-methods/method-submission/klarna-pay";
import { p24PayFunction } from "../payment-handlers/payment-methods/method-submission/p24-pay";
import { blikPayFunction } from "../payment-handlers/payment-methods/method-submission/blik-pay";
import { sepaPayFunction } from "../payment-handlers/payment-methods/method-submission/sepa-pay";
import { sofortPayFunction } from "../payment-handlers/payment-methods/method-submission/sofort-pay";
import { billinkPayFunction } from "../payment-handlers/payment-methods/method-submission/billink-pay";
import { paypalPayFunction } from "../payment-handlers/payment-methods/method-submission/paypal-pay";
import { cadeaukaartPayFunction } from "../payment-handlers/payment-methods/method-submission/cadeaukaart-pay";
import { creditclickPayFunction } from "../payment-handlers/payment-methods/method-submission/creditclick-pay";
import { fashiongiftcardPayFunction } from "../payment-handlers/payment-methods/method-submission/fashiongiftcard-pay";
import { in3PayFunction } from "../payment-handlers/payment-methods/method-submission/in3-pay";
import { multibancoPayFunction } from "../payment-handlers/payment-methods/method-submission/multibanco-pay";
import { overboekingPayFunction } from "../payment-handlers/payment-methods/method-submission/overboeking-pay";
import { paybybankPayFunction } from "../payment-handlers/payment-methods/method-submission/paybybank-pay";
import { payconiqPayFunction } from "../payment-handlers/payment-methods/method-submission/payconiq-pay";
import { paysafecardPayFunction } from "../payment-handlers/payment-methods/method-submission/paysafecard-pay";
import { pinPayFunction } from "../payment-handlers/payment-methods/method-submission/pin-pay";
import { yourgiftPayFunction } from "../payment-handlers/payment-methods/method-submission/yourgift-pay";
import { giftcardPayFunction } from "../payment-handlers/payment-methods/method-submission/giftcard-pay";
import { bankoverschrijvingPayFunction } from "../payment-handlers/payment-methods/method-submission/bankoverschrijving-pay";
import { applepayredirectPayFunction } from "../payment-handlers/payment-methods/method-submission/apple-pay-redirect-pay";

declare const window: any;
// temporary, in the future this will be sent by the server
export const PaymentMethodConfigs: { [index: string]: PaymentMethodConfig } =
  Object.freeze({
    card: {
      disabled: false,
      mountForm: true,
      options: [],
      displayName: "Card",
      payfunction: cardPayFunction,
      name: "card",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    ideal: {
      disabled: false,
      mountForm: false,
      payfunction: idealBankPayFunction,
      options: [
        new AdupPaymentMethodOptionGroup(
          "Banks",
          IdealAvailableBanksArray,
          {},
          "bank"
        ),
      ],
      displayName: "iDEAL",
      name: "ideal",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: false,
        showBankSelector: true,
        banks: IdealAvailableBanksArray,
      },
    },
    apple_pay: {
      disabled: false,
      mountForm: true,
      disableFromList: true,
      options: [],
      displayName: "Apple Pay",
      name: "apple_pay",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
        usesPaymentRequest: true,
      },
    },
    apple_pay_redirect: {
      disabled: window.ApplePaySession ? false : true,
      disableFromList: window.ApplePaySession ? false : true,
      mountForm: false,
      options: [],
      displayName: "Apple Pay",
      payfunction: applepayredirectPayFunction,
      name: "apple_pay_redirect",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    google_pay: {
      disabled: false,
      mountForm: true,
      disableFromList: true,
      options: [],
      displayName: "Google Pay",
      name: "google_pay",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
        usesPaymentRequest: true,
      },
    },
    klarna: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Klarna",
      name: "klarna",
      payfunction: klarnaPayFunction,
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    bancontact: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Bancontact",
      name: "bancontact",
      payfunction: bancontactPayFunction,
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    p24: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "P24",
      payfunction: p24PayFunction,
      name: "p24",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: true,
      },
    },
    eps: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "EPS",
      payfunction: epsPayFunction,
      name: "eps",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: true,
      },
    },
    giropay: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Giropay",
      payfunction: giropayPayFunction,
      name: "giropay",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    sofort: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Sofort",
      payfunction: sofortPayFunction,
      name: "sofort",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    sepa_debit: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "SEPA Direct Debit",
      payfunction: sepaPayFunction,
      name: "sepa_debit",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    billink: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Billink",
      payfunction: billinkPayFunction,
      name: "billink",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    blik: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "Blik",
      payfunction: blikPayFunction,
      name: "blik",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    paypal: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "PayPal",
      payfunction: paypalPayFunction,
      name: "paypal",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    cadeaukaart: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Cadeaukaart",
      payfunction: cadeaukaartPayFunction,
      name: "cadeaukaart",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    creditclick: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Creditclick",
      payfunction: creditclickPayFunction,
      name: "creditclick",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    fashion_giftcard: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Fashion Giftcard",
      payfunction: fashiongiftcardPayFunction,
      name: "fashion_giftcard",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    giftcard: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Gift Card",
      payfunction: giftcardPayFunction,
      name: "giftcard",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    bankoverschrijving: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Bankoverschrijving",
      payfunction: bankoverschrijvingPayFunction,
      name: "bankoverschrijving",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    in3: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "in3",
      payfunction: in3PayFunction,
      name: "in3",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    multibanco: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Multibanco",
      payfunction: multibancoPayFunction,
      name: "multibanco",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    overboeking: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Overboeking",
      payfunction: overboekingPayFunction,
      name: "overboeking",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    paybybank: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Paybybank",
      payfunction: paybybankPayFunction,
      name: "paybybank",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    payconiq: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Payconiq",
      payfunction: payconiqPayFunction,
      name: "payconiq",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    paysafecard: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Paysafecard",
      payfunction: paysafecardPayFunction,
      name: "paysafecard",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    pin: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Pin",
      payfunction: pinPayFunction,
      name: "pin",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    yourgift: {
      disabled: false,
      mountForm: false,
      options: [],
      displayName: "Yourgift",
      payfunction: yourgiftPayFunction,
      name: "yourgift",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    afterpay_clearpay: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "Afterpay Clearpay",
      name: "afterpay_clearpay",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    alipay: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "AliPay",
      name: "alipay",
      meta: {
        oneclick: true,
        changePage: false,
        showFullForm: false,
        showBankSelector: false,
        showQR: false,
      },
    },
    boleto: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "Boleto",
      name: "boleto",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    fpx: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "FPX",
      name: "fpx",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    grabpay: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "Grabpay",
      name: "grabpay",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    konbini: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "Konbini",
      name: "konbini",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    oxxo: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "Oxxo",
      name: "oxxo",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    paynow: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "Paynow",
      name: "paynow",
      meta: {
        oneclick: false,
        changePage: true,
        showFullForm: true,
        showBankSelector: false,
      },
    },
    wechat_pay: {
      disabled: true,
      mountForm: false,
      disableFromList: true,
      options: [],
      displayName: "WeChat Pay",
      name: "wechat_pay",
      meta: {
        oneclick: false,
        changePage: false,
        showFullForm: false,
        showBankSelector: false,
        showQR: true,
      },
    },
  });

interface PaymentMethodConfig {
  disabled: boolean;
  disableFromList?: boolean;
  displayName: string;
  name: string;
  meta: any;
  options: AdupPaymentMethodOptionGroup[];
  onSelect?: () => void;
  mountForm: boolean;
  payfunction?: (meta: any) => void;
}
