/**
 *
 * @param {string} key
 * @return { Object }
 */
export function buildHeaders(key: string) {
  return Object.freeze({
    "Content-Type": "application/json",
    // "X-API-Key": key,
    Authorization: key ? `Bearer ${key}` : "",
  });
}

/**
 *
 * @param {string} key
 * @return { Object }
 */
export function buildHeadersURLEncoded(key: string) {
  return Object.freeze({
    "Content-Type": "application/x-www-form-urlencoded",
    // "X-API-Key": key,
    Authorization: key ? `Bearer ${key}` : "",
  });
}
