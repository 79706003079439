import React from "react";

const TestWidgets = () => {
  return (
    <div className="testWidget">
      <p>Test Widget</p>
      <p>This is a test widget component.</p>
    </div>
  );
};

export default TestWidgets;
