import { t } from "i18next";
import store from "../../store/store";
import { SHOPPER_URL } from "../constants/urls";
import { RequestInfo } from "../../interfaces/RequestInfo";
import { asyncHandler } from "./async-handler";

export async function patchUserAccount(
  account: any,
  customMsg: string
): Promise<any> {
  // send request
  const result = await sendPatchUserAccountRequest(account, customMsg);
  return result;
}
async function sendPatchUserAccountRequest(account: any, customMsg: string) {
  const key = store.getState().users.currentUser.token;

  if (key) {
    try {
      const requestInfo: RequestInfo = {
        method: "PATCH",
        url: SHOPPER_URL,
        headers: {
          "Content-Type": "application/json",
          Authorization: key ? `Bearer ${key}` : "",
        },
        body: JSON.stringify({
          first_name: account?.first_name ?? undefined,
          last_name: account?.last_name ?? undefined,
          email: account?.email ?? undefined,
          language: account?.language ?? undefined,
        }),
      };

      const actionType = "UPDATE_USER_PROFILE";

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const data = res?.data;

      if (res.actionType === actionType && data.success === true) {
        console.log(data);
        return data;
      }
    } catch (exception) {
      console.log("Error sending auth request");
      console.error(exception);
      return null;
    }
  } else {
    return null;
  }
}
