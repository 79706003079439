import { PAYMENT_STATUS_URL } from "../constants/urls";
import store from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";
import { t } from "i18next";

export async function checkPaymentStatus(cartId: string) {
  const token = store.getState().users.currentUser.token;
  const lastCartUrl = store.getState().app.UI.lastCartUrl;
  const params = `/${cartId}`;
  try {
    const response = await fetch(PAYMENT_STATUS_URL + params, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    });

    // trigger API Errors as Toasts
    const res = await response.json();
    if (res) {
      toastHandler(res, store.dispatch, setToastMessage);
    }

    const { result } = res;
    return result;
  } catch (error) {
    console.log("checkPaymentStatus API Error :", error);
    window.open(lastCartUrl, "_self");
    store.dispatch(
      setToastMessage({
        text: t("PaymentFailed"),
        type: "ERROR",
      })
    );
  }
}
