import { t } from "i18next";
import React from "react";

type Props = {
  onDeleteAccountClick: () => void;
  onCancelClick: () => void;
};

const DeleteAccountMsg = ({ onDeleteAccountClick, onCancelClick }: Props) => {
  return (
    <div onClick={onCancelClick} className="deleteAddressMsg__overlay">
      <div className="deleteAddressMsg__wrapper column">
        <div className="deleteAddressMsg-title">
          <h1> {t("DeleteThisAccount")}</h1>
        </div>
        <div className="deleteAddressMsg flex-column">
          <div className="textContent-top">{`${t("AreYouSure")} ${t(
            "DeleteThisAccount"
          )}?`}</div>
          <div className="textContent-bottom">{t("DeleteThisAccountInfo")}</div>
          <div className="buttonContainer">
            <div
              onClick={onDeleteAccountClick}
              style={{ backgroundColor: "#e02020" }}
              className="btn1"
            >
              {t("Yes")}
            </div>
            <div onClick={onCancelClick} className="btn2">
              {t("Cancel")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountMsg;
