import { t } from "i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const PoweredByAdUp = () => {
  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );

  const imgURL =
    merchantTheme?.merchant_name === "AdUp" //TODO: remove hardcoded merchant validation
      ? `${imageStaticUrl}/medium/${merchantTheme?.merchant_logo_dark}`
      : `${imageStaticUrl}/medium/${merchantTheme?.merchant_icon}`;

  return (
    <div className="poweredByAdUp">
      <div className="poweredByAdUp__left">
        <LazyLoadImage
          loading="lazy"
          src={imgURL}
          alt={`${merchantTheme?.merchant_name} logo`}
        />
      </div>
      <div className="poweredByAdUp__right">
        <span> {t("PoweredBy")}</span>
        <h3>{merchantTheme?.merchant_name}</h3>
      </div>
    </div>
  );
};

export default PoweredByAdUp;
