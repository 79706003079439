import arrowDark from "../../assets/svg/arrowDark.svg";
import "../../sass/components/profilePage/listNavigation.scss";
import { useNavigate } from "react-router-dom";
import SwitchButton from "../switch-button/SwitchButton";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ListNavigation: React.FC<any> = ({ title, list, selectedKey }) => {
  const navigate = useNavigate();
  const getTextColor = (item: { buttonType: string; key?: string }) => {
    let style = {} as any;
    if (item?.buttonType === "delete") {
      style.color = "#e02020";
    } else if (item?.buttonType === "signout") {
      style.color = "#0073f7";
    } else if (item?.buttonType === "update") {
      style.color = "#0073f7";
      style.textAlign = "center";
    }

    if (item?.key && selectedKey && item?.key === selectedKey) {
      style.color = "#0073f7";
    }

    return style;
  };

  return (
    <div className="listNavigation-main">
      <div className="listNavigation">
        <h2 className="title">{title}</h2>
        <div className="listContainer">
          {/* --- */}
          {list?.map((item: any, i: any) => {
            return (
              <div
                onClick={() => {
                  if (item?.route) {
                    navigate(item?.route);
                  } else if (item?.hrefLink) {
                    window.open(item?.hrefLink, "_blank");
                  } else if (item?.handlerFunction) {
                    item?.handlerFunction();
                  }
                }}
                key={i}
                className="listItem"
              >
                <div className="listItem-top">
                  <div className="listItem__title">
                    {/* {item?.linkTitle} */}
                    <h2 style={getTextColor(item)}>{item?.linkTitle}</h2>
                    <span>{item?.spanText ? item?.spanText : ""}</span>
                  </div>
                  <div className="listItem__button">
                    {item?.buttonType === "normal" && (
                      <LazyLoadImage
                        loading="lazy"
                        src={arrowDark}
                        alt="arrow-dark"
                      />
                    )}
                    {item?.buttonType === "toggle" && (
                      <div className="listItem__button--toggle">
                        <SwitchButton />
                      </div>
                    )}
                  </div>
                </div>
                <div className="listItem-bottom" />
              </div>
            );
          })}

          {/* --- */}
        </div>
      </div>
    </div>
  );
};

export default ListNavigation;
