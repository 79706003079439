import { PAYMENT_ATTRIBUTES_DELETE_URL, SHOPPER_URL } from "../constants/urls";
import store from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";

export async function deletePaymentAttributes(attribute: string) {
  const key = store.getState().users.currentUser.token;
  const response = await fetch(
    PAYMENT_ATTRIBUTES_DELETE_URL + `/${attribute}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: key ? `Bearer ${key}` : "",
      },
    }
  );

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;
  return result;
}
