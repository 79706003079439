import React, { useEffect, useState } from "react";
import FastCheckoutDesktopLayoutForPopups from "./desktop/FastCheckoutDesktopLayoutForPopups";
import FastCheckoutMobileLayoutForPopups from "./mobile/FastCheckoutMobileLayoutForPopups";

interface Props {
  showHeader: boolean;
  theme?: string;
}

const FastCheckoutLayoutForPopups = ({ showHeader, theme }: Props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth > 750 ? (
        <FastCheckoutDesktopLayoutForPopups
          showHeader={showHeader}
          theme={theme}
        />
      ) : (
        <FastCheckoutMobileLayoutForPopups
          showHeader={showHeader}
          theme={theme}
        />
      )}
    </>
  );
};

export default FastCheckoutLayoutForPopups;
