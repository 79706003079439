import { AdupPaymentMethodOption } from "../../interfaces/AdupPaymentMethodOption";

// removed below and fetched from the INIT API
export const IdealAvailableBanks: { [index: string]: AdupPaymentMethodOption } =
  {
    test: {
      displayName: "Test Bank",
      value: "test",
      onSelect: () => null,
      meta: {
        icon: "",
      },
    },
    // asn_bank: {
    //   displayName: "ASN Bank",
    //   value: "asn_bank",
    //   onSelect: () => null,
    //   meta: {
    //     icon: abn_amroImage ? abn_amroImage : "",
    //   },
    // },
    // bunq: {
    //   displayName: "Bunq",
    //   value: "bunq",
    //   onSelect: () => null,
    //   meta: {
    //     icon: bunqImage ? bunqImage : "",
    //   },
    // },
    // handelsbanken: {
    //   displayName: "handelsbanken",
    //   value: "handelsbanken",
    //   onSelect: () => null,
    //   meta: {
    //     icon: handelsbankenImage ? handelsbankenImage : "",
    //   },
    // },
    // ing: {
    //   displayName: "ING",
    //   value: "ing",
    //   onSelect: () => null,
    //   meta: {
    //     icon: ingImage ? ingImage : "",
    //   },
    // },
    // knab: {
    //   displayName: "Knab",
    //   value: "knab",
    //   onSelect: () => null,
    //   meta: {
    //     icon: knabImage ? knabImage : "",
    //   },
    // },
    // // moneyou: { displayName: "Moneyou", value: "moneyou", icon: ( ? : ''), meta: {  } },
    // rabobank: {
    //   displayName: "Rabo Bank",
    //   value: "rabobank",
    //   onSelect: () => null,
    //   meta: {
    //     icon: raboImage ? raboImage : "",
    //   },
    // },
    // regiobank: {
    //   displayName: "Regio Bank",
    //   value: "regiobank",
    //   onSelect: () => null,
    //   meta: {
    //     icon: regioImage ? regioImage : "",
    //   },
    // },
    // revolut: {
    //   displayName: "Revolut",
    //   value: "revolut",
    //   onSelect: () => null,
    //   meta: {
    //     icon: revolutImage ? revolutImage : "",
    //   },
    // },
    // sns_bank: {
    //   displayName: "SNS Bank",
    //   value: "sns_bank",
    //   onSelect: () => null,
    //   meta: {
    //     icon: snsImage ? snsImage : "",
    //   },
    // },
    // triodos_bank: {
    //   displayName: "Triodos Bank",
    //   value: "triodos_bank",
    //   onSelect: () => null,
    //   meta: {
    //     icon: triodosImage ? triodosImage : "",
    //   },
    // },
    // van_lanschot: {
    //   displayName: "Van Lanschot",
    //   value: "van_lanschot",
    //   onSelect: () => null,
    //   meta: {
    //     icon: vanLascnhot ? vanLascnhot : "",
    //   },
    // },
  };

export const IdealAvailableBanksArray = Object.keys(IdealAvailableBanks).map(
  (bank) => IdealAvailableBanks[bank]
);
