import React, { Fragment, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PaymentReceivedView } from "./pages/payment-received/PaymentReceived";
import "./sass/app.scss";
import "./lib/state/subscriptions/cart-change-intent";
import "./lib/state/subscriptions/address-change-intent";
import "./lib/state/subscriptions/profile-change-intent";
import { unsubscribeHandleCartChange } from "./lib/state/subscriptions/cart-change-intent";
import { unsubscribeHandleAddressChange } from "./lib/state/subscriptions/address-change-intent";
import { unsubscribeHandleProfileChange } from "./lib/state/subscriptions/profile-change-intent";
import { useDispatch, useSelector } from "react-redux";
import { AdupUserProfile } from "./interfaces/AdupUserProfile";
import { RootState } from "./store/store";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  emptyToast,
  enableFastCheckoutUISegment,
  setIsPayButtonLoading,
  setToastMessage,
  updateUserType,
} from "./store/reducers/appSlice";
import {
  setCreateAccountFlag,
  setPaymentErrorOccurred,
} from "./store/reducers/paymentSlice";
import { CookieConsent } from "./components/cookie-consent/CookieConsent";
import i18n from "./internationalization/i18n";
import { t } from "i18next";
import { useFeature } from "@growthbook/growthbook-react";
import { submitLog } from "./lib/api/log";
import {
  ANALYTICS_EXECUTION_INTERVAL,
  AUTH_SSO_URL,
} from "./lib/constants/urls";
import appThemeEngine from "./lib/utils/appThemeEngine";
import { growthBookConfig } from ".";
import { setStatisticsSessionId } from "./store/reducers/statisticsSlice";
import submitStatisticsDataForAnalytics from "./lib/utils/submit-statistics-data";
import FastCheckoutPageWrapper from "./components/page-wrappers/FastCheckoutPageWrapper";
import ToastContainer from "./components/toasts/ToastContainer";
import DummyCheckoutGenerator from "./pages/demo/DummyCheckoutGenerator";
import ProfilePage from "./pages/profile/profilePage/ProfilePage";
import RootPage from "./pages/root/RootPage";
import OverviewPage from "./pages/socialCommerce/OverviewPage";
import SocialCommercePage from "./pages/socialCommerce/SocialCommercePage";
import { unsubscribeHandlePaymentMethodChange } from "./lib/state/subscriptions/payment-method-change-intent";
import { setGrandTotal } from "./store/reducers/cartSlice";
import { setUserToken } from "./store/reducers/userSlice";
import { logOutUser } from "./lib/state/clear-user-data";

const App: React.FunctionComponent = () => {
  const head: any = document.querySelector("head");

  const currentUserProfile: AdupUserProfile = useSelector(
    (state: RootState) => state.users.currentUser.profile
  );

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const route = window.decodeURI(useLocation().pathname);

  const sessionId = useSelector(
    (state: RootState) => state.statistics?.session_id
  );

  const country = useSelector(
    (state: RootState) => state.users.device_country?.country
  );

  const userData = useSelector((state: RootState) => state.users.currentUser);

  const toast = useSelector((state: RootState) => state.app.UI.toastMessage);

  const isInitAPILoading = useSelector(
    (state: RootState) => state.app.UI.isInitAPILoading
  );

  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );

  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );

  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );

  const merchantTheme = useSelector(
    (state: RootState) => state.users.merchant.config
  );

  const socialMediaCookie = useSelector(
    (state: RootState) => state.app.UI.socialMediaCookie
  );

  const appLanguage = useSelector((state: RootState) => state.app.UI.language);

  const cartData = useSelector((state: RootState) => state.cart);

  const [bgVideoUrl, setBgVideoUrl] = useState<any>();
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const feature_id = useFeature("theme");
  const feature_theme = feature_id?.value || "classic";
  const [testTheme, settestTheme] = useState<any>(feature_theme);

  useEffect(() => {
    console.log("🚀 GB > feature_theme", feature_theme);
    settestTheme(feature_theme);
  }, [feature_theme]);

  useEffect(() => {
    const updatedGradTotal = (
      +cartData?.total +
      +cartData?.shipping -
      +cartData?.discount +
      +cartData?.convenienceFee
    ).toFixed(2);
    dispatch(setGrandTotal(+updatedGradTotal));
    console.log("Updating Cart Grand Total :: ", +updatedGradTotal);
  }, [
    cartData?.total,
    cartData?.shipping,
    cartData?.discount,
    cartData?.convenienceFee,
  ]);

  /*  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); */

  //* Submit Analytics data to server at given interval --- START *//
  useEffect(() => {
    const interval = setInterval(() => {
      console.log("Analytics data submission interval reached ⏰");
      //? Sending statistics data to Analytics Endpoint at  given interval 👇🏼
      submitStatisticsDataForAnalytics();
    }, ANALYTICS_EXECUTION_INTERVAL);

    return () => clearInterval(interval);
  }, []);
  //* Submit Analytics data to server at given interval --- END *//

  //* Initial UI Segment loader
  useEffect(() => {
    if (userData?.token && userData?.token !== "") {
      dispatch(updateUserType({ user: applicationUserType.OLD_USER }));
      dispatch(disableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("DETAILS_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT"));
      dispatch(disableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT"));
    } else {
      dispatch(updateUserType({ user: applicationUserType.NEW_USER }));
      dispatch(enableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("DETAILS_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT"));
      dispatch(enableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT"));
    }
  }, []);

  //* AdUp SSO mechanism --- START *//
  useEffect(() => {
    var isBrowserSafari = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );
    console.log("🌍🍎 Is browser Safari ==> ", isBrowserSafari);

    // Create an iframe and append it to the body
    const iframe = document.createElement("iframe");

    iframe.style.display = "none"; // Hide the iframe
    iframe.src = AUTH_SSO_URL;
    iframe.id = "adUp-SSO-iFrame";

    // Append it directly to the body
    document.body?.appendChild(iframe);

    // Wait for the AdUp-SSO iframe to load
    iframe.onload = () => {
      // Send a Global Token request to the AdUp-SSO iframe
      console.log("*** Requesting Global token ***");
      const message = { action: "GET_GLOBAL_TOKEN", key: "globalToken" };
      iframe.contentWindow?.postMessage(message, AUTH_SSO_URL);
    };
  }, []);

  useEffect(() => {
    // Listen for Global Tokens sent via the AdUp-SSO iframe
    const handleMessage = (event: any) => {
      if (event.origin !== AUTH_SSO_URL) return;

      // Extract the message data
      const { action, key, value } = event.data;

      if (action === "RECEIVE_GLOBAL_TOKEN") {
        if (value && value !== "null" && value !== "") {
          if (userData?.token && userData?.token === value) {
            console.log("*** User is already Logged IN ***");
          } else {
            console.log(
              "*** Global Token received successfully & logged IN the user ***"
            );
            dispatch(setUserToken(value));
            window.location.reload();
          }
        } else {
          if (userData?.token && userData?.token !== "") {
            console.log(
              "*** Global Token not available  & logged OUT the user ***"
            );
            logOutUser();
            window.location.reload();
          } else {
            console.log("*** User is already Logged OUT ***");
          }
        }
      }
    };

    // Register the event listener for 'message' events
    window.addEventListener("message", handleMessage);

    // Specify how to clean up after this effect
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    if (userData?.token && userData?.token !== "") {
      console.log("*** Updating Global Token ***");
      const iframe: any = document.getElementById("adUp-SSO-iFrame");
      const message = {
        action: "SET_GLOBAL_TOKEN",
        key: "globalToken",
        value: userData?.token,
      };
      if (iframe && iframe?.contentWindow) {
        iframe.contentWindow?.postMessage(message, AUTH_SSO_URL);
      }
    }
  }, [userData]);
  //* AdUp SSO mechanism --- END *//

  useEffect(() => {
    if (userData?.verified?.otp?.id && userData?.verified?.otp?.id !== "") {
      dispatch(setCreateAccountFlag(true));
    } else {
      dispatch(setCreateAccountFlag(false));
    }
  }, [userData?.verified]);

  useEffect(() => {
    document.addEventListener("onautocomplete", function (e) {
      if ((e.target as HTMLElement).hasAttribute("autocompleted")) {
        const element = e.target as HTMLElement;

        if (element.classList.contains("form-input")) {
          element.parentElement?.classList.add("filled");
          element.parentElement?.classList.add("focused");
        }
      }
      // e.preventDefault(); // prevent autocomplete
    });
    try {
      i18n.changeLanguage(appLanguage);
    } catch (exception) {
      console.warn("Invalid language sent from server");

      //IIFE
      //IIFE
      (async () => {
        await submitLog("warning", "Invalid language sent from server");
      })().catch(console.error);

      dispatch(
        setToastMessage({
          text: t("LanguageError"),
          type: "ERROR",
        })
      );
    }
  }, []);

  useEffect(() => {
    try {
      i18n.changeLanguage(appLanguage);
    } catch (exception) {
      console.warn("Invalid language sent from server");
      //IIFE
      (async () => {
        await submitLog("warning", "Invalid language sent from server");
      })().catch(console.error);

      dispatch(
        setToastMessage({
          text: t("LanguageError"),
          type: "ERROR",
        })
      );
    }
  }, [appLanguage]);

  useEffect(() => {
    const BgVideoUrlReturned = appThemeEngine(
      isInitAPILoading,
      imageStaticUrl,
      settings,
      merchantTheme
    );
    setBgVideoUrl(BgVideoUrlReturned);

    if (!isInitAPILoading) {
      //* Injecting Facebook Pixel Code
      if (socialMediaCookie && settings?.facebook_pixel_id) {
        let facebookPixelCode1 = document.createElement("script");

        facebookPixelCode1.innerHTML = `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${settings?.facebook_pixel_id}');
    fbq('track', 'PageView');`;

        let facebookPixelCode2 = document.createElement("noscript");

        facebookPixelCode2.innerHTML = `
      <img height="1" width="1" style="display:none" 
           src="https://www.facebook.com/tr?id=${settings?.facebook_pixel_id}&ev=PageView&noscript=1"/>`;

        head.appendChild(facebookPixelCode1);
        head.appendChild(facebookPixelCode2);
        console.log(
          "Facebook Pixel Code is injected to the App HTML Head",
          settings?.facebook_pixel_id
        );
      }

      //* Injecting Tiktok Pixel Code
      if (socialMediaCookie && settings?.tiktok_pixel_id) {
        let tiktokPixelCode = document.createElement("script");

        tiktokPixelCode.innerHTML = `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
        ttq.load('${settings?.tiktok_pixel_id}');
        ttq.page();
      }(window, document, 'ttq');`;

        head.appendChild(tiktokPixelCode);
        console.log(
          "Tiktok Pixel Code is injected to the App HTML Head",
          settings?.tiktok_pixel_id
        );
      }
    }
  }, [settings, merchantTheme, isInitAPILoading, socialMediaCookie]);

  //* Commented out below to prevent Scrolling issue
  // useEffect(() => {
  //   // prevent overscrolling on IOS safari

  //   window.addEventListener("scroll", function (event: Event) {
  //     event.stopPropagation();

  //     const focusedInputs = document.querySelectorAll("input:focus");
  //     if (focusedInputs.length > 0) {
  //       const visualViewportHeight = window.visualViewport!.height;
  //       const keyboardHeight = window.innerHeight - visualViewportHeight;
  //       // alert(`${window.innerHeight} - ${visualViewportHeight} = ${keyboardHeight}`)

  //       const bottomYOfBody = document.body.getBoundingClientRect().bottom;
  //       if (visualViewportHeight > bottomYOfBody) {
  //         event.preventDefault();
  //         window.scrollBy({
  //           top: -(visualViewportHeight - bottomYOfBody + 60),
  //           behavior: "smooth",
  //         });
  //       } else {
  //       }
  //     } else {
  //     }
  //   });
  // }, []);

  useEffect(() => {
    let session_id;
    if (!sessionId || sessionId === 0) {
      console.log("NEW session");
      // generating a unique id for new sessions
      session_id = Math.floor(Math.random() * Date.now() + Date.now());
      dispatch(setStatisticsSessionId(session_id));
    } else {
      console.log("OLD session");
      session_id = sessionId;
    }
    // Set user attributes for targeting (from cookie, auth system, etc.)
    growthBookConfig.setAttributes({
      id: session_id,
      country,
    });
  }, []);

  useEffect(() => {
    dispatch(setIsPayButtonLoading(false));
    dispatch(setPaymentErrorOccurred(false));
    dispatch(setToastMessage(emptyToast));
    growthBookConfig.loadFeatures({ autoRefresh: true });
    return () => {
      unsubscribeHandleCartChange();
      unsubscribeHandleProfileChange();
      unsubscribeHandleAddressChange();
      unsubscribeHandlePaymentMethodChange();
    };
  }, []);

  // //* EventListener for pop-up window based payments returning to iFrame
  // useEffect(() => {
  //   const handleMessage = (event: {
  //     data: { status: string; newUrl: string };
  //   }) => {
  //     if (event.data.status === "success") {
  //       navigate(event.data.newUrl);
  //     }
  //   };
  //   window.addEventListener("message", handleMessage);
  //   // Cleanup
  //   return () => {
  //     window.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  return (
    <Fragment>
      <React.Suspense fallback={<div className="App app-container"></div>}>
        <div className="App app-container">
          {/* //* <!-- BG Video element --> */}
          {!isInitAPILoading && bgVideoUrl && (
            <video autoPlay muted loop id="bgVideo" src={bgVideoUrl} />
          )}

          <React.Fragment>
            {toast.text && toast.text !== "" && (
              <ToastContainer text={toast.text} type={toast.type} />
            )}
          </React.Fragment>

          <React.Fragment>
            {!isInitAPILoading &&
              route !== "/demo" &&
              route !== "/demo/social-commerce" &&
              route !== "/" && <CookieConsent />}
          </React.Fragment>

          <React.Fragment>
            <Routes>
              {/* //* Root Page - Enter shop slug to continue */}
              <Route path="/" element={<RootPage />}></Route>

              {/* //* Demo Page - Dummy Checkout Generator */}
              <Route path="/demo" element={<DummyCheckoutGenerator />}></Route>

              {/* //* Demo Page - Dummy Social Commerce Page */}
              <Route
                path="/demo/social-commerce"
                element={<SocialCommercePage />}
              ></Route>

              {/* //* Fastcheckout Page */}
              <Route
                path="/fastcheckout/:shopSlug/:productid"
                element={
                  <FastCheckoutPageWrapper showHeader={true} theme="normal" />
                }
              ></Route>

              {/* //* AI Checkout Page */}
              <Route
                path="/:shopSlug/:productid"
                element={<SocialCommercePage />}
              ></Route>

              {/* //* Social Commerce Page */}
              <Route
                path="/social-commerce/:shopSlug/:productid"
                element={<SocialCommercePage />}
              ></Route>

              {/* //* Overview Page */}
              <Route path="/:shopSlug" element={<OverviewPage />}></Route>

              {/* //* Overview Page via Fastcheckout */}
              <Route
                path="/fastcheckout/:shopSlug"
                element={<OverviewPage />}
              ></Route>

              {/* //* Profile Page */}
              <Route
                path="/profile/*"
                element={
                  <ProfilePage
                    userData={userData}
                    profile={currentUserProfile}
                  />
                }
              ></Route>

              {/* //* Thank You / Order Completed Page */}
              <Route
                path="/thank-you/:cartId"
                element={<PaymentReceivedView />}
              ></Route>
            </Routes>
          </React.Fragment>
        </div>
      </React.Suspense>
    </Fragment>
  );
};

export default App;
