import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import "../../sass/components/_payment-methods.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import getAllPaymentMethodsAsArray from "../../lib/utils/get-all-payment-methods-array";
import { handleNewPaymentMethodSelection } from "../../lib/payment-handlers/payment-methods/method-selection";
import {
  clearUnsavedSelectedPaymentMethod,
  setIsPaymentMethodEditable,
  setIsWalletPaymentMethodInUse,
  setPaymentProgress,
} from "../../store/reducers/paymentSlice";
import { AnimatedLoadingCircle } from "../../assets/svg/animated-svg-components/AnimatedLoadingCircle";
import { useLocation } from "react-router-dom";
import { buildNewPaymentMethodIcon } from "../new-payment-method/NewPaymentMethod";
import { LazyLoadImage } from "react-lazy-load-image-component";
import executeScrollOn from "../../lib/utils/executeScrollOn";

const cardNumberFormat = (cardNumber: string) => {
  return "**** " + cardNumber.substring(cardNumber.length - 5);
};

export const PaymentMethodsView: React.FC<{}> = ({}: any) => {
  const route = window.decodeURI(useLocation().pathname);
  const paymentData = useSelector((state: RootState) => state.payment);
  const shopName: any = useSelector(
    (state: RootState) => state.cart?.shop?.name
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app?.appData?.static_base_url
  );
  const dispatch = useDispatch();
  const [isWalletMethodAvailable, setIsWalletMethodAvailable] = useState(false);
  const [isPaymentMethodChanged, setIsPaymentMethodChanged] = useState(false);

  const { t } = useTranslation();

  const [newPaymentMethods, setNewPaymentMethods] = useState<any>(
    getAllPaymentMethodsAsArray()
  );

  const onNewPaymentMethodClick = (method: any) => {
    if (route !== "/demo/social-commerce")
      dispatch(setPaymentProgress("PAYMENT_STARTED"));
    handleNewPaymentMethodSelection(method);
  };

  const isPaymentMethodsLoading = paymentData?.isPaymentMethodsLoading;

  useEffect(() => {
    setNewPaymentMethods(getAllPaymentMethodsAsArray());
  }, []);

  useEffect(() => {
    if (
      !(
        newPaymentMethods &&
        newPaymentMethods.find((item: any) => item.name === "google_pay") ===
          undefined
      ) ||
      !(
        newPaymentMethods &&
        newPaymentMethods.find((item: any) => item.name === "apple_pay") ===
          undefined
      )
    ) {
      setIsWalletMethodAvailable(true);
    } else {
      setIsWalletMethodAvailable(false);
    }
  }, [paymentData.allPaymentMethods]);

  return (
    <div className="payment-methods fast-checkout-page-segment">
      <h2 className="heading-2-regular fast-checkout-segment-heading">
        {t("PaymentMethods")}
      </h2>
      {isPaymentMethodsLoading && (
        <>
          <span
            style={{
              height: "25px",
              width: "90px",
              alignSelf: "center",
              paddingLeft: 30,
              paddingTop: 20,
            }}
          >
            <AnimatedLoadingCircle />
          </span>
          <div style={{ textAlign: "center", paddingTop: 20 }}>
            <span> {t("PaymentMethodsLoading")}</span>
          </div>
        </>
      )}
      {(!paymentData?.availablePaymentMethods ||
        paymentData?.availablePaymentMethods === null ||
        paymentData?.availablePaymentMethods.length <= 0) && (
        <>
          <div style={{ textAlign: "left", fontSize: "1.4rem", color: "red" }}>
            <span> {`${shopName} ` + t("NoPaymentMethods")}</span>
          </div>
        </>
      )}
      {/* <HeadingWithRightButton
        headingText={t("PaymentMethods")}
        button={
          <SearchPaymentMethod
            allMethods={getAllPaymentMethodsAsArray()}
            setPaymentMethods={setNewPaymentMethods}
          />
        }
      /> */}

      {/* <div className="payment-methods-saved">
        {Object.keys(paymentData.savedPaymentMethods).map((key, idx) => {
          const method = paymentData.savedPaymentMethods[key];
          return (
            <SavedPaymentMethodIcon
              name={cardNumberFormat(method.label.text)}
              onClick={() => handleSavedPaymentMethodClick(method)}
              key={key}
              method={method}
            />
          );
        })}
         {paymentData.savedPaymentMethods.length > 4 && <ViewAllItems />} 
      </div> */}

      <div className="payment-methods-new">
        {/* {Object.keys(paymentData.savedPaymentMethods).length > 0 && (
          <div className="new-payment-method-heading">
            <hr />
            <span>&nbsp; Or use a new method &nbsp;</span>
          </div>
        )} */}
        <div className="payment-methods-list">
          {(paymentData.isWalletPaymentsSupportedByDevice &&
            isPaymentMethodChanged) ||
          (paymentData.isWalletPaymentsSupportedByDevice &&
            !paymentData?.isWalletPaymentMethodInUse) ? (
            <>
              <div
                className={"new-payment-method-icon card-details-retracted "}
                onClick={() => {
                  dispatch(clearUnsavedSelectedPaymentMethod(null));
                  setIsPaymentMethodChanged(false);
                  dispatch(setIsWalletPaymentMethodInUse(true));
                  dispatch(setIsPaymentMethodEditable(false));
                }}
              >
                {(paymentData?.walletPaymentMethodTypeInUse &&
                  paymentData?.walletPaymentMethodTypeInUse === "apple_pay") ||
                (newPaymentMethods &&
                  newPaymentMethods.find(
                    (item: any) => item.name === "apple_pay"
                  )) ? (
                  <>
                    <LazyLoadImage
                      loading="lazy"
                      src={`${imageStaticUrl}/small/payment-method/apple_pay.png`}
                      alt="apple_pay"
                    />
                    Apple Pay
                  </>
                ) : (paymentData?.walletPaymentMethodTypeInUse &&
                    paymentData?.walletPaymentMethodTypeInUse ===
                      "google_pay") ||
                  (newPaymentMethods &&
                    newPaymentMethods.find(
                      (item: any) => item.name === "google_pay"
                    )) ? (
                  <>
                    <LazyLoadImage
                      loading="lazy"
                      src={`${imageStaticUrl}/small/payment-method/google_pay.png`}
                      alt="google_pay"
                    />
                    Google Pay
                  </>
                ) : null}
              </div>
            </>
          ) : null}

          {newPaymentMethods &&
            newPaymentMethods.map((method: any, key: any) =>
              buildNewPaymentMethodIcon({
                name: method.name,
                image: method.iconURL,
                key: key,
                onClick:
                  method.name !== "google_pay" && method.name !== "apple_pay"
                    ? () => {
                        if (paymentData?.isRequiredFieldsNotFilled)
                          // executeScrollOnProfileError();
                          executeScrollOn("profileDetailsFormRef001");
                        onNewPaymentMethodClick(method);
                      }
                    : null,
                displayTitle: method.displayTitle,
                paymentMethod: method,
                walletMethodAvailable: isWalletMethodAvailable,
                setPaymentMethodChanged: setIsPaymentMethodChanged,
                paymentMethodChanged: isPaymentMethodChanged,
                isDemoActive: route === "/demo/social-commerce",
              })
            )}
          {/* <ViewAllItems /> */}
        </div>
      </div>
    </div>
  );
};
