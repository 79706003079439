import { OTP_URL } from "../constants/urls";
import store from "../../store/store";
import {
  setUserToken,
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import { sendPhoneCheck } from "./phone-check";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  initialHiddenSegments,
  updateUserType,
} from "../../store/reducers/appSlice";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import { getUniqueBrowserId } from "../utils/helper-functions";
import { detectIncognito } from "detectincognitojs";

/**
 * @param { string } id The OTP id string received from the server
 * @param { string } code The OTP code entered/ being sent for verification
 * @param { string } phoneNumber The coresponding Phone Number
 * @param { string } countryCode The coresponding Country Code
 */
export async function sendOTPCode(
  id: string,
  phoneNumber: string,
  countryCode: string,
  code: string
) {
  const currentState = store.getState().users.currentUser.verified;
  const key = store.getState().users.currentUser.token;
  const shopId = store.getState().cart?.shop?.id;
  var isBrowserSafari = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent
  );
  let isIncognitoWindow = false;
  try {
    await detectIncognito().then((result) => {
      console.log(
        "🌍👻 is Incognito Window ==> ",
        result.browserName,
        result.isPrivate
      );
      isIncognitoWindow = result.isPrivate;
    });

    const response = await fetch(OTP_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "X-API-Key": key,
        Authorization: key ? `Bearer ${key}` : "",
      },
      body: JSON.stringify({
        otp_id: id,
        otp_code: code,
        phone_number: phoneNumber,
        country_code: countryCode,
        shop_id: shopId,
        unique_browser_id:
          isBrowserSafari && !isIncognitoWindow
            ? `${getUniqueBrowserId()}`
            : undefined,
      }),
    });

    // trigger API Errors as Toasts
    const res = await response.json();
    if (!res.success || !res.result || res.result === null) {
      console.log("OTP Verification failed");
      // set the state to verification failed user
      store.dispatch(
        setUserVerified({
          ...currentState,
          state: verificationStates.failed,
          _: true,
          firstPayment: "pending",
        })
      );
    }

    return res;
  } catch (error) {
    console.log("OTP Verification failed");
    // set the state to verification failed user
    store.dispatch(
      setUserVerified({
        ...currentState,
        state: verificationStates.failed,
        _: true,
        firstPayment: "pending",
      })
    );
  }
}

/**
 * @param { string } id
 * @param { string } code
 * @param { string } phoneNumber
 * @param { string } countryCode
 * @return {void}
 */
export async function validateOTP(
  id: string,
  phoneNumber: string,
  countryCode: string,
  code: string
): Promise<void> {
  const currentState = store.getState().users.currentUser.verified;
  try {
    const { result } = await sendOTPCode(id, phoneNumber, countryCode, code);
    if (result) {
      const { access_token, shopper } = result;
      if (access_token) {
        // set the state to verified user
        store.dispatch(
          setUserVerified({
            ...currentState,
            state: verificationStates.verified,
            _: true,
            loggedInMethod: "otp",
            firstPayment: "pending",
          })
        );
        store.dispatch(setUserToken(access_token));

        if (shopper) {
          updateStateFromShopperObject(shopper);
          updateStateFromPaymentMethodsBlock(shopper);
          for (const segment of Object.keys(initialHiddenSegments)) {
            store.dispatch(disableFastCheckoutUISegment(segment));
          }

          store.dispatch(
            updateUserType({ user: applicationUserType.OLD_USER })
          );
        }
      } else {
        console.log("OTP Verification failed");
        // set the state to verification failed user
        store.dispatch(
          setUserVerified({
            ...currentState,
            state: verificationStates.failed,
            _: true,
            firstPayment: "pending",
          })
        );
      }
    } else {
      console.log("OTP Verification failed");
      // set the state to verification failed user
      store.dispatch(
        setUserVerified({
          ...currentState,
          state: verificationStates.failed,
          _: true,
          firstPayment: "pending",
        })
      );
    }
  } catch (error) {
    console.log("OTP Verification failed", error);
    // set the state to verification failed user
    store.dispatch(
      setUserVerified({
        ...currentState,
        state: verificationStates.failed,
        _: true,
        firstPayment: "pending",
      })
    );
  }
}

/**
 * Sets up the OTP id and saves the data to local storage
 * @param { string } country Telephone number country dial code
 * @param { string } phone Telephone number
 */
export async function setupOTPId(country: string, phone: string) {
  // store.dispatch( //? removed Toast as requested : https://app.asana.com/0/1203632011484827/1203780217361885/f
  //   setToastMessage({
  //     text: t("SendingOTP"),
  //     type: "INFO",
  //   })
  // );
  const otpData = await sendPhoneCheck(country, phone);

  // if an otp check was sent, we also need to reset the phone state, i.e. remove the phone id from the state
  // store.dispatch(setPhoneId(""));
  // store.dispatch(clearOTPId(null));
  if (otpData && otpData.otp_id) {
    const currentState = store.getState().users.currentUser.verified;
    store.dispatch(
      setUserVerified({
        ...currentState,
        state: verificationStates.trying,
        otp: { id: otpData.otp_id, phone_number: phone, country_code: country },
        firstPayment: "pending",
      })
    );
    // store.dispatch( //? removed Toast as requested : https://app.asana.com/0/1203632011484827/1203780217361885/f
    //   setToastMessage({
    //     text: t("OTPSent"),
    //     type: "SUCCESS",
    //   })
    // );

    // we receive an id for the phone number if it has been saved on the backend.
    // we need this id when checking between user profiles, and indexing phone numbers from the phone/otp response
    // if (otpData.phone_id) {
    //   store.dispatch(addNewPhoneNumber({ key: otpData.phone_id, value: {} }));
    //   store.dispatch(setPhoneId(otpData.phone_id));
    //   // TO-DO: need to save the phone number object in local storage
    // }

    // the user is saved on the server side, i.e. an old user
    // if (otpData.saved) {
    //   store.dispatch(updateUserType({ user: applicationUserType.OLD_USER }));
    // } else {
    //   store.dispatch(updateUserType({ user: applicationUserType.NEW_USER }));
    // }
  } else if (otpData && !otpData.otp_id) {
    store.dispatch(
      setUserVerified({
        _: false,
        state: verificationStates.unverified,
        otp: {
          id: "",
        },
        firstPayment: "pending",
      })
    );
  }
}
