import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import FastCheckoutPopupWrapperSegment from "../../pages/fast-checkout/subcomponents/FastCheckoutPopupWrapperSegment";
import PageTransitionContainer from "./PageTransitionContainer";
import FastCheckoutPageOTPSegment from "../../pages/fast-checkout/segments/FastCheckoutPageOTPSegment";
import FastCheckoutPage from "../../pages/fast-checkout/FastCheckout";

const mainPageSlideBackInAimation = {
  exit: {
    x: "-200vw",
  },
  initial: {
    x: "-200vw",
  },
  entrance: {
    x: "none",
  },
  transition: {
    duration: 0.25,
  },
};
interface Props {
  showHeader: boolean;
  theme?: string;
}

const FastCheckoutPageWrapper = ({ showHeader, theme }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentProgress = useSelector(
    (state: RootState) => state.payment.paymentProgress
  );
  const cartId = useSelector((state: RootState) => state.payment.cartSessionId);
  const isOtpOverlayVisible = useSelector(
    (state: RootState) => state.app?.UI?.isOtpOverlayVisible
  );

  useEffect(() => {
    if (paymentProgress === "PAYMENT_SUCCEEDED") {
      navigate(`/thank-you/${cartId}`);
    }
  }, [paymentProgress]);

  return (
    <React.Fragment>
      <AnimatePresence initial={false}>
        <PageTransitionContainer
          goBack={null}
          animation={mainPageSlideBackInAimation}
          key={"fast-checkout-page"}
        >
          <FastCheckoutPage showHeader={showHeader} theme={theme} />
        </PageTransitionContainer>
        <FastCheckoutPopupWrapperSegment key="fast-checkout-popup-wrapper">
          {isOtpOverlayVisible && <FastCheckoutPageOTPSegment />}
        </FastCheckoutPopupWrapperSegment>
      </AnimatePresence>
    </React.Fragment>
  );
};

export default FastCheckoutPageWrapper;
