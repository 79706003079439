import getSymbolFromCurrency from "currency-symbol-map";
import { AnimatePresence } from "framer-motion";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FastCheckoutPageWrapper from "../../../components/page-wrappers/FastCheckoutPageWrapper";
import { addShopperEvent } from "../../../lib/api/events-methods";
import { initialAPICall } from "../../../lib/api/initial-api-call";
import { getAllProductsByShopSlug } from "../../../lib/api/products-methods";
import {
  setIsAICheckout,
  setToastMessage,
} from "../../../store/reducers/appSlice";
import {
  addItem,
  setFastcheckoutCartItems,
} from "../../../store/reducers/cartSlice";
import store, { RootState } from "../../../store/store";
import { submitLog } from "../../../lib/api/log";
import TestWidgets from "../../../components/widgets/TestWidgets";
import Header from "../../../components/header/Header";
import IntroBannerSC from "../../../components/socialCommerce/IntroBannerSC";
import LinkBannerSC from "../../../components/socialCommerce/LinkBannerSC";
import SingleProductSC from "../../../components/socialCommerce/SingleProductSC";
import SplashScreen from "../../../components/splash-screen/SplashScreen";
interface productsInterface {
  image: string;
  name: string;
  price: string | number;
  cartItemsNum: number;
  currency: string;
  sku: string;
  type: string;
  id: string | number;
}

const OverviewPageMobileLayout1 = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const route = window.decodeURI(useLocation().pathname);
  const shop: any = useSelector((state: RootState) => state.cart.shop);
  const isInitAPILoading = useSelector(
    (state: RootState) => state.app.UI.isInitAPILoading
  );
  const isInitApiFetched = useSelector(
    (state: RootState) => state.payment.isInitApiFetched
  );
  const { appData, UI } = useSelector((state: RootState) => state.app) as any;
  const initProducts: any = useSelector(
    (state: RootState) => state.cart.allProducts
  );
  const userEmail = useSelector(
    (state: RootState) => state.users.currentUser?.profile?.email
  );
  const widgets = useSelector(
    (state: RootState) => state.users.merchant.widgets
  );
  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );
  const imageStaticUrl = appData?.static_base_url;
  const [allProducts, setAllProducts] = useState<productsInterface[]>([]);
  const [page, setPage] = useState<number>(1);
  const [isShowMoreAvailable, setIsShowMoreAvailable] =
    useState<boolean>(false);
  const [isMoreProductsLoading, setIsMoreProductsLoading] =
    useState<boolean>(false);
  const [isGoBackAvailable, seIisGoBackAvailable] = useState<boolean>(true);
  const app_mode = route.split("/")[1];
  const fastcheckoutCartItems: any =
    useSelector((state: RootState) => state.cart.fastcheckoutCartItems) ?? [];

  useEffect(() => {
    if (!isInitApiFetched) {
      if (app_mode === "fastcheckout") {
        // If user manually loads the fastcheckout/shop_slug URL it is redirected to usual overview page without /fastcheckout
        navigate(`/${route.split("/")[2]}`);
      } else {
        // INIT API Call
        initialAPICall(`/overview/${app_mode}`);
      }
      seIisGoBackAvailable(false);
    } else {
      // Products Get All API Call\
      fetchAllProducts();
    }

    //* Triggering Events API on user actions
    addShopperEvent("view_page", {
      url: `${window.location.href}`,
      page: "overview",
      email: userEmail && userEmail !== "" ? userEmail : undefined,
    });
  }, [route]);

  useEffect(() => {
    document.title = `Overview - ${shop.name} - Powered by ${merchantTheme?.merchant_name}`;
  }, []);

  useEffect(() => {
    if (!isGoBackAvailable && initProducts && initProducts.length > 0) {
      let finalArray: productsInterface[] = [];
      for (let item of initProducts) {
        let product = {
          name: item?.name,
          price: item?.display_price,
          cartItemsNum: 1,
          image: item?.images[0],
          currency: item?.currency,
          sku: item?.sku,
          type: item?.type,
          id: item?.id,
        };
        finalArray.push(product);
      }
      if (initProducts.length < 8) {
        setIsShowMoreAvailable(false);
      } else {
        setIsShowMoreAvailable(true);
      }
      setPage(page + 1);
      setAllProducts(finalArray);
      setIsMoreProductsLoading(false);
    }
    if (!isGoBackAvailable && initProducts && initProducts.length <= 0) {
      setAllProducts([]);
    }
  }, [initProducts]);

  const fetchAllProducts = () => {
    let shopSlug;
    if (app_mode === "fastcheckout") {
      shopSlug = route.split("/")[2];
    } else {
      shopSlug = route.split("/")[1];
    }
    try {
      setIsShowMoreAvailable(true);
      setIsMoreProductsLoading(true);
      store.dispatch(setIsAICheckout(false));
      const res = getAllProductsByShopSlug(shopSlug, page);
      let finalArray: productsInterface[] = [];
      res.then((e: []) => {
        for (let i of e) {
          let item = Object.values(i)[0] as any;
          let product = {
            name: item?.name,
            price: item?.display_price,
            cartItemsNum: 1,
            image: item?.images[0],
            currency: item?.currency,
            sku: item?.sku,
            type: item?.type,
            id: item?.id,
          };
          finalArray.push(product);
        }
        if (e.length < 8) {
          setIsShowMoreAvailable(false);
        } else {
          setIsShowMoreAvailable(true);
        }
        setPage(page + 1);
        setAllProducts(allProducts.concat(finalArray));
        setIsMoreProductsLoading(false);
      });
    } catch (error) {
      setIsMoreProductsLoading(false);
      console.log("Products Get All API Error :", error);
      //IIFE
      (async () => {
        await submitLog("error", t("ProductsError"));
      })().catch(console.error);

      store.dispatch(
        setToastMessage({
          text: t("ProductsError"),
          type: "ERROR",
        })
      );
    }
  };

  const addToFastcheckoutCart = (item: any) => {
    let sku = item?.sku;
    if (sku) {
      //? Checking if the variant is already in the Cart with a different QTY
      if (
        fastcheckoutCartItems.filter((i: any) => i.includes(`${sku}`))[0] &&
        !fastcheckoutCartItems.includes(`${sku}[${1}]`)
      ) {
        let tempData = fastcheckoutCartItems.filter(
          (i: any) =>
            i !==
            fastcheckoutCartItems.filter((i: any) => i.includes(`${sku}`))[0]
        );
        store.dispatch(setFastcheckoutCartItems([...tempData, `${sku}[${1}]`]));
        store.dispatch(
          setToastMessage({
            text: t("ItemAlreadyInCart"),
            type: "INFO",
          })
        );
      }
      //? Checking if the variant is already in the Cart with the same QTY
      else if (!fastcheckoutCartItems.includes(`${sku}[${1}]`)) {
        store.dispatch(
          setFastcheckoutCartItems([...fastcheckoutCartItems, `${sku}[${1}]`])
        );
        store.dispatch(
          addItem({
            price: item?.price ? item?.price : 0,
            name: item?.name,
            currency: item.currency,
            image: item?.image,
            amount: 1,
            id: item?.id,
            sku: item?.sku,
          })
        );
        store.dispatch(
          setToastMessage({
            text: t("AddedToCart"),
            type: "SUCCESS",
          })
        );
      } else {
        store.dispatch(
          setToastMessage({
            text: t("ItemAlreadyInCart"),
            type: "INFO",
          })
        );
      }
    }
  };

  if (isInitAPILoading) {
    return (
      <React.Suspense fallback={<div></div>}>
        <AnimatePresence>
          <SplashScreen />
        </AnimatePresence>
      </React.Suspense>
    );
  } else {
    return (
      <React.Suspense fallback={<div className="socialCommerce"></div>}>
        <Header />
        <div className="socialCommerce">
          <div className="max-width-container">
            <div className="max-width-container__content">
              <IntroBannerSC />
              {widgets.includes("test") && <TestWidgets />}
              {
                <>
                  {isGoBackAvailable && (
                    <LinkBannerSC
                      onClick={() => {
                        navigate(`${UI?.lastSocialCommerceUrl}`);
                      }}
                      title={`${t("OverviewPageTitle")}`}
                    />
                  )}
                  {allProducts.length > 0 ? (
                    <>
                      <div className="socialCommerce__productsHolder">
                        {allProducts.map(
                          (item: productsInterface, i: number) => {
                            return (
                              <SingleProductSC
                                name={item.name}
                                price={item.price}
                                type={item.type}
                                image={`${imageStaticUrl}/medium/${item.image}`}
                                cartItemsNum={item.cartItemsNum}
                                currency={getSymbolFromCurrency(item.currency)}
                                onClick={(e: any) => {
                                  if (e.ctrlKey || e.metaKey) {
                                    window.open(
                                      `/${shop.slug}/${item?.sku}`,
                                      "_blank"
                                    );
                                  } else {
                                    navigate(`/${shop.slug}/${item?.sku}`);
                                  }
                                }}
                                onAddToCartClick={() => {
                                  addToFastcheckoutCart(item);
                                }}
                              />
                            );
                          }
                        )}
                      </div>
                    </>
                  ) : !isMoreProductsLoading ? (
                    <div
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        color: "red",
                        paddingTop: "2vh",
                      }}
                    >
                      <span> {`${shop?.name} ` + t("NoProducts")}</span>
                    </div>
                  ) : null}

                  {isShowMoreAvailable && (
                    <p
                      className={
                        !isMoreProductsLoading
                          ? "socialCommerce__browseMoreLink"
                          : "socialCommerce__productsLoading"
                      }
                      style={{ marginBottom: 0 }}
                      onClick={() => {
                        if (!isMoreProductsLoading) fetchAllProducts();
                      }}
                    >
                      {isMoreProductsLoading
                        ? t("ProductsLoading")
                        : t("ShowMore")}
                    </p>
                  )}
                </>
              }
            </div>
          </div>
        </div>
        {isGoBackAvailable && !isMoreProductsLoading && (
          <FastCheckoutPageWrapper showHeader={false} />
        )}
      </React.Suspense>
    );
  }
};

export default OverviewPageMobileLayout1;
