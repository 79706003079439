import { t } from "i18next";
import React from "react";

type Props = {
  onLogOutClick: () => void;
  onCancelClick: () => void;
};

const LogOutMsg = ({ onLogOutClick, onCancelClick }: Props) => {
  return (
    <div onClick={onCancelClick} className="deleteAddressMsg__overlay">
      <div className="deleteAddressMsg__wrapper column">
        <div className="deleteAddressMsg-title">
          <h1> {t("LogOut")}</h1>
        </div>
        <div className="deleteAddressMsg flex-column">
          <div className="textContent">{`${t("AreYouSure")} ${t(
            "LogOut"
          )} ?`}</div>
          <div className="buttonContainer">
            <div
              onClick={onLogOutClick}
              style={{ backgroundColor: "#e02020" }}
              className="btn1"
            >
              {t("Yes")}
            </div>
            <div onClick={onCancelClick} className="btn2">
              {t("Cancel")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogOutMsg;
