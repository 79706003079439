import React, { Fragment, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Elements } from "@stripe/react-stripe-js";
import { MakePayment } from "./MakePaymentPage";
import { t } from "i18next";
import HeadingWithRightButton from "../../components/heading/HeadingWithRightButton";
import executeScrollOn from "../../lib/utils/executeScrollOn";

const StripeContainer: React.FC<any> = ({ PUBLIC_KEY_STRIPE }) => {
  const stripeClientSecret = useSelector(
    (state: RootState) => state.payment.stripe.clientSecret
  );
  const stripePMIntent = useSelector(
    (state: RootState) => state.payment.stripe.intentType
  );
  const isWalletPaymentMethodInUse = useSelector(
    (state: RootState) => state.payment?.isWalletPaymentMethodInUse
  );
  const [stripePromise, setStripePromise] = useState<any>();

  const [clientSecret, setClientSecret] = useState(stripeClientSecret);

  useEffect(() => {
    setClientSecret(stripeClientSecret);
  }, [stripeClientSecret]);

  useEffect(() => {
    const promise = loadStripe(PUBLIC_KEY_STRIPE);
    setStripePromise(promise);
  }, []);

  useEffect(() => {
    // executeScrollOnFastCheckoutPaymentMount();
    executeScrollOn("fastCheckoutPaymentMountRef001");
  }, []);

  const [elements, setElements] = useState(
    <Elements
      options={{
        appearance: { theme: "stripe" },
        clientSecret: clientSecret,
      }}
      stripe={stripePromise}
    >
      <MakePayment intentType={stripePMIntent} clientSecret={clientSecret} />
    </Elements>
  );

  useEffect(() => {
    setElements(
      stripeClientSecret && stripePromise && (
        <Elements
          options={{
            appearance: { theme: "stripe" },
            clientSecret: stripeClientSecret,
          }}
          key={stripeClientSecret}
          stripe={stripePromise}
        >
          <MakePayment
            intentType={stripePMIntent}
            clientSecret={stripeClientSecret}
          />
        </Elements>
      )
    );
  }, [stripeClientSecret, stripePromise, stripePMIntent]);

  return (
    <>
      {!isWalletPaymentMethodInUse && (
        <>
          <HeadingWithRightButton
            headingText={t("PaymentDetailsTitle")}
            button={<Fragment></Fragment>}
          />
          <div className="stripe-container">
            {clientSecret && stripePromise && elements}
          </div>
        </>
      )}
    </>
  );
};

export default StripeContainer;
