import "../../sass/themes/theme1/theme1-productPrice.scss";
interface Props {
  price: string | number;
  currency: string;
}
const Theme1ProductPrice = ({ price, currency }: Props) => {
  return (
    <div className="theme1-productPrice">
      {currency}
      {price}
    </div>
  );
};

export default Theme1ProductPrice;
