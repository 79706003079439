import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Country from "../../interfaces/Country";
import {
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import { RootState } from "../../store/store";
import { CountrySearchModal } from "../country-input-selector/CountryInputSelector";
import { validateAndGetPhoneNumber } from "./validate-phone-number";
import { useTranslation } from "react-i18next";
import CreateAccountToggle from "../create-account/CreateAccountToggle";
import { useLocation } from "react-router-dom";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

interface PhoneNumberWithModalProps {
  initialPhone: string;
  initialCountry: Country;
  changeListeners?: ((event: any, meta: any) => void)[];
  showVerificationPill?: boolean;
  countries: { [index: string]: Country };
  dialCodeToCountryCodeMap: { [dialCode: string]: Country };
  disabled?: boolean;
  modernUI?: boolean;
}

const CustomPhoneField: React.FC<PhoneNumberWithModalProps> = ({
  initialCountry,
  initialPhone = "",
  changeListeners,
  countries,
  dialCodeToCountryCodeMap,
  disabled,
  modernUI,
}) => {
  const route = window.decodeURI(useLocation().pathname);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [country, setCountry] = useState(initialCountry);

  const [showingModal, setShowingModal] = useState(false);

  // value displayed in the input field
  // i.e. with dial code
  const [value, setValue] = useState(
    initialCountry?.dialCode ? initialCountry?.dialCode + initialPhone : ""
  );
  const [flag, setFlag] = useState(
    initialCountry?.dialCode ? initialCountry?.flag : ""
  );

  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const paymentProgress = useSelector(
    (state: RootState) => state.payment.paymentProgress
  );

  // function checkValueForStartingZeros(inputString: string): string {
  //   let withoutDialCode = inputString;

  //   if (inputString.startsWith(country.dialCode))
  //     withoutDialCode = inputString.substring(country.dialCode.length);
  //   else if (inputString.startsWith(country.dialCode.substring(1))) {
  //     withoutDialCode = inputString.substring(country.dialCode.length - 1);
  //   }

  //   while (withoutDialCode.startsWith("0")) {
  //     withoutDialCode = withoutDialCode.substring(1);
  //   }
  //   if (
  //     country.dialCode + withoutDialCode !== inputString &&
  //     inputString.startsWith("00")
  //   ) {
  //     return checkValueForStartingZeros(withoutDialCode);
  //   }
  //   return country.dialCode + withoutDialCode;
  // }

  function handlePhoneNumberChange(event: ChangeEvent<HTMLInputElement>) {
    let inputString = event.target.value;
    const validatedPhoneValue = validateAndGetPhoneNumber(
      inputString,
      country,
      value
    );
    setCountry(validatedPhoneValue.country);
    setValue(validatedPhoneValue.fullNumber);
    setFlag(validatedPhoneValue?.country?.flag);
  }

  useEffect(() => {
    setCountry(initialCountry);
  }, [initialCountry]);

  useEffect(() => {
    const maxCountryCodeLength = 5;
    let codeSize = maxCountryCodeLength;

    if (value.length < codeSize) codeSize = value.length;

    while (codeSize > 1) {
      if (dialCodeToCountryCodeMap[value.substring(0, codeSize)]) {
        setCountry(
          (_) => dialCodeToCountryCodeMap[value.substring(0, codeSize)]
        );
        break;
      }
      codeSize--;
    }
  }, [value]);

  useEffect(() => {
    if (country?.dialCode && country.dialCode !== "")
      changeListeners?.forEach((listener) => {
        listener(
          {},
          {
            country: country,
            number: value.substring(country.dialCode.length),
          }
        );
      });
  }, [value]);

  useEffect(() => {
    if (country && value === "" && country?.dialCode !== "") {
      setValue(country.dialCode);
      setFlag(country?.flag);
    }
  }, [value, country]);

  return (
    <>
      {showingModal && country ? (
        <CountrySearchModal
          setCountry={setCountry}
          setPhoneNumber={(v) => {
            const validatedPhoneValue = validateAndGetPhoneNumber(
              v,
              country,
              value
            );
            setCountry(validatedPhoneValue.country);
            setValue(validatedPhoneValue.fullNumber);
            setFlag(validatedPhoneValue.country?.flag);
          }}
          hide={() => {
            setShowingModal(false);
          }}
          phoneNumberValue={value.substring(country.dialCode.length)}
          selectedCountry={country}
          currentDialCode={country.dialCode}
        />
      ) : null}
      <div className="phone-number-with-country-modal-wrapper">
        <div
          className={`phone-number-with-country-modal ${
            paymentProgress === "PAYMENT_STARTED" &&
            (!validateAndGetPhoneNumber(value, country, value)?.number ||
              validateAndGetPhoneNumber(value, country, value)?.number === "")
              ? "phone-number-with-country-modal__required"
              : ""
          }`}
        >
          <div className="phone-number-with-country-inner">
            <div className="phone-number-inputs focused">
              <div
                id="country-flag-holder"
                className="country-flag-holder"
                onClick={() => {
                  if (!disabled) setShowingModal(true);
                }}
              >
                {flag}
              </div>
              <div className="phone-input-field-container">
                <label className="form-label-phone" htmlFor="phone-input-main">
                  {`${t("PhoneNumber")} ${
                    paymentProgress === "PAYMENT_STARTED" &&
                    (!validateAndGetPhoneNumber(value, country, value)
                      ?.number ||
                      validateAndGetPhoneNumber(value, country, value)
                        ?.number === "")
                      ? `(${t("Required")})`
                      : ""
                  }`}
                </label>
                <input
                  className="phone-number-input-field"
                  style={{ fontFamily: "var(--main-font-secondary)" }}
                  value={value}
                  onChange={handlePhoneNumberChange}
                  type="tel"
                  name="phone"
                  id="phone-input-main"
                  disabled={disabled}
                  onFocus={() => {
                    dispatch(setInteractionsPerSessionCount());
                  }}
                ></input>
              </div>
            </div>
            {verificationState?.state === verificationStates.pending && (
              <div className="verification-alert-container">
                <div
                  className="verification-alert"
                  onClick={(event: React.MouseEvent) => {
                    dispatch(
                      setUserVerified({
                        ...verificationState,
                        state: verificationStates.trying,
                        firstPayment: "pending",
                      })
                    );
                  }}
                >
                  {t("Verify")}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* //* Create Merchant Account Prompt ----- START */}
      {(verificationState?.state === verificationStates.unverified ||
        (verificationState?.firstPayment !== "completed" &&
          route !== "/profile/account" &&
          (!verificationState?.otp?.id ||
            verificationState?.otp?.id === ""))) &&
        !modernUI && <CreateAccountToggle />}

      {/* <AnimatePresence>
        {validateAndGetPhoneNumber(value, country, value)?.number &&
          validateAndGetPhoneNumber(value, country, value)?.number.length > 3 &&
          verificationState?.state === verificationStates.unverified && (
            <motion.div
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: -50, opacity: 0 }}
              transition={{ duration: 0.4 }}
              exit={{ y: -50, opacity: 0 }}
            >
              <div className="create-adup-account-slider">
                {true && (
                  <div className="createAdUpAccount">
                    <div className="createAdUpAccount__left">
                      <img loading="lazy" src={logo} alt="adup logo" />
                    </div>
                    <div className="createAdUpAccount__right">
                      <h3>{t("SaveInfoTitle")}</h3>
                      <span>{t("SaveInfoSubTitle")}</span>
                      <br />
                      <a
                        onClick={() => {
                          dispatch(setCreateAccountFlag(true));
                        }}
                        style={{ fontWeight: "800" }}
                      >
                        {t("YesSaveInfo")}
                      </a>
                      <a
                        onClick={() => {
                          dispatch(setCreateAccountFlag(false));
                        }}
                        style={{ fontWeight: "400" }}
                      >
                        {t("No")}
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </motion.div>
          )}
      </AnimatePresence> */}
      {/* //* Create Merchant Account Prompt ----- END */}
    </>
  );
};

export default CustomPhoneField;
