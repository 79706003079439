import { capitalizeFirstLetter } from "../utils/helper-functions";

const generateErrorMessage = (responseObj: any) => {
  let errorMessage;
  if (responseObj.message && typeof responseObj.message === "string") {
    return (errorMessage = capitalizeFirstLetter(responseObj.message));
  }
  if (typeof responseObj.errors === "object") {
    if (typeof responseObj.errors[0] === "string") {
      return (errorMessage = capitalizeFirstLetter(
        responseObj.errors.toString().replaceAll('"', "")
      ));
    }
    if (typeof Object.values(responseObj.errors) === "object") {
      return (errorMessage = capitalizeFirstLetter(
        Object.values(responseObj.errors).toString().replaceAll('"', "")
      ));
    }
  } else {
    return (errorMessage = "Something went wrong");
  }

  return errorMessage;
};

export default generateErrorMessage;
