import store from "../../store/store";
import { EVENT_URL } from "../constants/urls";

export type iEvents = "add_to_cart" | "view_page" | "add_payment_info";

/**
 * @param { string } event The event type which is triggered by the User
 * @param { object } eventDataObject The event Dataset  which is associated with the triggered event
 */

export async function addShopperEvent(event: iEvents, eventDataObject: object) {
  //? disabled temporally
  return;
  const shopId = store.getState().cart?.shop?.id;
  const userEmail = store.getState().users?.currentUser?.profile?.email;
  const key = store.getState().users.currentUser.token;

  if (shopId && userEmail && key) {
    const eventDataString = JSON.stringify(eventDataObject);

    const response = await fetch(EVENT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "X-API-Key": key,
        Authorization: key ? `Bearer ${key}` : "",
      },
      body: JSON.stringify({
        event: event,
        event_data: eventDataString,
        shop_id: shopId,
      }),
    });

    // trigger API Errors as Toasts
    const res = await response.json();
    // if (res) {
    //   toastHandler(res, store.dispatch, setToastMessage);
    // }

    return res;
  } else {
    return;
  }
}
