export interface AdupPaymentMethodOption {
  displayName: string;
  meta: any;
  onSelect: (args: any) => void;
  value: any;
}

export class AdupPaymentMethodOptionGroup {
  displayName: string = "";
  options: AdupPaymentMethodOption[] = [];
  meta: any = {};
  groupName: string = "";

  constructor(
    displayName: string,
    optionList: AdupPaymentMethodOption[],
    meta: any,
    groupName: string
  ) {
    this.options = optionList;
    this.displayName = displayName;
    this.meta = meta;
    this.groupName = groupName;
  }
}
