import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CheckoutStatisticsState,
  DeviceInfoStatisticsState,
  ProductStatisticsState,
  StatisticsState,
  UIStatisticsState,
  UserDemographicsStatisticsState,
} from "../../interfaces/states/StatisticsState";

const initialState: StatisticsState = {
  ui: {
    checkoutFormCount: 0,
    poweredByVisible: false,
    poweredByLogoVisible: false,
    pageFont: "",
    fontH1Size: "17px",
    payButtonColor: "",
    payButtonFont: "",
    poweredByLocation: "below header",
    checkoutFormLocation: "",
    colorPalette: "",
    cartTotalLocation: "in under summary grand total",
    priceLocation: "right side of product title",
    payButtonTemplate: "normal",
    poweredByTemplate: "normal",
    headerTemplate: "normal",
    imageTemplate: "normal",
    variantsTemplate: "normal",
    addToCartTemplate: "normal",
    allProductsTemplate: "normal",
    orderSummaryTemplate: "normal",
    checkoutTemplate: "normal",
    payButtonLocation: "fixed",
    payButtonSize: "",
    payButtonClickCount: 0,
    imageSize: "",
    fontSizeBody: "",
    isNotificationBox: false,
    interactionsPerSession: 0,
    customFieldCount: 0,
    checkoutFormsOrder:
      "phone number, create account, user details, shipping address, billing address, payment details",
    checkoutFormsColor: "",
  },
  checkout: {
    checkoutShippingCost: 0,
    paymentAttributes: "",
    activatedPaymentMethods: [],
  },
  product: {
    productName: "",
    productNameLength: 0,
    productDescription: "",
    productDescriptionLength: 0,
    productVariantsCount: 0,
    productPrice: 0,
    productImageCount: 0,
    productDiscount: 0,
    productShippingCost: 0,
    productDeliveryTime: "",
  },
  device_info: {
    deviceLanguage: "",
    deviceType: "",
    deviceBrowserType: "",
    deviceOperatingSystem: "",
    deviceOrientation: "",
  },
  user_demographics: {
    screenResolution: "",
    countryCode: "",
    country: "",
  },
  session_id: 0,
  initial_session_saved: false,
  last_statistics_dataset: {},
};

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    setUIStatistics: (state, action: PayloadAction<UIStatisticsState>) => {
      state.ui = action.payload;
    },

    setCheckoutStatistics: (
      state,
      action: PayloadAction<CheckoutStatisticsState>
    ) => {
      state.checkout = action.payload;
    },

    setProductStatistics: (
      state,
      action: PayloadAction<ProductStatisticsState>
    ) => {
      state.product = action.payload;
    },

    setDeviceInfoStatistics: (
      state,
      action: PayloadAction<DeviceInfoStatisticsState>
    ) => {
      state.device_info = action.payload;
    },

    setUserDemographicsStatistics: (
      state,
      action: PayloadAction<UserDemographicsStatisticsState>
    ) => {
      state.user_demographics = action.payload;
    },

    setPayButtonClickCount: (state) => {
      state.ui.payButtonClickCount = state.ui.payButtonClickCount + 1;
    },

    setCustomFieldCount: (state) => {
      state.ui.customFieldCount = state.ui.customFieldCount + 1;
    },

    setInteractionsPerSessionCount: (state) => {
      state.ui.interactionsPerSession = state.ui.interactionsPerSession + 1;
    },

    setStatisticsSessionId: (state, action: PayloadAction<Number>) => {
      state.session_id = action.payload;
    },

    setStatisticsInitialSessionSavedFlag: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.initial_session_saved = action.payload;
    },

    setLastStatisticsDataset: (state, action: PayloadAction<any>) => {
      state.last_statistics_dataset = action.payload;
    },
  },
});

export const {
  setUIStatistics,
  setCheckoutStatistics,
  setProductStatistics,
  setDeviceInfoStatistics,
  setUserDemographicsStatistics,
  setPayButtonClickCount,
  setCustomFieldCount,
  setInteractionsPerSessionCount,
  setStatisticsSessionId,
  setStatisticsInitialSessionSavedFlag,
  setLastStatisticsDataset,
} = statisticsSlice.actions;

export default statisticsSlice.reducer;
