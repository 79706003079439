import { t } from "i18next";
import { useEffect, useState } from "react";
import { makeFirstLetterCapital } from "../../lib/utils/toast-handler";
import "../../sass/components/socialCommerce/variantSelecter.scss";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

const VariantSelecter = ({
  attributes,
  defaultSelectedAttributes,
  setMap,
}: any) => {
  const variants = Object.keys(attributes);
  const [selectedVariants, setSelectedVariants] = useState<any>();
  const [attributeSkuMap, setAttributeSkuMap] = useState<any>();
  const variantSelectors = [];
  const dispatch = useDispatch();
  useEffect(() => {
    let defaultSelectedVariants: any;
    if (defaultSelectedAttributes) {
      for (let i of defaultSelectedAttributes) {
        const key = Object.keys(i)[0];
        defaultSelectedVariants = {
          ...defaultSelectedVariants,
          [key]: `${key}_${i[key]}`,
        };
      }
      setSelectedVariants(defaultSelectedVariants);
    }
  }, [defaultSelectedAttributes]);

  useEffect(() => {
    if (!defaultSelectedAttributes) {
      setSelectedVariants({});
    }
  }, []);

  useEffect(() => {
    console.log("Variant attribute selection changed : ", selectedVariants);
    if (selectedVariants) {
      const isSelectionsNotTouched = Object.keys(selectedVariants).length === 0;
      let map;
      if (!isSelectionsNotTouched) {
        for (let variant of variants) {
          if (selectedVariants[variant]) {
            map = map
              ? `${map}_${selectedVariants[variant]}`
              : `${selectedVariants[variant]}`;
          }
        }
        setAttributeSkuMap(map);
      }
    }
  }, [selectedVariants]);

  useEffect(() => {
    setMap(attributeSkuMap);
  }, [attributeSkuMap]);
  if (variants.length > 0) {
    for (let variant of variants) {
      const variantAttributes = [];

      if (attributes?.[variant].length > 0) {
        for (let attribute of attributes?.[variant]) {
          variantAttributes.push(
            <option value={`${variant}_${attribute}`}>
              {makeFirstLetterCapital(attribute)}
            </option>
          );
        }
      }

      if (selectedVariants && variantAttributes.length > 0) {
        variantSelectors.push(
          <div className="variantSelecter__select">
            <select
              onFocus={() => {
                dispatch(setInteractionsPerSessionCount());
              }}
              name="variantSelecter-1"
              id="variantSelecter-1"
              onChange={(e) => {
                setSelectedVariants({
                  ...selectedVariants,
                  [variant]: e.target.value,
                });
              }}
              defaultValue={`${selectedVariants[variant]}`}
            >
              <option value={``}>
                {t("Select")} {variant}
              </option>
              {variantAttributes}
            </select>
            <label htmlFor="variantSelecter-1">
              {makeFirstLetterCapital(variant)}
            </label>
          </div>
        );
      }
    }
  }
  return (
    <div className="variantSelecter">
      <div className="variantSelecter--container">{variantSelectors}</div>
    </div>
  );
};

export default VariantSelecter;
