import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdupUserAddress,
  EmptyAdupUserAddress,
} from "../../interfaces/AdupUserAddress";
import { AdupUserPhone } from "../../interfaces/AdupUserPhone";
import { AdupUserProfile } from "../../interfaces/AdupUserProfile";
import Country from "../../interfaces/Country";

const VERIFIED = "VERIFIED";
const UNVERIFIED = "UNVERIFIED";
const TRYING_VERIFICATION = "TRYING_VERIFICATION";
const PENDING_VERIFICATION = "PENDING_VERIFICATION";
const FAILED_VERIFICATION = "FAILED VERIFICATION";

export const verificationStates = {
  verified: VERIFIED,
  unverified: UNVERIFIED,
  trying: TRYING_VERIFICATION,
  pending: PENDING_VERIFICATION,
  failed: FAILED_VERIFICATION,
};

interface UserState {
  currentUser: {
    accountPhone: AdupUserPhone;
    phoneId: string;
    profile: AdupUserProfile;
    shipping?: any;
    addressId: string; // !! TODO:  @deprecate this
    name: undefined | string;
    registered: boolean;
    loggedIn: boolean;
    webauthn?: boolean;
    verified: {
      _: boolean;
      state: string;
      otp: {
        phone_number: any;
        country_code: any;
        id: string;
      };
      loggedInMethod: string;
      firstPayment: string;
    };
    phones: {
      savedNumbers: { [key: string]: any };
    };
    addresses: {
      savedAddresses: { [index: string]: AdupUserAddress };
    };
    token: undefined | string;
    valuesUpdated: {
      name: boolean;
      addresses: boolean;
    };
  };

  merchant: {
    id: string;
    merchant_id: string;
    merchant_name: string;
    fastcheckout_domain: string;
    dashboard_domain: string;
    status: string;
    views: string[];
    widgets: string[];
    config: {
      main_brand_color: string;
      secondary_brand_color: string;
      main_brand_hover_color: string;
      header_gradient_color_1: string;
      header_gradient_color_2: string;
      main_btn_color: string;
      main_btn_hover_color: string;
      main_btn_text_color: string;
      main_text_color: string;
      merchant_name: string;
      banner_text1: string;
      banner_text2: string;
      create_account_text: string;
      footer_text: string;
      monthly_newsletter_text: string;
      signup_text1: string;
      merchant_favicon: string;
      merchant_icon: string;
      merchant_logo_light: string;
      merchant_logo_dark: string;
      terms_url: string;
      privacy_url: string;
      faq_url: string;
      how_fastcheckout_works_url: string;
      login_image: string;
      signup_image: string;
    };
  };

  otherUsers: [];

  device_country: {
    country: string;
    country_code: string;
  };
}

const initialState: UserState = {
  currentUser: {
    accountPhone: {
      country: { countryCode: "", dialCode: "", flag: "", name: "" },
      number: "",
    },
    phoneId: "",
    profile: {
      email: "",
      id: "",
      meta: null,
      name: { first: "", second: "" },
    },
    shipping: {
      shippingAddress: EmptyAdupUserAddress,
      billingAddress: EmptyAdupUserAddress,
      isBillingShipping: true,
    },
    addressId: "",
    name: "",
    registered: false,
    loggedIn: false,
    webauthn: false,
    verified: {
      _: false,
      state: verificationStates.unverified,
      otp: {
        id: "",
        phone_number: "",
        country_code: "",
      },
      loggedInMethod: "",
      firstPayment: "pending",
    },
    phones: {
      savedNumbers: {},
    },
    addresses: {
      savedAddresses: {},
    },
    token: undefined,
    valuesUpdated: {
      addresses: false,
      name: false,
    },
  },

  merchant: {
    id: "",
    merchant_id: "",
    merchant_name: "",
    fastcheckout_domain: "",
    dashboard_domain: "",
    status: "",
    views: [],
    widgets: [],
    config: {
      main_brand_color: "",
      secondary_brand_color: "",
      main_brand_hover_color: "",
      header_gradient_color_1: "",
      header_gradient_color_2: "",
      main_btn_color: "",
      main_btn_hover_color: "",
      main_btn_text_color: "",
      main_text_color: "",
      merchant_name: "",
      monthly_newsletter_text: "",
      signup_text1: "",
      merchant_favicon: "",
      merchant_icon: "",
      merchant_logo_light: "",
      merchant_logo_dark: "",
      terms_url: "",
      privacy_url: "",
      faq_url: "",
      how_fastcheckout_works_url: "",
      login_image: "",
      signup_image: "",
      banner_text1: "",
      banner_text2: "",
      create_account_text: "",
      footer_text: "",
    },
  },

  otherUsers: [],

  device_country: {
    country: "",
    country_code: "",
  },
};

const userSlice = createSlice({
  name: "users",
  reducers: {
    setUserWebAuthn: (state, action: PayloadAction<boolean>) => {
      state.currentUser.webauthn = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string | undefined>) => {
      state.currentUser.token = action.payload;
    },
    setUserPhone: (state, action: PayloadAction<AdupUserPhone>) => {
      state.currentUser.accountPhone = action.payload;
    },
    setInitCountryCode: (state, action: PayloadAction<Country>) => {
      if (action.payload.countryCode) {
        state.currentUser.accountPhone.country = action.payload;
      }
    },
    updateUserState: (state, action) => {
      /**
       * Can happen when
       * (a) User first logs in and receives initial request
       * (b) User switches profiles
       */
      state.currentUser = action.payload.newUserState.currentUser;
      state.otherUsers = action.payload.newUserState.otherUsers;
    },
    setUserLoggedIn: (state, action) => {
      state.currentUser.loggedIn = action.payload;
    },
    setUserRegistered: (state, action) => {
      state.currentUser.registered = action.payload;
    },
    updateUserAddressValues: (
      state,
      action: PayloadAction<{ index: number; address: AdupUserAddress }>
    ) => {
      if (state.currentUser.addresses.savedAddresses[action.payload.index]) {
        state.currentUser.addresses.savedAddresses[action.payload.index] =
          action.payload.address;
      }
      console.log("An address was updated");
      state.currentUser.valuesUpdated.addresses = true;
    },
    updateExistingAddressById: (
      state,
      action: PayloadAction<AdupUserAddress>
    ) => {
      state.currentUser.addresses.savedAddresses[action.payload.id] =
        action.payload;

      console.log("An address was updated");
      state.currentUser.valuesUpdated.addresses = true;
    },
    setUserVerified: (state, action) => {
      state.currentUser.verified = action.payload;
    },
    clearUserSavedAddresses: (state, action) => {
      state.currentUser.addresses.savedAddresses = {};
    },
    deleteUserSavedAddress: (state, action) => {
      delete state.currentUser.addresses.savedAddresses[action.payload.id];

      console.log("An address was deleted");
      state.currentUser.valuesUpdated.addresses = true;
    },
    addNewUserAddress: (state, action: PayloadAction<AdupUserAddress>) => {
      state.currentUser.addresses.savedAddresses[action.payload.id] =
        action.payload;

      console.log("An address was updated");
      state.currentUser.valuesUpdated.addresses = true;
    },
    addNewPhoneNumber: (state, action) => {
      state.currentUser.phones.savedNumbers = {
        ...state.currentUser.phones.savedNumbers,
        [action.payload.key]: action.payload.value,
      };
    },
    setPhoneId: (state, action: PayloadAction<string>) => {
      state.currentUser.phoneId = action.payload;
    },
    setUserName: (state, action) => {
      state.currentUser.name = action.payload.name;

      console.log("Username was updated");
      state.currentUser.valuesUpdated.name = true;
    },
    setUserProfile: (state, action: PayloadAction<AdupUserProfile>) => {
      state.currentUser.profile = action.payload;

      console.log("Profile was updated");
      state.currentUser.valuesUpdated.name = true;
    },
    setUserAddressId: (state, action: PayloadAction<string>) => {
      state.currentUser.addressId = action.payload;
    },
    clearOTPId: (state, _) => {
      state.currentUser.verified.otp.id = "";
    },
    clearUser: (state, _) => {
      state.currentUser = { ...initialState.currentUser };
      state.otherUsers = [];
    },
    setBillingIsShipping: (state, action: PayloadAction<boolean>) => {
      state.currentUser.shipping.isBillingShipping = action.payload;
      if (
        action.payload === true &&
        state.currentUser?.shipping?.shippingAddress
      ) {
        state.currentUser.shipping.billingAddress =
          state.currentUser?.shipping?.shippingAddress;
      }
    },
    setBillingAddress: (state, action: PayloadAction<AdupUserAddress>) => {
      state.currentUser.shipping.billingAddress = action.payload;
    },
    setShippingAddress: (state, action: PayloadAction<AdupUserAddress>) => {
      state.currentUser.shipping.shippingAddress = action.payload;
      if (state?.currentUser?.shipping?.isBillingShipping === true) {
        state.currentUser.shipping.billingAddress = action.payload;
      }
    },
    clearAddresses: (state, _) => {
      state.currentUser.shipping.shippingAddress = EmptyAdupUserAddress;
      state.currentUser.shipping.billingAddress = EmptyAdupUserAddress;
      state.currentUser.shipping.isBillingShipping = true;
    },

    updateMerchant: (state, action: PayloadAction<any>) => {
      const {
        id,
        merchant_id,
        merchant_name,
        fastcheckout_domain,
        dashboard_domain,
        status,
        config,
        views,
      } = action.payload;

      if (id) state.merchant.id = id;
      if (merchant_id) state.merchant.merchant_id = merchant_id;
      if (merchant_name) state.merchant.merchant_name = merchant_name;
      if (fastcheckout_domain)
        state.merchant.fastcheckout_domain = fastcheckout_domain;
      if (dashboard_domain) state.merchant.dashboard_domain = dashboard_domain;
      if (status) state.merchant.status = status;
      if (views) state.merchant.views = views;
      if (config) state.merchant.config = config;
    },

    setDeviceCountry: (state, action: PayloadAction<any>) => {
      state.device_country = action.payload;
    },
  },
  initialState,
});

export const {
  clearUser,
  addNewPhoneNumber,
  setUserWebAuthn,
  setUserToken,
  setUserPhone,
  setUserLoggedIn,
  setUserRegistered,
  updateUserAddressValues,
  setUserVerified,
  addNewUserAddress,
  updateUserState,
  setUserName,
  setPhoneId,
  setUserProfile,
  setUserAddressId,
  clearOTPId,
  clearUserSavedAddresses,
  deleteUserSavedAddress,
  setInitCountryCode,
  updateExistingAddressById,
  setBillingIsShipping,
  setBillingAddress,
  setShippingAddress,
  updateMerchant,
  clearAddresses,
  setDeviceCountry,
} = userSlice.actions;

export default userSlice.reducer;
