import { makeFirstLetterCapital } from "../../lib/utils/toast-handler";
import "../../sass/components/profilePage/nameNumber.scss";

interface Props {
  fname: string;
  lname: string;
  code: string | number;
  number: number | string;
}

const NameNumber = ({ fname, lname, code, number }: Props) => {
  const first = makeFirstLetterCapital(fname?.toLocaleLowerCase());
  const last = makeFirstLetterCapital(lname?.toLocaleLowerCase());
  return (
    <div className="listNavigation-main">
      <div className="listNavigation">
        <div className="profilePageNameNumber">
          <div className="profilePageNameNumber__name">
            <h2>{`${first} ${last}`}</h2>
          </div>
          <div className="profilePageNameNumber__number">
            <p>{`${code} ${number}`}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameNumber;
