import { debounce } from "lodash";
import { SelectedPaymentMethodSavedStatus } from "../../../store/reducers/paymentSlice";
import store, { RootState } from "../../../store/store";
import { updatePaymentIntentValues } from "../../api/address/update-payment-intent-values";

/**
 * Intent should be re-fetched when the cart is edited
 */
function select(state: RootState) {
  return state.cart.items;
}

let currentCartValue: any = [];
function handleCartChange() {
  const route = window.decodeURI(window.location.pathname);

  const app_mode = route.split("/")[1];
  const shop_slug = route.split("/")[2];

  let previousValue = currentCartValue;
  currentCartValue = select(store.getState());
  const { selectedPaymentMethodSaveState } = store.getState().payment;
  let paymentMethodType = "";

  if (
    selectedPaymentMethodSaveState ===
    SelectedPaymentMethodSavedStatus.SAVED_METHOD
  ) {
    paymentMethodType =
      store.getState().payment.savedPaymentMethodSelected.type;
  } else {
    paymentMethodType =
      store.getState().payment.unsavedPaymentMethodSelected.type;
  }

  if (previousValue !== currentCartValue) {
    console.log("Cart changed from", previousValue, "to", currentCartValue);
    //* Update browser URL with updatedFastCheckoutUrl
    // if (app_mode === "fastcheckout") {
    //   const updatedFastCheckoutUrl = `/${app_mode}/${shop_slug}/${generateFastCheckoutUrlUsingCartItems(
    //     currentCartValue
    //   )}`;
    //   console.log(
    //     "Updating browser URL with updatedFastCheckoutUrl : ",
    //     updatedFastCheckoutUrl
    //   );
    //   window.history.replaceState({}, "", `${updatedFastCheckoutUrl}`);
    // }
    if (paymentMethodType && paymentMethodType !== "") {
      sessionCallDebounce();
    }
  }
}

const sessionCallDebounce = debounce(() => updatePaymentIntentValues(), 400);

export const unsubscribeHandleCartChange = store.subscribe(handleCartChange);
// unsubscribe();
