import { useDispatch, useSelector } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";
import "./giftSelector.scss";
import { setGift } from "../../store/reducers/cartSlice";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store/store";

const GiftSelector = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const giftValue = useSelector((state: RootState) => state.cart.gift);

  //the values are hardcoded for now
  const giftOptions = [
    { name: t("NoGift"), sku: "null" },
    { name: "Shaker", sku: "9143" },
    { name: "Shaker roze", sku: "12076" },
    // { name: "Whey delicious sample", sku: "14468" },
    // { name: "Blast sample", sku: "1371_1" },
    // { name: "Perfect mass gainer sample", sku: "11111" },
  ];

  return (
    <div className="fast-checkout-page-segment">
      <h2 className="heading-2-regular fast-checkout-segment-heading">
        {t("Gift")}
      </h2>
      {/* -------------- */}
      <div className="giftSelector">
        <div className="giftSelector--container">
          <div className="giftSelector__select">
            <select
              onFocus={() => {
                dispatch(setInteractionsPerSessionCount());
              }}
              onChange={(e) => {
                dispatch(setGift(e.target.value));
              }}
              name="giftSelector-1"
              id="giftSelector-1"
              value={giftValue ? giftValue : "null"}
            >
              {giftOptions?.map((item: any, key: number) => {
                return (
                  <option key={key} value={item.sku}>
                    {item.name}
                  </option>
                );
              })}
            </select>

            <label htmlFor="giftSelector-1">{"Select Gift"}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftSelector;
