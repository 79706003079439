import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdupUserAddress } from "../../interfaces/AdupUserAddress";
import { setShippingAddress } from "../../store/reducers/userSlice";
import { RootState } from "../../store/store";
import { AddressDetails } from "../address-details-form/AddressDetails";

/**
 * List of all the saved addresses
 */
interface Props {
  profilePageAddress?: boolean;
}
// const ShippingAddressList: React.FC = () => {
const ShippingAddressList = ({ profilePageAddress }: Props) => {
  const dispatch = useDispatch();

  const addressState = useSelector(
    (state: RootState) => state.users.currentUser?.addresses
  );

  const shippingAddress = useSelector(
    (state: RootState) => state.users.currentUser?.shipping?.shippingAddress
  );

  // when the current address is selected
  const changeSelectedAddressId = (key: string) => {
    if (addressState.savedAddresses[key]) {
      dispatch(setShippingAddress(addressState.savedAddresses[key]));
    }
  };

  return (
    <div className="shipping-address-list fast-checkout-page-segment">
      <div className="address-details-holder">
        {Object.keys(addressState.savedAddresses).map(
          (addressId: any, key: number) => (
            <AddressDetails
              index={key}
              address={addressState.savedAddresses[addressId]}
              retracted={true}
              isSelected={
                addressState.savedAddresses[addressId]?.id ===
                  shippingAddress?.id && !profilePageAddress
              }
              key={key}
              onClick={() => changeSelectedAddressId(addressId)}
              changeListeners={[]}
            />
          )
        )}
      </div>
    </div>
  );
};

export default ShippingAddressList;
