import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import HeadingWithRightButton from "../../../components/heading/HeadingWithRightButton";
import NewAddressForm from "../../../components/new-address-form/NewAddressForm";
import ShippingAddressList from "../../../components/shipping-address-list/ShippingAddressList";
import RoundButton from "../../../components/round-button/RoundButton";
import { editSVG, plusSVG } from "../../../assets/js/allCustomSVGs";

interface Props {
  profilePageAddress?: boolean;
}

const FastCheckoutShippingAddress = ({ profilePageAddress }: Props) => {
  const { t } = useTranslation();

  const [displayingNewAddressForm, setDisplayingNewAddressForm] =
    useState(false);

  const [isEditingIconsVisible, setIsEditingIconsVisible] = useState(false);

  return (
    <div className="shipping-details fast-checkout-page-segment">
      <span
        data-shippingeditingiconsvisible={isEditingIconsVisible.toString()}
        className="shipping-address-form-title"
      >
        <HeadingWithRightButton
          headingText={
            profilePageAddress ? t("Addresses") : t("ShippingAddressTitle")
          }
          button={
            <div className="headingWithRightButton__button--container">
              {/* <AddShippingAddressButton
                onClick={() => setDisplayingNewAddressForm(true)}
              /> */}
              <RoundButton
                color={"dark"}
                icon={plusSVG}
                onClick={() =>
                  setDisplayingNewAddressForm(!displayingNewAddressForm)
                }
                classString={`round-button-with-plus ${
                  displayingNewAddressForm
                    ? "icon-rotate-half-left"
                    : "icon-rotate-half-right"
                }`}
              />
              <RoundButton
                color={"dark"}
                icon={editSVG}
                onClick={() => setIsEditingIconsVisible(!isEditingIconsVisible)}
                classString="round-button-with-edit"
              />
              {/* <SearchShippingAddressButton onClick={() => null} /> */}
            </div>
          }
        />

        {displayingNewAddressForm && (
          <NewAddressForm
            addressType="shipping"
            close={() => setDisplayingNewAddressForm(false)}
          />
        )}

        <ShippingAddressList profilePageAddress={profilePageAddress} />
      </span>
    </div>
  );
};

export default FastCheckoutShippingAddress;
