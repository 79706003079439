import { t } from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import HeadingWithRightButton from "../../components/heading/HeadingWithRightButton";
import { BuckarooClientSideEncryption } from "../../lib/buckaroo.nl-sdk/BuckarooCardClientSideEncryptionSdk";
import AnimatedPayButtonBlack from "../../pages/fast-checkout/subcomponents/AnimatedPayButtonBlack";
import { setEncryptedBuckarooCardData } from "../../store/reducers/paymentSlice";
import { verificationStates } from "../../store/reducers/userSlice";
import store from "../../store/store";
import {
  detectCardType,
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./FormatMethods";
import executeScrollOn from "../../lib/utils/executeScrollOn";

export interface CardDetails {
  cvc: string;
  expiry: string;
  name: string;
  number: string;
  issuer: any;
  focus: undefined;
}

const BuckarooContainer: React.FC = () => {
  const { profile } = store.getState().users.currentUser;
  const verificationState = store.getState().users.currentUser.verified;
  const isWalletPaymentMethodInUse =
    store.getState().payment?.isWalletPaymentMethodInUse;
  const nameOnCard = profile?.name?.first + " " + profile?.name?.second;
  const [cardIssuer, setCardIssuer] = useState<String>();
  const [cardDetails, setCardDetails] = useState<CardDetails>({
    cvc: "",
    expiry: "",
    name: nameOnCard ?? "",
    number: "",
    issuer: "",
    focus: undefined,
  });

  const handleInputFocus = (e: any) => {
    setCardDetails({ ...cardDetails, focus: e.target.name });
  };

  const handleInputChange = (e: any) => {
    let { name, value } = e.target;
    let issuer;
    if (name === "number") {
      issuer = detectCardType(value);
      setCardIssuer(issuer);
      value = formatCreditCardNumber(value, issuer);
    } else if (name === "expiry") {
      value = formatExpirationDate(value);
    } else if (name === "cvc") {
      value = formatCVC(value, cardIssuer);
    }

    setCardDetails({ ...cardDetails, [name]: value });
  };

  useEffect(() => {
    console.log("Card Issuer ::", cardIssuer);
    setCardDetails({ ...cardDetails, issuer: cardIssuer });
  }, [cardIssuer]);

  useEffect(() => {
    // executeScrollOnFastCheckoutPaymentMount();
    executeScrollOn("fastCheckoutPaymentMountRef001");
  }, []);

  useEffect(() => {
    if (
      cardDetails.number &&
      cardDetails.number !== "" &&
      cardDetails.expiry &&
      cardDetails.expiry !== "" &&
      cardDetails.cvc &&
      cardDetails.cvc !== ""
    ) {
      encryptBuckarooCardData(cardDetails);
    } else {
      store.dispatch(
        setEncryptedBuckarooCardData({
          value: "",
          issuer: "",
        })
      );
    }
  }, [cardDetails]);

  // Encrypt Card Details using Buckaroo SDK
  const encryptBuckarooCardData = (cardDetails: CardDetails) => {
    let expYear = cardDetails.expiry.split("/")[1];
    let expMonth = cardDetails.expiry.split("/")[0];
    BuckarooClientSideEncryption.V001.encryptCardData(
      `${cardDetails.number}`, // Card Number
      `20${expYear}`, // Card Expiration Year //? Works well until Year 2099 😅
      `${expMonth}`, // Card Expiration Month
      `${cardDetails.cvc}`, // CVC
      cardDetails.name, // Cardholder Name
      function (encryptedCardData: any) {
        store.dispatch(
          setEncryptedBuckarooCardData({
            value: encryptedCardData,
            issuer: cardDetails.issuer,
          })
        );
      }
    );
  };

  return (
    <>
      {profile?.name?.first && !isWalletPaymentMethodInUse ? (
        <>
          <HeadingWithRightButton
            headingText={t("PaymentDetailsTitle")}
            button={<Fragment></Fragment>}
          />
          {/* <div>
            <Cards
              locale={{ valid: `MM\xa0 / \xa0YY` }}
              cvc={cardDetails.cvc}
              expiry={cardDetails.expiry}
              name={cardDetails.name}
              number={cardDetails.number}
              focused={cardDetails.focus}
              callback={handleCallback}
            />
          </div> */}
          <div id="PaymentForm">
            <form>
              <div className="flex-row">
                <div className={"animated-input-regular "}>
                  <input
                    type="tel"
                    name="number"
                    value={cardDetails.number}
                    className="animated__input"
                    style={{ fontFamily: "var(--main-font-secondary)" }}
                    pattern="[\d| ]{16,22}"
                    required
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                  <label className="animated__input--label">
                    {t("CardNumber")}
                  </label>
                </div>
              </div>
              <div className="flex-row-space-between">
                <div className="col-6 flex-grow">
                  <div className={"animated-input-regular "}>
                    <input
                      type="tel"
                      name="expiry"
                      value={cardDetails.expiry}
                      style={{
                        marginRight: "1rem",
                        fontFamily: "var(--main-font-secondary)",
                      }}
                      className="animated__input"
                      pattern="\d\d/\d\d"
                      required
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                    />
                    <label className="animated__input--label">
                      {t("Expiration")}
                    </label>
                  </div>
                </div>
                <div className="col-6">
                  <div className={"animated-input-regular "}>
                    <input
                      type="tel"
                      name="cvc"
                      value={cardDetails.cvc}
                      className="animated__input"
                      style={{ fontFamily: "var(--main-font-secondary)" }}
                      pattern="\d{3,4}"
                      required
                      onChange={handleInputChange}
                      onFocus={handleInputFocus}
                    />
                    <label className="animated__input--label">CVC</label>
                  </div>
                </div>
              </div>
              <input type="hidden" name="issuer" value={cardDetails.issuer} />
            </form>
          </div>
          {verificationState?.state !== verificationStates.pending && (
            <AnimatedPayButtonBlack />
          )}
        </>
      ) : null}
    </>
  );
};

export default BuckarooContainer;
