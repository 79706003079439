import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cartReducer from "./reducers/cartSlice";
import paymentReducer from "./reducers/paymentSlice";
import userReducer from "./reducers/userSlice";
import appReducer from "./reducers/appSlice";
import statisticsReducer from "./reducers/statisticsSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

/**
 * 'app', 'cart', 'user' data should be persisted (we use the default storage option of redux-persist, which is the browsers' localstorage)
 * payments
 */
const persistConfig = {
  key: "primary",
  version: 1,
  storage,
  whitelist: ["app", "users", "cart", "statistics"],
};

const rootReducer = combineReducers({
  statistics: statisticsReducer,
  cart: cartReducer,
  payment: paymentReducer,
  users: userReducer,
  app: appReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
