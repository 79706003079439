import { t } from "i18next";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HeadingWithRightButton from "../../components/heading/HeadingWithRightButton";
import { setIbanNumber, setBicNumber } from "../../store/reducers/paymentSlice";
import store from "../../store/store";
import executeScrollOn from "../../lib/utils/executeScrollOn";

const GiropayContainer: React.FC = () => {
  const paymentState = store.getState().payment;
  const ibanNumber = paymentState.ibanNumber;
  const bicNumber = paymentState.bicNumber;
  const [iban, setIban] = useState<string>(ibanNumber);
  const [bic, setBic] = useState<string>(bicNumber);
  const isWalletPaymentMethodInUse =
    store.getState().payment?.isWalletPaymentMethodInUse;
  const dispatch = useDispatch();

  const handleInputChange = (e: any) => {
    if (e.target?.name === "bic") {
      setBic(e.target?.value);
    }
    if (e.target?.name === "iban") {
      setIban(e.target?.value);
    }
  };

  useEffect(() => {
    if (iban) {
      dispatch(setIbanNumber(iban));
    }
    if (bic) {
      dispatch(setBicNumber(bic));
    }
  }, [iban, bic]);

  useEffect(() => {
    // executeScrollOnFastCheckoutPaymentMount();
    executeScrollOn("fastCheckoutPaymentMountRef001");
  }, []);

  return (
    <>
      {!isWalletPaymentMethodInUse && (
        <>
          <HeadingWithRightButton
            headingText={t("PaymentDetailsTitle")}
            button={<Fragment></Fragment>}
          />
          <div id="PaymentForm" style={{ paddingBottom: 20 }}>
            <form>
              <div className="flex-row">
                <div className={"animated-input-regular "}>
                  <input
                    type="text"
                    name="bic"
                    value={bic}
                    className="animated__input"
                    style={{ fontFamily: "var(--main-font-secondary)" }}
                    required
                    onChange={handleInputChange}
                  />
                  <label className="animated__input--label">BIC</label>
                </div>
              </div>
              <div className="flex-row">
                <div className={"animated-input-regular "}>
                  <input
                    type="text"
                    name="iban"
                    value={iban}
                    className="animated__input"
                    style={{ fontFamily: "var(--main-font-secondary)" }}
                    required
                    onChange={handleInputChange}
                  />
                  <label className="animated__input--label">
                    IBAN {t("Number")}
                  </label>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default GiropayContainer;
