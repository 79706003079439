import "../../sass/themes/theme1/theme1-orderSummary.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import getSymbolFromCurrency from "currency-symbol-map";
import { RootState } from "../../store/store";

interface Props {
  app_mode: string;
}

const Theme1OrderSummary = ({ app_mode }: Props) => {
  const cartData = useSelector((state: RootState) => state.cart);

  const { t } = useTranslation();

  const currency = getSymbolFromCurrency(cartData.shop?.currency) ?? "";
  const cartTotal = cartData.total;
  const discount = cartData.discount;
  const shippingFee = cartData.shipping;
  const convenienceFee = cartData.convenienceFee;

  return (
    /*  <h2 className="heading-2-regular fast-checkout-segment-heading">
        {app_mode === "fastcheckout" ? t("CartSummary") : t("OrderSummary")}
      </h2> */
    <div className="theme1-order-cart-summary">
      <div className="theme1-order-amounts">
        <div className="theme1-order-summary-heading">
          <h5>
            {app_mode === "fastcheckout" ? t("CartSummary") : t("OrderSummary")}{" "}
          </h5>
        </div>
        <hr></hr>
        <div className="theme1-order-amount-field">
          {app_mode === "fastcheckout" ? t("CartTotal") : t("OrderTotal")}
          <div>{`${currency} ${cartTotal?.toFixed(2)}`}</div>
        </div>
        <div className="theme1-order-amount-field">
          {t("Shipping")}
          <div>{`${currency} ${shippingFee?.toFixed(2)}`}</div>
        </div>
        {/* //* Commented out Discount until it's ready from BE 👇🏼
        <div className="theme1-order-amount-field">
          {t("Discount")}
          <div>{`- ${currency} ${discount?.toFixed(2)}`}</div>
        </div> */}
        {convenienceFee > 0 && (
          <div className="order-amount-field">
            {t("ConvenienceFee")}
            <div>{`${currency} ${convenienceFee?.toFixed(2)}`}</div>
          </div>
        )}
        <br></br>
        <hr></hr>
        <div className="theme1-order-total-holder">
          <h5>{t("GrandTotal")} </h5>{" "}
          <h5>
            {" "}
            {currency}{" "}
            {(+cartTotal + +shippingFee - +discount + +convenienceFee).toFixed(
              2
            )}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Theme1OrderSummary;
