import React, { useState } from "react";
import DeleteAddressMsg from "../delete-address-message/DeleteAddressMsg";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { trashSVG } from "../../assets/js/allCustomSVGs";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

type Props = {
  mainTitle: string;
  confirmDeleteMsg: string;
  onClick: () => void;
};

const DeleteButton: React.FC<Props> = ({
  mainTitle,
  confirmDeleteMsg,
  onClick,
}) => {
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const onCancelHandler = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };
  const dispatch = useDispatch();
  return (
    <div className="deleteButton-component">
      <button
        /* className="deleteButton" */
        onClick={(e) => {
          e.preventDefault();
          setIsDeletePopupOpen(!isDeletePopupOpen);
        }}
      >
        {/* <LazyLoadImage loading="lazy" src={deleteIcon} alt="delete address" /> */}
        <div className="deleteButton-component-iconHolder">
          <CustomSVGs svg={trashSVG} />
        </div>
      </button>
      {isDeletePopupOpen && (
        <DeleteAddressMsg
          mainTitle={mainTitle}
          confirmDeleteMsg={confirmDeleteMsg}
          onDeleteClick={() => {
            dispatch(setInteractionsPerSessionCount());
            onClick();
          }}
          onCancelClick={onCancelHandler}
        />
      )}
    </div>
  );
};

export default DeleteButton;
