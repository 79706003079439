import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { AnimatedLoadingCircle } from "../../assets/svg/animated-svg-components/AnimatedLoadingCircle";
import logo from "../../assets/png/logo_voorrosa-logoonly.png";
import { createAdUpAccount } from "../../lib/api/create-adup-account";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { setToastMessage } from "../../store/reducers/appSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

interface CreateAccountOverlayProps {
  hideSegment: () => void;
}

const backdropOpacityAnimation = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
    delay: 0.5,
  },
  exit: {
    opacity: 0,
  },
};

const slideUpAnimation = {
  animate: {
    transform: "translateY(0px)",
  },
  initial: {
    transform: "translateY(1000px)",
  },
  exit: {
    transform: "translateY(1000px)",
  },
  transition: {
    delay: 0,
    duration: 0.5,
  },
};

export const CreateAccountOverlay: React.FC<CreateAccountOverlayProps> = ({
  hideSegment,
}) => {
  const { t } = useTranslation();
  const [creatingAccount, setCreatingAccount] = useState(false);
  const merchantTheme = useSelector(
    (state: RootState) => state.users.merchant.config
  );

  const selfRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const pathname = window.decodeURI(window.location.pathname);
  const cart_id = pathname.split("/")[2];

  useEffect(() => {
    selfRef.current.focus();
  }, []);

  return (
    <motion.div ref={selfRef} className="verify-phone-number" tabIndex={0}>
      <motion.div
        key="backdrop"
        className="backdrop"
        {...backdropOpacityAnimation}
      ></motion.div>
      <motion.div
        key="phonenumberprompt"
        {...slideUpAnimation}
        className="verify-phone-number-prompt"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <h1>
          {t("AccountCreationTitle", {
            merchant_name: merchantTheme?.merchant_name
              ? merchantTheme.merchant_name
              : "",
          })}
        </h1>
        <LazyLoadImage
          loading="lazy"
          src={logo}
          alt={logo}
          width={120}
        ></LazyLoadImage>
        <p style={{ textAlign: "center" }}>
          {t("AccountCreationTitle2")} <br />
          <a href="">
            {t("AccountCreationTitle3", {
              merchant_name: merchantTheme?.merchant_name
                ? merchantTheme.merchant_name
                : "",
            })}
          </a>
        </p>
        <div className="verification-methods">
          <div
            className="send-again-link"
            onClick={() => {
              if (!creatingAccount) {
                backdropOpacityAnimation.transition = {
                  ...backdropOpacityAnimation.transition,
                  delay: 0.5,
                };
                slideUpAnimation.transition = {
                  ...slideUpAnimation.transition,
                  delay: 0,
                };

                hideSegment();
              }
            }}
          >
            {t("MaybeLater")}
          </div>
          <button
            className={
              "btn-use-email" + (creatingAccount ? " lighten-button" : "")
            }
            onClick={async () => {
              try {
                setCreatingAccount(true);
                const response = (await createAdUpAccount(cart_id)) as any;
                if (response?.success) {
                  setCreatingAccount(false);
                  hideSegment();
                } else {
                  setCreatingAccount(false);
                  setToastMessage({
                    text: t("AccountCreationError", {
                      merchant_name: merchantTheme?.merchant_name
                        ? merchantTheme.merchant_name
                        : "",
                    }),
                    type: "ERROR",
                  });
                }
              } catch (error) {
                console.log("Error creating Merchant Account", error);
                setCreatingAccount(false);
                setToastMessage({
                  text: t("AccountCreationError", {
                    merchant_name: merchantTheme?.merchant_name
                      ? merchantTheme.merchant_name
                      : "",
                  }),
                  type: "ERROR",
                });
              }
            }}
          >
            {(creatingAccount && (
              <div className="verifying-now-text">
                <AnimatedLoadingCircle />
                {t("Creating")} &nbsp;
              </div>
            )) || (
              <div className="webAuthn-text">
                {t("YesAccountCreation", {
                  merchant_name: merchantTheme?.merchant_name
                    ? merchantTheme.merchant_name
                    : "",
                })}
              </div>
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};
