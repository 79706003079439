import "../../sass/components/profilePage/orderDetails.scss";
import { useEffect } from "react";
import {
  capitalizeFirstLetter,
  formatDate,
} from "../../lib/utils/helper-functions";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { crossSVG, linkSVG } from "../../assets/js/allCustomSVGs";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  hide: () => void;
  selectedOrder: any;
}

const OrderDetails = ({ hide, selectedOrder }: Props) => {
  const { t } = useTranslation();
  const slug = selectedOrder?.shop_slug;
  const location = window.location.origin;

  const getAddress = (type: "billing" | "shipping") => {
    console.log(selectedOrder?.cart_data?.shopper?.addresses?.shipping_address);
    let address;
    const shippingAddress =
      selectedOrder?.cart_data?.shopper?.addresses?.shipping_address;
    const billingAddress =
      selectedOrder?.cart_data?.shopper?.addresses?.billing_address;
    if (type === "billing" && billingAddress) {
      address = billingAddress;
    }
    if (type === "shipping" && shippingAddress) {
      address = shippingAddress;
    }
    if (address) {
      const text1 = `${address?.house_number}, ${address?.street}, ${
        address?.street2 ? address?.street2 + "," : ""
      } ${address?.city},`;
      const text2 = `${address?.postcode}, ${
        address?.country?.country ? address?.country?.country : address?.country
      }`;

      return { text1, text2 };
    }

    return { text1: "------", text2: "------" };
  };

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (
        event.key === "Escape" ||
        event.keyCode === 27 ||
        event.key === "End"
      ) {
        event.preventDefault();
        console.log("pressed Esc ╳");
        hide();
      }
    };

    document.addEventListener("keydown", keyDownHandler);
    // 👇️ clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  return (
    <>
      <div
        onClick={() => {
          hide();
        }}
        className="orderDetails__overlay"
      ></div>
      <div className="orderDetails__wrapper ">
        <div className="orderDetails-title">
          <h1>{`${t("OrderDetails")} ${selectedOrder?.id}`}</h1>
          {/*  <RoundButton
            color={"light"}
            icon={close}
            
          /> */}
          <div
            onClick={() => {
              hide();
            }}
            className="orderDetails-title-svg"
          >
            <CustomSVGs svg={crossSVG} />
          </div>
        </div>
        <div className="orderDetails__content">
          {/* ------- */}
          <div className="orderDetails__section orderDetails__content--webshop">
            <div className="left">
              <h2 className="orderDetails__content--title">{t("Webshop")}</h2>
              <p>{selectedOrder?.shop_name}</p>
              {/* <span>myshopname.nl</span> */}
            </div>
            <div className="right">
              {/* <RoundButton color={"light"} icon={editIcon} /> */}

              <Link target={"_blank"} to={`/${slug}`}>
                <div className="orderDetails-right-svg">
                  <CustomSVGs svg={linkSVG} />
                </div>
              </Link>
            </div>
          </div>
          {/* ------- */}
          <div className="orderDetails__section orderDetails__content--normal">
            <h2 className="orderDetails__content--title">{t("DateTime")}</h2>
            <p>
              {selectedOrder?.created_at
                ? formatDate(selectedOrder?.created_at)
                : "------"}
            </p>
          </div>
          {/* ------- */}
          <div className="orderDetails__section orderDetails__content--paymethod">
            <h2 className="orderDetails__content--title">
              {t("PaymentMethod")}
            </h2>
            <div className="bottom">
              {selectedOrder?.payment_method && (
                <div className="left">
                  <LazyLoadImage
                    loading="lazy"
                    src={`${location}/payment-method/${selectedOrder?.payment_method}.svg`}
                    alt={selectedOrder?.payment_method}
                  />
                </div>
              )}
              <div className="right">
                {/* <p>card</p> */}
                {/* <p>Bank : ASN</p> */}
                {/* <p>Account Number : 1234567890</p> */}
                <p>{`${t("Method")} : ${
                  selectedOrder?.payment_method
                    ? capitalizeFirstLetter(selectedOrder?.payment_method)
                    : "------"
                }`}</p>
                <p>{`${t("Gateway")} : ${
                  selectedOrder?.payment_gateway
                    ? capitalizeFirstLetter(selectedOrder?.payment_gateway)
                    : "------"
                }`}</p>
                <p>{`${t("Status")} : ${
                  selectedOrder?.status
                    ? capitalizeFirstLetter(selectedOrder?.status)
                    : "------"
                }`}</p>
              </div>
            </div>
          </div>
          {/* ------- */}
          <div className="orderDetails__section orderDetails__content--normal">
            <h2 className="orderDetails__content--title">
              {t("ShippingAddressTitle")}
            </h2>
            <p>{getAddress("shipping").text1}</p>
            <p>{getAddress("shipping").text2}</p>
          </div>
          {/* ------- */}
          <div className="orderDetails__section orderDetails__content--normal">
            <h2 className="orderDetails__content--title">
              {t("BillingAddressTitle")}
            </h2>
            <p>{getAddress("billing").text1}</p>
            <p>{getAddress("billing").text2}</p>
          </div>
          {/* ------- */}
        </div>
      </div>
    </>
  );
};

export default OrderDetails;
