import React, { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import store, { RootState } from "../../store/store";
import {
  setAcceptSocialMediaCookie,
  setShowCookieConsent,
} from "../../store/reducers/appSlice";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ToggleSwitch from "../button/ToggleSwitch";

interface CookieConsentProps {}

const backdropOpacityAnimation = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
    delay: 0,
  },
  exit: {
    opacity: 0,
    duration: 0.1,
  },
};

const slideUpAnimation = {
  animate: {
    transform: "translateY(0px)",
  },
  initial: {
    transform: "translateY(1000px)",
  },
  exit: {
    transform: "translateY(1000px)",
    duration: 0.5,
  },
  transition: {
    delay: 0,
    duration: 0.5,
  },
};

export const CookieConsent: React.FC<CookieConsentProps> = ({}) => {
  const { t } = useTranslation();
  const selfRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [showPreferences, setShowPreferences] = useState<boolean>(false);
  const [socialMediaCookie, setSocialMediaCookie] = useState<boolean>(true);
  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );

  useEffect(() => {
    if (showCookieConsent) selfRef.current.focus();
  }, [showCookieConsent]);

  return (
    <AnimatePresence>
      {showCookieConsent && (
        <div className="verify-phone-segment">
          <motion.div
            ref={selfRef}
            className="verify-phone-number"
            tabIndex={0}
          >
            <motion.div
              key="backdrop"
              className="backdrop"
              {...backdropOpacityAnimation}
            ></motion.div>
            <motion.div
              key="phonenumberprompt"
              {...slideUpAnimation}
              className="verify-phone-number-prompt"
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
              }}
            >
              <h1>{t("CookieConsentTitle")}</h1>
              <p style={{ textAlign: "center" }}>
                {t("CookieConsentDescription")}
              </p>
              <React.Suspense
                fallback={<div className="verify-phone-segment"></div>}
              >
                {showPreferences && (
                  <div>
                    <ToggleSwitch
                      title={t("EssentialCookies")}
                      defaultChecked
                      disabled
                      isOn={true}
                    />
                    <ToggleSwitch
                      title={t("SocialMediaCookies")}
                      defaultChecked
                      isOn={socialMediaCookie}
                      onClick={setSocialMediaCookie}
                    />
                  </div>
                )}

                <div
                  className="flex-row"
                  style={{ padding: 5 }}
                  onClick={() => {
                    setShowPreferences(!showPreferences);
                  }}
                >
                  <span className="send-again-link" style={{ margin: 10 }}>
                    {t("CookieConsentPreferences")}
                  </span>
                  <div className={`roundButton-component`}>
                    <button style={{ backgroundColor: "#f7f5f9" }}>
                      <div
                        style={{ paddingTop: 5 }}
                        className={`roundButton-component-iconHolder ${
                          !showPreferences ? "icon-rotate" : ""
                        }`}
                      >
                        ^
                      </div>
                    </button>
                  </div>
                </div>
              </React.Suspense>
              <div className="verification-methods">
                {!showPreferences && (
                  <button
                    className={"btn-use-accept"}
                    onClick={() => {
                      store.dispatch(setAcceptSocialMediaCookie(true));
                      store.dispatch(setShowCookieConsent(false));
                      console.log("Cookie Consent All Accepted");
                      console.log("Social Media Cookie : ", true);
                    }}
                  >
                    {t("AcceptAll")}
                  </button>
                )}
                {showPreferences && (
                  <button
                    className={"btn-use-accept"}
                    onClick={() => {
                      store.dispatch(
                        setAcceptSocialMediaCookie(socialMediaCookie)
                      );
                      store.dispatch(setShowCookieConsent(false));
                      console.log("Cookie Consent Saved Preferences");
                      console.log("Social Media Cookie : ", socialMediaCookie);
                    }}
                  >
                    {t("SavePreferences")}
                  </button>
                )}
                <div className="verifyLater">
                  <span
                    onClick={() => {
                      store.dispatch(setAcceptSocialMediaCookie(false));
                      store.dispatch(setShowCookieConsent(false));
                      console.log("Cookie Consent All Rejected");
                      console.log("Social Media Cookie : ", false);
                    }}
                    className="verifyLater__span2"
                  >
                    {t("RejectAll")}
                  </span>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};
