import { motion } from "framer-motion";
// import { WhiteArrowRight } from "../svg-components/white-arrow-right";
import { BlackArrowRight } from "../svg-components/black-arrow-right";

export const AnimatedWhiteArrowRight = () => {
  return (
    <motion.div
      className="animated-arrow-holder"
      transition={{ repeat: Infinity, duration: 0.6, repeatType: "mirror" }}
      animate={{ transform: "translateX(6.0px)" }}
    >
      <BlackArrowRight />
    </motion.div>
  );
};
