import AnimatedTextFieldRegular from "../custom-input-field/AnimatedTextFieldRegular";
import { useEffect, useState } from "react";
import { RootState } from "../../store/store";
import { AdupUserProfile } from "../../interfaces/AdupUserProfile";
import {
  setUserProfile,
  verificationStates,
} from "../../store/reducers/userSlice";
import { cssRedBorderClass } from "../constants";
import { useTranslation } from "react-i18next";
import { setIsRequiredFieldsNotFilled } from "../../store/reducers/paymentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Country from "../../interfaces/Country";
import CustomPhoneField from "../custom-input-field/CustomPhoneField";
import { phoneNumberChangeListeners } from "../account-form/phone-change-listeners";
import CreateAccountToggle from "../create-account/CreateAccountToggle";
import { Link } from "react-router-dom";
import executeScrollOn from "../../lib/utils/executeScrollOn";

const UserDetails = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // username associated with the phone number above
  const currentUserProfile: AdupUserProfile = useSelector(
    (state: RootState) => state.users.currentUser.profile
  );

  // username associated with the phone number above
  const authState = useSelector((state: RootState) => state.app.auth);

  const updateCurrentUserFirstName = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        name: { ...currentUserProfile.name, first: newName },
      })
    );
  };
  const updateCurrentUserSecondName = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        name: { ...currentUserProfile.name, second: newName },
      })
    );
  };
  const updateCurrentEmail = (newName: string) => {
    dispatch(
      setUserProfile({
        ...currentUserProfile,
        email: newName,
      })
    );
  };

  const paymentStatus = useSelector(
    (state: RootState) => state.payment.paymentProgress
  );

  const hasApiError = useSelector(
    (state: RootState) => state.payment.hasApiError
  );

  useEffect(() => {
    if (hasApiError?.email) setIsEmailInvalid(true);
  }, [hasApiError]);

  // for setting a class if the input field is incomplete
  const [namesIncomplete, setNamesIncomplete] = useState<{
    first: boolean | null;
    second: boolean | null;
    email: boolean | null;
  }>({ first: null, second: null, email: null });

  // for setting a class if the email input is invalid
  const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);

  // Validate inputs on submitting for payment
  useEffect(() => {
    if (paymentStatus === "PAYMENT_STARTED") {
      if (currentUserProfile.name.first === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: true,
          second: namesIncomplete.second,
          email: namesIncomplete.email,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: false,
          second: namesIncomplete.second,
          email: namesIncomplete.email,
        }));
      }

      if (currentUserProfile.name.second === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: true,
          email: namesIncomplete.email,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: false,
          email: namesIncomplete.email,
        }));
      }

      if (currentUserProfile.email === "") {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: namesIncomplete.second,
          email: true,
        }));
      } else {
        setNamesIncomplete((namesIncomplete) => ({
          first: namesIncomplete.first,
          second: namesIncomplete.second,
          email: false,
        }));
      }
    }
    if (paymentStatus === "PAYMENT_NOT_STARTED") {
      setNamesIncomplete({ first: null, second: null, email: null });
    }
  }, [
    paymentStatus,
    currentUserProfile.name?.first,
    currentUserProfile.name?.second,
    currentUserProfile.email,
  ]);

  useEffect(() => {
    if (
      namesIncomplete.first ||
      namesIncomplete.second ||
      namesIncomplete.email ||
      isEmailInvalid
    ) {
      // executeScrollOnProfileError();
      executeScrollOn("profileDetailsFormRef001");
      dispatch(setIsRequiredFieldsNotFilled(true));
    } else {
      dispatch(setIsRequiredFieldsNotFilled(false));
    }
  }, [namesIncomplete, isEmailInvalid]);

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  //phone input stuff
  const route = window.decodeURI(useLocation().pathname);

  // current phone number attached to a user account
  const userPhone = useSelector(
    (state: RootState) => state.users.currentUser.accountPhone
  );

  // country returned from the server depending on the customer IP
  const appIPCountry = useSelector(
    (state: RootState) => state.app.settings.ipAddressCountryCode
  );

  // map of countries supported, key is country code ('UK', 'LK', 'US', 'AU', 'IN' etc)
  const countries = useSelector(
    (state: RootState) => state.app.phone.countryCodes
  );

  // map of countries supported, key is dial code ('+94', '+1' etc)
  const dialCodesMap = useSelector(
    (state: RootState) => state.app.phone.countryDialCodeMap
  );

  const [phone, setPhone] = useState(userPhone ? userPhone.number : "");

  const [country, setCountry] = useState<Country>(
    userPhone?.country?.countryCode && userPhone.country.countryCode !== ""
      ? userPhone.country
      : countries[appIPCountry]
  );

  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  return (
    <div className="userDetails-wraper">
      <div className="account-phone-heading-holder">
        <div className="account-phone-heading-holder__left">
          <h2>{t("YourDetails")}</h2>
        </div>
        <div className="account-phone-heading-holder__right">
          <Link to="/profile">
            <div
              title={t("Profile")}
              className="account-phone-heading-holder__right--profile"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 24 24"
              >
                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm7.753 18.305c-.261-.586-.789-.991-1.871-1.241-2.293-.529-4.428-.993-3.393-2.945 3.145-5.942.833-9.119-2.489-9.119-3.388 0-5.644 3.299-2.489 9.119 1.066 1.964-1.148 2.427-3.393 2.945-1.084.25-1.608.658-1.867 1.246-1.405-1.723-2.251-3.919-2.251-6.31 0-5.514 4.486-10 10-10s10 4.486 10 10c0 2.389-.845 4.583-2.247 6.305z" />
              </svg>
            </div>
          </Link>
        </div>
      </div>
      <div className="userDetails">
        <div
          style={{ marginBottom: "0" }}
          className="account-form fast-checkout-page-segment"
        >
          <CustomPhoneField
            initialCountry={country}
            initialPhone={phone}
            countries={countries}
            dialCodeToCountryCodeMap={dialCodesMap}
            changeListeners={
              route !== "/demo/social-commerce"
                ? phoneNumberChangeListeners
                : undefined
            }
            disabled={route === "/profile/account" ? true : false}
            modernUI={true}
          />
          <span>
            <AnimatedTextFieldRegular
              placeholder={
                t("FirstName") +
                (namesIncomplete.first ? ` (${t("Required")})` : "")
              }
              onChange={(e: any) =>
                updateCurrentUserFirstName((e.target as HTMLInputElement).value)
              }
              key={"fname"}
              name="first_name"
              value={currentUserProfile.name?.first}
              extraClasses={namesIncomplete.first ? cssRedBorderClass : ""}
            />

            <AnimatedTextFieldRegular
              placeholder={
                t("LastName") +
                (namesIncomplete.second ? ` (${t("Required")})` : "")
              }
              onChange={(e: any) =>
                updateCurrentUserSecondName(
                  (e.target as HTMLInputElement).value
                )
              }
              key={"sname"}
              name="last_name"
              value={currentUserProfile.name?.second}
              extraClasses={namesIncomplete.second ? cssRedBorderClass : ""}
            />

            <AnimatedTextFieldRegular
              isLastInput={true}
              placeholder={
                t("Email") +
                (namesIncomplete.email
                  ? ` (${t("Required")})`
                  : isEmailInvalid
                  ? ` (${t("EmailInvalid")})`
                  : "")
              }
              onChange={(e: any) => {
                if (validateEmail((e.target as HTMLInputElement).value)) {
                  setIsEmailInvalid(false);
                } else {
                  setIsEmailInvalid(true);
                }
                updateCurrentEmail((e.target as HTMLInputElement).value);
              }}
              key={"email"}
              name="email"
              value={currentUserProfile.email}
              extraClasses={
                namesIncomplete.email || isEmailInvalid ? cssRedBorderClass : ""
              }
            />
          </span>
        </div>
      </div>

      {/* //* Create Merchant Account Prompt ----- START */}
      {(verificationState?.state === verificationStates.unverified ||
        (verificationState?.firstPayment !== "completed" &&
          (!verificationState?.otp?.id ||
            verificationState?.otp?.id === ""))) && <CreateAccountToggle />}
    </div>
  );
};

export default UserDetails;
