import { OTP_URL } from "../constants/urls";
import store from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";

/**
 * @param { string } country Telephone number country dial code
 * @param { string } phone Telephone number
 */
export async function sendPhoneCheck(country: string, phone: string) {
  const key = store.getState().users.currentUser.token;
  const shopId = store.getState().cart?.shop?.id;

  const params = `?phone_number=${phone}&country_code=${country}&shop_id=${shopId}`;

  const response = await fetch(OTP_URL + params, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: key ? `Bearer ${key}` : "",
    },
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;
  return result;
}
