import {
  clearAdupPaymentMethods,
  newAdupPaymentMethod,
  setBicNumber,
  setIbanNumber,
  setSavedPaymentAttributes,
  setSelectedPaymentMethodOption,
} from "../../store/reducers/paymentSlice";
import store from "../../store/store";

export default function updateStateFromPaymentMethodsBlock(shopper: any) {
  const paymentMethods = store.getState().payment.availablePaymentMethods;
  let paymentAttributes;

  const clearComplete = store.dispatch(clearAdupPaymentMethods(null));

  //* store all saved payment attributes to state
  store.dispatch(setSavedPaymentAttributes(shopper?.payment_attributes));

  for (const key in paymentMethods) {
    //*- referring 0 index below /&&/ also API should return only 1 object here
    const paymentMethodItem = paymentMethods[key][0];
    if (clearComplete) {
      store.dispatch(
        newAdupPaymentMethod({
          displayTitle: paymentMethodItem.label, // Credit Card
          iconURL: paymentMethodItem.icon, // "default_logo.png"
          name: key, // Card
          serviceProvider: paymentMethodItem.gateway, // "Stripe"
          // isSavable: true,
          // isOnetime: false,
          type: key, // Card
          processor: paymentMethodItem.processor,
          banks: paymentMethodItem?.data?.bank,
          session: paymentMethodItem.session,
          allowedData: paymentMethodItem.allowed_data,
          data: paymentMethodItem.data,
          fields: paymentMethodItem.fields,
        })
      );
    }

    if (key === "ideal") {
      if (shopper && shopper?.payment_attributes?.ideal_bank) {
        paymentAttributes = shopper?.payment_attributes?.ideal_bank;
      }
    } else if (key === "p24") {
      if (shopper && shopper?.payment_attributes?.p24_bank) {
        paymentAttributes = shopper?.payment_attributes?.p24_bank;
      }
    } else if (key === "eps") {
      if (shopper && shopper?.payment_attributes?.eps_bank) {
        paymentAttributes = shopper?.payment_attributes?.eps_bank;
      }
    } else if (key === "sepa_debit") {
      if (shopper && shopper?.payment_attributes?.iban_number) {
        paymentAttributes = shopper?.payment_attributes?.sepa_debit_iban_number;
        store.dispatch(setIbanNumber(paymentAttributes));
      }
    } else if (key === "giropay") {
      if (
        shopper &&
        shopper?.payment_attributes?.bic_number &&
        shopper?.payment_attributes?.iban_number
      ) {
        paymentAttributes = {
          bic_number: shopper?.payment_attributes?.giropay_bic_number,
          iban_number: shopper?.payment_attributes?.giropay_iban_number,
        };
        store.dispatch(setBicNumber(paymentAttributes?.bic_number));
        store.dispatch(setIbanNumber(paymentAttributes?.iban_number));
      }
    }

    //* Setting up the Payment methods with option default value
    if (shopper?.payment_method_last_used === key) {
      if (key === "ideal" || key === "p24" || key === "eps") {
        if (paymentMethodItem?.data && paymentMethodItem?.data?.bank) {
          const bankOptions = paymentMethodItem?.data?.bank;
          store.dispatch(
            setSelectedPaymentMethodOption({
              key: paymentAttributes ?? Object.keys(bankOptions)[0],
              optionData:
                bankOptions[paymentAttributes] ??
                bankOptions[Object.keys(bankOptions)[0]],
            })
          );
        }
      }
    }
  }
}
