import { debounce } from "lodash";
import {
  AdupPaymentMethodSelection,
  ConvenienceFeeRate,
} from "../../../interfaces/AdupPaymentMethod";
import {
  setConvenienceFee,
  setGrandTotal,
} from "../../../store/reducers/cartSlice";
import {
  SelectedPaymentMethodSavedStatus,
  initialPaymentSelected,
} from "../../../store/reducers/paymentSlice";
import store, { RootState } from "../../../store/store";
import { updatePaymentIntentValues } from "../../api/address/update-payment-intent-values";

/**
 * Intent should be re-fetched when payment methods are edited
 */
function selectPaymentMethod(state: RootState) {
  return state.payment.unsavedPaymentMethodSelected;
}
function selectAvailablePaymentMethods(state: RootState) {
  return state.payment.availablePaymentMethods;
}
function getUserVerificationState(state: RootState) {
  return state.users?.currentUser?.verified;
}
function getCartGrandTotalBeforeConvenienceFee(state: RootState) {
  return state.cart?.grandTotalBeforeConvenienceFee;
}
function getShop(state: RootState) {
  return state.cart?.shop;
}

let currentPaymentMethod: AdupPaymentMethodSelection = initialPaymentSelected;
let currentCartGrandTotalBeforeConvenienceFee: any = 0;
async function handlePaymentMethodChange() {
  try {
    let previousPaymentMethod = currentPaymentMethod;
    let previousCartGrandTotalBeforeConvenienceFee =
      currentCartGrandTotalBeforeConvenienceFee;
    currentPaymentMethod = selectPaymentMethod(store.getState());
    currentCartGrandTotalBeforeConvenienceFee =
      getCartGrandTotalBeforeConvenienceFee(store.getState()) &&
      !Number.isNaN(+getCartGrandTotalBeforeConvenienceFee(store.getState()))
        ? +getCartGrandTotalBeforeConvenienceFee(store.getState())
        : 0;
    const { selectedPaymentMethodSaveState } = store.getState().payment;
    const shopSettings: any = getShop(store.getState())?.settings;
    let paymentMethodType = "";

    if (
      selectedPaymentMethodSaveState ===
      SelectedPaymentMethodSavedStatus.SAVED_METHOD
    ) {
      paymentMethodType =
        store.getState().payment.savedPaymentMethodSelected.type;
    } else {
      paymentMethodType =
        store.getState().payment.unsavedPaymentMethodSelected.type;
    }

    const calculationLogic = () => {
      if (paymentMethodType && paymentMethodType !== "") {
        const selectedPaymentMethod: any = selectAvailablePaymentMethods(
          store.getState()
        )[currentPaymentMethod.name];
        const method = selectedPaymentMethod[0];
        if (
          method &&
          method?.convenience_fee !== "" &&
          method?.convenience_fee_type !== "" &&
          method?.convenience_fee !== null &&
          method?.convenience_fee_type !== null
        ) {
          if (
            !shopSettings?.fixed_conveniencefee ||
            shopSettings?.fixed_conveniencefee === "false"
          ) {
            console.log("PAYMENT METHOD BASED Convenience Fee enabled");

            const convenienceFeeRate = {
              rate_type: method?.convenience_fee_type,
              rate: method?.convenience_fee,
            };
            calculatePaymentMethodBasedConvenienceFee(
              currentCartGrandTotalBeforeConvenienceFee,
              convenienceFeeRate,
              shopSettings?.default_conveniencefee_cost
            );
          } else {
            console.log("FIXED Convenience Fee enabled");
            calculateFixedConvenienceFee(
              currentCartGrandTotalBeforeConvenienceFee,
              shopSettings?.default_conveniencefee_cost
            );
          }
        } else {
          console.log(
            "No Payment Method Based Convenience Fee rate found for selected Payment Method..."
          );
          console.log(
            "Fallback : Re-calculating fixed Convenience Fee rate..."
          );
          calculateFixedConvenienceFee(
            currentCartGrandTotalBeforeConvenienceFee,
            shopSettings?.default_conveniencefee_cost
          );
        }

        sessionCallDebounce();
      } else {
        console.log(
          "No Payment Method is selected.. Setting Convenience Fee as 0.0"
        );
        calculateFixedConvenienceFee(
          currentCartGrandTotalBeforeConvenienceFee,
          0.0
        );
      }
    };

    if (previousPaymentMethod !== currentPaymentMethod) {
      console.log(
        "Payment Method changed from",
        previousPaymentMethod,
        "to",
        currentPaymentMethod
      );

      calculationLogic();
    }

    if (
      previousCartGrandTotalBeforeConvenienceFee !==
      currentCartGrandTotalBeforeConvenienceFee
    ) {
      console.log(
        "Cart Total changed from",
        previousCartGrandTotalBeforeConvenienceFee,
        "to",
        currentCartGrandTotalBeforeConvenienceFee
      );

      calculationLogic();
    }
  } catch (error) {
    console.log("Error : ", error);
  }
}

function calculatePaymentMethodBasedConvenienceFee(
  currentCartGrandTotal: number,
  convenienceFeeRate: ConvenienceFeeRate | undefined,
  defaultConvenienceFee: number
) {
  let type = convenienceFeeRate?.rate_type;
  let rate =
    convenienceFeeRate?.rate && !Number.isNaN(+convenienceFeeRate?.rate)
      ? +convenienceFeeRate?.rate
      : 0;
  let newConvenienceFeeRate = 0.0;
  let newGrandTotal;

  console.log("Re-calculating Payment Method Based Convenience Fee rate...");

  if (type && String(rate)) {
    if (type === "percentage") {
      newConvenienceFeeRate = currentCartGrandTotal * (rate / 100);
    } else if (type === "flat") {
      newConvenienceFeeRate = rate;
    }
  } else {
    console.log(
      "No Payment Method Based Convenience Fee rate found for selected Payment Method..."
    );
    console.log("Re-calculating fixed Convenience Fee rate...");
    newConvenienceFeeRate =
      defaultConvenienceFee && !Number.isNaN(+defaultConvenienceFee)
        ? +defaultConvenienceFee
        : 0.0;
  }

  console.log("Calculated new Convenience Fee : ", newConvenienceFeeRate);
  store.dispatch(setConvenienceFee(+newConvenienceFeeRate));
  newGrandTotal = (+currentCartGrandTotal + +newConvenienceFeeRate).toFixed(2);
  console.log("Calculated new cartGrandTotal : ", newGrandTotal);
  store.dispatch(setGrandTotal(+newGrandTotal));
}

function calculateFixedConvenienceFee(
  currentCartGrandTotal: number,
  defaultConvenienceFee: number
) {
  let newConvenienceFeeRate = 0.0;
  let newGrandTotal;

  console.log("Re-calculating fixed Convenience Fee rate...");

  newConvenienceFeeRate =
    defaultConvenienceFee && !Number.isNaN(+defaultConvenienceFee)
      ? +defaultConvenienceFee
      : 0.0;

  console.log("Fixed Convenience Fee : ", newConvenienceFeeRate);
  store.dispatch(setConvenienceFee(+newConvenienceFeeRate));
  newGrandTotal = (+currentCartGrandTotal + +newConvenienceFeeRate).toFixed(2);
  console.log("Calculated new cartGrandTotal : ", newGrandTotal);
  store.dispatch(setGrandTotal(+newGrandTotal));
}

const sessionCallDebounce = debounce(() => {
  updatePaymentIntentValues();
}, 400);

export const unsubscribeHandlePaymentMethodChange = store.subscribe(
  handlePaymentMethodChange
);
// unsubscribe();
