import { debounce } from "lodash";
import { SelectedPaymentMethodSavedStatus } from "../../../store/reducers/paymentSlice";
import store, { RootState } from "../../../store/store";
import { updatePaymentIntentValues } from "../../api/address/update-payment-intent-values";

/**
 * Intent should be re-fetched when the profile is edited
 */
function selectUserProfile(state: RootState) {
  return state.users.currentUser.profile;
}
function selectCreateAccountFlag(state: RootState) {
  return state.payment?.createAccountFlag;
}

let currentUserProfile: any = [];
let currentCreateAccountFlg: boolean = false;
function handleProfileChange() {
  let previousValue = currentUserProfile;
  let previousCreateAccountFlg = currentCreateAccountFlg;
  currentUserProfile = selectUserProfile(store.getState());
  currentCreateAccountFlg = selectCreateAccountFlag(store.getState());
  const { selectedPaymentMethodSaveState } = store.getState().payment;
  let paymentMethodType = "";

  if (
    selectedPaymentMethodSaveState ===
    SelectedPaymentMethodSavedStatus.SAVED_METHOD
  ) {
    paymentMethodType =
      store.getState().payment.savedPaymentMethodSelected.type;
  } else {
    paymentMethodType =
      store.getState().payment.unsavedPaymentMethodSelected.type;
  }

  if (previousValue !== currentUserProfile) {
    console.log(
      "Profile changed from",
      previousValue,
      "to",
      currentUserProfile
    );
    if (paymentMethodType && paymentMethodType !== "") {
      sessionCallDebounce();
    }
  } else if (previousCreateAccountFlg !== currentCreateAccountFlg) {
    console.log(
      "Create Account Flag changed from",
      previousCreateAccountFlg,
      "to",
      currentCreateAccountFlg
    );
    if (paymentMethodType && paymentMethodType !== "") {
      sessionCallDebounce();
    }
  }
}

const sessionCallDebounce = debounce(() => {
  updatePaymentIntentValues();
}, 400);

export const unsubscribeHandleProfileChange =
  store.subscribe(handleProfileChange);
// unsubscribe();
