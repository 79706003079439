import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { Fragment, useEffect, useState } from "react";
import StripePaymentRequestAdupButton from "./StripePaymentRequestAdupButton";

const StripePaymentRequestAdupContainer = (
  stripePaymentRequestAdupContainerProps: any
) => {
  const [stripePromise, setStripePromise] = useState<any>();

  const PUBLIC_KEY_STRIPE =
    stripePaymentRequestAdupContainerProps?.paymentMethod?.data?.public_key ??
    "";

  useEffect(() => {
    setStripePromise(() => loadStripe(PUBLIC_KEY_STRIPE));
  }, []);

  return (
    <Fragment>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <StripePaymentRequestAdupButton
            stripePaymentRequestAdupContainerProps={
              stripePaymentRequestAdupContainerProps
            }
            key={999}
          />
        </Elements>
      )}
    </Fragment>
  );
};

export default StripePaymentRequestAdupContainer;
