import React from "react";
import { useTranslation } from "react-i18next";

interface CartSummaryProps {
  cartTotal: number;
  convenienceFee: number;
  shippingFee: number;
  discount: number;
  currency: string;
  app_mode: string;
}

export const CartSummary: React.FC<CartSummaryProps> = ({
  currency,
  cartTotal = 0,
  convenienceFee = 0,
  shippingFee = 0,
  discount = 0,
  app_mode,
}) => {
  const { t } = useTranslation();

  return (
    <div className="order-cart-summary">
      <div className="order-amounts">
        <div className="order-amount-field">
          {app_mode === "fastcheckout" ? t("CartTotal") : t("OrderTotal")}
          <div>{`${currency} ${cartTotal?.toFixed(2)}`}</div>
        </div>
        <div className="order-amount-field">
          {t("Shipping")}
          <div>{`${currency} ${shippingFee?.toFixed(2)}`}</div>
        </div>
        {/* //* Commented out Discount until it's ready from BE 👇🏼
        <div className="order-amount-field">
          {t("Discount")}
          <div>{`- ${currency} ${discount?.toFixed(2)}`}</div>
        </div> */}
        {convenienceFee > 0 && (
          <div className="order-amount-field">
            {t("ConvenienceFee")}
            <div>{`${currency} ${convenienceFee?.toFixed(2)}`}</div>
          </div>
        )}
        <br></br>
        <hr></hr>
        <div className="order-total-holder">
          <h5>{t("GrandTotal")} </h5>{" "}
          <h5>
            {" "}
            {currency}{" "}
            {(+cartTotal + +shippingFee - +discount + +convenienceFee).toFixed(
              2
            )}
          </h5>
        </div>
      </div>
    </div>
  );
};
