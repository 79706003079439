export const BackArrowBlack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
    >
      <path
        d="M1.11611 9.11612C0.627951 9.60427 0.627951 10.3957 1.11611 10.8839L9.07106 18.8388C9.55921 19.327 10.3507 19.327 10.8388 18.8388C11.327 18.3507 11.327 17.5592 10.8388 17.0711L3.76776 10L10.8388 2.92893C11.327 2.44078 11.327 1.64932 10.8388 1.16117C10.3507 0.67301 9.55921 0.67301 9.07106 1.16117L1.11611 9.11612ZM2.8576 8.75H1.99999V11.25H2.8576V8.75Z"
        fill="#232323"
      />
    </svg>
  );
};
