import { AdupUserAddress } from "../../../interfaces/AdupUserAddress";
import { deleteUserSavedAddress } from "../../../store/reducers/userSlice";
import store from "../../../store/store";
import { SHOPPER_ADDRESS_URL } from "../../constants/urls";
import { buildHeadersURLEncoded } from "../../utils/build-headers";
import { setToastMessage } from "../../../store/reducers/appSlice";
import { toastHandler } from "../../utils/toast-handler";
import { t } from "i18next";
import { submitLog } from "../log";

export async function deleteUserAddress(
  address: AdupUserAddress
): Promise<boolean> {
  // send request
  const addressId = await sendDeleteUserAddressRequest(address);

  // save state
  if (addressId) {
    store.dispatch(deleteUserSavedAddress(address));
    return true;
  }

  return false;
}
async function sendDeleteUserAddressRequest(
  address: AdupUserAddress
): Promise<string | null> {
  const key = store.getState().users.currentUser.token;
  const params = `/${address.id}`;

  if (key) {
    try {
      const response = await fetch(SHOPPER_ADDRESS_URL + params, {
        method: "DELETE",
        headers: buildHeadersURLEncoded(key),
      });

      // trigger API Errors as Toasts
      const res = await response.json();
      if (res) {
        toastHandler(res, store.dispatch, setToastMessage);
      }

      /* const {
        result: { is_deleted },
      } = res; */
      const is_deleted = res.success;

      return is_deleted;
    } catch (e) {
      console.log(e);
      await submitLog("error", t("DeleteAddressError"));
      store.dispatch(
        setToastMessage({
          text: t("DeleteAddressError"),
          type: "ERROR",
        })
      );
      return null;
    }
  } else {
    return null;
  }
}
