import { motion } from "framer-motion";

export const AnimatedLoadingCircle = () => (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <motion.circle
      cx="15"
      cy="15"
      r="12"
      stroke="#000000"
      fill="#00000000"
      strokeWidth={2}
      initial={{ pathLength: 0.69, transform: "rotate(1deg)" }}
      animate={{ pathLength: 0.7, transform: "rotate(360deg)" }}
      transition={{
        repeat: Infinity,
        duration: 0.45,
        bounceDamping: 13,
      }}
    />

    <circle
      cx="15"
      cy="15"
      r="12"
      stroke="#00000033"
      strokeWidth={2}
      fill="#00000000"
      pathLength={1}
    />
  </svg>
);
