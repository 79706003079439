import React, { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  AdupUserAddress,
  EmptyAdupUserAddress,
} from "../../interfaces/AdupUserAddress";
import { GOOGLE_MAPS_API_KEY } from "../../lib/constants/urls";
import store, { RootState } from "../../store/store";
import AutocompleteAddressField from "../autocomplete-address-field/AutocompleteAddressField";
import { cssRedBorderClass } from "../constants";
import AddressField from "../manual-address-field/AddressField";
import RoundButton from "../round-button/RoundButton";
import arrow from "../../assets/svg/arrowDown.svg";
import { setIsRequiredFieldsNotFilled } from "../../store/reducers/paymentSlice";
import { arrowHeadDownSVG } from "../../assets/js/allCustomSVGs";
import executeScrollOn from "../../lib/utils/executeScrollOn";

type Props = {
  changeListeners: ((address: AdupUserAddress) => void)[];
  addressType: string;
};

const AddressFormWithSearch: React.FC<Props> = ({
  changeListeners,
  addressType,
}) => {
  const { t } = useTranslation();
  const paymentStatus = useSelector(
    (state: RootState) => state.payment.paymentProgress
  );
  const shippingAddress =
    store.getState().users?.currentUser?.shipping?.shippingAddress;
  const billingAddress =
    store.getState().users?.currentUser?.shipping?.billingAddress;

  const [autocompleteEnabled, setAutocompleteEnabled] = useState(true);

  const [customAddress, setCustomAddress] = useState(EmptyAdupUserAddress);

  const [isEditingManually, setIsEditingManually] = useState(false);

  const [isFormValid, setIsFormValid] = useState<boolean>(true);

  useEffect(() => {
    const requiredFieldsFilled = Boolean(
      (shippingAddress?.number &&
        shippingAddress?.number !== "" &&
        shippingAddress?.street &&
        shippingAddress?.street !== "" &&
        shippingAddress?.city &&
        shippingAddress?.city !== "" &&
        shippingAddress?.province &&
        shippingAddress?.province !== "" &&
        shippingAddress?.postcode &&
        shippingAddress?.postcode !== "" &&
        shippingAddress?.country &&
        shippingAddress?.country !== "") ||
        (customAddress?.number &&
          customAddress?.number !== "" &&
          customAddress?.street &&
          customAddress?.street !== "" &&
          customAddress?.city &&
          customAddress?.city !== "" &&
          customAddress?.province &&
          customAddress?.province !== "" &&
          customAddress?.postcode &&
          customAddress?.postcode !== "" &&
          customAddress?.country &&
          customAddress?.country !== "")
    );
    if (!requiredFieldsFilled && paymentStatus === "PAYMENT_STARTED") {
      setIsFormValid(false);
      store.dispatch(setIsRequiredFieldsNotFilled(true));
      // executeScrollOnProfileError();
      executeScrollOn("profileDetailsFormRef001");
    } else if (requiredFieldsFilled) {
      setIsFormValid(true);
      store.dispatch(setIsRequiredFieldsNotFilled(false));
    }
  }, [paymentStatus, shippingAddress]);

  function checkManualFields(address: AdupUserAddress) {
    if (
      address.city === "" ||
      address.country === "" ||
      address.number === "" ||
      address.postcode === "" ||
      address.province === "" ||
      address.street === ""
    ) {
      setIsEditingManually(true);
    }
    setCustomAddress(address);
  }

  useEffect(() => {
    for (const listener of changeListeners) listener(customAddress);
  }, [customAddress]);

  return (
    <div
      data-is-address-expanded={isEditingManually?.toString()}
      className="address-form-with-search"
    >
      <AutocompleteAddressField
        defaultValue={
          addressType === "shipping" ? shippingAddress : billingAddress
        }
        classes={
          !isFormValid
            ? "plain-address-field " + cssRedBorderClass
            : "plain-address-field "
        }
        googleMapsApiKey={GOOGLE_MAPS_API_KEY}
        inputEnabled={autocompleteEnabled}
        inputId={"address-form-with-search-autocomplete-input"}
        title={
          !isFormValid
            ? t(
                addressType === "shipping"
                  ? "ShippingAddressTitle"
                  : "BillingAddressTitle"
              ) + ` (${t("Required")})`
            : t(
                addressType === "shipping"
                  ? "ShippingAddressTitle"
                  : "BillingAddressTitle"
              )
        }
        changeListeners={[...changeListeners, checkManualFields]}
      />

      {/*  {isEditingManually && ( */}
      <RoundButton
        color={"light"}
        icon={arrowHeadDownSVG}
        onClick={() => setIsEditingManually(!isEditingManually)}
        rotate={isEditingManually}
        classString="round-button-with-arrow"
      />
      {/*    )} */}

      {isEditingManually && (
        <Fragment>
          <AddressField
            id="address-form-country-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                country: e.target.value,
              }))
            }
            placeHolder={t("Country")}
            value={customAddress.country}
            required={true}
          />
          <AddressField
            id="address-form-city-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                city: e.target.value,
              }))
            }
            placeHolder={t("City")}
            value={customAddress.city}
            required={true}
          />
          <AddressField
            id="address-form-province-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                province: e.target.value,
              }))
            }
            placeHolder={t("Province")}
            value={customAddress.province}
            required={true}
          />
          <AddressField
            id="address-form-postcode-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                postcode: e.target.value,
              }))
            }
            placeHolder={t("PostalCode")}
            value={customAddress.postcode}
            required={true}
          />
          <AddressField
            id="address-form-street-1-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                street: e.target.value,
              }))
            }
            placeHolder={t("Street")}
            value={customAddress.street}
            required={true}
          />
          <AddressField
            id="address-form-street-2-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                street2: e.target.value,
              }))
            }
            placeHolder={t("OtherStreet")}
            value={customAddress.street2}
            required={false}
          />
          <AddressField
            id="address-form-number-input"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setCustomAddress((address) => ({
                ...address,
                number: e.target.value,
              }))
            }
            placeHolder={t("HouseNumber")}
            value={customAddress.number}
            required={true}
          />
        </Fragment>
      )}
    </div>
  );
};

export default AddressFormWithSearch;
