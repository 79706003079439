import { getiPhoneModel } from "./iphone-model-check";
import {
  osName,
  osVersion,
  mobileModel,
  mobileVendor,
} from "react-device-detect";

export enum WebAuthnTypes {
  APPLE_FACEID = "Face Id",
  APPLE_TOUCHID = "Touch Id",
  ANDROID_FACE = "Face Unlock",
  ANDROID_FINGERPRINT = "Fingerprint",
  BIOMETRICS = "Biometrics",
  NO_BIOMETRICS = "NO_BIOMETRICS",
}

export function detectWebAuthnType() {
  let iphoneModel = getiPhoneModel();
  let webAuthnType = WebAuthnTypes.NO_BIOMETRICS;
  let webAuthnNotSupported;

  // if (osName === "iOS" && parseFloat(osVersion) < 13) {
  //   webAuthnNotSupported = `iOS ${osVersion} does not support WebAuthn`;
  //   console.log(webAuthnNotSupported);
  // }

  if (window.PublicKeyCredential) {
    console.log("---- WebAuthn Supported ----");
  } else {
    webAuthnNotSupported = "---- WebAuthn Not supported ----";
    console.log(webAuthnNotSupported);
  }

  console.log("---- Device MODEL ----", mobileVendor + " " + mobileModel);
  console.log("---- iPhone MODEL ----", iphoneModel);

  if (webAuthnNotSupported) {
    webAuthnType = WebAuthnTypes.NO_BIOMETRICS;
  } else if (iphoneModel === "Not an iPhone" || mobileModel !== "iPhone") {
    if (osName === "iOS") {
      webAuthnType = WebAuthnTypes.APPLE_TOUCHID;
    } else {
      if (
        //TODO : Should find a better solution to detect Android Devices
        // Most Popular Samsung S series & Note series devices
        mobileModel === "SM-S901B" ||
        mobileModel === "SM-S901B/DS" ||
        mobileModel === "SM-S901U" ||
        mobileModel === "SM-S901U1" ||
        mobileModel === "SM-S901W" ||
        mobileModel === "SM-S901N" ||
        mobileModel === "SM-S9010" ||
        mobileModel === "SM-S901E" ||
        mobileModel === "SM-S901E/DS" ||
        mobileModel === "SM-G991B" ||
        mobileModel === "SM-G991B/DS" ||
        mobileModel === "SM-G991U" ||
        mobileModel === "SM-G991U1" ||
        mobileModel === "SM-G991W" ||
        mobileModel === "SM-G991N" ||
        mobileModel === "SM-G9910" ||
        mobileModel === "SM-G991Q" ||
        mobileModel === "SM-G980" ||
        mobileModel === "SM-G980F" ||
        mobileModel === "SM-G980F/DS" ||
        mobileModel === "SM-G981B" ||
        mobileModel === "SM-G988" ||
        mobileModel === "SM-G988U" ||
        mobileModel === "SM-G988U1" ||
        mobileModel === "SM-G9880" ||
        mobileModel === "SM-G988B/DS" ||
        mobileModel === "SM-G988N" ||
        mobileModel === "SM-G988B" ||
        mobileModel === "SM-G988W" ||
        mobileModel === "SM-S908B" ||
        mobileModel === "SM-S908B/DS" ||
        mobileModel === "SM-S908U" ||
        mobileModel === "SM-S908U1" ||
        mobileModel === "SM-S908W" ||
        mobileModel === "SM-S908N" ||
        mobileModel === "SM-S9080" ||
        mobileModel === "SM-S908E" ||
        mobileModel === "SM-G998B" ||
        mobileModel === "SM-G998B/DS" ||
        mobileModel === "SM-G998U" ||
        mobileModel === "SM-G998U1" ||
        mobileModel === "SM-G998W" ||
        mobileModel === "SM-G998N" ||
        mobileModel === "SM-G9980"
      ) {
        webAuthnType = WebAuthnTypes.ANDROID_FACE;
      } else if (mobileVendor === "Samsung") {
        webAuthnType = WebAuthnTypes.ANDROID_FINGERPRINT;
      } else {
        webAuthnType = WebAuthnTypes.BIOMETRICS;
      }
    }
  } else if (
    iphoneModel === "iPhone X or Above with FaceId" ||
    iphoneModel === "iPad Pro 3rd Gen or Above with FaceId"
  ) {
    webAuthnType = WebAuthnTypes.APPLE_FACEID;
  } else if (
    iphoneModel === "iPhone 8+ or Below with TouchId" ||
    iphoneModel === "iPad with TouchId"
  ) {
    webAuthnType = WebAuthnTypes.APPLE_TOUCHID;
  } else if (
    iphoneModel === "iPhone 5 or Below without TouchId" ||
    iphoneModel === "iPad without TouchId"
  ) {
    webAuthnType = WebAuthnTypes.NO_BIOMETRICS;
  }

  console.log("---- webAuthn TYPE ----", webAuthnType);

  return webAuthnType;
}
