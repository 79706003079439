export function webauthnRegister(key: any, callback: any) {
  key = JSON.parse(key);
  key.publicKey.attestation = undefined;
  key.publicKey.challenge = new Uint8Array(key.publicKey.challenge); // convert type for use by key
  key.publicKey.user.id = new Uint8Array(key.publicKey.user.id);

  navigator.credentials
    .create({ publicKey: key.publicKey })
    .then(function (aNewCredentialInfo: any) {
      // console.log("Credentials.Create response: ", aNewCredentialInfo);
      var cd = JSON.parse(
        String.fromCharCode.apply(
          null,
          new Uint8Array(aNewCredentialInfo.response.clientDataJSON) as any
        )
      );
      if (key.b64challenge != cd.challenge) {
        callback(false, "key returned something unexpected (1)");
      }
      if ("https://" + key.publicKey.rp.name != cd.origin) {
        return callback(false, "key returned something unexpected (2)");
      }
      if (!("type" in cd)) {
        return callback(false, "key returned something unexpected (3)");
      }
      if (cd.type != "webauthn.create") {
        return callback(false, "key returned something unexpected (4)");
      }

      var ao: number[] = [];
      new Uint8Array(aNewCredentialInfo?.response.attestationObject).forEach(
        function (v) {
          ao.push(v);
        }
      );
      var rawId: number[] = [];
      new Uint8Array(aNewCredentialInfo.rawId).forEach(function (v) {
        rawId.push(v);
      });
      var info = {
        rawId: rawId,
        id: aNewCredentialInfo.id,
        type: aNewCredentialInfo.type,
        response: {
          attestationObject: ao,
          clientDataJSON: JSON.parse(
            String.fromCharCode.apply(
              null,
              new Uint8Array(aNewCredentialInfo.response.clientDataJSON) as any
            )
          ),
        },
      };
      callback(true, JSON.stringify(info));
    });
}
