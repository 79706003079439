import "../../sass/components/socialCommerce/introBannerSC.scss";
import { t } from "i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const IntroBannerSC = () => {
  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );
  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );

  const imgURL =
    merchantTheme?.merchant_name === "AdUp" //TODO: remove hardcoded merchant validation
      ? `${imageStaticUrl}/medium/${merchantTheme?.merchant_logo_dark}`
      : `${imageStaticUrl}/medium/${merchantTheme?.merchant_icon}`;
  const merchantName = merchantTheme?.merchant_name;

  return (
    <div className="introBannerSC">
      <div className="introBannerSC__left">
        {!showCookieConsent && (
          <LazyLoadImage
            loading="lazy"
            height={"100%"}
            width={"auto"}
            src={imgURL}
            alt={`${merchantTheme?.merchant_name} logo`}
          />
        )}
      </div>
      <div className="introBannerSC__right">
        {merchantTheme?.banner_text1 ? (
          <p>{merchantTheme?.banner_text1}</p>
        ) : (
          <h3>
            {t("Introducing")} <span>{merchantName}</span>{" "}
            <span>Social Commerce !</span>
          </h3>
        )}
        {/* --------- */}
        {merchantTheme?.banner_text2 ? (
          <p>{merchantTheme?.banner_text2}</p>
        ) : (
          <p>{t("SocialComIntro")}</p>
        )}
      </div>
    </div>
  );
};

export default IntroBannerSC;
