import {
  SHOPPER_LOGOUT_URL,
  SHOPPER_URL,
  WALLET_SHOPPER_CREATION_URL,
} from "../constants/urls";
import store from "../../store/store";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  enableFastCheckoutUISegment,
  initialHiddenSegments,
  setToastMessage,
  updateUserType,
} from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";
import {
  SelectedPaymentMethodSavedStatus,
  setPaymentErrorOccurred,
} from "../../store/reducers/paymentSlice";
import {
  setUserToken,
  verificationStates,
  setUserVerified,
} from "../../store/reducers/userSlice";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import { getUniqueBrowserId } from "../utils/helper-functions";
import { detectIncognito } from "detectincognitojs";

/**
 * @param { string } country Telephone number country dial code
 * @param { string } phone Telephone number
 */
export async function getShopperDetails(country: string, phone: string) {
  // store.dispatch( //? removed Toast as requested : https://app.asana.com/0/1203632011484827/1203780217361885/f
  //   setToastMessage({
  //     text: t("ValidatingPhone"),
  //     type: "INFO",
  //   })
  // );
  const shopId = store.getState().cart?.shop?.id;

  const params = `?phone_number=${phone}&country_code=${country}&shop_id=${shopId}`;

  const response = await fetch(SHOPPER_URL + params, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();
  const { result, messages } = res;

  if (messages.includes("the shopper is not found")) {
    console.log("NEW USER");
    store.dispatch(updateUserType({ user: applicationUserType.NEW_USER }));
    // store.dispatch( //? removed Toast as requested : https://app.asana.com/0/1203632011484827/1203780217361885/f
    //   setToastMessage({
    //     text: t("NewPhone"),
    //     type: "SUCCESS",
    //   })
    // );
  }
  // trigger API Errors as Toasts
  else if (res) {
    // toastHandler(res, store.dispatch, setToastMessage);
  }
  return result;
}

/**
 * @param { any } walletDataObject wallet data
 */
export async function createShopperViaWalletData(walletDataObject: any) {
  const token = store.getState().users.currentUser.token;
  const shopId = store.getState().cart?.shop?.id;
  const { selectedPaymentMethodSaveState, createAccountFlag } =
    store.getState().payment;
  const cartItems = store.getState().cart.items;
  const grandTotal = store.getState().cart.grandTotal;
  const verificationState = store.getState().users.currentUser.verified;

  let paymentMethodType = "";
  const cart: any = [];
  for (const item of cartItems) {
    cart.push({ sku: item.sku, qty: item.amount });
  }
  const cartString = JSON.stringify(cart);

  if (
    selectedPaymentMethodSaveState ===
    SelectedPaymentMethodSavedStatus.SAVED_METHOD
  ) {
    paymentMethodType =
      store.getState().payment.savedPaymentMethodSelected.type;
  } else {
    paymentMethodType =
      store.getState().payment.unsavedPaymentMethodSelected.type;
  }

  const saveShopperString = JSON.stringify(walletDataObject);

  const response = await fetch(WALLET_SHOPPER_CREATION_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // "X-API-Key": key,
      Authorization: token ? `Bearer ${token}` : "",
    },
    body: JSON.stringify({
      shopper: saveShopperString,
      shop_id: shopId,
      payment_method: paymentMethodType,
      cart: cartString,
      cart_total: grandTotal ?? 0.0,
      create_account: token ? true : createAccountFlag,
    }),
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;
  const key = result?.authKey;

  if (key && createAccountFlag === true) {
    store.dispatch(setUserToken(key));
  }

  if (createAccountFlag !== true) {
    store.dispatch(setUserToken(undefined));
  }

  //* NEW User saving in state
  if (
    result?.shopper &&
    verificationState?.state !== verificationStates.verified
  ) {
    console.log("NEW User saving in state...");
    updateStateFromShopperObject(result?.shopper);
    updateStateFromPaymentMethodsBlock(result?.shopper);
    for (const segment of Object.keys(initialHiddenSegments)) {
      store.dispatch(disableFastCheckoutUISegment(segment));
    }

    store.dispatch(updateUserType({ user: applicationUserType.OLD_USER }));
    store.dispatch(
      setUserVerified({
        ...verificationState,
        state: verificationStates.verified,
        _: true,
        loggedInMethod: "otp",
        firstPayment: "pending",
      })
    );
    store.dispatch(enableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("DETAILS_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT"));
    store.dispatch(enableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT"));
    console.log("NEW User saved in state ✅");
  }
  store.dispatch(setPaymentErrorOccurred(false));
  return result;
}

export async function shopperLogOut() {
  const key = store.getState().users.currentUser.token;
  if (key && key !== "") {
    var isBrowserSafari = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );

    let isIncognitoWindow = false;

    await detectIncognito().then((result) => {
      console.log(
        "🌍👻 is Incognito Window ==> ",
        result.browserName,
        result.isPrivate
      );
      isIncognitoWindow = result.isPrivate;
    });

    let uniqueBrowserId: string =
      isBrowserSafari && !isIncognitoWindow
        ? `?unique_browser_id=${getUniqueBrowserId()}`
        : "";

    const response = await fetch(SHOPPER_LOGOUT_URL + uniqueBrowserId, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    });

    // trigger API Errors as Toasts
    const res = await response.json();
    if (res) {
      toastHandler(res, store.dispatch, setToastMessage);
    }

    const { success } = res;
    if (success) {
      store.dispatch(setUserToken(undefined));
    }
    return success;
  } else {
    return null;
  }
}
