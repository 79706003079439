import "../../sass/components/socialCommerce/productImagesSlider.scss";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setUIStatistics } from "../../store/reducers/statisticsSlice";

interface Props {
  images: string[];
  isDemoActive?: boolean;
}

const ProductImagesSlider = ({ images, isDemoActive }: Props) => {
  const [activeThumb, setActiveThumb] = useState();
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const { appData } = useSelector((state: RootState) => state.app) as any;
  const imageStaticUrl = appData?.static_base_url;

  //////////////////save stats////////////////////////
  const uiStatistics: any = useSelector(
    (state: RootState) => state.statistics.ui
  );
  const dispatch = useDispatch();
  const imageRef = useRef<HTMLDivElement>(null); // Specify the type of the ref

  const imageStats = () => {
    if (imageRef.current) {
      const width = imageRef.current.offsetWidth;
      const height = imageRef.current.offsetHeight;

      if (width !== undefined && height !== undefined) {
        console.log("saving image size stat");
        const updatedUiStats = {
          ...uiStatistics,
          imageSize: `width: ${width}px, height: ${height}px`,
        };
        dispatch(setUIStatistics(updatedUiStats));
      }
    }
  };

  return (
    <>
      <div className="mainProductImage" ref={imageRef}>
        <Swiper
          loop={images?.length > 1 ? true : false}
          spaceBetween={10}
          navigation={true}
          modules={[Navigation, Thumbs]}
          grabCursor={true}
          thumbs={{ swiper: activeThumb }}
          className="product-images-slider"
        >
          {images?.map((item, index) => (
            <SwiperSlide key={index}>
              {/* <img
              className={
                !isImgLoaded ? "product-images-slider--placeholder" : ""
              }
              onLoad={() => {
                setIsImgLoaded(true);
              }}
              onError={() => {
                setIsImgLoaded(true);
              }}
              loading="lazy"
              src={isDemoActive ? item : `${imageStaticUrl}/medium/${item}`}
              alt="product images"
            /> */}
              <LazyLoadImage
                className={
                  !isImgLoaded ? "product-images-slider--placeholder" : ""
                }
                onLoad={() => {
                  setIsImgLoaded(true);
                  imageStats();
                }}
                onError={() => {
                  setIsImgLoaded(true);
                }}
                loading="lazy"
                alt="product images"
                src={isDemoActive ? item : `${imageStaticUrl}/medium/${item}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Swiper
        // @ts-ignore:next-line
        onSwiper={setActiveThumb}
        loop={false}
        spaceBetween={10}
        slidesPerView={4}
        modules={[Navigation, Thumbs]}
        className={
          images?.length > 1 ? "product-images-slider-thumbs" : "display-none"
        }
      >
        {images?.map((item, index) => (
          <SwiperSlide key={index}>
            <div className="product-images-slider-thumbs-wrapper">
              {/* <img
                className={
                  !isImgLoaded ? "product-images-slider--placeholder" : ""
                }
                onLoad={() => {
                  setIsImgLoaded(true);
                }}
                onError={() => {
                  setIsImgLoaded(true);
                }}
                src={isDemoActive ? item : `${imageStaticUrl}/medium/${item}`}
                alt="product images"
              /> */}
              <LazyLoadImage
                className={
                  !isImgLoaded ? "product-images-slider--placeholder" : ""
                }
                onLoad={() => {
                  setIsImgLoaded(true);
                }}
                onError={() => {
                  setIsImgLoaded(true);
                }}
                src={isDemoActive ? item : `${imageStaticUrl}/medium/${item}`}
                alt="product images"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

ProductImagesSlider.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ProductImagesSlider;
