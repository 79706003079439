import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setShippingAddress } from "../../store/reducers/userSlice";
import { applicationUserType } from "../../store/reducers/appSlice";
import AddressFormWithSearch from "../custom-input-field/AddressFormWithSearch";
import { EmptyAdupUserAddress } from "../../interfaces/AdupUserAddress";

const UserAddresses = () => {
  const dispatch = useDispatch();
  // username associated with the phone number above
  const authState = useSelector((state: RootState) => state.app.auth);

  return (
    <div className="account-form fast-checkout-page-segment">
      {authState.user === applicationUserType.NEW_USER && (
        <div style={{ marginBottom: "-0.3rem" }}>
          <AddressFormWithSearch
            addressType="shipping"
            changeListeners={[
              (address) => {
                if (address !== EmptyAdupUserAddress)
                  dispatch(setShippingAddress(address));
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default UserAddresses;
