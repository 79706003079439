// import { useSelector } from "react-redux";
// import { RootState } from "../../store/store";
// import FastCheckoutLayout1 from "../../layout/layout1/FastCheckoutLayout1";
import FastCheckoutLayoutForPopups from "../../layout/layoutForPopups/FastCheckoutLayoutForPopups";

interface Props {
  showHeader: boolean;
  theme?: string;
}

const FastCheckoutPage = ({ showHeader, theme }: Props) => {
  /* const app_layout: any = useSelector(
    (state: RootState) => state.cart.shop.theme?.app_layout
  ); */

  const renderLayout = () => {
    /* if (app_layout === "1") {
      return <FastCheckoutLayout1 showHeader={showHeader} theme={theme} />;
    } else {
      return <FastCheckoutLayout1 showHeader={showHeader} theme={theme} />;
    } */
    return (
      <FastCheckoutLayoutForPopups showHeader={showHeader} theme={theme} />
    );
  };

  return renderLayout();
};

export default FastCheckoutPage;
