import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsPayButtonLoading,
  setToastMessage,
} from "../../store/reducers/appSlice";
import { RootState } from "../../store/store";
import { checkPaymentStatus } from "../../lib/api/payment-status";
import { useNavigate } from "react-router-dom";
import AnimatedPayButtonBlack from "../../pages/fast-checkout/subcomponents/AnimatedPayButtonBlack";
import { t } from "i18next";
import { verificationStates } from "../../store/reducers/userSlice";
import { submitLog } from "../../lib/api/log";

interface Props {
  clientSecret: string;
  intentType: string;
}

export const MakePayment: React.FC<Props> = ({ clientSecret, intentType }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const paymentMethodReturnURL = useSelector(
    (state: RootState) => state.payment.stripe.returnURL
  );
  const cartId = useSelector((state: RootState) => state.payment.cartSessionId);
  const shippingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.shippingAddress
  );
  const billingAddress = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.billingAddress
  );
  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    if (intentType === "setup_intent") {
      stripe
        .retrieveSetupIntent(clientSecret)
        .then(({ setupIntent, error }) => {
          console.log(setupIntent);
          if (error) {
            dispatch(
              setToastMessage({
                text: t("PayGatewayError"),
                type: "ERROR",
              })
            );
          }
        });
    } else if (intentType === "payment_intent") {
      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent, error }) => {
          // switch (paymentIntent?.status) {
          //   case "succeeded":
          //     setMessage("Payment succeeded!");
          //     break;
          //   case "processing":
          //     setMessage("Your payment is processing.");
          //     break;
          //   case "requires_payment_method":
          //     setMessage("Your payment was not successful, please try again.");
          //     break;
          //   default:
          //     setMessage("Something went wrong.");
          //     break;
          // }

          if (error) {
            dispatch(
              setToastMessage({
                text: t("PayGatewayError"),
                type: "ERROR",
              })
            );
          }
        });
    }
  }, [stripe]);

  const handleSubmit = async (e?: any) => {
    e?.preventDefault();
    e?.nativeEvent?.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    dispatch(setIsPayButtonLoading(true));

    let result;
    if (intentType === "setup_intent") {
      result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: paymentMethodReturnURL,
          payment_method_data: {
            billing_details: {
              address: {
                country: billingAddress.country?.iso2
                  ? billingAddress.country?.iso2
                  : shippingAddress.country?.iso2,
                postal_code: billingAddress.postcode
                  ? billingAddress.postcode
                  : shippingAddress.postcode,
              },
            },
          },
        },
        redirect: "if_required",
      });
    } else {
      result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: paymentMethodReturnURL,
          payment_method_data: {
            billing_details: {
              address: {
                country: billingAddress.country?.iso2
                  ? billingAddress.country?.iso2
                  : shippingAddress.country?.iso2,
                postal_code: billingAddress.postcode
                  ? billingAddress.postcode
                  : shippingAddress.postcode,
              },
            },
          },
        },
        redirect: "if_required",
      });
    }

    if (result?.error) {
      const { error } = result;
      if (
        error.type === "card_error" ||
        error.type === "validation_error" ||
        error.type === "invalid_request_error"
      ) {
        if (error.message)
          dispatch(
            setToastMessage({
              text: error.message,
              type: "ERROR",
            })
          );
        dispatch(setIsPayButtonLoading(false));
        return;
      } else {
        dispatch(
          setToastMessage({
            text: t("UnexpectedError"),
            type: "ERROR",
          })
        );
        dispatch(setIsPayButtonLoading(false));
        return;
      }
    } else {
      // dispatch(
      //   setCurrentFastCheckoutPage(fastCheckoutPages.PAYMENT_SUCCESSFUL)
      // );
    }

    // sendPaymentPostRequest();
    // handlePaymentRequest();
    const paymentStatus = await checkPaymentStatus(cartId);
    if (paymentStatus.payment_completed) {
      dispatch(
        setToastMessage({ text: t("PaymentSuccessful"), type: "SUCCESS" })
      );
      navigate(`/thank-you/${cartId}`);
    } else {
      await submitLog("error", t("PaymentFailed"));
      dispatch(
        setToastMessage({
          text: t("PaymentFailed"),
          type: "ERROR",
        })
      );
    }

    dispatch(setIsPayButtonLoading(false));
  };

  useEffect(() => {
    // dispatch(
    //   setPayButtonFunction({
    //     payFunction: () =>  handleSubmit(null)
    //   })
    // );
  }, []);

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement
        id="payment-element"
        options={{
          wallets: { applePay: "never", googlePay: "never" },
          fields: {
            billingDetails: {
              address: {
                country: "never",
                postalCode: "never",
              },
            },
          },
        }}
      />
      {verificationState?.state !== verificationStates.pending && (
        <AnimatedPayButtonBlack />
      )}
      {/* Show any error or success messages */}
      {/* {message && <div id="payment-message">{message}</div>} */}
    </form>
  );
};
