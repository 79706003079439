import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { arrowHeadLeftSVG } from "../../assets/js/allCustomSVGs";
import { RootState } from "../../store/store";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  backRoute: any;
  backRouteTitle: string;
}

const HeaderProfile = ({ backRoute, backRouteTitle }: Props) => {
  const navigate = useNavigate();
  const shopData: any = useSelector((state: RootState) => state.cart?.shop);
  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );
  const imageStaticUrl = useSelector(
    (state: RootState) => state.app.appData?.static_base_url
  );
  const slug = useSelector((state: RootState) => state.cart.shop.slug);

  const isXXL =
    window.location.origin === "https://xxlnutrition.secure-checkout.eu"
      ? true
      : false;

  const isLogo = () => {
    if (
      shopData?.settings?.company_logo &&
      shopData?.settings?.company_logo !== "" &&
      shopData?.settings?.company_logo !== "null"
    ) {
      return true;
    }

    return false;
  };

  const renderCompanyLogo = () => {
    if (isLogo()) {
      return (
        <div
          onClick={() => {
            if (!isXXL) {
              navigate(`/${slug}`);
            }
          }}
          className="img__container"
        >
          <LazyLoadImage
            loading="lazy"
            src={`${imageStaticUrl}/medium/${shopData?.settings?.company_logo}`}
            alt="company logo"
          />
        </div>
      );
    } else {
      return <div className="img__container"></div>;
    }
  };

  return (
    <>
      <div
        data-is-header-profile-fixed={settings?.setting}
        className="headerProfile"
      >
        <div className="headerProfile-max-width-container">
          <div
            onClick={() => {
              if (backRoute === -1) {
                navigate(-1);
              } else {
                navigate(`${backRoute}`);
              }
            }}
            className="headerProfile__left"
          >
            <CustomSVGs svg={arrowHeadLeftSVG} />
            <div>
              <h2>{backRouteTitle}</h2>
            </div>
          </div>

          {renderCompanyLogo()}
        </div>
      </div>
      <div
        data-is-header-profile-fixed={settings?.setting}
        className="headerProfile-hidden"
      />
    </>
  );
};

export default HeaderProfile;
