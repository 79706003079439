import React, { useState } from "react";
import { PaymentMethodConfigs } from "../../lib/config/payment-methods-config";
import { AnimatedLoadingCircleColored } from "../../assets/svg/animated-svg-components/AnimatedLoadingCircle2";
import { AdupPaymentMethodSelection } from "../../interfaces/AdupPaymentMethod";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import {
  SelectedPaymentMethodSavedStatus,
  setIsPaymentMethodsLoading,
  setIsWalletPaymentMethodInUse,
  setPaymentMethodLastUsed,
  setUnsavedSelectedPaymentMethod,
} from "../../store/reducers/paymentSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BuckarooPaymentRequestAdupContainer from "../../pgp-components/payment-request-components/BuckarooPaymentRequestAdupContainer";
import MolliePaymentRequestAdupContainer from "../../pgp-components/payment-request-components/MolliePaymentRequestAdupContainer";
import PaynlPaymentRequestAdupContainer from "../../pgp-components/payment-request-components/PaynlPaymentRequestAdupContainer";
import StripePaymentRequestAdupContainer from "../../pgp-components/payment-request-components/StripePaymentRequestAdupContainer";
import NewPaymentMethodWithOptions from "./NewPaymentMethodWithOptions";

const NewPaymentMethod: React.FC<PaymentMethodType> = ({
  image,
  onClick,
  paymentMethod,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  dispatch(setIsPaymentMethodsLoading(false));

  const selectedPaymentMethod = useSelector(
    (state: RootState) => state.payment.unsavedPaymentMethodSelected
  );

  const selectedPaymentState = useSelector(
    (state: RootState) => state.payment.selectedPaymentMethodSaveState
  );

  function handleClick() {
    dispatch(setPaymentMethodLastUsed(""));
    dispatch(setIsWalletPaymentMethodInUse(false));
    //? To prevent making Session calls when the already selected same payment method is clicked
    if (
      !(
        selectedPaymentMethod.displayTitle === paymentMethod.displayTitle &&
        SelectedPaymentMethodSavedStatus.UNSAVED_METHOD === selectedPaymentState
      )
    ) {
      dispatch(setUnsavedSelectedPaymentMethod(paymentMethod));
      onClick();
    } else {
      console.log(
        "Prevented already selected Payment method Click :",
        selectedPaymentMethod.displayTitle
      );
    }
  }

  return (
    <React.Suspense fallback={<div></div>}>
      <div
        className={
          "new-payment-method-icon card-details-retracted " +
          (selectedPaymentMethod.displayTitle === paymentMethod.displayTitle &&
          SelectedPaymentMethodSavedStatus.UNSAVED_METHOD ===
            selectedPaymentState
            ? "selected-payment-method"
            : "")
        }
        onClick={() => handleClick()}
      >
        <LazyLoadImage loading="lazy" src={image} alt="pay" />{" "}
        {loading ? (
          <AnimatedLoadingCircleColored />
        ) : (
          paymentMethod.displayTitle
        )}
      </div>
    </React.Suspense>
  );
};

type PaymentMethodType = {
  name: string;
  image: string;
  svgIcon?: JSX.Element;
  key?: number;
  displayTitle: string;
  link?: string;
  onClick?: any;
  paymentMethod: AdupPaymentMethodSelection;
  walletMethodAvailable: boolean;
  setPaymentMethodChanged: any;
  paymentMethodChanged: boolean;
  isDemoActive: boolean;
};

export function buildNewPaymentMethodIcon({
  name,
  image,
  key = 999,
  displayTitle,
  onClick,
  paymentMethod,
  walletMethodAvailable,
  setPaymentMethodChanged,
  paymentMethodChanged,
  isDemoActive,
}: PaymentMethodType) {
  const config = PaymentMethodConfigs[name];

  const displayName = config?.displayName ? config?.displayName : name;

  if (config?.disabled) return;
  const imageStaticUrl = store.getState().app?.appData?.static_base_url;

  if (config === undefined) {
    return null;
  }

  // For google pay, apple pay, microsoft pay
  //! If a wallet payment is sent via BE It is a must to have a wallet payment component for that psp or setPaymentMethodChanged(true); or else payment methods will not be visible
  if (walletMethodAvailable && !paymentMethodChanged) {
    if (config?.meta?.usesPaymentRequest) {
      console.log("isWalletMethodAvailable : ", name, walletMethodAvailable);
      console.log("isPaymentMethodChanged : ", paymentMethodChanged);
      console.log(
        "Wallet : For google pay, apple pay, microsoft pay",
        paymentMethod
      );
      if (paymentMethod?.serviceProvider.toLocaleLowerCase() === "stripe") {
        console.log("stripe : ", name);
        return (
          <React.Suspense fallback={<div></div>}>
            <StripePaymentRequestAdupContainer
              name={name}
              key={key}
              paymentMethod={paymentMethod}
              setPaymentMethodChanged={setPaymentMethodChanged}
            />
          </React.Suspense>
        );
      }
      if (paymentMethod?.serviceProvider.toLocaleLowerCase() === "buckaroo") {
        console.log("buckaroo : ", name);
        return (
          <React.Suspense fallback={<div></div>}>
            <BuckarooPaymentRequestAdupContainer
              name={name}
              key={key}
              paymentMethod={paymentMethod}
              setPaymentMethodChanged={setPaymentMethodChanged}
            />
          </React.Suspense>
        );
      }
      if (
        paymentMethod?.serviceProvider.toLocaleLowerCase() === "mollie" ||
        paymentMethod?.serviceProvider.toLocaleLowerCase() === "aduppay"
      ) {
        console.log("mollie / aduppay : ", name);
        return (
          <React.Suspense fallback={<div></div>}>
            <MolliePaymentRequestAdupContainer
              name={name}
              key={key}
              paymentMethod={paymentMethod}
              setPaymentMethodChanged={setPaymentMethodChanged}
            />
          </React.Suspense>
        );
      }
      if (paymentMethod?.serviceProvider.toLocaleLowerCase() === "paynl") {
        console.log("paynl : ", name);
        return (
          <React.Suspense fallback={<div></div>}>
            <PaynlPaymentRequestAdupContainer
              name={name}
              key={key}
              paymentMethod={paymentMethod}
              setPaymentMethodChanged={setPaymentMethodChanged}
            />
          </React.Suspense>
        );
      }
      if (
        //? disable wallet payments for selected PSPs
        paymentMethod?.serviceProvider.toLocaleLowerCase() === "magentomollie"
      ) {
        setPaymentMethodChanged(true);
      }
    }
  } else {
    if (config?.meta?.showBankSelector && paymentMethod?.data?.bank) {
      return (
        <React.Suspense fallback={<div></div>}>
          <NewPaymentMethodWithOptions
            image={
              isDemoActive ? `/${image}` : `${imageStaticUrl}/small/${image}`
            }
            name={name}
            displayTitle={displayTitle}
            key={key}
            paymentMethod={paymentMethod}
            onClick={() => onClick()}
          />
        </React.Suspense>
      );
    }
    if (!config?.disableFromList) {
      return (
        <React.Suspense fallback={<div></div>}>
          <NewPaymentMethod
            image={
              isDemoActive ? `/${image}` : `${imageStaticUrl}/small/${image}`
            }
            name={name}
            displayTitle={displayTitle}
            key={key}
            paymentMethod={paymentMethod}
            onClick={() => onClick()}
            walletMethodAvailable={walletMethodAvailable}
            setPaymentMethodChanged={setPaymentMethodChanged}
            paymentMethodChanged={paymentMethodChanged}
            isDemoActive={isDemoActive}
          />
        </React.Suspense>
      );
    }
  }
}
