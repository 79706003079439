import { SHIPPING_CAL_URL } from "../constants/urls";
import { AdupUserAddress } from "../../interfaces/AdupUserAddress";

export async function fetchShippingCost(
  shopId: string,
  address: AdupUserAddress
) {
  const params = `?shopid=${shopId}&address=${JSON.stringify(address)}`;

  const response = await fetch(SHIPPING_CAL_URL + params, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const res = await response.json();

  const { result } = res;
  return result;
}
