import "./roundButton.scss";
import CustomSVGs from "../custom-svgs/CustomSVGs";

interface Props {
  color: "light" | "dark";
  icon: any;
  onClick?: () => void;
  rotate?: boolean;
  classString?: string;
}

const RoundButton = ({ color, icon, onClick, rotate, classString }: Props) => {
  return (
    <div className={`roundButton-component ${classString}`}>
      <button
        onClick={onClick}
        style={{ backgroundColor: color === "dark" ? "#464646" : "#f7f5f9" }}
      >
        <div
          className={`roundButton-component-iconHolder ${
            rotate ? "icon-rotate" : ""
          } ${color === "dark" ? "roundButton-icon-dark" : ""}`}
        >
          <CustomSVGs svg={icon} />
        </div>
      </button>
    </div>
  );
};

export default RoundButton;
