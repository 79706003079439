import Country from "../../interfaces/Country";

export const defaultCountries: { countries: { [key: string]: any } } = {
  countries: {
    AF: {
      name: "Afghanistan",
      dial_code: "+93",
      flag: "\ud83c\udde6\ud83c\uddeb",
    },
    AX: {
      name: "Aland Islands",
      dial_code: "+358",
      flag: "\ud83c\udde6\ud83c\uddfd",
    },
    AL: {
      name: "Albania",
      dial_code: "+355",
      flag: "\ud83c\udde6\ud83c\uddf1",
    },
    DZ: {
      name: "Algeria",
      dial_code: "+213",
      flag: "\ud83c\udde9\ud83c\uddff",
    },
    AS: {
      name: "American Samoa",
      dial_code: "+1684",
      flag: "\ud83c\udde6\ud83c\uddf8",
    },
    AD: {
      name: "Andorra",
      dial_code: "+376",
      flag: "\ud83c\udde6\ud83c\udde9",
    },
    AO: {
      name: "Angola",
      dial_code: "+244",
      flag: "\ud83c\udde6\ud83c\uddf4",
    },
    AI: {
      name: "Anguilla",
      dial_code: "+1264",
      flag: "\ud83c\udde6\ud83c\uddee",
    },
    AQ: {
      name: "Antarctica",
      dial_code: "+672",
      flag: "\ud83c\udde6\ud83c\uddf6",
    },
    AG: {
      name: "Antigua and Barbuda",
      dial_code: "+1268",
      flag: "\ud83c\udde6\ud83c\uddec",
    },
    AR: {
      name: "Argentina",
      dial_code: "+54",
      flag: "\ud83c\udde6\ud83c\uddf7",
    },
    AM: {
      name: "Armenia",
      dial_code: "+374",
      flag: "\ud83c\udde6\ud83c\uddf2",
    },
    AW: {
      name: "Aruba",
      dial_code: "+297",
      flag: "\ud83c\udde6\ud83c\uddfc",
    },
    AU: {
      name: "Australia",
      dial_code: "+61",
      flag: "\ud83c\udde6\ud83c\uddfa",
    },
    AT: {
      name: "Austria",
      dial_code: "+43",
      flag: "\ud83c\udde6\ud83c\uddf9",
    },
    AZ: {
      name: "Azerbaijan",
      dial_code: "+994",
      flag: "\ud83c\udde6\ud83c\uddff",
    },
    BS: {
      name: "Bahamas",
      dial_code: "+1242",
      flag: "\ud83c\udde7\ud83c\uddf8",
    },
    BH: {
      name: "Bahrain",
      dial_code: "+973",
      flag: "\ud83c\udde7\ud83c\udded",
    },
    BD: {
      name: "Bangladesh",
      dial_code: "+880",
      flag: "\ud83c\udde7\ud83c\udde9",
    },
    BB: {
      name: "Barbados",
      dial_code: "+1246",
      flag: "\ud83c\udde7\ud83c\udde7",
    },
    BY: {
      name: "Belarus",
      dial_code: "+375",
      flag: "\ud83c\udde7\ud83c\uddfe",
    },
    BE: {
      name: "Belgium",
      dial_code: "+32",
      flag: "\ud83c\udde7\ud83c\uddea",
    },
    BZ: {
      name: "Belize",
      dial_code: "+501",
      flag: "\ud83c\udde7\ud83c\uddff",
    },
    BJ: {
      name: "Benin",
      dial_code: "+229",
      flag: "\ud83c\udde7\ud83c\uddef",
    },
    BM: {
      name: "Bermuda",
      dial_code: "+1441",
      flag: "\ud83c\udde7\ud83c\uddf2",
    },
    BT: {
      name: "Bhutan",
      dial_code: "+975",
      flag: "\ud83c\udde7\ud83c\uddf9",
    },
    BO: {
      name: "Bolivia, Plurinational State of bolivia",
      dial_code: "+591",
      flag: "\ud83c\udde7\ud83c\uddf4",
    },
    BA: {
      name: "Bosnia and Herzegovina",
      dial_code: "+387",
      flag: "\ud83c\udde7\ud83c\udde6",
    },
    BW: {
      name: "Botswana",
      dial_code: "+267",
      flag: "\ud83c\udde7\ud83c\uddfc",
    },
    BV: {
      name: "Bouvet Island",
      dial_code: "+47",
      flag: "\ud83c\udde7\ud83c\uddfb",
    },
    BR: {
      name: "Brazil",
      dial_code: "+55",
      flag: "\ud83c\udde7\ud83c\uddf7",
    },
    IO: {
      name: "British Indian Ocean Territory",
      dial_code: "+246",
      flag: "\ud83c\uddee\ud83c\uddf4",
    },
    BN: {
      name: "Brunei Darussalam",
      dial_code: "+673",
      flag: "\ud83c\udde7\ud83c\uddf3",
    },
    BG: {
      name: "Bulgaria",
      dial_code: "+359",
      flag: "\ud83c\udde7\ud83c\uddec",
    },
    BF: {
      name: "Burkina Faso",
      dial_code: "+226",
      flag: "\ud83c\udde7\ud83c\uddeb",
    },
    BI: {
      name: "Burundi",
      dial_code: "+257",
      flag: "\ud83c\udde7\ud83c\uddee",
    },
    KH: {
      name: "Cambodia",
      dial_code: "+855",
      flag: "\ud83c\uddf0\ud83c\udded",
    },
    CM: {
      name: "Cameroon",
      dial_code: "+237",
      flag: "\ud83c\udde8\ud83c\uddf2",
    },
    CA: {
      name: "Canada",
      dial_code: "+1",
      flag: "\ud83c\udde8\ud83c\udde6",
    },
    CV: {
      name: "Cape Verde",
      dial_code: "+238",
      flag: "\ud83c\udde8\ud83c\uddfb",
    },
    KY: {
      name: "Cayman Islands",
      dial_code: "+345",
      flag: "\ud83c\uddf0\ud83c\uddfe",
    },
    CF: {
      name: "Central African Republic",
      dial_code: "+236",
      flag: "\ud83c\udde8\ud83c\uddeb",
    },
    TD: {
      name: "Chad",
      dial_code: "+235",
      flag: "\ud83c\uddf9\ud83c\udde9",
    },
    CL: {
      name: "Chile",
      dial_code: "+56",
      flag: "\ud83c\udde8\ud83c\uddf1",
    },
    CN: {
      name: "China",
      dial_code: "+86",
      flag: "\ud83c\udde8\ud83c\uddf3",
    },
    CO: {
      name: "Colombia",
      dial_code: "+57",
      flag: "\ud83c\udde8\ud83c\uddf4",
    },
    KM: {
      name: "Comoros",
      dial_code: "+269",
      flag: "\ud83c\uddf0\ud83c\uddf2",
    },
    CG: {
      name: "Congo",
      dial_code: "+242",
      flag: "\ud83c\udde8\ud83c\uddec",
    },
    CD: {
      name: "Congo, The Democratic Republic of the Congo",
      dial_code: "+243",
      flag: "\ud83c\udde8\ud83c\udde9",
    },
    CK: {
      name: "Cook Islands",
      dial_code: "+682",
      flag: "\ud83c\udde8\ud83c\uddf0",
    },
    CR: {
      name: "Costa Rica",
      dial_code: "+506",
      flag: "\ud83c\udde8\ud83c\uddf7",
    },
    CI: {
      name: "Cote d'Ivoire",
      dial_code: "+225",
      flag: "\ud83c\udde8\ud83c\uddee",
    },
    HR: {
      name: "Croatia",
      dial_code: "+385",
      flag: "\ud83c\udded\ud83c\uddf7",
    },
    CU: {
      name: "Cuba",
      dial_code: "+53",
      flag: "\ud83c\udde8\ud83c\uddfa",
    },
    CY: {
      name: "Cyprus",
      dial_code: "+357",
      flag: "\ud83c\udde8\ud83c\uddfe",
    },
    CZ: {
      name: "Czech Republic",
      dial_code: "+420",
      flag: "\ud83c\udde8\ud83c\uddff",
    },
    DK: {
      name: "Denmark",
      dial_code: "+45",
      flag: "\ud83c\udde9\ud83c\uddf0",
    },
    DJ: {
      name: "Djibouti",
      dial_code: "+253",
      flag: "\ud83c\udde9\ud83c\uddef",
    },
    DM: {
      name: "Dominica",
      dial_code: "+1767",
      flag: "\ud83c\udde9\ud83c\uddf2",
    },
    DO: {
      name: "Dominican Republic",
      dial_code: "+1849",
      flag: "\ud83c\udde9\ud83c\uddf4",
    },
    EC: {
      name: "Ecuador",
      dial_code: "+593",
      flag: "\ud83c\uddea\ud83c\udde8",
    },
    EG: {
      name: "Egypt",
      dial_code: "+20",
      flag: "\ud83c\uddea\ud83c\uddec",
    },
    SV: {
      name: "El Salvador",
      dial_code: "+503",
      flag: "\ud83c\uddf8\ud83c\uddfb",
    },
    GQ: {
      name: "Equatorial Guinea",
      dial_code: "+240",
      flag: "\ud83c\uddec\ud83c\uddf6",
    },
    ER: {
      name: "Eritrea",
      dial_code: "+291",
      flag: "\ud83c\uddea\ud83c\uddf7",
    },
    EE: {
      name: "Estonia",
      dial_code: "+372",
      flag: "\ud83c\uddea\ud83c\uddea",
    },
    ET: {
      name: "Ethiopia",
      dial_code: "+251",
      flag: "\ud83c\uddea\ud83c\uddf9",
    },
    FK: {
      name: "Falkland Islands (Malvinas)",
      dial_code: "+500",
      flag: "\ud83c\uddeb\ud83c\uddf0",
    },
    FO: {
      name: "Faroe Islands",
      dial_code: "+298",
      flag: "\ud83c\uddeb\ud83c\uddf4",
    },
    FJ: {
      name: "Fiji",
      dial_code: "+679",
      flag: "\ud83c\uddeb\ud83c\uddef",
    },
    FI: {
      name: "Finland",
      dial_code: "+358",
      flag: "\ud83c\uddeb\ud83c\uddee",
    },
    FR: {
      name: "France",
      dial_code: "+33",
      flag: "\ud83c\uddeb\ud83c\uddf7",
    },
    GF: {
      name: "French Guiana",
      dial_code: "+594",
      flag: "\ud83c\uddec\ud83c\uddeb",
    },
    PF: {
      name: "French Polynesia",
      dial_code: "+689",
      flag: "\ud83c\uddf5\ud83c\uddeb",
    },
    TF: {
      name: "French Southern Territories",
      dial_code: "+262",
      flag: "\ud83c\uddf9\ud83c\uddeb",
    },
    GA: {
      name: "Gabon",
      dial_code: "+241",
      flag: "\ud83c\uddec\ud83c\udde6",
    },
    GM: {
      name: "Gambia",
      dial_code: "+220",
      flag: "\ud83c\uddec\ud83c\uddf2",
    },
    GE: {
      name: "Georgia",
      dial_code: "+995",
      flag: "\ud83c\uddec\ud83c\uddea",
    },
    DE: {
      name: "Germany",
      dial_code: "+49",
      flag: "\ud83c\udde9\ud83c\uddea",
    },
    GH: {
      name: "Ghana",
      dial_code: "+233",
      flag: "\ud83c\uddec\ud83c\udded",
    },
    GI: {
      name: "Gibraltar",
      dial_code: "+350",
      flag: "\ud83c\uddec\ud83c\uddee",
    },
    GR: {
      name: "Greece",
      dial_code: "+30",
      flag: "\ud83c\uddec\ud83c\uddf7",
    },
    GL: {
      name: "Greenland",
      dial_code: "+299",
      flag: "\ud83c\uddec\ud83c\uddf1",
    },
    GD: {
      name: "Grenada",
      dial_code: "+1473",
      flag: "\ud83c\uddec\ud83c\udde9",
    },
    GP: {
      name: "Guadeloupe",
      dial_code: "+590",
      flag: "\ud83c\uddec\ud83c\uddf5",
    },
    GU: {
      name: "Guam",
      dial_code: "+1671",
      flag: "\ud83c\uddec\ud83c\uddfa",
    },
    GT: {
      name: "Guatemala",
      dial_code: "+502",
      flag: "\ud83c\uddec\ud83c\uddf9",
    },
    GG: {
      name: "Guernsey",
      dial_code: "+44",
      flag: "\ud83c\uddec\ud83c\uddec",
    },
    GN: {
      name: "Guinea",
      dial_code: "+224",
      flag: "\ud83c\uddec\ud83c\uddf3",
    },
    GW: {
      name: "Guinea-Bissau",
      dial_code: "+245",
      flag: "\ud83c\uddec\ud83c\uddfc",
    },
    GY: {
      name: "Guyana",
      dial_code: "+592",
      flag: "\ud83c\uddec\ud83c\uddfe",
    },
    HT: {
      name: "Haiti",
      dial_code: "+509",
      flag: "\ud83c\udded\ud83c\uddf9",
    },
    HM: {
      name: "Heard Island and Mcdonald Islands",
      dial_code: "+672",
      flag: "\ud83c\udded\ud83c\uddf2",
    },
    VA: {
      name: "Holy See (Vatican City State)",
      dial_code: "+379",
      flag: "\ud83c\uddfb\ud83c\udde6",
    },
    HN: {
      name: "Honduras",
      dial_code: "+504",
      flag: "\ud83c\udded\ud83c\uddf3",
    },
    HK: {
      name: "Hong Kong",
      dial_code: "+852",
      flag: "\ud83c\udded\ud83c\uddf0",
    },
    HU: {
      name: "Hungary",
      dial_code: "+36",
      flag: "\ud83c\udded\ud83c\uddfa",
    },
    IS: {
      name: "Iceland",
      dial_code: "+354",
      flag: "\ud83c\uddee\ud83c\uddf8",
    },
    IN: {
      name: "India",
      dial_code: "+91",
      flag: "\ud83c\uddee\ud83c\uddf3",
    },
    ID: {
      name: "Indonesia",
      dial_code: "+62",
      flag: "\ud83c\uddee\ud83c\udde9",
    },
    IR: {
      name: "Iran, Islamic Republic of Persian Gulf",
      dial_code: "+98",
      flag: "\ud83c\uddee\ud83c\uddf7",
    },
    IQ: {
      name: "Iraq",
      dial_code: "+964",
      flag: "\ud83c\uddee\ud83c\uddf6",
    },
    IE: {
      name: "Ireland",
      dial_code: "+353",
      flag: "\ud83c\uddee\ud83c\uddea",
    },
    IM: {
      name: "Isle of Man",
      dial_code: "+44",
      flag: "\ud83c\uddee\ud83c\uddf2",
    },
    IL: {
      name: "Israel",
      dial_code: "+972",
      flag: "\ud83c\uddee\ud83c\uddf1",
    },
    IT: {
      name: "Italy",
      dial_code: "+39",
      flag: "\ud83c\uddee\ud83c\uddf9",
    },
    JM: {
      name: "Jamaica",
      dial_code: "+1876",
      flag: "\ud83c\uddef\ud83c\uddf2",
    },
    JP: {
      name: "Japan",
      dial_code: "+81",
      flag: "\ud83c\uddef\ud83c\uddf5",
    },
    JE: {
      name: "Jersey",
      dial_code: "+44",
      flag: "\ud83c\uddef\ud83c\uddea",
    },
    JO: {
      name: "Jordan",
      dial_code: "+962",
      flag: "\ud83c\uddef\ud83c\uddf4",
    },
    KZ: {
      name: "Kazakhstan",
      dial_code: "+7",
      flag: "\ud83c\uddf0\ud83c\uddff",
    },
    KE: {
      name: "Kenya",
      dial_code: "+254",
      flag: "\ud83c\uddf0\ud83c\uddea",
    },
    KI: {
      name: "Kiribati",
      dial_code: "+686",
      flag: "\ud83c\uddf0\ud83c\uddee",
    },
    KP: {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: "+850",
      flag: "\ud83c\uddf0\ud83c\uddf5",
    },
    KR: {
      name: "Korea, Republic of South Korea",
      dial_code: "+82",
      flag: "\ud83c\uddf0\ud83c\uddf7",
    },
    XK: {
      name: "Kosovo",
      dial_code: "+383",
      flag: "\ud83c\uddfd\ud83c\uddf0",
    },
    KW: {
      name: "Kuwait",
      dial_code: "+965",
      flag: "\ud83c\uddf0\ud83c\uddfc",
    },
    KG: {
      name: "Kyrgyzstan",
      dial_code: "+996",
      flag: "\ud83c\uddf0\ud83c\uddec",
    },
    LA: {
      name: "Laos",
      dial_code: "+856",
      flag: "\ud83c\uddf1\ud83c\udde6",
    },
    LV: {
      name: "Latvia",
      dial_code: "+371",
      flag: "\ud83c\uddf1\ud83c\uddfb",
    },
    LB: {
      name: "Lebanon",
      dial_code: "+961",
      flag: "\ud83c\uddf1\ud83c\udde7",
    },
    LS: {
      name: "Lesotho",
      dial_code: "+266",
      flag: "\ud83c\uddf1\ud83c\uddf8",
    },
    LR: {
      name: "Liberia",
      dial_code: "+231",
      flag: "\ud83c\uddf1\ud83c\uddf7",
    },
    LY: {
      name: "Libyan Arab Jamahiriya",
      dial_code: "+218",
      flag: "\ud83c\uddf1\ud83c\uddfe",
    },
    LI: {
      name: "Liechtenstein",
      dial_code: "+423",
      flag: "\ud83c\uddf1\ud83c\uddee",
    },
    LT: {
      name: "Lithuania",
      dial_code: "+370",
      flag: "\ud83c\uddf1\ud83c\uddf9",
    },
    LU: {
      name: "Luxembourg",
      dial_code: "+352",
      flag: "\ud83c\uddf1\ud83c\uddfa",
    },
    MO: {
      name: "Macao",
      dial_code: "+853",
      flag: "\ud83c\uddf2\ud83c\uddf4",
    },
    MK: {
      name: "Macedonia",
      dial_code: "+389",
      flag: "\ud83c\uddf2\ud83c\uddf0",
    },
    MG: {
      name: "Madagascar",
      dial_code: "+261",
      flag: "\ud83c\uddf2\ud83c\uddec",
    },
    MW: {
      name: "Malawi",
      dial_code: "+265",
      flag: "\ud83c\uddf2\ud83c\uddfc",
    },
    MY: {
      name: "Malaysia",
      dial_code: "+60",
      flag: "\ud83c\uddf2\ud83c\uddfe",
    },
    MV: {
      name: "Maldives",
      dial_code: "+960",
      flag: "\ud83c\uddf2\ud83c\uddfb",
    },
    ML: {
      name: "Mali",
      dial_code: "+223",
      flag: "\ud83c\uddf2\ud83c\uddf1",
    },
    MT: {
      name: "Malta",
      dial_code: "+356",
      flag: "\ud83c\uddf2\ud83c\uddf9",
    },
    MH: {
      name: "Marshall Islands",
      dial_code: "+692",
      flag: "\ud83c\uddf2\ud83c\udded",
    },
    MQ: {
      name: "Martinique",
      dial_code: "+596",
      flag: "\ud83c\uddf2\ud83c\uddf6",
    },
    MR: {
      name: "Mauritania",
      dial_code: "+222",
      flag: "\ud83c\uddf2\ud83c\uddf7",
    },
    MU: {
      name: "Mauritius",
      dial_code: "+230",
      flag: "\ud83c\uddf2\ud83c\uddfa",
    },
    YT: {
      name: "Mayotte",
      dial_code: "+262",
      flag: "\ud83c\uddfe\ud83c\uddf9",
    },
    MX: {
      name: "Mexico",
      dial_code: "+52",
      flag: "\ud83c\uddf2\ud83c\uddfd",
    },
    FM: {
      name: "Micronesia, Federated States of Micronesia",
      dial_code: "+691",
      flag: "\ud83c\uddeb\ud83c\uddf2",
    },
    MD: {
      name: "Moldova",
      dial_code: "+373",
      flag: "\ud83c\uddf2\ud83c\udde9",
    },
    MC: {
      name: "Monaco",
      dial_code: "+377",
      flag: "\ud83c\uddf2\ud83c\udde8",
    },
    MN: {
      name: "Mongolia",
      dial_code: "+976",
      flag: "\ud83c\uddf2\ud83c\uddf3",
    },
    ME: {
      name: "Montenegro",
      dial_code: "+382",
      flag: "\ud83c\uddf2\ud83c\uddea",
    },
    MS: {
      name: "Montserrat",
      dial_code: "+1664",
      flag: "\ud83c\uddf2\ud83c\uddf8",
    },
    MA: {
      name: "Morocco",
      dial_code: "+212",
      flag: "\ud83c\uddf2\ud83c\udde6",
    },
    MZ: {
      name: "Mozambique",
      dial_code: "+258",
      flag: "\ud83c\uddf2\ud83c\uddff",
    },
    MM: {
      name: "Myanmar",
      dial_code: "+95",
      flag: "\ud83c\uddf2\ud83c\uddf2",
    },
    NA: {
      name: "Namibia",
      dial_code: "+264",
      flag: "\ud83c\uddf3\ud83c\udde6",
    },
    NR: {
      name: "Nauru",
      dial_code: "+674",
      flag: "\ud83c\uddf3\ud83c\uddf7",
    },
    NP: {
      name: "Nepal",
      dial_code: "+977",
      flag: "\ud83c\uddf3\ud83c\uddf5",
    },
    NL: {
      name: "Netherlands",
      dial_code: "+31",
      flag: "\ud83c\uddf3\ud83c\uddf1",
    },
    AN: {
      name: "Netherlands Antilles",
      dial_code: "+599",
      flag: "",
    },
    NC: {
      name: "New Caledonia",
      dial_code: "+687",
      flag: "\ud83c\uddf3\ud83c\udde8",
    },
    NZ: {
      name: "New Zealand",
      dial_code: "+64",
      flag: "\ud83c\uddf3\ud83c\uddff",
    },
    NI: {
      name: "Nicaragua",
      dial_code: "+505",
      flag: "\ud83c\uddf3\ud83c\uddee",
    },
    NE: {
      name: "Niger",
      dial_code: "+227",
      flag: "\ud83c\uddf3\ud83c\uddea",
    },
    NG: {
      name: "Nigeria",
      dial_code: "+234",
      flag: "\ud83c\uddf3\ud83c\uddec",
    },
    NU: {
      name: "Niue",
      dial_code: "+683",
      flag: "\ud83c\uddf3\ud83c\uddfa",
    },
    NF: {
      name: "Norfolk Island",
      dial_code: "+672",
      flag: "\ud83c\uddf3\ud83c\uddeb",
    },
    MP: {
      name: "Northern Mariana Islands",
      dial_code: "+1670",
      flag: "\ud83c\uddf2\ud83c\uddf5",
    },
    NO: {
      name: "Norway",
      dial_code: "+47",
      flag: "\ud83c\uddf3\ud83c\uddf4",
    },
    OM: {
      name: "Oman",
      dial_code: "+968",
      flag: "\ud83c\uddf4\ud83c\uddf2",
    },
    PK: {
      name: "Pakistan",
      dial_code: "+92",
      flag: "\ud83c\uddf5\ud83c\uddf0",
    },
    PW: {
      name: "Palau",
      dial_code: "+680",
      flag: "\ud83c\uddf5\ud83c\uddfc",
    },
    PS: {
      name: "Palestinian Territory, Occupied",
      dial_code: "+970",
      flag: "\ud83c\uddf5\ud83c\uddf8",
    },
    PA: {
      name: "Panama",
      dial_code: "+507",
      flag: "\ud83c\uddf5\ud83c\udde6",
    },
    PG: {
      name: "Papua New Guinea",
      dial_code: "+675",
      flag: "\ud83c\uddf5\ud83c\uddec",
    },
    PY: {
      name: "Paraguay",
      dial_code: "+595",
      flag: "\ud83c\uddf5\ud83c\uddfe",
    },
    PE: {
      name: "Peru",
      dial_code: "+51",
      flag: "\ud83c\uddf5\ud83c\uddea",
    },
    PH: {
      name: "Philippines",
      dial_code: "+63",
      flag: "\ud83c\uddf5\ud83c\udded",
    },
    PN: {
      name: "Pitcairn",
      dial_code: "+64",
      flag: "\ud83c\uddf5\ud83c\uddf3",
    },
    PL: {
      name: "Poland",
      dial_code: "+48",
      flag: "\ud83c\uddf5\ud83c\uddf1",
    },
    PT: {
      name: "Portugal",
      dial_code: "+351",
      flag: "\ud83c\uddf5\ud83c\uddf9",
    },
    PR: {
      name: "Puerto Rico",
      dial_code: "+1939",
      flag: "\ud83c\uddf5\ud83c\uddf7",
    },
    QA: {
      name: "Qatar",
      dial_code: "+974",
      flag: "\ud83c\uddf6\ud83c\udde6",
    },
    RO: {
      name: "Romania",
      dial_code: "+40",
      flag: "\ud83c\uddf7\ud83c\uddf4",
    },
    RU: {
      name: "Russia",
      dial_code: "+7",
      flag: "\ud83c\uddf7\ud83c\uddfa",
    },
    RW: {
      name: "Rwanda",
      dial_code: "+250",
      flag: "\ud83c\uddf7\ud83c\uddfc",
    },
    RE: {
      name: "Reunion",
      dial_code: "+262",
      flag: "\ud83c\uddf7\ud83c\uddea",
    },
    BL: {
      name: "Saint Barthelemy",
      dial_code: "+590",
      flag: "\ud83c\udde7\ud83c\uddf1",
    },
    SH: {
      name: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "+290",
      flag: "\ud83c\uddf8\ud83c\udded",
    },
    KN: {
      name: "Saint Kitts and Nevis",
      dial_code: "+1869",
      flag: "\ud83c\uddf0\ud83c\uddf3",
    },
    LC: {
      name: "Saint Lucia",
      dial_code: "+1758",
      flag: "\ud83c\uddf1\ud83c\udde8",
    },
    MF: {
      name: "Saint Martin",
      dial_code: "+590",
      flag: "\ud83c\uddf2\ud83c\uddeb",
    },
    PM: {
      name: "Saint Pierre and Miquelon",
      dial_code: "+508",
      flag: "\ud83c\uddf5\ud83c\uddf2",
    },
    VC: {
      name: "Saint Vincent and the Grenadines",
      dial_code: "+1784",
      flag: "\ud83c\uddfb\ud83c\udde8",
    },
    WS: {
      name: "Samoa",
      dial_code: "+685",
      flag: "\ud83c\uddfc\ud83c\uddf8",
    },
    SM: {
      name: "San Marino",
      dial_code: "+378",
      flag: "\ud83c\uddf8\ud83c\uddf2",
    },
    ST: {
      name: "Sao Tome and Principe",
      dial_code: "+239",
      flag: "\ud83c\uddf8\ud83c\uddf9",
    },
    SA: {
      name: "Saudi Arabia",
      dial_code: "+966",
      flag: "\ud83c\uddf8\ud83c\udde6",
    },
    SN: {
      name: "Senegal",
      dial_code: "+221",
      flag: "\ud83c\uddf8\ud83c\uddf3",
    },
    RS: {
      name: "Serbia",
      dial_code: "+381",
      flag: "\ud83c\uddf7\ud83c\uddf8",
    },
    SC: {
      name: "Seychelles",
      dial_code: "+248",
      flag: "\ud83c\uddf8\ud83c\udde8",
    },
    SL: {
      name: "Sierra Leone",
      dial_code: "+232",
      flag: "\ud83c\uddf8\ud83c\uddf1",
    },
    SG: {
      name: "Singapore",
      dial_code: "+65",
      flag: "\ud83c\uddf8\ud83c\uddec",
    },
    SK: {
      name: "Slovakia",
      dial_code: "+421",
      flag: "\ud83c\uddf8\ud83c\uddf0",
    },
    SI: {
      name: "Slovenia",
      dial_code: "+386",
      flag: "\ud83c\uddf8\ud83c\uddee",
    },
    SB: {
      name: "Solomon Islands",
      dial_code: "+677",
      flag: "\ud83c\uddf8\ud83c\udde7",
    },
    SO: {
      name: "Somalia",
      dial_code: "+252",
      flag: "\ud83c\uddf8\ud83c\uddf4",
    },
    ZA: {
      name: "South Africa",
      dial_code: "+27",
      flag: "\ud83c\uddff\ud83c\udde6",
    },
    SS: {
      name: "South Sudan",
      dial_code: "+211",
      flag: "\ud83c\uddf8\ud83c\uddf8",
    },
    GS: {
      name: "South Georgia and the South Sandwich Islands",
      dial_code: "+500",
      flag: "\ud83c\uddec\ud83c\uddf8",
    },
    ES: {
      name: "Spain",
      dial_code: "+34",
      flag: "\ud83c\uddea\ud83c\uddf8",
    },
    LK: {
      name: "Sri Lanka",
      dial_code: "+94",
      flag: "\ud83c\uddf1\ud83c\uddf0",
    },
    SD: {
      name: "Sudan",
      dial_code: "+249",
      flag: "\ud83c\uddf8\ud83c\udde9",
    },
    SR: {
      name: "Suriname",
      dial_code: "+597",
      flag: "\ud83c\uddf8\ud83c\uddf7",
    },
    SJ: {
      name: "Svalbard and Jan Mayen",
      dial_code: "+47",
      flag: "\ud83c\uddf8\ud83c\uddef",
    },
    SZ: {
      name: "Swaziland",
      dial_code: "+268",
      flag: "\ud83c\uddf8\ud83c\uddff",
    },
    SE: {
      name: "Sweden",
      dial_code: "+46",
      flag: "\ud83c\uddf8\ud83c\uddea",
    },
    CH: {
      name: "Switzerland",
      dial_code: "+41",
      flag: "\ud83c\udde8\ud83c\udded",
    },
    SY: {
      name: "Syrian Arab Republic",
      dial_code: "+963",
      flag: "\ud83c\uddf8\ud83c\uddfe",
    },
    TW: {
      name: "Taiwan",
      dial_code: "+886",
      flag: "\ud83c\uddf9\ud83c\uddfc",
    },
    TJ: {
      name: "Tajikistan",
      dial_code: "+992",
      flag: "\ud83c\uddf9\ud83c\uddef",
    },
    TZ: {
      name: "Tanzania, United Republic of Tanzania",
      dial_code: "+255",
      flag: "\ud83c\uddf9\ud83c\uddff",
    },
    TH: {
      name: "Thailand",
      dial_code: "+66",
      flag: "\ud83c\uddf9\ud83c\udded",
    },
    TL: {
      name: "Timor-Leste",
      dial_code: "+670",
      flag: "\ud83c\uddf9\ud83c\uddf1",
    },
    TG: {
      name: "Togo",
      dial_code: "+228",
      flag: "\ud83c\uddf9\ud83c\uddec",
    },
    TK: {
      name: "Tokelau",
      dial_code: "+690",
      flag: "\ud83c\uddf9\ud83c\uddf0",
    },
    TO: {
      name: "Tonga",
      dial_code: "+676",
      flag: "\ud83c\uddf9\ud83c\uddf4",
    },
    TT: {
      name: "Trinidad and Tobago",
      dial_code: "+1868",
      flag: "\ud83c\uddf9\ud83c\uddf9",
    },
    TN: {
      name: "Tunisia",
      dial_code: "+216",
      flag: "\ud83c\uddf9\ud83c\uddf3",
    },
    TR: {
      name: "Turkey",
      dial_code: "+90",
      flag: "\ud83c\uddf9\ud83c\uddf7",
    },
    TM: {
      name: "Turkmenistan",
      dial_code: "+993",
      flag: "\ud83c\uddf9\ud83c\uddf2",
    },
    TC: {
      name: "Turks and Caicos Islands",
      dial_code: "+1649",
      flag: "\ud83c\uddf9\ud83c\udde8",
    },
    TV: {
      name: "Tuvalu",
      dial_code: "+688",
      flag: "\ud83c\uddf9\ud83c\uddfb",
    },
    UG: {
      name: "Uganda",
      dial_code: "+256",
      flag: "\ud83c\uddfa\ud83c\uddec",
    },
    UA: {
      name: "Ukraine",
      dial_code: "+380",
      flag: "\ud83c\uddfa\ud83c\udde6",
    },
    AE: {
      name: "United Arab Emirates",
      dial_code: "+971",
      flag: "\ud83c\udde6\ud83c\uddea",
    },
    GB: {
      name: "United Kingdom",
      dial_code: "+44",
      flag: "\ud83c\uddec\ud83c\udde7",
    },
    US: {
      name: "United States",
      dial_code: "+1",
      flag: "\ud83c\uddfa\ud83c\uddf8",
    },
    UY: {
      name: "Uruguay",
      dial_code: "+598",
      flag: "\ud83c\uddfa\ud83c\uddfe",
    },
    UZ: {
      name: "Uzbekistan",
      dial_code: "+998",
      flag: "\ud83c\uddfa\ud83c\uddff",
    },
    VU: {
      name: "Vanuatu",
      dial_code: "+678",
      flag: "\ud83c\uddfb\ud83c\uddfa",
    },
    VE: {
      name: "Venezuela, Bolivarian Republic of Venezuela",
      dial_code: "+58",
      flag: "\ud83c\uddfb\ud83c\uddea",
    },
    VN: {
      name: "Vietnam",
      dial_code: "+84",
      flag: "\ud83c\uddfb\ud83c\uddf3",
    },
    VG: {
      name: "Virgin Islands, British",
      dial_code: "+1284",
      flag: "\ud83c\uddfb\ud83c\uddec",
    },
    VI: {
      name: "Virgin Islands, U.S.",
      dial_code: "+1340",
      flag: "\ud83c\uddfb\ud83c\uddee",
    },
    WF: {
      name: "Wallis and Futuna",
      dial_code: "+681",
      flag: "\ud83c\uddfc\ud83c\uddeb",
    },
    YE: {
      name: "Yemen",
      dial_code: "+967",
      flag: "\ud83c\uddfe\ud83c\uddea",
    },
    ZM: {
      name: "Zambia",
      dial_code: "+260",
      flag: "\ud83c\uddff\ud83c\uddf2",
    },
    ZW: {
      name: "Zimbabwe",
      dial_code: "+263",
      flag: "\ud83c\uddff\ud83c\uddfc",
    },
  },
};

// creates a mapping of dial codes to country
const buildDialCodeMap = (countries: any) => {
  const map: { [dial_code: string]: Country } = {};

  Object.keys(countries).forEach((key: any) => {
    const country = countries[key];
    map[country.dial_code] = {
      name: country.name,
      flag: country.flag,
      countryCode: key,
      dialCode: country.dial_code,
    };
  });

  return map;
};

export const dialCodeToCountryMap = buildDialCodeMap(
  defaultCountries.countries
);

export const countryCodeToCountryMap: { [idx: string]: Country } = Object.keys(
  defaultCountries.countries
).reduce((current: { [index: string]: Country }, k) => {
  const country: any = defaultCountries.countries[k];
  current[k] = {
    countryCode: k,
    dialCode: country.dial_code,
    name: country.name,
    flag: country.flag,
  };
  return current;
}, {});
