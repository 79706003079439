import { PRODUCTS_URL } from "../constants/urls";
import store from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";

/**
 * @param { string } slug Shop Slug
 * @param { number } page Current Page
 */
export async function getAllProductsByShopSlug(slug: string, page: number) {
  const params = `?slug=${slug}&page=${page}&per_page=8`;

  const response = await fetch(PRODUCTS_URL + params, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;
  return result;
}
