import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdupPaymentMethodSelection } from "../../interfaces/AdupPaymentMethod";
import { getKeyByValue } from "../../lib/utils/helper-functions";
import {
  setIsPaymentMethodsLoading,
  setIsWalletPaymentMethodInUse,
  setPaymentMethodLastUsed,
  setSelectedPaymentMethodOption,
  setUnsavedSelectedPaymentMethod,
} from "../../store/reducers/paymentSlice";
import { RootState } from "../../store/store";
import { LazyLoadImage } from "react-lazy-load-image-component";

type PaymentMethodWithOptionsProps = {
  name: string;
  image: string;
  svgIcon?: JSX.Element;
  key?: number;
  link?: string;
  onClick?: any;
  displayTitle: string;
  paymentMethod: AdupPaymentMethodSelection;
};

const NewPaymentMethodWithOptions: React.FC<PaymentMethodWithOptionsProps> = ({
  displayTitle,
  paymentMethod,
  image,
  onClick,
}) => {
  const dispatch = useDispatch();

  dispatch(setIsPaymentMethodsLoading(false));

  const selectedPaymentMethod = useSelector(
    (state: RootState) => state.payment.unsavedPaymentMethodSelected
  );

  const paymentMethodOptionSelected = useSelector(
    (state: RootState) => state.payment.paymentMethodOptionSelected
  );

  const savedPaymentAttributes = useSelector(
    (state: RootState) => state.payment?.savedPaymentAttributes
  );

  const [isSelectedMethod, setIsSelectedMethod] = useState(
    selectedPaymentMethod.displayTitle === paymentMethod.displayTitle
  );

  const bankOptions = selectedPaymentMethod.banks;

  useEffect(() => {
    let savedBankOption;

    if (selectedPaymentMethod?.name === "ideal") {
      if (savedPaymentAttributes && savedPaymentAttributes?.ideal_bank) {
        savedBankOption = savedPaymentAttributes?.ideal_bank;
      } else {
        savedBankOption = undefined;
      }
    } else if (selectedPaymentMethod?.name === "p24") {
      if (savedPaymentAttributes && savedPaymentAttributes?.p24_bank) {
        savedBankOption = savedPaymentAttributes?.p24_bank;
      } else {
        savedBankOption = undefined;
      }
    } else if (selectedPaymentMethod?.name === "eps") {
      if (savedPaymentAttributes && savedPaymentAttributes?.eps_bank) {
        savedBankOption = savedPaymentAttributes?.eps_bank;
      } else {
        savedBankOption = undefined;
      }
    }

    //* Setting up the Payment methods with option default value
    if (
      selectedPaymentMethod?.name === "ideal" ||
      selectedPaymentMethod?.name === "p24" ||
      selectedPaymentMethod?.name === "eps"
    ) {
      if (selectedPaymentMethod?.data && selectedPaymentMethod?.data?.bank) {
        const bankOptions = selectedPaymentMethod?.data?.bank;
        dispatch(
          setSelectedPaymentMethodOption({
            key: savedBankOption ?? Object.keys(bankOptions)[0],
            optionData:
              bankOptions[savedBankOption] ??
              bankOptions[Object.keys(bankOptions)[0]],
          })
        );
        if (savedBankOption)
          console.log(
            "Saved Bank auto selected : ",
            selectedPaymentMethod?.name,
            ">",
            savedBankOption
          );
      }
    }

    setIsSelectedMethod(
      selectedPaymentMethod.displayTitle === paymentMethod.displayTitle
    );
  }, [selectedPaymentMethod]);

  function handleClick() {
    dispatch(setPaymentMethodLastUsed(""));
    dispatch(setIsWalletPaymentMethodInUse(false));
    //? To prevent making Session calls when the already selected same payment method is clicked
    if (!isSelectedMethod) {
      dispatch(setUnsavedSelectedPaymentMethod(paymentMethod));
      onClick();
    } else {
      console.log(
        "Prevented already selected Payment method Click :",
        selectedPaymentMethod.displayTitle
      );
    }
  }

  function handleOptionChange() {
    dispatch(setUnsavedSelectedPaymentMethod(paymentMethod));
    onClick();
  }

  let optionsArray: any = [];
  if (bankOptions) {
    Object.keys(bankOptions).forEach(function (key, index) {
      optionsArray.push(
        <option key={key} value={bankOptions[key]}>
          {key}
        </option>
      );
    });
  }

  return (
    <div
      className={
        "new-payment-method-with-extras card-details-retracted " +
        (isSelectedMethod ? "selected-payment-method" : "")
      }
      onClick={() => handleClick()}
    >
      <div className="payment-method-icon-and-name ">
        <LazyLoadImage loading="lazy" src={image} alt="pay" />{" "}
        <div>{displayTitle}</div>
      </div>

      <div className="new-payment-method-options">
        <div className="new-payment-method-options-list-name">Banks</div>
        <select
          className="payment-method-option-selector"
          defaultValue={
            bankOptions ? bankOptions[Object.keys(bankOptions)[0]] : ""
          }
          value={
            paymentMethodOptionSelected
              ? paymentMethodOptionSelected
              : undefined
          }
          onChange={(e) => {
            const value = e.target.value;
            dispatch(
              setSelectedPaymentMethodOption({
                key: getKeyByValue(bankOptions, value) ?? "",
                optionData: value,
              })
            );
            handleOptionChange();
          }}
        >
          {optionsArray}
          {/* {options[0].options.map((listItem, key) => (
            <option key={key} value={listItem.value}>
              {listItem.displayName}
            </option>
          ))} */}
        </select>
      </div>
    </div>
  );
};

export default NewPaymentMethodWithOptions;
