import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IToast } from "../../components/toasts/BasicToastNotification";
import {
  countryCodeToCountryMap,
  defaultCountries,
} from "../../lib/constants/default-countries";

// creates a mapping of dial codes to country
const buildDialCodeMap = (countries: any) => {
  const map: { [dial_code: string]: any } = {};

  Object.keys(countries).forEach((key: any) => {
    const country = countries[key];
    map[country.dial_code] = {
      name: country.name,
      flag: country.flag,
      countryCode: key,
      dialCode: country.dial_code,
    };
  });

  return map;
};

/**
 * The application can be accessed by either a new user or an old (existing) user
 * This user may be connecting via an old browser, i.e. one where profile data is saved,
 * or a new browser, where there is no api key in local storage
 */
export const applicationBrowserStates = {
  NEW_BROWSER: "NEW_BROWSER",
  OLD_BROWSER: "OLD_BROWSER",
};
export const applicationUserType = {
  OLD_USER: "OLD_USER",
  NEW_USER: "NEW_USER",
};

export const initialHiddenSegments: { [index: string]: boolean } = {
  ACCOUNT_SEGMENT: false,
  PAYMENT_METHODS_SEGMENT: false,
  DETAILS_SEGMENT: false,
  SHIPPING_ADDRESS_SEGMENT: false,
  BILLING_ADDRESS_SEGMENT: false,
  CREATE_ADUP_ACCOUNT_SEGMENT: false,
};

export const emptyToast: IToast = {
  text: "",
  type: "",
};

const initialState = {
  UI: {
    // scrollable: true,
    pages: {
      fastCheckout: {
        hiddenSegments: initialHiddenSegments,
      },
    },

    toastMessage: emptyToast,
    lastCartUrl: "",
    lastSocialCommerceUrl: "",
    isPayButtonLoading: false,
    isPaymentOnPopUpViaIframe: false,
    isInitAPILoading: true,
    showCookieConsent: true,
    socialMediaCookie: false,
    isOtpOverlayVisible: false,
    isAICheckout: false,
    language: "en",

    cart: {
      expanded: false,
    },
  },
  settings: { ipAddressCountryCode: "" },
  phone: {
    countryCodes: countryCodeToCountryMap,
    countryDialCodeMap: buildDialCodeMap(defaultCountries.countries),
  },
  auth: {
    browser: applicationBrowserStates.NEW_BROWSER,
    user: applicationUserType.NEW_USER,
  },

  //* Setting up Demo Data ---- *//
  demoCheckoutInitData: {
    shop: {
      shop_slug: "demo",
      id: 3,
      initial: "DEMO",
      subscription_status: "true",
      name: undefined,
      currency: "EUR",
      status: true,
      merchant_id: "0",
      subscription_lock: false,
      shop_id: 0,
      settings: {
        background_image: null,
        company_logo: null,
        company_icon: null,
        theme_color: "#00f6cd",
        font_color: "#000000",
        background_color: "#f6f6f6",
        default_shipping_cost: 0,
        min_free_shipping: 0,
        fixed_shipping: true,
        send_monthly_newsletter: false,
        facebook_pixel_id: null,
        tiktok_pixel_id: null,
        google_analytics_id: null,
        currency: "EUR",
      },
    },
    products: [
      {
        "demo-sku": {
          id: 0,
          sku: "demo-sku",
          type: "simple",
          name: undefined,
          slug: "demo",
          currency: "EUR",
          description: undefined,
          display_price: undefined,
          images: [],
          attributes: null,
          variant: {
            "demo-sku": {
              id: 1,
              name: undefined,
              sku: "demo-sku",
              description: undefined,
              images: [],
              total_sales: "100",
              status: "true",
              price: undefined,
              attributes: null,
            },
          },
        },
      },
    ],
    user_data: {
      device_ip: "123",
      device_country: {
        country: "Netherlands",
        country_code: "NL",
      },
      language: "en",
      browser_name: "Mobile Safari",
      is_bot: false,
      os: "iOS 15",
    },
    app_data: {
      static_base_url: "",
      api_version: "v1",
      countries: defaultCountries.countries,
    },
    payment_method: {
      card: [
        {
          icon: "payment-method/card.svg",
          label: "Credit Cards",
          processor: "frontend",
          fields: null,
          data: [],
          gateway: "stripe",
        },
      ],
      ideal: [
        {
          icon: "payment-method/ideal.svg",
          label: "Ideal Payments",
          processor: "frontend",
          fields: null,
          data: {
            bank: {
              "ABN AMRO": "abn_amro",
              "ASN Bank": "asn_bank",
              ING: "ing",
              Rabobank: "rabobank",
              "SNS Bank": "sns_bank",
              "Triodos Bank": "triodos_bank",
              Revolut: "revolut",
              RegioBank: "regiobank",
              Knab: "asn_bank",
              Bunq: "bunq",
              Handelsbanken: "handelsbanken",
              "Van Lanschot": "van_lanschot",
            },
          },
          gateway: "stripe",
        },
      ],
      giropay: [
        {
          icon: "payment-method/giropay.svg",
          label: "Giropay",
          processor: "frontend",
          fields: null,
          data: [],
          gateway: "stripe",
        },
      ],
      google_pay: [
        {
          icon: "payment-method/google_pay.png",
          label: "Google Pay",
          processor: "frontend",
          fields: null,
          data: [],
          gateway: "stripe",
        },
      ],
      sofort: [
        {
          icon: "payment-method/sofort.svg",
          label: "Sofort",
          processor: "frontend",
          fields: null,
          data: [],
          gateway: "stripe",
        },
      ],
      eps: [
        {
          icon: "payment-method/eps.svg",
          label: "EPS",
          processor: "frontend",
          fields: null,
          data: {
            bank: {
              "Ärzte- und Apothekerbank": "arzte_und_apotheker_bank",
              "Austrian Anadi Bank AG": "austrian_anadi_bank_ag",
              "Bank Austria": "bank_austria",
              "Bankhaus Carl Spängler & Co.AG": "bankhaus_carl_spangler",
              "Bankhaus Schelhammer & Schattera AG":
                "bankhaus_schelhammer_und_schattera_ag",
              "BAWAG P.S.K. AG": "bawag_psk_ag",
              "BKS Bank AG": "bks_bank_ag",
              "Brüll Kallmus Bank AG": "brull_kallmus_bank_ag",
              "BTV VIER LÄNDER BANK": "btv_vier_lander_bank",
              "Capital Bank Grawe Gruppe AG": "capital_bank_grawe_gruppe_ag",
              Dolomitenbank: "dolomitenbank",
              "Easybank AG": "easybank_ag",
              "Erste Bank und Sparkassen": "erste_bank_und_sparkassen",
              "Hypo Alpe-Adria-Bank International AG":
                "hypo_alpeadriabank_international_ag",
              "HYPO NOE LB für Niederösterreich u. Wien":
                "hypo_noe_lb_fur_niederosterreich_u_wien",
              "HYPO Oberösterreich, Salzburg, Steiermark":
                "hypo_oberosterreich_salzburg_steiermark",
              "Hypo Tirol Bank AG": "hypo_tirol_bank_ag",
              "Hypo Vorarlberg Bank AG": "hypo_vorarlberg_bank_ag",
              "HYPO-BANK BURGENLAND Aktiengesellschaft":
                "hypo_bank_burgenland_aktiengesellschaft",
              "Marchfelder Bank": "marchfelder_bank",
              "Oberbank AG": "oberbank_ag",
              "Raiffeisen Bankengruppe Österreich":
                "raiffeisen_bankengruppe_osterreich",
              "Schoellerbank AG": "schoellerbank_ag",
              "Sparda-Bank Wien": "sparda_bank_wien",
              "Volksbank Gruppe": "volksbank_gruppe",
              "Volkskreditbank AGL": "volkskreditbank_ag",
              "VR-Bank Braunau": "vr_bank_braunau",
            },
          },
          gateway: "stripe",
        },
      ],
      blik: [
        {
          icon: "payment-method/blik.svg",
          label: "Blik",
          processor: "frontend",
          fields: null,
          data: [],
          gateway: "stripe",
        },
      ],
      p24: [
        {
          icon: "payment-method/p24.svg",
          label: "P24",
          processor: "frontend",
          fields: null,
          data: {
            bank: {
              "Alior Bank": "alior_bank",
              "Bank Millenium": "bank_millennium",
              "Bank Nowy BFG S.A.": "bank_nowy_bfg_sa",
              "Bank PEKAO S.A": "bank_pekao_sa",
              "Banki SpBdzielcze": "banki_spbdzielcze",
              "Blik via redirect": "blik",
              "BNP Paribas": "bnp_paribas",
              BOZ: "boz",
              CitiHandlowy: "citi_handlowy",
              "Credit Agricole": "credit_agricole",
              "e-Transfer Poctowy24": "etransfer_pocztowy24",
              "Getin Bank": "getin_bank",
              IdeaBank: "ideabank",
              ING: "ing",
              inteligo: "inteligo",
              "mBank-mtransfer": "mbank_mtransfer",
              "Nest Przelew": "nest_przelew",
              "Noble Pay": "noble_pay",
              "PBac z iPKO (PKO+BP)": "pbac_z_ipko",
              "Plus Bank": "plus_bank",
              "Santander-przelew24": "santander_przelew24",
              "Toyota Bank": "toyota_bank",
              "Volkswagen Bank": "volkswagen_bank",
            },
          },
          gateway: "stripe",
        },
      ],
      sepa_debit: [
        {
          icon: "payment-method/sepa.svg",
          label: "Sepa Direct Debit",
          processor: "frontend",
          fields: null,
          data: [],
          gateway: "stripe",
        },
      ],
      bancontact: [
        {
          icon: "payment-method/bancontact.svg",
          label: "Bancontact",
          processor: "frontend",
          fields: null,
          data: [],
          gateway: "stripe",
        },
      ],
      klarna: [
        {
          icon: "payment-method/klarna.svg",
          label: "Klarna",
          processor: "frontend",
          fields: null,
          data: [],
          gateway: "stripe",
        },
      ],
    },
    attribute_sku_map: [],
  },
  //*Demo Data End ---- *//

  appData: {} as any,
};

const ApplicationSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateBrowser: (state, action) => {
      state.auth.browser = action.payload.browser;
    },
    updateUserType: (state, action: PayloadAction<{ user: string }>) => {
      state.auth.user = action.payload.user;
    },
    setIPCountry: (state, action) => {
      state.settings.ipAddressCountryCode = action.payload.countryCode;
    },
    setupAvailableCountries: (state, action) => {
      state.phone.countryCodes = action.payload.countryCodes;
    },
    setPageScroll: (state, action) => {
      // state.UI.scrollable = action.payload.scrollable;
    },
    setToastMessage: (state, action: PayloadAction<IToast>) => {
      state.UI.toastMessage = action.payload;
    },
    clearToastMessage: (state, _) => {
      state.UI.toastMessage = emptyToast;
    },
    setLastCartUrl: (state, action: PayloadAction<string>) => {
      state.UI.lastCartUrl = action.payload;
    },
    setLastSocialCommerceUrl: (state, action: PayloadAction<string>) => {
      state.UI.lastSocialCommerceUrl = action.payload;
    },
    disableFastCheckoutUISegment: (state, action: PayloadAction<string>) => {
      if (
        state.UI.pages.fastCheckout.hiddenSegments &&
        (state.UI.pages.fastCheckout.hiddenSegments[action.payload] ||
          state.UI.pages.fastCheckout.hiddenSegments[action.payload] === false)
      )
        state.UI.pages.fastCheckout.hiddenSegments[action.payload] = true;
    },
    enableFastCheckoutUISegment: (state, action: PayloadAction<string>) => {
      if (
        state.UI.pages.fastCheckout.hiddenSegments &&
        (state.UI.pages.fastCheckout.hiddenSegments[action.payload] ||
          state.UI.pages.fastCheckout.hiddenSegments[action.payload] === false)
      )
        state.UI.pages.fastCheckout.hiddenSegments[action.payload] = false;
    },
    setIsPayButtonLoading: (state, action: PayloadAction<boolean>) => {
      state.UI.isPayButtonLoading = action.payload;
    },
    setIsPaymentOnPopUpViaIframe: (state, action: PayloadAction<boolean>) => {
      state.UI.isPaymentOnPopUpViaIframe = action.payload;
    },
    setIsInitAPILoading: (state, action: PayloadAction<boolean>) => {
      state.UI.isInitAPILoading = action.payload;
    },
    setShowCookieConsent: (state, action: PayloadAction<boolean>) => {
      state.UI.showCookieConsent = action.payload;
    },
    setAcceptSocialMediaCookie: (state, action: PayloadAction<boolean>) => {
      state.UI.socialMediaCookie = action.payload;
    },
    setIsOtpOverlayVisible: (state, action: PayloadAction<boolean>) => {
      state.UI.isOtpOverlayVisible = action.payload;
    },
    setIsAICheckout: (state, action: PayloadAction<boolean>) => {
      state.UI.isAICheckout = action.payload;
    },
    setAppLanguage: (state, action: PayloadAction<string>) => {
      state.UI.language = action.payload;
    },
    setAppData: (state, action: PayloadAction<any>) => {
      state.appData = action.payload;
    },
    setCartExpanded: (state, action) => {
      state.UI = {
        ...state?.UI,
        cart: {
          expanded: action.payload,
        },
      };
    },

    //* Setting up Demo Data ---- *//
    setDemoCompanyName: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.shop.name = action.payload;
    },
    setDemoProductName: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.products[0]["demo-sku"].name = action.payload;
      state.demoCheckoutInitData.products[0]["demo-sku"].variant[
        "demo-sku"
      ].name = action.payload;
    },
    setDemoProductDescription: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.products[0]["demo-sku"].description =
        action.payload;
      state.demoCheckoutInitData.products[0]["demo-sku"].variant[
        "demo-sku"
      ].description = action.payload;
    },
    setDemoProductPrice: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.products[0]["demo-sku"].display_price =
        action.payload;
      state.demoCheckoutInitData.products[0]["demo-sku"].variant[
        "demo-sku"
      ].price = action.payload;
    },
    setDemoProductImage: (state, action: PayloadAction<any>) => {
      state.demoCheckoutInitData.products[0]["demo-sku"].images =
        action.payload;
      state.demoCheckoutInitData.products[0]["demo-sku"].variant[
        "demo-sku"
      ].images = action.payload;
    },
    //*Demo Data End ---- *//
  },
});

export const {
  updateBrowser,
  updateUserType,
  setupAvailableCountries,
  setIPCountry,
  setPageScroll,
  disableFastCheckoutUISegment,
  enableFastCheckoutUISegment,
  setToastMessage,
  clearToastMessage,
  setLastCartUrl,
  setIsPayButtonLoading,
  setIsPaymentOnPopUpViaIframe,
  setIsInitAPILoading,
  setShowCookieConsent,
  setAcceptSocialMediaCookie,
  setIsOtpOverlayVisible,
  setIsAICheckout,
  setAppLanguage,
  setAppData,
  setLastSocialCommerceUrl,
  setDemoCompanyName,
  setDemoProductName,
  setDemoProductDescription,
  setDemoProductPrice,
  setDemoProductImage,
  setCartExpanded,
} = ApplicationSlice.actions;

export default ApplicationSlice.reducer;
