import React, { useEffect, useState } from "react";
import { InputAmount } from "../input-number-controls/InputAmount";
import { useDispatch, useSelector } from "react-redux";
import {
  decrementItemNumber,
  incrementItemNumber,
  markItemAsSelected,
  removeItem,
  setFastcheckoutCartItems,
} from "../../store/reducers/cartSlice";

import { motion } from "framer-motion";
import FakeCheckbox from "../checkboxes/FakeCheckbox";
import { RootState } from "../../store/store";
import { truncate } from "../../lib/utils/helper-functions";
import DeleteCartItemMsg from "../delete-cart-item-message/DeleteCartItemMsg";
import { useLocation, useNavigate } from "react-router-dom";
import { addShopperEvent } from "../../lib/api/events-methods";
import { debounce } from "lodash";
import { LazyLoadImage } from "react-lazy-load-image-component";
export interface CartItemProps {
  id: string;
  sku: string;
  src?: string;
  index: number;
  price: number;
  amount: number;
  name: string;
  key?: number;
  currency: string;
  image: string | undefined;
  init?: any;
  anim?: any;
  transition?: any;
  exit?: any;
  showSelector?: boolean;
  isExpanded: boolean;
}

export const CartItem: React.FC<CartItemProps> = ({
  id,
  sku,
  index,
  price,
  name,
  amount,
  currency,
  image,
  anim,
  init,
  transition,
  exit,
  showSelector = false,
  isExpanded,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const route = window.decodeURI(useLocation().pathname);

  const [isSelected, setIsSelected] = useState(false);
  const [isLastItemOnCart, setIsLastItemOnCart] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const userEmail = useSelector(
    (state: RootState) => state.users.currentUser?.profile?.email
  );
  const lastSessionCallPayload = useSelector(
    (state: RootState) => state.payment?.lastSessionCallPayload
  );
  const fastcheckoutCartItems: any =
    useSelector((state: RootState) => state.cart.fastcheckoutCartItems) ?? [];
  const setSelectedItems: any =
    useSelector((state: RootState) => state.cart.selectedItems) ?? [];

  const isXXL =
    window.location.origin === "https://xxlnutrition.secure-checkout.eu"
      ? true
      : false;

  const isFastcheckout = route.split("/")[1] === "fastcheckout" ? true : false;
  /* const isXXL =
    window?.location?.origin === "http://localhost:3000" ? true : false;
  */

  useEffect(() => {
    if (isSelected && setSelectedItems) {
      dispatch(markItemAsSelected({ id, mark: true }));
    } else if (setSelectedItems) {
      dispatch(markItemAsSelected({ id, mark: false }));
    }
  }, [isSelected]);

  /**
   *  increments the number of units of an item to purchase
   * @param { React.MouseEvent } event
   */
  function increaseItem(event: React.MouseEvent) {
    dispatch(incrementItemNumber({ id }));
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    eventAPIDebounce(amount + 1);
  }

  /**
   *  decrements the number of units of an item to purchase
   * @param { React.MouseEvent } event
   */
  function decreaseItem(event: React.MouseEvent) {
    if (amount > 1) {
      dispatch(decrementItemNumber({ id }));
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      eventAPIDebounce(amount - 1);
    } else {
      if (!isFastcheckout) {
        setIsDeletePopupOpen(!isDeletePopupOpen);
      }
    }
  }

  const eventAPIDebounce = debounce(
    (qty: number) =>
      //* Triggering Events API on user actions
      addShopperEvent("add_to_cart", {
        url: `${window.location.href}`,
        product: { id, sku, name, price },
        quantity: qty,
        email: userEmail && userEmail !== "" ? userEmail : undefined,
      }),
    400
  );

  const cartState = useSelector((state: RootState) => state.cart);

  const [markedItemsCount, setMarkedItemsCount] = useState(
    cartState.items.reduce((p, c) => (c.selected ? 1 : 0) + p, 0)
  );

  useEffect(() => {
    setMarkedItemsCount(
      cartState.items.reduce((p, c) => (c.selected ? 1 : 0) + p, 0)
    );
  }, [cartState.items]);

  // or "enable checkbox"
  const [showCheckBox, setShowCheckbox] = useState(false);

  const [isImgLoaded, setIsImgLoaded] = useState(false);

  const app_mode = route.split("/")[1];
  const products = route.split("/")[3];

  useEffect(() => {
    if (app_mode === "fastcheckout" && products && products !== "") {
      // Disable removing item when its the last item on cart
      if (cartState.items.length <= 1 && cartState.items?.[0]?.amount <= 1) {
        console.log("It's the last item on cart & qty is 1 :", cartState.items);
        setIsLastItemOnCart(true);
      } else {
        setIsLastItemOnCart(false);
      }
    } else {
      setIsLastItemOnCart(false);
    }

    setShowCheckbox(
      () =>
        app_mode !== "fastcheckout" ||
        isSelected ||
        markedItemsCount < cartState.items.length - 1
    );
  }, [showSelector, cartState.items, markedItemsCount, isSelected]);

  const onCancelHandler = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };
  const deleteSelectedItem = () => {
    dispatch(removeItem({ id }));
    if (fastcheckoutCartItems.filter((i: any) => i.includes(`${sku}`))[0]) {
      let tempData = fastcheckoutCartItems.filter(
        (i: any) =>
          i !==
          fastcheckoutCartItems.filter((i: any) => i.includes(`${sku}`))[0]
      );
      dispatch(setFastcheckoutCartItems([...tempData]));
    }
  };

  return (
    <>
      <div className="cart-item-container">
        {showSelector && (
          <FakeCheckbox
            disabled={!showCheckBox}
            checked={isSelected}
            onClick={() => {
              setIsSelected((s) => !s);
            }}
          />
        )}
        <motion.div
          className="cart-item"
          animate={anim}
          transition={transition}
          initial={init}
          exit={exit}
          key={index}
          layoutId={id}
        >
          <div
            className="cart-item-inner"
            onClick={() => {
              if (isExpanded && !isFastcheckout)
                navigate(`/${cartState?.shop?.slug}/${sku}`);
            }}
          >
            {/* <img
              className={!isImgLoaded ? "cart-item-inner--placeholder" : ""}
              onLoad={() => {
                setIsImgLoaded(true);
              }}
              onError={() => {
                setIsImgLoaded(true);
              }}
              loading="lazy"
              src={image ? image : ''}
              alt="cart item"
            /> */}
            {!isXXL ? (
              <LazyLoadImage
                className={!isImgLoaded ? "cart-item-inner--placeholder" : ""}
                onLoad={() => {
                  setIsImgLoaded(true);
                }}
                onError={() => {
                  setIsImgLoaded(true);
                }}
                loading="lazy"
                src={image ? image : ""}
                alt="cart item"
              />
            ) : null}
            {/* <img src={dummyImg} alt="cart item" /> */}
            <div
              style={{
                padding: `${isXXL ? "0.7rem 1.5rem" : "0.7rem 0.5rem"}`,
              }}
              className="cart-item-details"
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className="cart-item-name">{truncate(name, 75)}</span>
                <span className="cart-item-amount-container">
                  <div className="price-calc-holder">
                    <div>
                      {currency} {` `} {price.toFixed(2)}
                      {` `}
                    </div>
                    <b className="x-multiplication" style={{ color: "#999" }}>
                      X
                    </b>
                    <div>{amount}</div>
                  </div>
                  <div className="cart-item-total-holder">
                    {currency} {` `}
                    {(price * amount).toFixed(2)}
                  </div>
                </span>
              </div>
            </div>
          </div>
          <span className="input-amount-dial-holder">
            <InputAmount
              isLastItemOnCart={isLastItemOnCart}
              currentAmount={amount}
              decrease={decreaseItem}
              increase={increaseItem}
              isDisabled={lastSessionCallPayload === "loading"}
            />
          </span>
        </motion.div>
      </div>

      {isDeletePopupOpen && (
        <DeleteCartItemMsg
          onDeleteClick={deleteSelectedItem}
          onCancelClick={onCancelHandler}
        />
      )}
    </>
  );
};

export const CartItemLoading = () => (
  <div style={{ minHeight: "150px" }}>
    <div className="cart-item cart-item-loading">
      <div className="cart-item-inner">
        <div className="cart-image-loading animated-pulse" />
        <div className="cart-item-details">
          <span className="cart-item-name animated-pulse"></span>
          <span className="cart-item-amount-container">
            <div className="cart-item-amount animated-pulse"></div>
          </span>
          <span className="input-amount-dial-holder animated-pulse"></span>
        </div>
      </div>
    </div>
  </div>
);
