import {
  CheckoutStatisticsState,
  DeviceInfoStatisticsState,
  ProductStatisticsState,
  StatisticsState,
  UIStatisticsState,
  UserDemographicsStatisticsState,
} from "../../interfaces/states/StatisticsState";
import store from "../../store/store";
import {
  setCheckoutStatistics,
  setDeviceInfoStatistics,
  setProductStatistics,
  setUIStatistics,
  setUserDemographicsStatistics,
} from "../../store/reducers/statisticsSlice";
import { browserName, osName } from "react-device-detect";
import { applicationJSONBodyBuilder } from "./body-builder";
import { submitStatisticsData } from "../api/statistics-api-call";

export default function submitStatisticsDataForAnalytics() {
  const isAICheckout: boolean = store.getState().app?.UI?.isAICheckout;
  if (isAICheckout) {
    console.log("----- 🔥🔥🔥 AI Checkout -----");
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    const isMobile =
      /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      );
    const initTheme: any = store.getState().users?.merchant?.config;
    const deviceCountry: any = store.getState().users?.device_country;
    const availablePaymentMethods: any =
      store.getState().payment?.availablePaymentMethods;
    const language: any = store.getState().app?.UI?.language;
    const cartData: any = store.getState().cart;
    const settings: any = cartData?.shop?.settings;
    const socialCommerceSingleProduct = cartData?.allProducts[0];
    const {
      ui,
      checkout,
      product,
      device_info,
      user_demographics,
      last_statistics_dataset,
    }: StatisticsState = store.getState().statistics;

    let statisticsUI: UIStatisticsState = {
      ...ui,
      checkoutFormCount: 4,
      poweredByVisible: true,
      poweredByLogoVisible: true,
      fontSizeBody: "14px",
      pageFont: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;`,
      payButtonFont: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;`,
    };

    let statisticsCheckout: CheckoutStatisticsState = {
      ...checkout,
      checkoutShippingCost: cartData?.shipping,
      activatedPaymentMethods: Object.keys(availablePaymentMethods),
    };

    let statisticsProduct: ProductStatisticsState = {
      ...product,
      productName: socialCommerceSingleProduct?.name,
      productNameLength: socialCommerceSingleProduct?.name?.length,
      productDescription: socialCommerceSingleProduct?.description,
      productDescriptionLength:
        socialCommerceSingleProduct?.description?.length,
      productVariantsCount: Object.keys(socialCommerceSingleProduct?.variant)
        .length,
      productPrice: socialCommerceSingleProduct?.display_price,
      productImageCount: socialCommerceSingleProduct?.images?.length,
      productDiscount: cartData?.discount,
      productShippingCost: cartData?.shipping,
    };

    let statisticsDeviceInfo: DeviceInfoStatisticsState = {
      ...device_info,
      deviceLanguage: language,
      deviceType: isTablet ? "Tablet" : isMobile ? "Mobile" : "Desktop",
      deviceBrowserType: browserName,
      deviceOperatingSystem: osName,
      deviceOrientation: window.matchMedia("(orientation: landscape)").matches
        ? "Landscape"
        : "Portrait",
    };

    let statisticsUserDemographics: UserDemographicsStatisticsState = {
      ...user_demographics,
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      countryCode: deviceCountry?.country_code,
      country: deviceCountry?.country,
    };

    //setting theme color
    if (settings?.theme_color !== "" && settings?.theme_color) {
      statisticsUI.payButtonColor = settings?.theme_color;
      statisticsUI.checkoutFormsColor = settings?.theme_color;
    } else {
      statisticsUI.payButtonColor = "#000000";
      statisticsUI.checkoutFormsColor = "#000000";
    }

    //setting image template
    if (settings?.product_image_ratio !== "" && settings?.product_image_ratio) {
      statisticsUI.imageTemplate = settings?.product_image_ratio;
    } else {
      statisticsUI.imageTemplate = "";
    }

    //setting image template
    if (settings?.theme !== "" && settings?.theme) {
      /* statisticsUI.variantsTemplate = settings?.theme;
    statisticsUI.orderSummaryTemplate = settings?.theme; */

      if (settings?.theme === "theme1") {
        statisticsUI.fontH1Size = "34px";
        statisticsUI.checkoutFormCount = 6;
        statisticsUI.payButtonLocation = "not fixed";
        statisticsUI.priceLocation = "below product variants selectors";
        statisticsUI.cartTotalLocation = "in under summary grand total";
        statisticsUI.checkoutFormsOrder =
          "phone number, create account, user details, shipping address, billing address, payment details";
      } else {
        statisticsUI.checkoutFormCount = 6;
      }
    }

    // populating Final Data Set
    let finalStatisticsDataSet = {
      statisticsUI,
      statisticsCheckout,
      statisticsProduct,
      statisticsDeviceInfo,
      statisticsUserDemographics,
    };

    // saving statistics data in state
    store.dispatch(setUIStatistics(statisticsUI));
    store.dispatch(setCheckoutStatistics(statisticsCheckout));
    store.dispatch(setProductStatistics(statisticsProduct));
    store.dispatch(setDeviceInfoStatistics(statisticsDeviceInfo));
    store.dispatch(setUserDemographicsStatistics(statisticsUserDemographics));

    if (
      applicationJSONBodyBuilder(last_statistics_dataset) !==
      applicationJSONBodyBuilder(finalStatisticsDataSet)
    ) {
      //? submitting Final Data Set to Analytics Endpoint
      console.log("✅ Analytics Endpoint execution accepted");
      submitStatisticsData(finalStatisticsDataSet);
    } else {
      //? avoid Analytics Endpoint execution due to unchanged statistics dataset
      console.log("❌ Analytics Endpoint execution declined");
    }
  } else {
    console.log("----- NOT in AI Checkout -----");
  }
}
