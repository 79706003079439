import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../sass/components/socialCommerce/productInfoSC.scss";

interface Props {
  title: string;
  currency: string;
  price: string | number;
  productDetails: string;
  deliveryDetails: string;
}

let descriptionRef: any;

const ProductInfoSC = ({
  title,
  currency,
  price,
  productDetails,
  deliveryDetails,
}: Props) => {
  const [isOversizedDescription, setIsOversizedDescription] = useState(false);
  const [isShowingMore, seIisShowingMore] = useState(false);
  const { t } = useTranslation();
  descriptionRef = useRef(null);

  useEffect(() => {
    if (productDetails?.length > 250) {
      setIsOversizedDescription(true);
    } else {
      setIsOversizedDescription(false);
    }
  }, [productDetails]);

  return (
    <div className="productInfoSC">
      <div className="productInfoSC__top">
        <div ref={descriptionRef} className="productInfoSC__top--title">
          <h2>{title}</h2>
          <h1>{`${currency} ${price}`}</h1>
        </div>
        <div className="productInfoSC__top--description">
          {isOversizedDescription && !isShowingMore ? (
            <p>
              {productDetails?.slice(0, 250)}...
              <a
                onClick={() => {
                  seIisShowingMore(true);
                }}
              >
                {" " + t("ReadMore")}
              </a>
            </p>
          ) : isOversizedDescription && isShowingMore ? (
            <p>
              {productDetails}
              <a
                onClick={() => {
                  executeScrollOnSocialCommerceDescription();
                  setTimeout(() => {
                    seIisShowingMore(false);
                  }, 800);
                }}
              >
                {" " + t("ReadLess")}
              </a>
            </p>
          ) : (
            <p>{productDetails}</p>
          )}
        </div>
      </div>
      {deliveryDetails && (
        <div className="productInfoSC__bottom">
          <h2>Delivery</h2>
          <p>{deliveryDetails}</p>
        </div>
      )}
    </div>
  );
};

export default ProductInfoSC;

export const executeScrollOnSocialCommerceDescription = () =>
  descriptionRef?.current?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
