import { getPaymentIntentForProcessing } from "../get-payment-intent";

export async function updatePaymentIntentValues() {
  try {
    const intentResponse = await getPaymentIntentForProcessing();

    //? Added below Globally in API Call
    // const {
    //   id,
    //   psp,
    //   psp_method,
    //   type,
    //   public_key,
    //   client_secret,
    //   return_url,
    //   currency,
    //   amount,
    // } = intentResponse;

    // store.dispatch(setPaymentIntent(id));
    // store.dispatch(setPublicKeyStripe(public_key));
    // store.dispatch(setStripeClientSecret(client_secret));
    // store.dispatch(setStripeReturnURL(return_url));
  } catch (e) {
    console.log("Error occurred when updating payment details");
  }
}
