import { setIsPayButtonLoading } from "../../../../store/reducers/appSlice";
import { SelectedPaymentMethodSavedStatus } from "../../../../store/reducers/paymentSlice";
import store from "../../../../store/store";
import { handlePaymentRequest } from "../../../api/payment-post-request";

export async function paypalPayFunction() {
  store.dispatch(setIsPayButtonLoading(true));
  const paymentState = store.getState().payment;

  const selectedPaymentMethod =
    paymentState.selectedPaymentMethodSaveState ===
    SelectedPaymentMethodSavedStatus.SAVED_METHOD
      ? paymentState.savedPaymentMethodSelected
      : paymentState.unsavedPaymentMethodSelected;

  if (
    (selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "mollie" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "magentomollie" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "aduppay") ||
    selectedPaymentMethod.type !== "paypal"
  ) {
    store.dispatch(setIsPayButtonLoading(false));
    return;
  }

  if (
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "mollie" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() ===
      "magentomollie" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "aduppay"
  ) {
    handlePaymentRequest();
  }
}
