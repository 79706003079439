import Country from "../../interfaces/Country";
import { dialCodeToCountryMap as dialCodeToCountryCodeMap } from "../../lib/constants/default-countries";

interface ValidatedPhoneResult {
  number: string;
  country: Country;
  fullNumber: string;
}

// function hasInvalidCharacters(fullNumber: string) {
//   // var regExp1 = /([a-zA-Z!@#$%^&*)(+=._-]+$)/;
//   var regExp1 = /[a-zA-Z`!@#$%^&*()_\-=\[\]{};':"\\|,.<>\/?~]/;
//   return regExp1.test(fullNumber);
// }

function removeSpecialCharacters(input: string) {
  let withCharsRemoved = input.trim().split(" ").join("");
  withCharsRemoved = withCharsRemoved.split("(").join("").split(")").join("");
  withCharsRemoved = withCharsRemoved.split(".").join("").split("-").join("");

  if (withCharsRemoved.startsWith("+")) {
    withCharsRemoved = "+" + withCharsRemoved.split("+").join("");
  } else {
    withCharsRemoved = withCharsRemoved.split("+").join("");
  }

  return withCharsRemoved;
}

function hasInvalidCharacters(phoneNumber: string) {
  let digits = phoneNumber;

  if (phoneNumber.startsWith("+")) {
    digits = digits.substring(1);
  }
  return !/^[0-9]*$/.test(digits);
}

function checkDoubleDialCodes(inputString: string, country: Country) {
  if (inputString.startsWith(country.dialCode)) {
    if (
      inputString
        .substring(country.dialCode.length)
        .startsWith(country.dialCode)
    ) {
      return inputString.substring(country.dialCode.length);
    }
  }
  return inputString;
}

export function validateAndGetPhoneNumber(
  inputString: string,
  country: Country,
  oldValue: string
): ValidatedPhoneResult {
  var regExp2 = /(^(\+)[0-9])/g;

  let temp = checkDoubleDialCodes(inputString, country);
  let value = removeSpecialCharacters(temp);
  let valueCountry = country;

  // to allow backspacing the whole string
  if (value === "") {
    return {
      country,
      number: "",
      fullNumber: "",
    };
  }

  if (hasInvalidCharacters(value)) {
    return {
      country,
      fullNumber:
        oldValue !== "" && !hasInvalidCharacters(oldValue)
          ? oldValue
          : country.dialCode,
      number: "",
    };
  }

  if (country.dialCode === value) {
    return {
      country: country,
      number: "",
      fullNumber: country.dialCode,
    };
  }

  if (
    value.startsWith("+") &&
    !value.startsWith(country.dialCode) &&
    !hasInvalidCharacters(value)
  ) {
    if (value in dialCodeToCountryCodeMap) {
      //   if the whole number is just a dial code
      valueCountry = dialCodeToCountryCodeMap[inputString];
      return {
        country: valueCountry,
        number: "",
        fullNumber: valueCountry.dialCode,
      };
    } else {
      const maxCountryCodeLength = 5;
      let codeSize = maxCountryCodeLength;

      if (inputString.length < codeSize) codeSize = inputString.length;

      while (codeSize > 1) {
        if (dialCodeToCountryCodeMap[inputString.substring(0, codeSize)]) {
          const number = oldValue.substring(country.dialCode.length);
          value = inputString.substring(0, codeSize) + number;

          valueCountry =
            dialCodeToCountryCodeMap[inputString.substring(0, codeSize)];

          return {
            country: valueCountry,
            fullNumber: value,
            number,
          };
        }
        codeSize--;
      }

      if (codeSize === 1) {
        return {
          country,
          fullNumber: value,
          number: "",
        };
      }
    }
  }
  if (
    (regExp2.test(inputString) || inputString === "+" || inputString === "") &&
    !hasInvalidCharacters(inputString)
  ) {
    value = checkValueForStartingZeros(inputString, country);
  } else {
    // setValue((_) => checkValueForStartingZeros(inputString));
    // debugger;
  }

  return {
    number: oldValue.substring(country.dialCode.length),
    fullNumber: checkValueForStartingZeros(value, valueCountry),
    country: valueCountry,
  };
}

function checkValueForStartingZeros(
  inputString: string,
  country: Country
): string {
  let withoutDialCode = inputString;

  if (inputString.startsWith(country.dialCode))
    withoutDialCode = inputString.substring(country.dialCode.length);
  else if (inputString.startsWith(country.dialCode.substring(1))) {
    withoutDialCode = inputString.substring(country.dialCode.length - 1);
  }

  while (withoutDialCode.startsWith("0")) {
    withoutDialCode = withoutDialCode.substring(1);
  }
  if (
    country.dialCode + withoutDialCode !== inputString &&
    inputString.startsWith("00")
  ) {
    return checkValueForStartingZeros(withoutDialCode, country);
  }
  return country.dialCode + withoutDialCode;
}
