import { setHasApiError } from "../../store/reducers/paymentSlice";
import store from "../../store/store";
import executeScrollOn from "./executeScrollOn";

export const toastHandler = async (
  responseObj: any,
  dispatch: any,
  setToastMessage: any,
  customMsg?: string
) => {
  if (responseObj) {
    let text: any, type: any;
    if (responseObj.success && customMsg) {
      text = customMsg;
      type = "SUCCESS";
    } else if (responseObj.success) {
      return;
    } else if (!responseObj.success) {
      text = generateErrorMessage(responseObj);
      type = "ERROR";
    }

    dispatch(setToastMessage({ text, type }));
  }
};

export const generateErrorMessage = (responseObj: any) => {
  let errorMessage;

  //* Scrolling to form fields on API Errors - ( //TODO: need to optimize using an API Error Map )
  if (responseObj?.errors) {
    if (responseObj?.errors?.email) {
      store.dispatch(setHasApiError({ email: true }));
      // executeScrollOnProfileError();
      executeScrollOn("profileDetailsFormRef001");
    }
  }

  try {
    if (responseObj.message && typeof responseObj.message === "string") {
      return (errorMessage = makeFirstLetterCapital(responseObj.message));
    }
    if (typeof responseObj.errors === "object") {
      if (typeof responseObj.errors[0] === "string") {
        return (errorMessage = makeFirstLetterCapital(
          responseObj.errors.toString().replaceAll('"', "")
        ));
      }
      if (typeof Object.values(responseObj.errors) === "object") {
        return (errorMessage = makeFirstLetterCapital(
          Object.values(responseObj.errors).toString().replaceAll('"', "")
        ));
      }
    }
    if (responseObj.errors && typeof responseObj.errors === "string") {
      return (errorMessage = makeFirstLetterCapital(responseObj.errors));
    }
    if (responseObj.messages) {
      return (errorMessage = makeFirstLetterCapital(responseObj.messages[0]));
    } else {
      return (errorMessage = "Something went wrong");
    }
  } catch (error: any) {
    return (errorMessage = "Something went wrong");
  }
};

export const makeFirstLetterCapital = (sentence: string) => {
  let sentenceLowercase, formatedSentence;
  sentenceLowercase = sentence.toLowerCase();

  if (sentenceLowercase.length > 0) {
    const sentenceWithoutFirstLetter = sentenceLowercase.substring(1);
    const firstLetterOfSentence = sentenceLowercase[0].toUpperCase();
    formatedSentence = firstLetterOfSentence + sentenceWithoutFirstLetter;
    return formatedSentence;
  }
};
