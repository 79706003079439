import React from "react";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { editSVG } from "../../assets/js/allCustomSVGs";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

type Props = {
  onClick: () => void;
  rightAlign?: boolean;
};
const EditButtonLight: React.FC<Props> = ({ onClick, rightAlign }) => {
  const dispatch = useDispatch();
  return (
    <div
      data-is-edit-btn-right-aligned={rightAlign?.toString()}
      className="editButton-component"
    >
      <button
        onClick={() => {
          dispatch(setInteractionsPerSessionCount());
          onClick();
        }}
      >
        <div className="editButton-component-iconHolder">
          <CustomSVGs svg={editSVG} />
        </div>
      </button>
    </div>
  );
};

export default EditButtonLight;
