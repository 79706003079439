const version = "v1";

let basePath = ""; // base URL of the APIs
let authUrl = ""; // redirect URL of the Shopper FrontEnd
let env: "DEV" | "TEST" | "STAGING" | "LIVE";

if (
  window.location.href.split("/")[2].includes("localhost") ||
  process.env.NODE_ENV === "development" ||
  window.location.href.split("/")[2].includes("dev")
) {
  basePath = "https://wl-shopper-dev.adup.io/api";
  env = "DEV";
  authUrl = "https://sso-auth-dev.adup.io";
  // authUrl = "https://development.d8bwa6grglh9n.amplifyapp.com";
} else if (window.location.href.split("/")[2].includes("test")) {
  basePath = "https://wl-shopper-test.adup.io/api";
  env = "TEST";
  authUrl = "https://sso-auth-test.adup.io";
} else if (window.location.href.split("/")[2].includes("staging")) {
  basePath = "https://wl-shopper-staging.adup.io/api";
  env = "STAGING";
  authUrl = "https://sso-auth-staging.adup.io";
} else {
  basePath = "https://wl-shopper-main.adup.io/api";
  env = "LIVE";
  authUrl = "https://sso-auth.adup.io";
}

export const API_BASE = basePath;
export const APP_ENV = env;
export const AUTH_SSO_URL = authUrl;

export const SHOPPER_API_URL = `${API_BASE}/${version}/`;

//* app-initialization
export const INIT_URL = SHOPPER_API_URL + "init";

//* shopper
export const SHOPPER_URL = SHOPPER_API_URL + "shopper";
export const OTP_URL = SHOPPER_API_URL + "shopper/otp";
export const SHOPPER_ADDRESS_URL = SHOPPER_API_URL + "shopper/address";
export const SHIPPING_CAL_URL = SHOPPER_API_URL + "shipping";
export const SHOPPER_LOGOUT_URL = SHOPPER_API_URL + "shopper/logout";

//* products
export const PRODUCTS_URL = SHOPPER_API_URL + "products";

//* orders
export const ORDERS_URL = SHOPPER_API_URL + "shopper/orders";

//* log
export const LOG_URL = SHOPPER_API_URL + "log";

//* analytics
//TODO- Change Later to proper API 👇🏼
export const ANALYTICS_URL = "https://wl-seller-main.adup.io/api/v1/analytics";
export const ANALYTICS_EXECUTION_INTERVAL = 10000; //* 10 seconds

//* payments
export const PAYMENT_SESSION_URL = SHOPPER_API_URL + "payment/session";
export const PAYMENT_STATUS_URL = SHOPPER_API_URL + "payment/status";
export const PAYMENT_PROCESS_URL = SHOPPER_API_URL + "payment/process";
export const CREATE_ADUP_ACCOUNT_URL = SHOPPER_API_URL + "payment/account";
export const PAYMENT_ATTRIBUTES_DELETE_URL =
  SHOPPER_API_URL + "deletePaymentMethod";
export const WALLET_SHOPPER_CREATION_URL =
  SHOPPER_API_URL + "payment/collection";

//* webauthn
export const WEBAUTHN_URL = SHOPPER_API_URL + "web_authn";

//* events
export const EVENT_URL = SHOPPER_API_URL + "addshopperevent";

//! API keys need to be move to another file !
export const GOOGLE_MAPS_API_KEY = "AIzaSyDNEHfzNraau785iMc6kyFLzsg0HL5RnhE";
export const GROWTHBOOK_API_HOST = "https://cdn.growthbook.io";
export const GROWTHBOOK_CLIENT_KEY = "sdk-HHjExCOn4qRgKeq";
