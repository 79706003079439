import { ORDERS_URL } from "../constants/urls";
import store from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";

export async function getAllOrders(
  currentPageNumber: number,
  itemsPerPage: number
) {
  const key = store.getState().users.currentUser.token;
  const response = await fetch(
    `${ORDERS_URL}?page=${currentPageNumber}&perpage=${itemsPerPage}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: key ? `Bearer ${key}` : "",
      },
    }
  );

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res?.success) {
    /* toastHandler(res, store.dispatch, setToastMessage); */
    const { result } = res;
    return result?.orders;
  } else {
    return null;
  }
}

/**
 * @param { string } orderId order id
 */
export async function getSingleOrders(orderId: string) {
  const key = store.getState().users.currentUser.token;
  const response = await fetch(`${ORDERS_URL}/${orderId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: key ? `Bearer ${key}` : "",
    },
  });

  // trigger API Errors as Toasts
  const res = await response.json();
  if (res) {
    toastHandler(res, store.dispatch, setToastMessage);
  }

  const { result } = res;
  return result;
}
