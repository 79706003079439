import { useSelector } from "react-redux";
import {
  arrowHeadLeftSVG,
  cartSVG,
  cartSVG_XXL,
} from "../../assets/js/allCustomSVGs";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { RootState } from "../../store/store";
import getSymbolFromCurrency from "currency-symbol-map";
import FastCheckoutPageCartSegment from "../../pages/fast-checkout/segments/FastCheckoutPageCart";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const CompactCart = () => {
  const route = window.decodeURI(useLocation().pathname);
  const app_mode = route.split("/")[1];
  const shop_slug = route.split("/")[2];
  const [isCartOpen, setIsCartOpen] = useState(false);
  const cartData = useSelector((state: RootState) => state.cart);

  let totalItems = 0;
  if (cartData.items.length > 0) {
    cartData.items.forEach((item) => {
      totalItems += item.amount;
    });
  }
  const cartItemCount = cartData.items.length;
  const cartTotal = cartData.total;
  const currency = getSymbolFromCurrency(cartData.shop?.currency);

  const isXXL =
    window.location.origin === "https://xxlnutrition.secure-checkout.eu"
      ? true
      : false;

  useEffect(() => {
    if (cartItemCount <= 0) {
      setIsCartOpen(false);
    }
  }, [cartItemCount]);

  return (
    <div className="compactCart">
      <div className="compactCart__count">
        <span>{totalItems}</span>
      </div>
      {/*------------ hidden block start ------------*/}
      <div className="compactCart__itemsHidden">
        <div className="compactCart__itemsHidden--cart">
          <CustomSVGs svg={isXXL ? cartSVG_XXL : cartSVG} />
        </div>
        <div className="compactCart__itemsHidden--amount">
          <h2>{`${cartTotal?.toFixed(2)}${currency}`}</h2>
        </div>
        <div className="compactCart__itemsHidden--arrow">
          <CustomSVGs svg={arrowHeadLeftSVG} />
        </div>
      </div>
      {/*------------ hidden block end --------------*/}
      <div className="compactCart__items">
        <div className="compactCart__items--cart">
          <CustomSVGs svg={isXXL ? cartSVG_XXL : cartSVG} />
        </div>
        <div className="compactCart__items--amount">
          <h2>{`${cartTotal?.toFixed(2)}${currency}`}</h2>
        </div>
        {cartItemCount > 0 && (
          <div
            onClick={() => {
              if (cartItemCount) setIsCartOpen(!isCartOpen);
            }}
            className="compactCart__items--arrow"
          >
            <CustomSVGs svg={arrowHeadLeftSVG} />
          </div>
        )}
      </div>

      {isCartOpen && (
        <>
          <div className="compactCart__dropdown">
            {/* {app_mode === "fastcheckout" &&
              shop_slug !== cartData?.shop?.slug && (
                <FastCheckoutPageCartSegment isCompactCart={true} />
              )} */}
            <FastCheckoutPageCartSegment isCompactCart={true} />
          </div>
          <div
            onClick={() => {
              setIsCartOpen(!isCartOpen);
            }}
            className="compactCart__overlay"
          ></div>
        </>
      )}
    </div>
  );
};

export default CompactCart;
