import { AdupPaymentMethodSelection } from "../../interfaces/AdupPaymentMethod";
import store from "../../store/store";

export default function getAllPaymentMethodsAsArray(): AdupPaymentMethodSelection[] {
  const allPaymentMethods = store.getState().payment.allPaymentMethods;

  return Object.keys(allPaymentMethods).map((key) => allPaymentMethods[key]);
}

export function getFilteredPaymentMethodsAsArray(methods: {
  [index: string]: AdupPaymentMethodSelection;
}): AdupPaymentMethodSelection[] {
  return Object.keys(methods).map((key) => methods[key]);
}
