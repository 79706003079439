import { setPaymentMethodLastUsed } from "../../store/reducers/paymentSlice";
import {
  addNewUserAddress,
  setBillingAddress,
  setBillingIsShipping,
  setPhoneId,
  setShippingAddress,
  setUserPhone,
  setUserProfile,
  setUserToken,
  setUserWebAuthn,
} from "../../store/reducers/userSlice";
import store from "../../store/store";
import { dialCodeToCountryMap } from "../constants/default-countries";
import { setAppLanguage } from "../../store/reducers/appSlice";

export default function updateStateFromShopperObject(shopper: any) {
  if (!shopper) return;

  //TODO : remove Old code later
  // const firstPhoneId: string = Object.keys(shopper.phones[0])[0];
  // const data = shopper.phones[0][firstPhoneId];
  // const { phone, addresses, payment_methods, profiles } = data;
  // const profileIdx = 0; // profiles are sent as an array, we currently only support one profile

  let billingLastUsed;
  let shippingLastUsed;
  let paymentMethodLastUsed;

  const {
    id,
    first_name,
    last_name,
    email,
    phone,
    country_code,
    addresses,
    billing_last_used,
    shipping_last_used,
    payment_method_last_used,
    webauthn,
    language,
    bearer_token,
  } = shopper;

  if (bearer_token) {
    store.dispatch(setUserToken(bearer_token));
  }

  if (language) {
    store.dispatch(setAppLanguage(language));
  }

  store.dispatch(
    setUserProfile({
      id,
      name: { first: first_name, second: last_name },
      email,
      meta: {},
    })
  );

  if (phone && country_code) {
    store.dispatch(
      setUserPhone({
        country: dialCodeToCountryMap["+" + country_code],
        number: phone,
      })
    );
    store.dispatch(setPhoneId(id));
  }

  if (addresses?.length > 0) {
    for (const address of addresses) {
      const currentAddress = address[Object.keys(address)[0]];
      const {
        city,
        country,
        id,
        number,
        postcode,
        province,
        street,
        street2,
        shipping_rate,
      } = currentAddress;

      store.dispatch(
        addNewUserAddress({
          city,
          country,
          id,
          number,
          postcode,
          province,
          street,
          street2,
          shipping_rate,
        })
      );

      if (id === billing_last_used) {
        billingLastUsed = currentAddress;
      }
      if (id === shipping_last_used) {
        shippingLastUsed = currentAddress;
      }
    }

    // const lastUsedAddress = (addresses as any[]).sort((prev, next) => {
    //   if (prev.last_used > next.last_used) return 1;
    //   else if (prev.last_used < next.last_used) return -1;
    //   return 0;
    // })[0];

    if (shipping_last_used === billing_last_used) {
      store.dispatch(setBillingIsShipping(true));
    } else {
      store.dispatch(setBillingIsShipping(false));
    }
    if (shippingLastUsed) {
      store.dispatch(setShippingAddress(shippingLastUsed));
    }
    if (billingLastUsed) {
      store.dispatch(setBillingAddress(billingLastUsed));
    }
  }
  store.dispatch(setUserWebAuthn(Boolean(webauthn)));
  store.dispatch(setPaymentMethodLastUsed(payment_method_last_used));
}
