import { ChangeEvent } from "react";
import { cssRedBorderClass } from "../constants";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

type AddressFieldProps = {
  id: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeHolder: string;
  required: boolean;
};

const AddressField: React.FC<AddressFieldProps> = ({
  id,
  onChange,
  value,
  placeHolder,
  required,
}) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`manual-address-field ${
        (!value || value === "") && required ? cssRedBorderClass : ""
      }`}
    >
      <input
        onFocus={() => {
          dispatch(setInteractionsPerSessionCount());
        }}
        onChange={(e) => onChange(e)}
        value={value}
        id={id}
        style={{ fontFamily: "var(--main-font-secondary)" }}
      ></input>
      <label className="manual-address-field-label" htmlFor={id}>
        {placeHolder}
      </label>
    </div>
  );
};

export default AddressField;
