import { SelectedPaymentMethodSavedStatus } from "../../../../store/reducers/paymentSlice";
import store from "../../../../store/store";
import { handlePaymentRequest } from "../../../api/payment-post-request";

export async function giftcardPayFunction() {
  const paymentState = store.getState().payment;

  const selectedPaymentMethod =
    paymentState.selectedPaymentMethodSaveState ===
    SelectedPaymentMethodSavedStatus.SAVED_METHOD
      ? paymentState.savedPaymentMethodSelected
      : paymentState.unsavedPaymentMethodSelected;

  if (
    (selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "mollie" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "magentomollie") ||
    selectedPaymentMethod.type !== "giftcard"
  ) {
    return;
  }
  handlePaymentRequest();
}
