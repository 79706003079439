import { AdupPaymentMethodSelection } from "../../../../interfaces/AdupPaymentMethod";
import { getPaymentIntentForProcessing } from "../../../api/get-payment-intent";
import serviceProviderHandlers, {
  ServiceProviderHandlerProps,
} from "../../service-providers/selection";
/**
 * Handles a new payment method being selected.
 * @param { AdupPaymentMethodSelection } selection
 */
export async function handleNewPaymentMethodSelection(
  selection: AdupPaymentMethodSelection
) {
  const { serviceProvider, processor } = selection;

  const serviceProviderHandler: (params: ServiceProviderHandlerProps) => void =
    serviceProviderHandlers[serviceProvider.toLocaleLowerCase()];

  if (processor === "frontend") {
    const getPaymentIntentResponseData = await getPaymentIntentForProcessing();

    if (getPaymentIntentResponseData?.action) {
      const {
        id,
        psp,
        psp_method,
        type,
        public_key,
        client_secret,
        return_url,
        currency,
        amount,
      } = getPaymentIntentResponseData;

      serviceProviderHandler({
        amount,
        clientSecret: client_secret,
        currency,
        id,
        pspMethod: psp_method,
        serviceProvider: psp,
        publicKey: public_key,
        returnURL: return_url,
        type,
      });
    }
  } else {
  }
}
