import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Theme1OrderSummary from "../../themes/theme1/Theme1OrderSummary";
import FastCheckoutOrderSummarySegment from "../../pages/fast-checkout/segments/FastCheckoutOrderSummarySegment";
import OrderSummaryTheme2 from "../../themes/theme2/OrderSummaryTheme2";

interface Props {
  app_mode: any;
  isCompact?: boolean;
}

const SummaryThemeWise = ({ app_mode, isCompact }: Props) => {
  const order_summary_theme: any =
    useSelector(
      (state: RootState) => state.cart?.shop?.theme?.order_summary_theme
    ) ?? "default";
  const app_layout: any =
    useSelector((state: RootState) => state.cart?.shop?.theme?.app_layout) ??
    "1";

  const renderProductDetailsThemeWise = () => {
    if (order_summary_theme === "theme1") {
      return <Theme1OrderSummary app_mode={app_mode} />;
    } else if (app_layout === "2" || order_summary_theme === "theme2") {
      return <OrderSummaryTheme2 app_mode={app_mode} />;
    } else {
      return (
        <FastCheckoutOrderSummarySegment
          app_mode={app_mode}
          isCompact={isCompact}
        />
      );
    }
  };

  return renderProductDetailsThemeWise();
};

export default SummaryThemeWise;
