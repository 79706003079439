import "../../sass/components/orderSummaryCompact.scss";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RoundButton from "../round-button/RoundButton";
import { arrowHeadDownSVG } from "../../assets/js/allCustomSVGs";

interface Props {
  grandTotal: string;
}

const OrderSummaryCompact: React.FC<Props> = ({ grandTotal, children }) => {
  const { t } = useTranslation();
  const [isSummaryOpen, setIsSummaryOpen] = useState(false);

  return (
    <div style={{ marginBottom: "1.5rem" }}>
      {!isSummaryOpen && (
        <div className="orderCartSummaryCompact">
          <div className="orderCartSummaryCompact__left">
            <h5>{`${t("GrandTotal")} :`} </h5>
            <h5>{grandTotal}</h5>
          </div>
          <div className="orderCartSummaryCompact__right">
            <RoundButton
              color={"light"}
              icon={arrowHeadDownSVG}
              onClick={() => setIsSummaryOpen(!isSummaryOpen)}
              rotate={isSummaryOpen}
              classString="round-button-with-arrow"
            />
          </div>
        </div>
      )}
      {isSummaryOpen && children}
    </div>
  );
};

export default OrderSummaryCompact;
