import { AnimatePresence } from "framer-motion";
import React from "react";

const FastCheckoutPopupWrapperSegment: React.FC = ({ children }) => {
  return (
    <React.Fragment>
      <AnimatePresence>{children}</AnimatePresence>
    </React.Fragment>
  );
};

export default FastCheckoutPopupWrapperSegment;
