import "../../sass/components/imageInput.scss";
import { useEffect } from "react";
import CustomSVGs from "../custom-svgs/CustomSVGs";
import { uploadSVG } from "../../assets/js/allCustomSVGs";

interface Props {
  mainText: string;
  subText: string;
  acceptedFileTypes: string;
  selectedFile: any;
  setSelectedFile: (para: any) => void;
  preview: any;
  setPreview: (para: any) => void;
  fileError: any;
  setFileError: (para: any) => void;
  id: string;
  manyImages: boolean;
  removeImage?: () => void;
  isFileUpload?: boolean;
  isVideoAllowed?: boolean;
}

const ImageInput = ({
  mainText,
  subText,
  acceptedFileTypes,
  selectedFile,
  setSelectedFile,
  preview,
  setPreview,
  fileError,
  setFileError,
  id,
  manyImages,
  removeImage,
  isFileUpload,
  isVideoAllowed,
}: Props) => {
  /* const [preview, setPreview] = useState<string>();
  const [fileError, setFileError] = useState(false); */

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    if (isFileUpload) {
      // setPreview(csvFileIcon);
      // // free memory when ever this component is unmounted
      // return () => URL.revokeObjectURL(csvFileIcon);
    } else {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);
      // free memory when ever this component is unmounted
      // return () => URL.revokeObjectURL(objectUrl);
    }
  }, [selectedFile]);

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    // Using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    setFileError(false);
  };

  return (
    <div className="imageInput-outer">
      <div className="imageInput-outer--label">{mainText}</div>
      <div className="imageInput">
        <div
          title={
            isFileUpload
              ? "Click to upload a file."
              : isVideoAllowed
              ? "Click to upload image / video"
              : "Click to upload an image."
          }
          className="imageInput__top"
        >
          <div>
            <label htmlFor={id}>
              {preview &&
              isVideoAllowed &&
              ((selectedFile && selectedFile?.type?.includes("video")) ||
                (!selectedFile && preview?.includes(".mp4"))) ? (
                <video autoPlay muted loop src={preview} />
              ) : preview ? (
                <img src={preview} alt={id} />
              ) : (
                <div>
                  <CustomSVGs svg={uploadSVG} />
                </div>
              )}
            </label>
            <input
              id={id}
              type="file"
              name="logo"
              style={{ fontFamily: "var(--main-font-secondary)" }}
              multiple={manyImages}
              onChange={onSelectFile}
              accept={acceptedFileTypes}
            />
          </div>
        </div>
        <div className="imageInput__bottom">
          {selectedFile?.name && isFileUpload && (
            <span>{selectedFile?.name}</span>
          )}
          {!(selectedFile?.name && isFileUpload) && (
            <>
              <p data-image-error={fileError.toString()}>
                {isFileUpload
                  ? "Select File"
                  : manyImages
                  ? "Select Images"
                  : isVideoAllowed
                  ? "Select Image / Video"
                  : "Select Image"}
              </p>
              <span>{subText}</span>
            </>
          )}
          {preview && (
            <a onClick={removeImage}>
              {isFileUpload
                ? "Remove File"
                : isVideoAllowed
                ? "Remove Image / Video"
                : "Remove Image"}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageInput;
