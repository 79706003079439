/**
 *
 * @param {Object} obj
 * @return { Object }
 */
export function applicationJSONBodyBuilder(obj: object) {
  return JSON.stringify(obj);
}

/**
 *
 * @param {Object} obj
 * @return { Object }
 */
export function applicationWWWXRLEncodedBodyBuilder(obj: {
  [key: string]: any;
}) {
  const formBody = [];
  // eslint-disable-next-line guard-for-in
  for (const property in obj) {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(obj[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }

  const result = formBody.join("&");

  return result;
}
