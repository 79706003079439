import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AdupPaymentMethodSelection,
  AdupSavedPaymentDetails,
} from "../../interfaces/AdupPaymentMethod";
import { StripeSelectionBank } from "../../interfaces/StripeBank";
import { handlePaymentRequest } from "../../lib/api/payment-post-request";

// need this here to prevent errors in typing
export const initialPaymentSelected: AdupPaymentMethodSelection = {
  displayTitle: "",
  iconURL: "",
  name: "",
  serviceProvider: "",
  type: "",
  processor: "frontend",
};

export const initialPaymentSelectedFromSavedMethods: AdupSavedPaymentDetails = {
  details: "",
  displayTitle: "",
  iconURL: "",
  id: "",
  label: { icon: "", text: "" },
  name: "",
  owner: "",
  paymentMethodId: "",
  serviceProvider: "",
  supportedCurrencies: [],
  type: "",
};

const initialPaymentMethodsList: { [index: string]: AdupSavedPaymentDetails } =
  {};

const initialAdupPaymentMethods: {
  [index: string]: AdupPaymentMethodSelection;
} = {};

const initialBankList: StripeSelectionBank[] = [];

// Payment progress state names
const PAYMENT_STARTED = "PAYMENT_STARTED";
const PAYMENT_NOT_STARTED = "PAYMENT_NOT_STARTED";
const PAYMENT_SUCCEEDED = "PAYMENT_SUCCEEDED";
const PAYMENT_FAILED = "PAYMENT_FAILED";

export enum SelectedPaymentMethodSavedStatus {
  SAVED_METHOD,
  UNSAVED_METHOD,
}

const initialPaymentMethodOptions: { [idx: string]: any } = {};

interface IInitialPaymentState {
  [key: string]: any;
}

const initialState: IInitialPaymentState = {
  paymentAmount: 0,
  paymentProgress: PAYMENT_NOT_STARTED,
  unsavedPaymentMethodSelected: initialPaymentSelected,
  savedPaymentMethodSelected: initialPaymentSelectedFromSavedMethods,
  selectedPaymentMethodSaveState:
    SelectedPaymentMethodSavedStatus.UNSAVED_METHOD,
  paymentMethodOptionSelected: "",
  savedPaymentMethods: initialPaymentMethodsList,
  availablePaymentMethods: null,
  allPaymentMethods: initialAdupPaymentMethods,
  paymentIntent: {
    id: "",
  },
  cartSessionId: "",
  stripe: {
    clientSecret: "",
    intentType: "",
    publicKey: "",
    returnURL: "",
  },
  savedBanks: {
    selectedBankIndex: -1,
    currentMethodUsesBank: false,
    list: initialBankList,
    availableBanks: initialBankList,
  },
  discount: {},
  encryptedBuckarooCardData: { value: "", issuer: "" },
  encryptedMollieCardToken: "",
  isWalletPaymentsSupportedByDevice: false,
  isWalletPaymentMethodInUse: false,
  isPaymentMethodEditable: false,
  isPaymentMethodsLoading: false,
  walletPaymentMethodTypeInUse: "",
  paymentMethodLastUsed: "",
  ibanNumber: "",
  bicNumber: "",
  errorOccurred: false,
  isInitApiFetched: false,
  isRequiredFieldsNotFilled: false,
  hasApiError: null,
  savedPaymentAttributes: null,
  lastSessionCallPayload: undefined,
  createAccountFlag: false,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPaymentSuccess: (state, action) => {
      state.paymentProgress = action.payload.success
        ? PAYMENT_SUCCEEDED
        : PAYMENT_FAILED;
    },
    setPaymentProgress: (state, action: PayloadAction<string>) => {
      state.paymentProgress = action.payload;
    },
    makePayment: (state, action) => {
      state.paymentProgress = PAYMENT_STARTED;
      state.paymentAmount = action.payload.total;
      handlePaymentRequest();
    },
    newSavedPaymentMethod: (
      state,
      action: PayloadAction<AdupSavedPaymentDetails>
    ) => {
      state.savedPaymentMethods[action.payload.id] = action.payload;
    },
    newAdupPaymentMethod: (
      state,
      action: PayloadAction<AdupPaymentMethodSelection>
    ) => {
      state.allPaymentMethods[action.payload.name] = action.payload;
    },
    setStripeClientSecret: (state, action: PayloadAction<string>) => {
      state.stripe.clientSecret = action.payload;
    },
    setStripeIntentType: (state, action: PayloadAction<string>) => {
      state.stripe.intentType = action.payload;
    },
    setUnsavedSelectedPaymentMethod: (
      state,
      action: PayloadAction<AdupPaymentMethodSelection>
    ) => {
      state.unsavedPaymentMethodSelected = action.payload;
      state.selectedPaymentMethodSaveState =
        SelectedPaymentMethodSavedStatus.UNSAVED_METHOD;
    },
    clearUnsavedSelectedPaymentMethod: (state, _) => {
      state.unsavedPaymentMethodSelected = initialPaymentSelected;
      state.selectedPaymentMethodSaveState =
        SelectedPaymentMethodSavedStatus.UNSAVED_METHOD;
    },
    setSavedSelectedPaymentMethod: (
      state,
      action: PayloadAction<AdupSavedPaymentDetails>
    ) => {
      state.selectedPaymentMethodSaveState =
        SelectedPaymentMethodSavedStatus.SAVED_METHOD;
      state.savedPaymentMethodSelected = action.payload;
    },
    setPublicKeyStripe: (state, action: PayloadAction<string>) => {
      state.stripe.publicKey = action.payload;
    },
    setStripeReturnURL: (state, action: PayloadAction<string>) => {
      state.stripe.returnURL = action.payload;
    },
    setCartSessionId: (state, action: PayloadAction<string>) => {
      state.cartSessionId = action.payload;
    },
    clearAdupPaymentMethods: (state, action) => {
      state.allPaymentMethods = {};
      state.savedBanks.availableBanks = [];
      state.savedBanks.currentMethodUsesBank = false;
      state.savedBanks.list = [];
      state.savedBanks.selectedBankIndex = -1;
    },
    clearSavedPaymentMethods: (state, action) => {
      state.savedPaymentMethods = {};
    },
    setAvailablePaymentMethods: (state, action: any) => {
      state.availablePaymentMethods = action.payload;
    },
    setAvailableBanks: (
      state,
      action: PayloadAction<StripeSelectionBank[]>
    ) => {
      state.savedBanks.currentMethodUsesBank = true;
      state.savedBanks.availableBanks = action.payload;
    },
    setSelectedBankIndex: (state, action: PayloadAction<number>) => {
      if (state.savedBanks.availableBanks[action.payload]) {
        state.savedBanks.selectedBankIndex = action.payload;
      }
    },
    setPaymentIntent: (state, action: PayloadAction<string>) => {
      state.paymentIntent.id = action.payload;
    },
    setSelectedPaymentSavedStatus: (
      state,
      action: PayloadAction<SelectedPaymentMethodSavedStatus>
    ) => {
      state.selectedPaymentMethodSaveState = action.payload;
    },
    clearSelectedPaymentMethodOptions: (state, _) => {
      state.paymentMethodOptionSelected = "";
    },
    setSelectedPaymentMethodOption: (
      state,
      action: PayloadAction<{ key: string; optionData: any }>
    ) => {
      state.paymentMethodOptionSelected = action.payload.optionData;
      // if (config && config.options.length !== 0) {
      //   const selectedOptionGroup: AdupPaymentMethodOptionGroup = config.options[0];
      //   state.paymentMethodOptionSelected[action.payload.key] =
      //   selectedOptionGroup.options.find(option => option.value);
      // }
    },
    clearPaymentState: (state, _) => {
      for (const key in initialState) {
        if (state[key] && key !== "allPaymentMethods") {
          state[key] = initialState[key];
        }
      }
    },
    setEncryptedBuckarooCardData: (
      state,
      action: PayloadAction<{ value: string; issuer: string }>
    ) => {
      state.encryptedBuckarooCardData = action.payload;
    },
    setEncryptedMollieCardToken: (state, action: PayloadAction<string>) => {
      state.encryptedMollieCardToken = action.payload;
    },
    setIsWalletPaymentsSupportedByDevice: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isWalletPaymentsSupportedByDevice = action.payload;
    },
    setIsWalletPaymentMethodInUse: (state, action: PayloadAction<boolean>) => {
      state.isWalletPaymentMethodInUse = action.payload;
    },
    setIsPaymentMethodEditable: (state, action: PayloadAction<boolean>) => {
      state.isPaymentMethodEditable = action.payload;
    },
    setIsPaymentMethodsLoading: (state, action: PayloadAction<boolean>) => {
      state.isPaymentMethodsLoading = action.payload;
    },
    setWalletPaymentMethodTypeInUse: (state, action: PayloadAction<any>) => {
      state.walletPaymentMethodTypeInUse = action.payload;
    },
    setPaymentMethodLastUsed: (state, action: PayloadAction<any>) => {
      state.paymentMethodLastUsed = action.payload;
    },
    setIbanNumber: (state, action: PayloadAction<string>) => {
      state.ibanNumber = action.payload;
    },
    setBicNumber: (state, action: PayloadAction<string>) => {
      state.bicNumber = action.payload;
    },
    setPaymentErrorOccurred: (state, action: PayloadAction<boolean>) => {
      state.errorOccurred = action.payload;
    },
    setIsInitApiFetched: (state, action: PayloadAction<boolean>) => {
      state.isInitApiFetched = action.payload;
    },
    setIsRequiredFieldsNotFilled: (state, action: PayloadAction<boolean>) => {
      state.isRequiredFieldsNotFilled = action.payload;
    },
    setHasApiError: (state, action: PayloadAction<any>) => {
      state.hasApiError = action.payload;
    },
    setSavedPaymentAttributes: (state, action: PayloadAction<any>) => {
      state.savedPaymentAttributes = action.payload;
    },
    deleteSavedPaymentAttributes: (state, action) => {
      delete state.savedPaymentAttributes[action.payload];
      console.log("A Payment attribute was deleted : ", action.payload);
    },
    setLastSessionCallPayload: (state, action: PayloadAction<any>) => {
      state.lastSessionCallPayload = action.payload;
    },
    setCreateAccountFlag: (state, action: PayloadAction<boolean>) => {
      state.createAccountFlag = action.payload;
    },
  },
});

export const {
  makePayment,
  newSavedPaymentMethod,
  setPaymentProgress,
  setPaymentSuccess,
  newAdupPaymentMethod,
  setStripeClientSecret,
  setStripeIntentType,
  setSavedSelectedPaymentMethod,
  setUnsavedSelectedPaymentMethod,
  clearUnsavedSelectedPaymentMethod,
  setPublicKeyStripe,
  setStripeReturnURL,
  setCartSessionId,
  clearAdupPaymentMethods,
  clearSavedPaymentMethods,
  setAvailablePaymentMethods,
  setAvailableBanks,
  setSelectedBankIndex,
  setPaymentIntent,
  setSelectedPaymentSavedStatus,
  clearSelectedPaymentMethodOptions,
  setSelectedPaymentMethodOption,
  clearPaymentState,
  setEncryptedBuckarooCardData,
  setEncryptedMollieCardToken,
  setIsWalletPaymentsSupportedByDevice,
  setIsWalletPaymentMethodInUse,
  setIsPaymentMethodEditable,
  setIsPaymentMethodsLoading,
  setWalletPaymentMethodTypeInUse,
  setPaymentMethodLastUsed,
  setIbanNumber,
  setBicNumber,
  setPaymentErrorOccurred,
  setIsInitApiFetched,
  setIsRequiredFieldsNotFilled,
  setHasApiError,
  setSavedPaymentAttributes,
  deleteSavedPaymentAttributes,
  setLastSessionCallPayload,
  setCreateAccountFlag,
} = paymentSlice.actions;

export default paymentSlice.reducer;
