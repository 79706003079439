import React, { useEffect, useState } from "react";
import OverviewPageDesktopLayout1 from "./desktop/OverviewPageDesktopLayout1";
import OverviewPageMobileLayout1 from "./mobile/OverviewPageMobileLayout1";

const OverviewPageLayout1 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth > 750 ? (
        <OverviewPageDesktopLayout1 />
      ) : (
        <OverviewPageMobileLayout1 />
      )}
    </>
  );
};

export default OverviewPageLayout1;
