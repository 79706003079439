import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setToastMessage } from "../../store/reducers/appSlice";
import {
  clearUnsavedSelectedPaymentMethod,
  setIsPaymentMethodEditable,
  setIsPaymentMethodsLoading,
  setIsWalletPaymentMethodInUse,
  setIsWalletPaymentsSupportedByDevice,
} from "../../store/reducers/paymentSlice";
import store, { RootState } from "../../store/store";
import "./payment-request-components.scss";
import { submitLog } from "../../lib/api/log";
import { createShopperViaWalletData } from "../../lib/api/shopper-methods";
import { setShippingAddress } from "../../store/reducers/userSlice";

declare const window: any;

const MolliePaymentRequestAdupContainer = (
  molliePaymentRequestAdupContainerProps: any
) => {
  const { t } = useTranslation();
  const accountPhone = store.getState().users.currentUser.accountPhone;
  const currency = useSelector((state: RootState) => state.cart.shop.currency);
  const grandTotal = useSelector((state: RootState) => state.cart.grandTotal);
  const shippingCost = useSelector((state: RootState) => state.cart.shipping);
  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );
  const isPaymentMethodEditable =
    store.getState().payment?.isPaymentMethodEditable;
  const [isApplePaySupported, setIsApplePaySupported] = useState(false);

  const [applePayRequest, setApplePayRequest] = useState({
    countryCode: "NL", // country //TODO hardcoded since LK is not supported
    currencyCode: currency.toLocaleUpperCase(),
    supportedNetworks: ["visa", "masterCard", "amex", "discover"], //TODO ??
    merchantCapabilities: ["supports3DS"], //TODO ??
    total: {
      label: `${merchantTheme?.merchant_name} Cart Total`,
      amount: grandTotal ?? 0.0,
    },
    shippingOptions: [
      {
        id: "shipping",
        label: "Delivery",
        detail: "Your order will be delivered to your Shipping address",
        amount: shippingCost ?? 0.0,
      },
    ],
    requestPayerName: true,
    requestPayerEmail: true,
    requestPayerPhone: true,
    requestShipping: true,
  });
  const dispatch = useDispatch();

  // Get an instance of `PhoneNumberUtil`.
  const phoneUtil: any =
    require("google-libphonenumber").PhoneNumberUtil.getInstance();

  const otherPayments = () => {
    dispatch(clearUnsavedSelectedPaymentMethod(null));
    molliePaymentRequestAdupContainerProps.setPaymentMethodChanged(true);
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsWalletPaymentsSupportedByDevice(false));
  };

  const skipAvailableWalletPayments = () => {
    dispatch(clearUnsavedSelectedPaymentMethod(null));
    dispatch(setIsWalletPaymentsSupportedByDevice(true));
    molliePaymentRequestAdupContainerProps.setPaymentMethodChanged(true);
    dispatch(setIsWalletPaymentMethodInUse(false));
    dispatch(setIsPaymentMethodEditable(false));
  };

  useEffect(() => {
    dispatch(setIsPaymentMethodsLoading(true));
  }, []);

  useEffect(() => {
    if (isApplePaySupported) dispatch(setIsWalletPaymentMethodInUse(true));
  }, [isApplePaySupported]);

  useEffect(() => {
    setApplePayRequest({
      ...applePayRequest,
      currencyCode: currency.toLocaleUpperCase(),
      total: {
        label: `${merchantTheme?.merchant_name} Cart Total`,
        amount: grandTotal ?? 0.0,
      },
      shippingOptions: [
        {
          id: "shipping",
          label: "Delivery",
          detail: "Your order will be delivered to your Shipping address",
          amount: shippingCost ?? 0.0,
        },
      ],
    });
    if (window.ApplePaySession) {
      try {
        setIsApplePaySupported(window.ApplePaySession.canMakePayments());
        console.log(
          "isApplePaySupported",
          window.ApplePaySession.canMakePayments()
        );
        dispatch(setIsPaymentMethodsLoading(false));
        if (isPaymentMethodEditable) {
          skipAvailableWalletPayments();
        }
      } catch (error) {
        console.log("ApplePay Not Supported", error);
        dispatch(setIsPaymentMethodsLoading(false));
        otherPayments();
        setIsApplePaySupported(false);
        // dispatch(
        //   setToastMessage({
        //     text: `${error}`,
        //     type: "ERROR",
        //   })
        // );
      }
    } else {
      dispatch(setIsPaymentMethodsLoading(false));
      otherPayments();
      setIsApplePaySupported(false);
    }
  }, [currency, grandTotal, shippingCost]);

  const handleApplePayment = () => {
    try {
      var session = new window.ApplePaySession(3, applePayRequest);
      if (session) {
        session.onshippingcontactselected = (event: {
          shippingContact: any;
        }) => {
          let shippingContact = event.shippingContact;
          // Log the updated address
          if (shippingContact) {
            console.log(
              "Updated MOLLIE Wallet Shipping Address:",
              shippingContact
            );
          }
          // Update the shipping address in state based on the selected address : which will also trigger address change & shipping cost Calculation
          var selectedShippingAddress = {
            id: "",
            number: shippingContact?.houseNumber ?? "",
            street: shippingContact?.street ?? "",
            street2: "",
            city: shippingContact?.city ?? "",
            province: shippingContact?.state ?? "",
            postcode: shippingContact?.postalCode ?? "",
            country: shippingContact?.country ?? "",
          };
          dispatch(setShippingAddress(selectedShippingAddress));
        };
        session.onpaymentauthorized = async (event: { payment: any }) => {
          const { payment } = event;

          //* Fetch Payer data from Wallet Payment - START *//
          let countryCode = "0";
          let phoneNumber = "0";
          try {
            const number = phoneUtil.parseAndKeepRawInput(
              payment?.billingContact?.phoneNumber
                ? payment?.billingContact?.phoneNumber.startsWith("+")
                  ? payment?.billingContact?.phoneNumber
                  : `+${payment?.billingContact?.phoneNumber}`
                : "0"
            );
            countryCode = number.getCountryCode();
            phoneNumber = number.getNationalNumber();
          } catch (error) {
            console.log("Wallet data phone number invalid :", error);
          }
          const walletPayerInfo = {
            country_code: countryCode ?? "",
            phone_number: phoneNumber ?? "",
            first_name: payment?.billingContact?.givenName ?? "",
            last_name: payment?.billingContact?.familyName ?? "",
            shipping_address: {
              house_number:
                payment?.shippingContact?.postalAddress?.houseNumber,
              street: payment?.shippingContact?.postalAddress?.street,
              street2: "",
              city: payment?.shippingContact?.postalAddress?.city,
              province: payment?.shippingContact?.postalAddress?.state,
              postcode: payment?.shippingContact?.postalAddress?.postalCode,
              country: payment?.shippingContact?.postalAddress?.country,
            },
            billing_address: {
              house_number:
                payment?.billingContact?.postalAddress?.houseNumber !== ""
                  ? payment?.billingContact?.postalAddress?.houseNumber
                  : payment?.shippingContact?.postalAddress?.houseNumber,
              street:
                payment?.billingContact?.postalAddress?.street !== ""
                  ? payment?.billingContact?.postalAddress?.street
                  : payment?.shippingContact?.postalAddress?.street,
              street2: "",
              city:
                payment?.billingContact?.postalAddress?.city !== ""
                  ? payment?.billingContact.postalAddress.city
                  : payment?.shippingContact.postalAddress.city,
              province:
                payment?.billingContact.postalAddress.state !== ""
                  ? payment?.billingContact?.postalAddress?.state
                  : payment?.shippingContact?.postalAddress?.state,
              postcode:
                payment?.billingContact?.postalAddress?.postalCode !== ""
                  ? payment?.billingContact?.postalAddress?.postalCode
                  : payment?.shippingContact?.postalAddress?.postalCode,
              country:
                payment?.billingContact.postalAddress.country !== ""
                  ? payment?.billingContact?.postalAddress?.country
                  : payment?.shippingContact?.postalAddress?.country,
            },
            email: payment?.billingContact?.emailAddress,
          };
          //* Fetch Payer data from Wallet Payment - END *//

          console.log("Wallet payer Info :", walletPayerInfo);
          const createdShopper = await createShopperViaWalletData(
            walletPayerInfo
          );
          console.log("Shopper created via wallet data :", createdShopper);

          // Continue with the payment authorization
          session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
        };
        session.begin();
      }
    } catch (error) {
      console.log("Error @ handleApplePayment", error);
      //IIFE
      (async () => {
        await submitLog("error", "Error handling Apple Payment");
      })().catch(console.error);

      dispatch(
        setToastMessage({
          text: `${error}`,
          type: "ERROR",
        })
      );
    }
  };

  if (
    !accountPhone ||
    !accountPhone.number ||
    !accountPhone.country.countryCode
  ) {
    skipAvailableWalletPayments();
  }

  if (isApplePaySupported && accountPhone.number) {
    return (
      <>
        <div className="change-pay-method-link" style={{ textAlign: "center" }}>
          <h2 onClick={skipAvailableWalletPayments}>
            {t("ChangePaymentMethod")}
          </h2>
        </div>
        <br />
        <AnimatePresence>
          <motion.div
            animate={{ transform: "translateY(20px" }}
            initial={{ transform: "translateY(500px)" }}
            transition={{
              delay: 0.2,
              duration: 0.51,
              type: "spring",
              bounce: 0.2,
            }}
            className="pay-button-holder-fixed"
          >
            <button className="pay-button-apple-pay">
              <div
                className="apple-pay-button apple-pay-button-black"
                onClick={handleApplePayment}
              ></div>
            </button>
          </motion.div>
        </AnimatePresence>
      </>
    );
  } else return null;
};

export default MolliePaymentRequestAdupContainer;
