import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

type props = {
  type?: string;
  required?: boolean;
  value?: string;
  placeholder?: string;
  changeListeners: ((value: any, option?: string) => void)[];
  focusListeners?: ((e: React.FocusEvent) => void)[];
  id: string;
  classes?: string;
  innerPlaceholder?: string;
  readonly?: boolean;
  pattern?: string;
  title?: string;
  defaultValue?: string;
  autoFocus?: boolean;
  labelClasses?: string;
  disabled?: boolean;
};

const MaskedCurrencyInput = React.forwardRef<HTMLInputElement, props>(
  (
    {
      type = "text",
      value,
      changeListeners,
      focusListeners,
      placeholder = "",
      required,
      id,
      classes = "",
      innerPlaceholder,
      readonly,
      pattern,
      title,
      defaultValue,
      autoFocus,
      labelClasses,
      disabled,
    }: props,
    ref
  ) => {
    const [focused, setFocused] = useState<boolean>(false);
    const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);
    const dispatch = useDispatch();
    function handleChangeValue(value: any) {
      changeListeners?.forEach((listener) => listener(value));
    }

    function handleFocusElement(event: React.FocusEvent<HTMLInputElement>) {
      focusListeners?.forEach((listener) => listener(event));
      setOnlyOnFocus(true);
      dispatch(setInteractionsPerSessionCount());
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      setOnlyOnFocus(false);
    };

    return (
      <div className="flex-row">
        <div className={"animated-input-regular "}>
          <CurrencyInput
            ref={ref}
            id={id}
            type={type}
            inputMode="text"
            name="price"
            title={title}
            className={classes}
            required={required}
            allowNegativeValue={false}
            intlConfig={{ locale: "de-DE", currency: "EUR" }} //* Dynamic config change based on the shop currency & country should be handled later
            decimalsLimit={2}
            defaultValue={defaultValue}
            onValueChange={(value) => {
              let formattedValue = value?.replace(",", ".");
              handleChangeValue(formattedValue);
            }}
            onFocus={handleFocusElement}
            onBlur={handleBlur}
            readOnly={readonly}
            data-focused={focused.toString()}
            pattern={pattern}
            autoFocus={autoFocus}
            disabled={disabled}
          />
          <label className="animated__input--label">{placeholder}</label>
        </div>
      </div>
    );
  }
);

export default MaskedCurrencyInput;
