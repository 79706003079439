import React, { useEffect, useMemo, useState } from "react";
import { PaymentMethodsView } from "../../../components/payment-methods-view/PaymentMethods";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useLocation, useNavigate } from "react-router-dom";
import { initialAPICall } from "../../../lib/api/initial-api-call";
import {
  applicationUserType,
  setCartExpanded,
  setIsOtpOverlayVisible,
  setLastSocialCommerceUrl,
  setToastMessage,
} from "../../../store/reducers/appSlice";
import {
  setUserVerified,
  verificationStates,
} from "../../../store/reducers/userSlice";
import { AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { BackArrowBlack } from "../../../assets/svg/svg-components/back-arrow-black";
import { motion } from "framer-motion";
import { AccountProfileForm } from "../../../components/account-profile-input-form/AccountProfileForm";
import { useTranslation } from "react-i18next";
import {
  SelectedPaymentMethodSavedStatus,
  setIsRequiredFieldsNotFilled,
} from "../../../store/reducers/paymentSlice";
import { PaymentMethodConfigs } from "../../../lib/config/payment-methods-config";
import { addShopperEvent } from "../../../lib/api/events-methods";
import { submitLog } from "../../../lib/api/log";
import SummaryThemeWise from "../../../components/theme-wise-switching/SummaryThemeWise";
import BillingAddressToggle from "../../../components/billing-is-shipping/BillingAddressToggle";
import Header from "../../../components/header/Header";
import OldUserSummary from "../../../components/old-user-summary/OldUserSummary";
import PhoneForm from "../../../components/phone-form/PhoneForm";
import SplashScreen from "../../../components/splash-screen/SplashScreen";
import UserAddresses from "../../../components/user-addresses/UserAddresses";
import UserDetails from "../../../components/user-details/UserDetails";
import FastCheckoutBillingAddress from "../../../pages/fast-checkout/segments/FastCheckoutBillingAddress";
import FastCheckoutPageCartSegment from "../../../pages/fast-checkout/segments/FastCheckoutPageCart";
import FastCheckoutPaymentMount from "../../../pages/fast-checkout/segments/FastCheckoutPaymentMount";
import FastCheckoutShippingAddress from "../../../pages/fast-checkout/segments/FastCheckoutShippingAddress";
import AnimatedPayButtonBlack from "../../../pages/fast-checkout/subcomponents/AnimatedPayButtonBlack";
import FastCheckoutSinglePageWrapper from "../../../pages/fast-checkout/subcomponents/FastCheckoutSinglePageWrapper";
import GiftSelector from "../../../components/gift-selector/GiftSelector";

interface PageProps {
  showHeader: boolean;
  theme?: string;
}

const FastCheckoutMobileLayoutForPopups: React.FC<PageProps> = ({
  showHeader,
  theme,
}) => {
  const route = window.decodeURI(useLocation().pathname);
  const q = useLocation().search;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      q
        .substring(1)
        .split("&")
        .find((v) => v === "adup_redirect_status=failed")
    ) {
      console.log(q.substring(1).split("&"));
      //IIFE
      (async () => {
        await submitLog("error", t("PaymentFailed"));
      })().catch(console.error);

      dispatch(
        setToastMessage({
          text: t("PaymentFailed"),
          type: "ERROR",
        })
      );
    }
  }, [q]);

  const paymentState = useSelector((state: RootState) => state.payment);
  const [paymentMethod, setPaymentMethod] = useState<any>(null);

  useEffect(() => {
    if (
      paymentState.selectedPaymentMethodSaveState ===
      SelectedPaymentMethodSavedStatus.SAVED_METHOD
    ) {
      setPaymentMethod(paymentState.savedPaymentMethodSelected);
    } else {
      setPaymentMethod(paymentState.unsavedPaymentMethodSelected);
    }
  }, [
    paymentState.unsavedPaymentMethodSelected,
    paymentState.selectedPaymentMethodSaveState,
    paymentState.savedPaymentMethodSelected,
  ]);

  const { t } = useTranslation();

  const isXXL =
    window.location.origin === "https://xxlnutrition.secure-checkout.eu"
      ? true
      : false;

  const isBillingShipping = useSelector(
    (state: RootState) => state.users?.currentUser?.shipping?.isBillingShipping
  );

  const uiState = useSelector(
    (state: RootState) => state.app.UI.pages.fastCheckout
  );

  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const userEmail = useSelector(
    (state: RootState) => state.users.currentUser?.profile?.email
  );

  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );

  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );

  // variables for payment request
  const cartData = useSelector((state: RootState) => state.cart);

  // auth
  const authState = useSelector((state: RootState) => state.app.auth);

  const isInitAPILoading = useSelector(
    (state: RootState) => state.app.UI.isInitAPILoading
  );

  // we use this here for checking and setting the cart expansion state
  const isCartExpanded = useSelector(
    (state: RootState) => state.app?.UI?.cart?.expanded
  );

  // get the user's verification state, if they are trying to validate, display OTP popup
  const userData = useSelector((state: RootState) => state.users.currentUser);
  useEffect(() => {
    if (
      userData.verified.state === verificationStates.trying ||
      userData.verified.state === verificationStates.failed
    ) {
      dispatch(setIsOtpOverlayVisible(true));
    } else {
      dispatch(setIsOtpOverlayVisible(false));
    }
  }, [userData.verified]);

  useEffect(() => {
    const fields: Element[] = Array.from(
      document.getElementsByClassName("form-input")
    );
    fields.forEach((field: Element) => {
      if ((field as HTMLInputElement).value) {
        const parent = field.parentElement;
        parent?.classList.add("focused");
        parent?.classList.add("filled");
      }
    });
    if (authState.user === applicationUserType.NEW_USER) {
      dispatch(setIsRequiredFieldsNotFilled(true));
    }
    if (!verificationState?.firstPayment) {
      dispatch(
        setUserVerified({
          ...verificationState,
          firstPayment: "pending",
        })
      );
    }
  }, []);

  const app_mode = route.split("/")[1];
  const products = route.split("/")[3];
  useEffect(() => {
    if (app_mode === "fastcheckout" && products && products !== "") {
      initialAPICall(route);

      //* Triggering Events API on user actions
      addShopperEvent("view_page", {
        url: `${window.location.href}`,
        page: "fastcheckout",
        email: userEmail && userEmail !== "" ? userEmail : undefined,
      });
    }

    //* Setting the last social commerce URL as the fastcheckout URL
    dispatch(setLastSocialCommerceUrl(route));
  }, [route]);

  useEffect(() => {
    if (authState.user === applicationUserType.NEW_USER) {
      dispatch(setIsRequiredFieldsNotFilled(true));
    } else {
      dispatch(setIsRequiredFieldsNotFilled(false));
    }
  }, [authState?.user]);

  const profileDetailsForm = useMemo(() => {
    return <AccountProfileForm />;
  }, []);

  if (isInitAPILoading) {
    return (
      <AnimatePresence>
        <SplashScreen />
      </AnimatePresence>
    );
  }

  const renderUserDetails = () => {
    // if (feature_theme === "modern") {
    if (settings?.theme === "modern") {
      return (
        <>
          <UserDetails />
          <UserAddresses />
        </>
      );
    } else {
      return (
        <>
          {!uiState.hiddenSegments?.ACCOUNT_SEGMENT && (
            <div id="phoneInputRef001">
              <PhoneForm />
            </div>
          )}

          {!uiState.hiddenSegments?.DETAILS_SEGMENT &&
            verificationState?.state !== verificationStates.trying &&
            verificationState?.state !== verificationStates.failed && (
              // verificationState?.state !== verificationStates.pending &&
              <div id="profileDetailsFormRef001">{profileDetailsForm}</div>
            )}
        </>
      );
    }
  };

  const renderNormalThemeContent = () => {
    return (
      <React.Suspense fallback={<div className="main-content-holder"></div>}>
        <div className="main-content-holder">{showHeader && <Header />}</div>
        <FastCheckoutSinglePageWrapper>
          <div className="max-width-container">
            <div className="max-width-container__content">
              <div className="main-content-holder">
                {/* ===================================================================== */}
                <div className="main-left-content-holder">
                  <motion.span
                    className="heading-and-back-chevron"
                    onClick={() => dispatch(setCartExpanded(false))}
                  >
                    <AnimatePresence>
                      {isCartExpanded && (
                        <motion.div
                          key={"back-chevron-black-container"}
                          animate={{ x: 0, opacity: 1 }}
                          initial={{ x: 10, opacity: 0 }}
                          exit={{ x: -10, opacity: 0 }}
                        >
                          <BackArrowBlack />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.span>
                  {app_mode === "fastcheckout" &&
                    products &&
                    products !== "" && <Link to={"/profile"}></Link>}

                  {app_mode === "fastcheckout" && products && products !== "" && (
                    <>
                      {settings?.fascheckout_ui !== "compact" && (
                        <FastCheckoutPageCartSegment />
                      )}
                      {/* {settings?.fascheckout_ui !== "compact" && (
                        <div>
                          <h3
                            onClick={() => {
                              navigate(`/fastcheckout/${cartData?.shop?.slug}`);
                              dispatch(setInteractionsPerSessionCount());
                            }}
                            className="socialCommerce__browseMoreLink"
                          >
                            {t("BrowseMore")}
                          </h3>
                          {settings?.webshop_url && (
                            <h3 className="socialCommerce__goToLink">
                              {t("Or-go-to")}{" "}
                              <a
                                target={"_blank"}
                                href={settings?.webshop_url}
                                rel="noreferrer"
                              >
                                {t("Webshop")}
                              </a>
                            </h3>
                          )}
                        </div>
                      )} */}
                    </>
                  )}

                  {/* <FastCheckoutOrderSummarySegment app_mode={app_mode} /> */}
                  <SummaryThemeWise app_mode={app_mode} />

                  {isXXL && <GiftSelector />}
                  {/* {app_mode === "fastcheckout" &&
                    settings?.fascheckout_ui === "compact" && (
                      <div>
                        <h3
                          onClick={() => {
                            navigate(`/fastcheckout/${cartData?.shop?.slug}`);
                            dispatch(setInteractionsPerSessionCount());
                          }}
                          className="socialCommerce__browseMoreLink"
                        >
                          {t("BrowseMore")}
                        </h3>
                        {settings?.webshop_url && (
                          <h3 className="socialCommerce__goToLink">
                            {t("Or-go-to")}{" "}
                            <a
                              target={"_blank"}
                              href={settings?.webshop_url}
                              rel="noreferrer"
                            >
                              {t("Webshop")}
                            </a>
                          </h3>
                        )}
                      </div>
                    )} */}
                </div>

                {/* ===================================================================== */}

                <div className="fastcheckout-page-container-flex main-right-content-holder">
                  {/*  {!uiState.hiddenSegments?.ACCOUNT_SEGMENT && (
                  <div ref={phoneInputRef}>
                    <PhoneForm />
                  </div>
                )} */}

                  {authState.user === applicationUserType.OLD_USER && (
                    // verificationState?.state !== verificationStates.pending &&
                    // verificationState?.state !== verificationStates.trying &&
                    <OldUserSummary />
                  )}

                  {!uiState.hiddenSegments?.DETAILS_SEGMENT &&
                    verificationState?.state !== verificationStates.trying &&
                    verificationState?.state !== verificationStates.failed && // verificationState?.state !== verificationStates.pending &&
                    // {/* <div ref={profileDetailsFormRef}>{profileDetailsForm}</div> */}
                    renderUserDetails()}

                  {/* Only processed users can have multiple addresses saved */}
                  {!uiState.hiddenSegments?.SHIPPING_ADDRESS_SEGMENT &&
                    authState.user === applicationUserType.OLD_USER &&
                    verificationState?.state !== verificationStates.trying &&
                    verificationState?.state !== verificationStates.failed &&
                    !showCookieConsent && (
                      // verificationState?.state !== verificationStates.pending &&
                      <FastCheckoutShippingAddress />
                    )}

                  {!uiState.hiddenSegments?.BILLING_ADDRESS_SEGMENT &&
                    !isBillingShipping &&
                    verificationState?.state !== verificationStates.trying &&
                    verificationState?.state !== verificationStates.failed &&
                    !showCookieConsent && (
                      // verificationState?.state !== verificationStates.pending &&
                      <FastCheckoutBillingAddress />
                    )}

                  {!uiState.hiddenSegments?.BILLING_ADDRESS_SEGMENT &&
                    verificationState?.state !== verificationStates.trying &&
                    verificationState?.state !== verificationStates.failed && (
                      // verificationState?.state !== verificationStates.pending &&
                      <BillingAddressToggle />
                    )}

                  {/* {authState.user !== applicationUserType.OLD_USER && (
                  <CreateAccountToggle />
                )} */}
                </div>
              </div>

              {/* ===================================================================== */}

              <div className="main-payment-content-holder">
                <div
                  id="fastCheckoutPaymentMountRef001"
                  className="main-payment-card-content-holder"
                >
                  {!paymentState.errorOccurred &&
                    verificationState?.state !== verificationStates.pending &&
                    verificationState?.state !== verificationStates.trying &&
                    verificationState?.state !== verificationStates.failed &&
                    !showCookieConsent && <FastCheckoutPaymentMount />}
                </div>
              </div>

              {(!uiState.hiddenSegments?.PAYMENT_METHODS_SEGMENT ||
                !paymentState?.availablePaymentMethods ||
                paymentState?.availablePaymentMethods === null ||
                paymentState?.availablePaymentMethods.length <= 0 ||
                !paymentState?.paymentMethodLastUsed ||
                paymentState?.paymentMethodLastUsed === null ||
                paymentState?.paymentMethodLastUsed === "" ||
                paymentState?.paymentMethodLastUsed === "apple_pay" ||
                paymentState?.paymentMethodLastUsed === "google_pay") &&
                verificationState?.state !== verificationStates.trying &&
                verificationState?.state !== verificationStates.failed &&
                !showCookieConsent && (
                  // verificationState?.state !== verificationStates.pending &&
                  <div id="paymentMethodsRef001">
                    <PaymentMethodsView />
                  </div>
                )}

              {/* ===================================================================== */}

              {PaymentMethodConfigs[paymentMethod?.type]?.mountForm !== true &&
                // verificationState?.state !== verificationStates.pending &&
                // verificationState?.state !== verificationStates.trying &&
                paymentMethod?.type !== "apple_pay" &&
                paymentMethod?.type !== "google_pay" &&
                !paymentState?.isWalletPaymentMethodInUse &&
                !showCookieConsent && <AnimatedPayButtonBlack />}
            </div>
          </div>
        </FastCheckoutSinglePageWrapper>
      </React.Suspense>
    );
  };

  return <>{renderNormalThemeContent()}</>;
};

export default FastCheckoutMobileLayoutForPopups;
