import { t } from "i18next";

type Props = {
  onDeleteClick: () => void;
  onCancelClick: () => void;
};

const DeleteCartItemMsg = ({ onDeleteClick, onCancelClick }: Props) => {
  return (
    <div onClick={onCancelClick} className="deleteAddressMsg__overlay">
      <div className="deleteAddressMsg__wrapper column">
        <div className="deleteAddressMsg-title">
          <h1> {t("RemoveCartItem")}</h1>
        </div>
        <div className="deleteAddressMsg flex-column">
          <div className="textContent">{`${t("AreYouSure")} ${t(
            "RemoveSelectedCartItems"
          )} ?`}</div>
          <div className="buttonContainer">
            <div
              onClick={onDeleteClick}
              style={{ backgroundColor: "#e02020" }}
              className="btn1"
            >
              {t("Yes")}
            </div>
            <div onClick={onCancelClick} className="btn2">
              {t("Cancel")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteCartItemMsg;
