import React from "react";
import { motion } from "framer-motion";

interface PageTransitionContainerProps {
  goBack: null | (() => void);
  key: any;
  lastPosition?: any;
  animation?: {
    initial?: any;
    entrance?: any;
    exit?: any;
    transition?: any;
  };
}

const PageTransitionContainer: React.FC<PageTransitionContainerProps> = ({
  children,
  animation,
  lastPosition,
  goBack,
}) => {
  return (
    <motion.div
      initial={animation?.initial}
      animate={animation?.entrance}
      exit={animation?.exit}
      transition={animation?.transition}
    >
      {children}
    </motion.div>
  );
};

export default PageTransitionContainer;
