import {
  applicationUserType,
  enableFastCheckoutUISegment,
  initialHiddenSegments,
  updateUserType,
} from "../../store/reducers/appSlice";
import {
  clearAddresses,
  clearUser,
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import store from "../../store/store";
import { shopperLogOut } from "../api/shopper-methods";
import { AUTH_SSO_URL } from "../constants/urls";

/**
 *  * When the "Not you?" button is clicked, we need to set the state to NEW_USER NEW_BROWSER
 *  * And
 *  * When the user types in a different phone number or click on LogOut Button
 */
export function logOutUser() {
  console.log("*** Removing Global Token - LOGGING OUT ***");
  const iframe: any = document.getElementById("adUp-SSO-iFrame");
  const message = { action: "REVOKE_GLOBAL_TOKEN", key: "globalToken" };
  if (iframe && iframe?.contentWindow) {
    iframe.contentWindow?.postMessage(message, AUTH_SSO_URL);
  }

  try {
    shopperLogOut();
  } catch (error) {
    console.log("Error when revoking token", error);
  }

  store.dispatch(clearUser(null));
  store.dispatch(clearAddresses(null));
  store.dispatch(updateUserType({ user: applicationUserType.NEW_USER }));
  store.dispatch(
    setUserVerified({
      _: false,
      state: verificationStates.unverified,
      otp: {
        id: "",
      },
      firstPayment: "pending",
    })
  );
  for (const segment of Object.keys(initialHiddenSegments)) {
    store.dispatch(enableFastCheckoutUISegment(segment));
  }
  // store.dispatch(resetCartQty(1));
  // store.dispatch(clearPaymentState(null));
}
