import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import tick from "../../assets/png/blue-tick.png";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { CartSummary } from "../../components/cart-summary/CartSummary";
import { Link, useNavigate } from "react-router-dom";
import { AnimatedBlackArrowRight } from "../../assets/svg/animated-svg-components/AnimatedBlackArrowRight";
import { RegisterWebAuthn } from "../../components/register-webauthn/RegisterWebAuthn";
import { detectWebAuthnType } from "../../lib/webAuthn/detect-webAuthn-type";
import { checkPaymentStatus } from "../../lib/api/payment-status";
import SplashScreen from "../../components/splash-screen/SplashScreen";
import {
  setIsAICheckout,
  setShowCookieConsent,
  setToastMessage,
} from "../../store/reducers/appSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import Header from "../../components/header/Header";
import { CreateAccountOverlay } from "../../components/create-account/CreateAccountOverlay";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setUserVerified } from "../../store/reducers/userSlice";
import { initialAPICall } from "../../lib/api/initial-api-call";

export const PaymentReceivedView: React.FC = () => {
  const [result, setResult] = useState<any>({});

  const [isPaymentCompleted, setIsPaymentCompleted] = useState<Boolean>(false);

  const [isShopperAvailable, setIsShopperAvailable] = useState<Boolean>(false);

  const [isWebAuthnClosed, setIsWebAuthnClosed] = useState<Boolean>();

  const [isCreateAccountClosed, setIsCreateAccountClosed] = useState<Boolean>();

  const [isPhoneAvailable, setIsPhoneAvailable] = useState<Boolean>();

  const { t } = useTranslation();

  const isUserVerified = useSelector(
    (state: RootState) => state.users.currentUser.verified._
  );

  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const token = useSelector(
    (state: RootState) => state.users.currentUser.token
  );

  const currentPhone = useSelector(
    (state: RootState) => state.users.currentUser.accountPhone.number
  );
  const currentCountry = useSelector(
    (state: RootState) => state.users.currentUser.accountPhone.country.dialCode
  );

  const webauthn = useSelector(
    (state: RootState) => state.users.currentUser.webauthn
  );

  const lastCartUrl = useSelector(
    (state: RootState) => state.app.UI.lastCartUrl
  );

  const otpId = useSelector(
    (state: RootState) => state.users.currentUser.verified.otp.id
  );

  const loggedInMethod = useSelector(
    (state: RootState) => state.users.currentUser.verified.loggedInMethod
  );

  const merchantTheme: any = useSelector(
    (state: RootState) => state.users?.merchant?.config
  );

  const webAuthnType = detectWebAuthnType();
  const pathname = window.decodeURI(window.location.pathname);
  const cart_id = pathname.split("/")[2];
  const navigate = useNavigate();

  // if (window.opener && isPaymentOnPopUpViaIframe) {
  //   console.log(
  //     "Inside a pop-up window via iFrame -- Returning user back to the parent window"
  //   );
  //   const message = { status: "success", newUrl: pathname };
  //   window.opener.postMessage(message, "*");
  //   window.close();
  // }

  useEffect(() => {
    store.dispatch(setIsAICheckout(false));
    if (currentCountry && currentPhone) {
      setIsPhoneAvailable(true);
      // setupOTPId(currentCountry, currentPhone);
    }
    if (cart_id) {
      try {
        const res = checkPaymentStatus(cart_id);
        res.then(async (e) => {
          setResult(e);
          if (e?.payment_completed) {
            store.dispatch(setShowCookieConsent(false));
            if (
              (merchantTheme?.id === "" ||
                merchantTheme?.merchant_id === "" ||
                merchantTheme?.merchant_name === "") &&
              e?.shop?.shop_slug &&
              e?.shop?.shop_slug !== ""
            ) {
              await initialAPICall(`/overview/${e?.shop?.shop_slug}`);
            }
            if (e?.shopper && e?.shopper?.id) {
              setIsShopperAvailable(true);
            } else {
              setIsShopperAvailable(false);
            }
            setIsPaymentCompleted(true);
            store.dispatch(
              setUserVerified({
                ...verificationState,
                firstPayment: "completed",
              })
            );
          } else {
            navigate(
              lastCartUrl && lastCartUrl !== ""
                ? lastCartUrl
                : `/${e?.shop?.shop_slug}`
            );
            store.dispatch(
              setToastMessage({
                text: t("PaymentFailed"),
                type: "ERROR",
              })
            );
          }
        });
      } catch (error) {
        console.log("checkPaymentStatus API Error :", error);
        navigate(
          lastCartUrl && lastCartUrl !== ""
            ? lastCartUrl
            : `/${result?.shop?.shop_slug}`
        );
        store.dispatch(
          setToastMessage({
            text: t("PaymentFailed"),
            type: "ERROR",
          })
        );
      }
    }
  }, []);

  useEffect(() => {
    document.title = `Thank You - Powered by ${merchantTheme?.merchant_name}`;
  }, [merchantTheme]);

  return (
    <div>
      {isPaymentCompleted && (
        <>
          <Header />
          <motion.div className="payment-received-view">
            <div className="payment-received-tick-holder">
              <LazyLoadImage
                loading="lazy"
                style={{ width: "10rem" }}
                src={tick}
                alt={"success"}
              ></LazyLoadImage>
            </div>
            <h1>Thank You !</h1>
            <h2>Your order has been received</h2>
            {/* <h2>{sellerName.current} will ship your order soon.</h2> */}
            <motion.div
              initial={{ opacity: 0, zoom: 0.6 }}
              animate={{ zoom: 1, opacity: 1 }}
              transition={{ delay: 0.45 }}
              className="payment-received-image-holder"
            >
              <h3> {t("OrderSummary")}</h3>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, zoom: 0.6 }}
              animate={{ zoom: 1, opacity: 1 }}
              transition={{ delay: 0.45 }}
              className="payment-received-image-holder"
            >
              <div
                className="payment-received-order-summary"
                /* style={{ width: "36rem" }} */
              >
                <CartSummary
                  currency={getSymbolFromCurrency(result?.shop?.currency) ?? ""}
                  cartTotal={
                    result?.cart_data?.sub_total &&
                    !Number.isNaN(+result?.cart_data?.sub_total)
                      ? +result?.cart_data?.sub_total
                      : 0
                  }
                  convenienceFee={
                    result?.cart_data?.convenience_fee &&
                    !Number.isNaN(+result?.cart_data?.convenience_fee)
                      ? +result?.cart_data?.convenience_fee
                      : 0
                  }
                  discount={
                    result?.order?.discount &&
                    !Number.isNaN(+result?.order?.discount)
                      ? +result?.order?.discount
                      : 0
                  }
                  shippingFee={
                    result?.cart_data?.shipping_cost &&
                    !Number.isNaN(+result?.cart_data?.shipping_cost)
                      ? +result?.cart_data?.shipping_cost
                      : 0
                  }
                  app_mode={"thank-you"}
                />
                <h4>
                  <a
                    target="_blank"
                    href={`${merchantTheme?.how_fastcheckout_works_url}`}
                    rel="noreferrer"
                  >
                    {t("HowAdUpWorks", {
                      merchant_name: merchantTheme?.merchant_name
                        ? merchantTheme.merchant_name
                        : "",
                    })}
                  </a>
                </h4>
              </div>
            </motion.div>

            <div className="payment-received-links">
              {token && token !== "" && (
                <motion.div
                  initial={{ opacity: 0, zoom: 0.6 }}
                  animate={{ zoom: 1, opacity: 1 }}
                  transition={{ delay: 0.45 }}
                  className="payment-received-image-holder"
                >
                  <Link className="menu-button-holder" to="/profile">
                    <a className="your-account" color="white">
                      {t("YourAccount")}
                      <div
                        style={{ width: 25, marginLeft: 250, marginTop: -25 }}
                      >
                        <AnimatedBlackArrowRight />
                      </div>
                    </a>
                  </Link>
                </motion.div>
              )}
              {result?.shop && result?.shop?.name && (
                <motion.div
                  initial={{ opacity: 0, zoom: 0.6 }}
                  animate={{ zoom: 1, opacity: 1 }}
                  transition={{ delay: 0.45 }}
                  className="payment-received-image-holder"
                >
                  <Link
                    className="menu-button-holder"
                    to={
                      lastCartUrl && lastCartUrl !== ""
                        ? lastCartUrl
                        : `/${result?.shop?.shop_slug}`
                    }
                  >
                    <a className="your-account" color="white">
                      {result?.shop?.name}
                      <div
                        style={{ width: 25, marginLeft: 250, marginTop: -25 }}
                      >
                        <AnimatedBlackArrowRight />
                      </div>
                    </a>
                  </Link>
                </motion.div>
              )}
            </div>

            {/* //TODO : add redirect URL / Location from API */}
            {/* <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.75 }}
            className="payment-received-page-bottom"
          >
            <Link className="shop-more-button-holder" to="">
              
              <button className="shop-more" color="white">
                <b>Shop More</b>
                <p>using AdUp AI Checkout</p>
                <img
                  style={{ width: 25, marginLeft: 220 }}
                  src={cart}
                  alt={"cart"}
                ></img>
              </button>
            </Link>
          </motion.div>  */}

            <div className="verify-phone-segment">
              {/* {(!webauthn ||
                !isOTPClosed ||
                webAuthnType === "NO_BIOMETRICS") &&
                !isUserVerified &&
                isPhoneAvailable && (
                  <VerifyPhone
                    hideSegment={() => {
                      setIsOTPClosed(true);
                    }}
                  />
                )}

              {webauthn &&
                webAuthnType !== "NO_BIOMETRICS" &&
                !isWebAuthnClosed &&
                !isUserVerified && (
                  <LoginWebAuthn
                    hideSegment={() => {
                      setIsWebAuthnClosed(true);
                      setIsOTPClosed(false);
                    }}
                    webAuthType={webAuthnType}
                  />
                )} */}
              {webAuthnType !== "NO_BIOMETRICS" &&
                !isWebAuthnClosed &&
                isUserVerified &&
                otpId &&
                otpId !== "" &&
                loggedInMethod !== "webauthn" && (
                  <RegisterWebAuthn
                    hideSegment={() => {
                      setIsWebAuthnClosed(true);
                    }}
                    webAuthType={webAuthnType}
                  />
                )}
              {!isCreateAccountClosed && !isShopperAvailable && (
                <CreateAccountOverlay
                  hideSegment={() => {
                    setIsCreateAccountClosed(true);
                  }}
                />
              )}
            </div>
          </motion.div>
        </>
      )}
      {!isPaymentCompleted && (
        <AnimatePresence>
          <SplashScreen />
        </AnimatePresence>
      )}
    </div>
  );
};
