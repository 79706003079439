import React, { useEffect, useState } from "react";
import AICheckoutDesktopLayout1 from "./desktop/AICheckoutDesktopLayout1";
import AICheckoutMobileLayout1 from "./mobile/AICheckoutMobileLayout1";

const AICheckoutLayout1 = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth > 750 ? (
        <AICheckoutDesktopLayout1 />
      ) : (
        <AICheckoutMobileLayout1 />
      )}
    </>
  );
};

export default AICheckoutLayout1;
