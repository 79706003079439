import { useDispatch } from "react-redux";
import { setInteractionsPerSessionCount } from "../../store/reducers/statisticsSlice";

type Props = {
  onChange: any;
  placeholder: string;
  value: string;
  extraClasses?: string;
  name?: string;
};

const AnimatedTextAreaFieldRegular = (props: Props) => {
  const { onChange, placeholder, value, extraClasses = "", name = "" } = props;
  const dispatch = useDispatch();
  return (
    <div className="flex-row">
      <div className={"animated-input-area-regular " + extraClasses}>
        <textarea
          className="animated__input"
          onChange={onChange}
          value={value}
          name={name}
          onFocus={() => {
            dispatch(setInteractionsPerSessionCount());
            window.innerHeight = 100;
          }}
        />
        <label className="animated__input--label">{placeholder}</label>
      </div>
    </div>
  );
};

export default AnimatedTextAreaFieldRegular;
