import React, { Fragment, useEffect, useState } from "react";
import HeadingWithRightButton from "../heading/HeadingWithRightButton";
import { useDispatch, useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import EditButtonLight from "../button/EditButton";
import { enableFastCheckoutUISegment } from "../../store/reducers/appSlice";
import {
  SelectedPaymentMethodSavedStatus,
  setIsPaymentMethodEditable,
  setIsPaymentMethodsLoading,
  setIsWalletPaymentMethodInUse,
  setUnsavedSelectedPaymentMethod,
} from "../../store/reducers/paymentSlice";
import { useTranslation } from "react-i18next";
import PoweredByAdUp from "../powered-by-AdUp/Powered-by-AdUp";
import { handleNewPaymentMethodSelection } from "../../lib/payment-handlers/payment-methods/method-selection";
import {
  AdupPaymentMethodSelection,
  AdupSavedPaymentDetails,
} from "../../interfaces/AdupPaymentMethod";
import { getKeyByValue } from "../../lib/utils/helper-functions";
import { setBillingIsShipping } from "../../store/reducers/userSlice";

const OldUserSummary = () => {
  const user = useSelector((state: RootState) => state.users.currentUser);
  const payments = useSelector((state: RootState) => state.payment);
  const cartData = useSelector((state: RootState) => state.cart);
  const imageStaticUrl = store.getState().app?.appData?.static_base_url;
  const [shippingAddress, setShippingAddress] = useState(
    user?.shipping?.shippingAddress
  );
  const [billingAddress, setBillingAddress] = useState(
    user?.shipping?.billingAddress
  );
  const [paymentMethod, setPaymentMethod] = useState<
    AdupPaymentMethodSelection | AdupSavedPaymentDetails
  >(payments?.savedPaymentMethodSelected);
  const [bankOptions, setBankOptions] = useState();
  const [optionTitle, setOptionTitle] = useState("");

  const { t } = useTranslation();
  let paymentMethodLastUsed = payments?.paymentMethodLastUsed;
  let paymentMethodToDisplayAsDefault: any =
    payments.allPaymentMethods[paymentMethodLastUsed];

  useEffect(() => {
    if (paymentMethodToDisplayAsDefault) {
      store.dispatch(
        setUnsavedSelectedPaymentMethod(paymentMethodToDisplayAsDefault)
      );
      if (
        paymentMethodLastUsed === "apple_pay" ||
        paymentMethodLastUsed === "google_pay"
      ) {
        store.dispatch(setIsWalletPaymentMethodInUse(true));
      } else {
        // store.dispatch(setIsWalletPaymentMethodInUse(false));
      }
    }
  });

  useEffect(() => {
    if (paymentMethodToDisplayAsDefault) {
      handleNewPaymentMethodSelection(paymentMethodToDisplayAsDefault);
      if (
        paymentMethodLastUsed === "apple_pay" ||
        paymentMethodLastUsed === "google_pay"
      ) {
        store.dispatch(setIsWalletPaymentMethodInUse(true));
      } else {
        store.dispatch(setIsWalletPaymentMethodInUse(false));
      }
    }
  }, [paymentMethodLastUsed, paymentMethodToDisplayAsDefault]);

  useEffect(() => {
    if (user?.shipping?.shippingAddress && user?.shipping?.billingAddress) {
      setShippingAddress(user?.shipping?.shippingAddress);
      setBillingAddress(user?.shipping?.billingAddress);
    }
  }, [user?.shipping]);

  useEffect(() => {
    if (
      payments?.selectedPaymentMethodSaveState ===
      SelectedPaymentMethodSavedStatus.SAVED_METHOD
    ) {
      setPaymentMethod(payments?.savedPaymentMethodSelected);
      setBankOptions(payments?.savedPaymentMethodSelected.banks);
    } else {
      setPaymentMethod(payments?.unsavedPaymentMethodSelected);
      setBankOptions(payments?.unsavedPaymentMethodSelected.banks);
    }
  }, [
    payments?.selectedPaymentMethodSaveState,
    payments?.unsavedPaymentMethodSelected,
    payments?.savedPaymentMethodSelected,
  ]);

  useEffect(() => {
    setBankOptions(paymentMethod?.banks);
  }, [paymentMethod]);

  useEffect(() => {
    if (bankOptions) {
      let caption = getKeyByValue(
        bankOptions,
        payments?.paymentMethodOptionSelected &&
          payments?.paymentMethodOptionSelected !== ""
          ? payments?.paymentMethodOptionSelected
          : bankOptions[Object.keys(bankOptions)[0]]
      );
      if (caption) setOptionTitle(` ( ${caption} ) `);
    } else {
      setOptionTitle("");
    }
  }, [paymentMethod, payments.paymentMethodOptionSelected, bankOptions]);

  const hiddenSegments = useSelector(
    (state: RootState) => state.app.UI.pages.fastCheckout.hiddenSegments
  );

  const [hideMe, setHideMe] = useState(false);

  useEffect(() => {
    let count = 0;
    for (const segment in hiddenSegments) {
      if (hiddenSegments[segment]) {
        count++;
        break;
      }
    }

    /* if (count <= 0) {
      setHideMe(true);
    } */

    if (
      !hiddenSegments?.ACCOUNT_SEGMENT &&
      !hiddenSegments?.PAYMENT_METHODS_SEGMENT &&
      !hiddenSegments?.DETAILS_SEGMENT &&
      !hiddenSegments?.SHIPPING_ADDRESS_SEGMENT &&
      !hiddenSegments?.BILLING_ADDRESS_SEGMENT
    ) {
      setHideMe(true);
    }
  }, [hiddenSegments]);

  useEffect(() => {
    if (!shippingAddress || !billingAddress) {
      store.dispatch(enableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT"));
      store.dispatch(enableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT"));
    }
  }, [shippingAddress, billingAddress]);

  if (hideMe) return null;

  return (
    <Fragment>
      <HeadingWithRightButton
        headingText={t("FastCheckout")}
        // button={<img src={sloganSmall} />}
        button={<PoweredByAdUp />}
      />
      <div className="old-user-summary ">
        <div>
          <SummarizedFastCheckoutSegment
            hideSegments={["ACCOUNT_SEGMENT", "DETAILS_SEGMENT"]}
          >
            <div className="flex-col">
              <h3>{t("YourAccount")}</h3>
              <p className="summary-user-phone">
                {user.accountPhone?.country.dialCode +
                  user.accountPhone?.number}
              </p>
              <p>
                {user.profile?.name?.first + " " + user.profile?.name?.second}
              </p>
            </div>
          </SummarizedFastCheckoutSegment>
          <SummarizedFastCheckoutSegment
            hideSegments={["SHIPPING_ADDRESS_SEGMENT"]}
          >
            <div className="flex-col">
              <h3>{t("ShippingAddressTitle")}</h3>
              <p>{`${shippingAddress?.number}, ${shippingAddress?.street}, ${shippingAddress?.city}`}</p>{" "}
              <p>{`${shippingAddress?.postcode}, ${shippingAddress?.country?.country}`}</p>{" "}
            </div>
          </SummarizedFastCheckoutSegment>
          <SummarizedFastCheckoutSegment
            hideSegments={["BILLING_ADDRESS_SEGMENT"]}
          >
            <div className="flex-col">
              <h3>{t("BillingAddressTitle")}</h3>
              <p>{`${billingAddress?.number ?? ""}, ${
                billingAddress?.street ?? ""
              }, ${billingAddress?.city ?? ""}`}</p>{" "}
              <p>{`${billingAddress?.postcode ?? ""}, ${
                billingAddress?.country?.country ?? ""
              }`}</p>{" "}
            </div>
          </SummarizedFastCheckoutSegment>
          {!payments?.availablePaymentMethods ||
          payments?.availablePaymentMethods === null ||
          payments?.availablePaymentMethods.length <= 0 ||
          !paymentMethodLastUsed ||
          paymentMethodLastUsed === null ||
          paymentMethodLastUsed === "" ||
          paymentMethodLastUsed === "apple_pay" ||
          paymentMethodLastUsed === "google_pay" ? null : (
            <SummarizedFastCheckoutSegment
              hideSegments={["PAYMENT_METHODS_SEGMENT"]}
            >
              <div className="flex-col">
                <h3>{t("PaymentMethod")}</h3>
                <div className="payment-method-summary">
                  <img
                    className="old-user-summary-payment-method-icon"
                    src={`${imageStaticUrl}/small/${paymentMethodToDisplayAsDefault?.iconURL}`}
                  />
                  {paymentMethodToDisplayAsDefault
                    ? paymentMethodToDisplayAsDefault?.displayTitle
                    : ""}
                  {`${optionTitle}`}
                </div>
              </div>
            </SummarizedFastCheckoutSegment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const SummarizedFastCheckoutSegment: React.FC<{ hideSegments: string[] }> = ({
  children,
  hideSegments,
}) => {
  const dispatch = useDispatch();

  const hiddenSegments = useSelector(
    (state: RootState) => state.app.UI.pages.fastCheckout.hiddenSegments
  );
  const payments = useSelector((state: RootState) => state.payment);

  for (const segment of hideSegments) {
    if (!hiddenSegments[segment]) return null;
  }

  function setSegmentEditable(key: string) {
    dispatch(enableFastCheckoutUISegment(key));
    if (key === "BILLING_ADDRESS_SEGMENT")
      dispatch(setBillingIsShipping(false));
    if (key === "PAYMENT_METHODS_SEGMENT") {
      if (
        !payments?.availablePaymentMethods ||
        payments?.availablePaymentMethods === null ||
        payments?.availablePaymentMethods.length <= 0
      ) {
        dispatch(setIsPaymentMethodsLoading(false));
      } else {
        dispatch(setIsPaymentMethodsLoading(true));
      }
      dispatch(setIsPaymentMethodEditable(true));
    }
  }

  return (
    <div className="summarized-fast-checkout-segment">
      {children}{" "}
      <EditButtonLight
        rightAlign={true}
        onClick={() => {
          for (const segment of hideSegments) {
            if (hiddenSegments[segment]) setSegmentEditable(segment);
          }
        }}
      />
    </div>
  );
};

export default OldUserSummary;
