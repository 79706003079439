import { handleStripeAsSelected } from "./stripe-handlers";

export interface ServiceProviderHandlerProps {
  id: string;
  serviceProvider: string;
  pspMethod: string;
  type: string;
  publicKey: string;
  clientSecret: string;
  returnURL: string;
  currency: string;
  amount: string;
}

const handlers: {
  [index: string]: (params: ServiceProviderHandlerProps) => void;
} = Object.freeze({
  stripe: handleStripeAsSelected,
});

export default handlers;
