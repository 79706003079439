import { loadStripe } from "@stripe/stripe-js/pure";
import { t } from "i18next";
import {
  setIsPayButtonLoading,
  setToastMessage,
} from "../../../../store/reducers/appSlice";
import { SelectedPaymentMethodSavedStatus } from "../../../../store/reducers/paymentSlice";
import store from "../../../../store/store";
import { handlePaymentRequest } from "../../../api/payment-post-request";

export async function giropayPayFunction() {
  store.dispatch(setIsPayButtonLoading(true));
  const paymentState = store.getState().payment;
  const profileName = store.getState().users.currentUser.profile.name;

  const selectedPaymentMethod =
    paymentState.selectedPaymentMethodSaveState ===
    SelectedPaymentMethodSavedStatus.SAVED_METHOD
      ? paymentState.savedPaymentMethodSelected
      : paymentState.unsavedPaymentMethodSelected;

  if (
    (selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "stripe" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "buckaroo" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "mollie" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "magentomollie" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !== "paynl" &&
      selectedPaymentMethod.serviceProvider.toLocaleLowerCase() !==
        "aduppay") ||
    selectedPaymentMethod.type !== "giropay"
  ) {
    store.dispatch(setIsPayButtonLoading(false));
    return;
  }

  if (selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "stripe") {
    const stripe = await loadStripe(selectedPaymentMethod?.data?.public_key);

    stripe
      ?.confirmGiropayPayment(paymentState.stripe.clientSecret, {
        payment_method: {
          billing_details: {
            name: profileName.first + " " + profileName.second,
          },
        },
        // Return URL where the customer should be redirected after the authorization.
        return_url: paymentState.stripe.returnURL,
      })
      .then(function (result) {
        if (result.error) {
          // Inform the customer that there was an error.
          console.error(result.error);
          store.dispatch(
            setToastMessage({
              text: result.error?.message
                ? result.error?.message
                : t("UnexpectedError"),
              type: "ERROR",
            })
          );
          store.dispatch(setIsPayButtonLoading(false));
        } else {
          store.dispatch(
            setToastMessage({
              text: t("PaymentSuccessful"),
              type: "SUCCESS",
            })
          );
          store.dispatch(setIsPayButtonLoading(false));
        }
      })
      .catch((e) => {
        console.log(e);
        store.dispatch(
          setToastMessage({
            text: e ? e : t("UnexpectedError"),
            type: "ERROR",
          })
        );
        store.dispatch(setIsPayButtonLoading(false));
      });
  } else if (
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "buckaroo" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "mollie" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() ===
      "magentomollie" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "paynl" ||
    selectedPaymentMethod.serviceProvider.toLocaleLowerCase() === "aduppay"
  ) {
    handlePaymentRequest();
  }
}
