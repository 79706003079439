import { t } from "i18next";
import { phoneNumberCheck } from "../../components/account-form/phone-change-listeners";
import {
  applicationBrowserStates,
  applicationUserType,
  disableFastCheckoutUISegment,
  initialHiddenSegments,
  setIPCountry,
  updateBrowser,
  updateUserType,
  setToastMessage,
  setLastCartUrl,
  setAppData,
  setIsInitAPILoading,
  setAppLanguage,
  setIsAICheckout,
} from "../../store/reducers/appSlice";
import {
  initialShop,
  setAllProducts,
  setAttributeSkuMap,
  setCart,
  setShop,
} from "../../store/reducers/cartSlice";
import {
  setAvailablePaymentMethods,
  setIsInitApiFetched,
} from "../../store/reducers/paymentSlice";
import {
  setDeviceCountry,
  setInitCountryCode,
  setUserToken,
  updateMerchant,
} from "../../store/reducers/userSlice";
import store from "../../store/store";
import { INIT_URL } from "../constants/urls";
import { logOutUser } from "../state/clear-user-data";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import { toastHandler } from "../utils/toast-handler";
import { submitLog } from "./log";
import { getUniqueBrowserId } from "../utils/helper-functions";
import { detectIncognito } from "detectincognitojs";
import submitStatisticsDataForAnalytics from "../utils/submit-statistics-data";

/**
 * Fetches the API token if there isn't one
 * @param { string } pathname
 * @return { Promise<any> | boolean }
 */
export async function initialAPICall(pathname: string): Promise<any> {
  store.dispatch(setIsInitApiFetched(true));
  store.dispatch(setIsInitAPILoading(true));
  const access_token = store.getState().users.currentUser.token;
  const saved_profile = store.getState().users.currentUser.profile.id;

  if (access_token) {
    // if there is an api token the browser should be set to the OLD_BROWSER state

    try {
      const response = await makeInitialAPICall(access_token, pathname);

      // trigger API Errors as Toasts
      const res = await response.json();

      if (res?.errors?.shop_error) {
        //* redirecting back to Root page if Shop Error occurs
        store.dispatch(setShop(initialShop));
        store.dispatch(
          setToastMessage({
            text: t("ShopError"),
            type: "ERROR",
          })
        );
        window.open(`/`, "_self");
      } else if (res?.errors?.shopper_error) {
        //* logout user if Shopper Error occurs
        store.dispatch(setUserToken(undefined));
        logOutUser();
        setupInitialState(
          res?.result,
          applicationBrowserStates.NEW_BROWSER,
          pathname
        );
      } else {
        if (res) {
          toastHandler(res, store.dispatch, setToastMessage);
        }

        const { result } = res;
        setupInitialState(
          result,
          saved_profile !== ""
            ? applicationBrowserStates.OLD_BROWSER
            : applicationBrowserStates.NEW_BROWSER,
          pathname,
          result.shopper
        );
      }
    } catch (exception) {
      console.log(exception);
      await submitLog("error", t("InitError"));
      store.dispatch(
        setToastMessage({
          text: t("InitError"),
          type: "ERROR",
        })
      );
      window.open(`/`, "_self");
      return false;
    }
  } else {
    // if there is no api token the browser should be in the NEW_BROWSER state
    try {
      const response = await makeInitialAPICall("", pathname);

      // trigger API Errors as Toasts
      const res = await response.json();
      if (res?.errors?.shop_error) {
        //* redirecting back to Root page if Shop Error occurs
        store.dispatch(setShop(initialShop));
        store.dispatch(
          setToastMessage({
            text: t("ShopError"),
            type: "ERROR",
          })
        );
        window.open(`/`, "_self");
      } else if (res?.errors?.shopper_error) {
        //* logout user if Shopper Error occurs
        store.dispatch(setUserToken(undefined));
        logOutUser();
        setupInitialState(
          res?.result,
          applicationBrowserStates.NEW_BROWSER,
          pathname
        );
      } else {
        if (res) {
          toastHandler(res, store.dispatch, setToastMessage);
        }

        const { result } = res;
        if (result.shopper) {
          setupInitialState(
            result,
            saved_profile !== ""
              ? applicationBrowserStates.OLD_BROWSER
              : applicationBrowserStates.NEW_BROWSER,
            pathname,
            result.shopper
          );
        } else {
          setupInitialState(
            result,
            applicationBrowserStates.NEW_BROWSER,
            pathname
          );
        }
      }
    } catch (exception) {
      console.log(exception);
      await submitLog("error", t("InitError"));
      store.dispatch(
        setToastMessage({
          text: t("InitError"),
          type: "ERROR",
        })
      );
      window.open(`/`, "_self");
      return false;
    }
  }
  store.dispatch(setIsInitAPILoading(false));
}

/**
 * @param {string} key
 * @param { string } pathname
 * A key will be sent if it is passed, otherwise a new key will be received
 */
async function makeInitialAPICall(key: string = "", pathname: string) {
  const cartItems = store.getState().cart.items;
  const cart: any = [];
  for (const item of cartItems) {
    cart.push({ sku: item.sku, qty: item.amount });
  }
  let isBrowserSafari = /^((?!chrome|android).)*safari/i.test(
    navigator.userAgent
  );
  let isIncognitoWindow = false;

  await detectIncognito().then((result) => {
    console.log(
      "🌍👻 is Incognito Window ==> ",
      result.browserName,
      result.isPrivate
    );
    isIncognitoWindow = result.isPrivate;
  });

  const app_mode = pathname.split("/")[1];
  let shop_slug: string;
  let products: string;
  let cartString: string;
  let params: string;
  let uniqueBrowserId: string =
    isBrowserSafari && !isIncognitoWindow
      ? `&unique_browser_id=${getUniqueBrowserId()}`
      : "";

  if (app_mode === "fastcheckout") {
    store.dispatch(setIsAICheckout(false));
    shop_slug = pathname.split("/")[2];
    products = pathname.split("/")[3].replace(/\[[\s\S]*?\]/g, ""); //* removing qty(s) from URL

    store.dispatch(setLastCartUrl(pathname));

    if (cart.length > 0) {
      cartString = JSON.stringify(cart);
      params = `?mode=fastcheckout&slug=${shop_slug}&products=${products}&cart_data=${cartString}`;
    } else {
      params = `?mode=fastcheckout&slug=${shop_slug}&products=${products}`;
    }
  } else if (app_mode === "overview") {
    store.dispatch(setIsAICheckout(false));
    shop_slug = pathname.split("/")[2];

    store.dispatch(setLastCartUrl(`/${shop_slug}`));

    params = `?mode=overview&slug=${shop_slug}`;
  } else if (app_mode === "social-commerce") {
    store.dispatch(setIsAICheckout(false));
    shop_slug = pathname.split("/")[2];
    products = pathname.split("/")[3];

    store.dispatch(setLastCartUrl(`/${shop_slug}/${products}`));

    params = `?mode=socialcommerce&slug=${shop_slug}&products=${products}`;
  } else {
    //* AI CHeckout */
    store.dispatch(setIsAICheckout(true));
    shop_slug = pathname.split("/")[1];
    products = pathname.split("/")[2];

    store.dispatch(setLastCartUrl(pathname));

    params = `?mode=socialcommerce&slug=${shop_slug}&products=${products}`;
  }

  const response = await fetch(INIT_URL + params + uniqueBrowserId, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      // "X-API-Key": key,
      Authorization: key ? `Bearer ${key}` : "",
    },
  });

  return response;
}

/**
 * set the state of the store according to the init response
 * @param { any } apiResult
 * @param { string } browser Whether the browser is a new browser or already contains localstorage data
 * @param { any | boolean } shopper If false, the user is a new user
 */
export function setupInitialState(
  apiResult: any,
  browser: string,
  pathname: any,
  shopper?: any,
  isDemoActive?: boolean
) {
  const app_mode = pathname.split("/")[1];
  console.log("INIT Mode ==> ", isDemoActive ? "demo" : app_mode);
  const cartItems = store.getState().cart.items;
  store.dispatch(updateBrowser({ browser }));
  const accountPhone = store.getState().users.currentUser.accountPhone;

  const user = shopper
    ? applicationUserType.OLD_USER
    : applicationUserType.NEW_USER;
  store.dispatch(
    updateUserType({
      user,
    })
  );

  if (shopper) {
    for (const segment of Object.keys(initialHiddenSegments)) {
      store.dispatch(disableFastCheckoutUISegment(segment));
    }
  }

  const { products, user_data, shop, app_data, attribute_sku_map, merchant } =
    apiResult;

  if (user_data?.language) {
    store.dispatch(setAppLanguage(user_data?.language));
  }

  if (app_data) {
    store.dispatch(setAppData(app_data));
  }

  if (attribute_sku_map) {
    store.dispatch(setAttributeSkuMap(attribute_sku_map));
  }

  if (merchant?.id) {
    const id = merchant?.id.toString();

    const merchantObj = merchant;
    merchant.id = id;

    store.dispatch(updateMerchant(merchantObj));
  }

  let currentCart: any;

  if (products[0] === "products_not_found") {
    store.dispatch(setAllProducts([]));
  } else {
    currentCart = products.map((itemId: any) => {
      return { ...itemId[Object.keys(itemId)[0]], key: Object.keys(itemId)[0] };
    });
    if (currentCart) {
      store.dispatch(setAllProducts(currentCart));
    }
  }

  let cartTotal: number = 0.0;
  let cartData: any;

  if (currentCart && currentCart.length > 0) {
    cartData = currentCart.filter((item: any) => {
      //* Simple products & Variants are supported , but Variable main products cannot be supported
      const variant = item?.variant[item?.key];
      if (!variant) {
        return false;
      }
      return true;
    });
  }

  const searchLocalCart = (sku: string) => {
    let item = cartItems.find((o) => o.sku === sku);
    return item?.amount;
  };
  if (app_mode !== "overview") {
    if (cartData) {
      if (app_mode === "fastcheckout") {
        //? Dynamically changing HTML Title of the application
        document.title = `${apiResult.shop.name} - Powered by ${apiResult.merchant?.config?.merchant_name} AI Checkout`;
        //* In fastcheckout mode Cart is persisted
        store.dispatch(
          setCart({
            shop: apiResult.shop,
            cart: cartData.map((item: any) => {
              const urlProducts = pathname.split("/")[3].split(",");
              const urlQuantity = urlProducts
                .find((v: any) => v.includes(item?.key))
                ?.split("[")[1]
                ?.split("]")[0];
              const variant = item?.variant[item?.key];
              cartTotal += variant?.price
                ? Number(variant?.price) *
                  (urlQuantity
                    ? parseInt(urlQuantity)
                    : searchLocalCart(variant?.sku) ?? 1)
                : 0;
              return {
                price: variant?.price ? variant?.price : 0,
                name: variant?.name,
                currency: item.currency,
                /* image:
                  item.type === "simple"
                    ? Array.isArray(variant?.images)
                      ? variant?.images[0]
                      : variant?.images ?? variant?.image
                    : variant?.image, */
                image: variant?.images[0] ? variant?.images[0] : "default.jpg",
                amount: urlQuantity
                  ? parseInt(urlQuantity)
                  : searchLocalCart(variant?.sku) ?? 1,
                id: variant?.id,
                sku: variant?.sku,
              };
            }),
            total: cartTotal,
          })
        );
      } else {
        //? Dynamically changing HTML Title of the application
        document.title = `${
          currentCart.length > 0 ? currentCart[0]?.name + " - " : ""
        }${apiResult.shop.name} - Powered by ${
          apiResult.merchant?.config?.merchant_name
        }`;
        //* In social-commerce mode Cart is NOT persisted
        //* And the Cart is handled in the src/pages/socialCommerce/SocialCommercePage.tsx
        store.dispatch(
          setCart({
            shop: apiResult.shop,
            cart: [],
            total: cartTotal,
          })
        );
      }
    } else {
      store.dispatch(
        setCart({
          shop: apiResult.shop,
          cart: [],
          total: cartTotal,
        })
      );
    }
  }

  store.dispatch(
    setIPCountry({ countryCode: user_data.device_country.country_code })
  );
  store.dispatch(setInitCountryCode(user_data.device_country.country_code));
  store.dispatch(setDeviceCountry(user_data.device_country));

  const { payment_method: paymentMethods } = apiResult;
  let addedPaymentMethodsToState;

  if (paymentMethods) {
    addedPaymentMethodsToState = store.dispatch(
      setAvailablePaymentMethods(paymentMethods)
    );
  }

  // Updating Payment Methods state
  try {
    if (addedPaymentMethodsToState) {
      updateStateFromPaymentMethodsBlock(shopper);
    }
  } catch (exception) {
    store.dispatch(
      setToastMessage({
        text: t("InitError"),
        type: "ERROR",
      })
    );
  }

  if (shop) {
    try {
      const defaultTheme = {
        app_layout: "1",
        header_theme: "default",
        intro_banner_theme: "default",
        product_details_theme: "default",
        user_info_theme: "default",
        payment_methods_theme: "default",
        order_summary_theme: "default",
        pay_button_theme: "default",
        footer_theme: "default",
      };

      const {
        id,
        currency,
        domain,
        subdomain,
        initial,
        name,
        shop_slug: slug,
        total_products: totalProducts,
        settings,
      } = shop;

      const theme = shop?.theme ? shop?.theme : defaultTheme;
      console.log(theme);

      store.dispatch(
        setShop({
          id,
          currency,
          domain,
          name,
          subdomain,
          initial,
          slug,
          totalProducts,
          settings,
          theme,
        })
      );
    } catch (exception) {
      // console.log("Possible error in the shop object", exception);
      // store.dispatch(
      //   setToastMessage({
      //     text: t("ShopError"),
      //     type: "ERROR",
      //   })
      // );
    }
  }

  // updating Shopper State
  try {
    if (shopper) {
      updateStateFromShopperObject(shopper);
    }
  } catch (exception) {
    console.log("Possible error in Shopper Object");
    if (accountPhone.country && accountPhone.number) {
      console.log("Attempting to verify OTP again");
      phoneNumberCheck(accountPhone.country, accountPhone.number);
    } else {
      console.log("Phone number not in state > Logging Out user");
      logOutUser();
    }
  }

  //? Sending statistics data to Analytics Endpoint at APP initilization 👇🏼
  submitStatisticsDataForAnalytics();
}
