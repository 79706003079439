import { ServiceProviderHandlerProps } from ".";
import {
  SelectedPaymentMethodSavedStatus,
  setPaymentIntent,
  setPublicKeyStripe,
  setSelectedPaymentSavedStatus,
  setStripeClientSecret,
  setStripeIntentType,
  setStripeReturnURL,
} from "../../../../store/reducers/paymentSlice";
import store from "../../../../store/store";

// if the selected payment method uses stripe as its payment service provider
export function handleStripeAsSelected({
  clientSecret,
  id,
  publicKey,
  returnURL,
  pspMethod,
}: ServiceProviderHandlerProps) {
  updateStripeGlobalStateValues(
    id,
    clientSecret,
    publicKey,
    returnURL,
    pspMethod
  );
}

function updateStripeGlobalStateValues(
  intentId: string,
  clientSecret: string,
  publicKey: string,
  returnURL: string,
  pspMethod: string
) {
  store.dispatch(
    setSelectedPaymentSavedStatus(
      SelectedPaymentMethodSavedStatus.UNSAVED_METHOD
    )
  );
  store.dispatch(setPaymentIntent(intentId));
  store.dispatch(setPublicKeyStripe(publicKey));
  store.dispatch(setStripeReturnURL(returnURL));
  store.dispatch(setStripeIntentType(pspMethod));
  store.dispatch(setStripeClientSecret(clientSecret));
}
