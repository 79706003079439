import React, { useEffect, useRef, useState } from "react";
import { OTPCodeInput } from "../otp-input/OTPCodeInput";
import { motion } from "framer-motion";
import { validateOTP } from "../../lib/api/otp-methods";
import { useSelector } from "react-redux";
import store, { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";
import {
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";

interface VerifyPhoneProps {
  hideSegment: () => void;
}

const backdropOpacityAnimation = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
  transition: {
    duration: 0.2,
    delay: 0.5,
  },
  exit: {
    opacity: 0,
  },
};

const slideUpAnimation = {
  animate: {
    transform: "translateY(0px)",
  },
  initial: {
    transform: "translateY(1000px)",
  },
  exit: {
    transform: "translateY(1000px)",
  },
  transition: {
    delay: 0,
    duration: 0.5,
  },
};

export const VerifyPhone: React.FC<VerifyPhoneProps> = ({ hideSegment }) => {
  const [verifyingOTP, setVerifyingOTP] = useState(false);

  const { t } = useTranslation();

  const otpId = useSelector(
    (state: RootState) => state.users.currentUser.verified.otp.id
  );
  const phoneNumber = useSelector(
    (state: RootState) => state.users.currentUser.verified.otp.phone_number
  );
  const countryCode = useSelector(
    (state: RootState) => state.users.currentUser.verified.otp.country_code
  );
  const verificationState = useSelector(
    (state: RootState) => state.users.currentUser.verified
  );

  const selfRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    selfRef.current.focus();
    store.dispatch(
      setUserVerified({
        ...verificationState,
        state: verificationStates.trying,
        firstPayment: "pending",
      })
    );
  }, []);

  useEffect(() => {
    if (verificationState.state === verificationStates.failed) {
      setVerifyingOTP(false);
    }
  }, [verificationState]);

  const verifyLaterHandler = () => {
    if (!verifyingOTP) {
      backdropOpacityAnimation.transition = {
        ...backdropOpacityAnimation.transition,
        delay: 0.5,
      };
      slideUpAnimation.transition = {
        ...slideUpAnimation.transition,
        delay: 0,
      };

      hideSegment();
    }
  };

  return (
    <motion.div ref={selfRef} className="verify-phone-number" tabIndex={0}>
      <motion.div
        key="backdrop"
        className="backdrop"
        {...backdropOpacityAnimation}
      ></motion.div>
      <motion.div
        key="phonenumberprompt"
        {...slideUpAnimation}
        className="verify-phone-number-prompt"
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
        }}
      >
        <div className="verify-phone-number-prompt-container">
          <h1>{t("VerifyPhoneTitle")}</h1>
          <p>{t("VerifyPhoneDescription")}</p>
          <div className="verifyLater">
            {verifyingOTP ? (
              <div style={{ textAlign: "center" }}>{t("Verifying")}...</div>
            ) : verificationState.state === verificationStates.failed ? (
              <>
                <span
                  onClick={verifyLaterHandler}
                  className="verifyLater__span2"
                >
                  ← {t("GoBack")}
                </span>
                <div
                  style={{
                    textAlign: "center",
                    color: "red",
                    paddingTop: "15px",
                  }}
                >
                  {t("InvalidOTP")}
                </div>
              </>
            ) : (
              <span onClick={verifyLaterHandler} className="verifyLater__span2">
                ← {t("GoBack")}
              </span>
            )}
          </div>

          <div className="otp-verify-phone">
            <OTPCodeInput
              onComplete={(code: string) => {
                store.dispatch(
                  setUserVerified({
                    ...verificationState,
                    state: verificationStates.trying,
                    firstPayment: "pending",
                  })
                );
                setVerifyingOTP(true);
                if (otpId) validateOTP(otpId, phoneNumber, countryCode, code);
              }}
            />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};
