/* ------api imports------- */
import {
  applicationJSONBodyBuilder,
  applicationWWWXRLEncodedBodyBuilder,
} from "../utils/body-builder";
import { RequestInfo } from "../../interfaces/RequestInfo";
import store from "../../store/store";
import {
  setLastStatisticsDataset,
  setStatisticsInitialSessionSavedFlag,
} from "../../store/reducers/statisticsSlice";
import { ANALYTICS_URL } from "../constants/urls";

/* ------api connection------- */
export async function submitStatisticsData(finalStatisticsDataSet: any) {
  let sendingData: any;
  const session_id = store.getState().statistics?.session_id;
  const initial_session_saved =
    store.getState().statistics?.initial_session_saved;
  sendingData = {
    session_id,
    analytics_data: applicationJSONBodyBuilder(finalStatisticsDataSet),
  };
  console.log("Final Statistics Data Set", finalStatisticsDataSet);
  try {
    const requestInfo: RequestInfo = {
      url: ANALYTICS_URL,
      method: initial_session_saved ? "PATCH" : "POST",
      body: applicationWWWXRLEncodedBodyBuilder(sendingData),
      headers: {
        Accept: "application/json",
        "Content-type": "application/x-www-form-urlencoded",
        Authorization: "",
      },
    };

    if (sendingData?.session_id && sendingData?.analytics_data) {
      const res: any = await fetch(requestInfo.url, {
        method: requestInfo.method,
        headers: requestInfo.headers,
        body: requestInfo.body,
      });

      const response = await res.json();

      if (response.success) {
        store.dispatch(setStatisticsInitialSessionSavedFlag(true));
        store.dispatch(setLastStatisticsDataset(finalStatisticsDataSet));
        console.log("Statistics Data sent -->");
      }
    }
  } catch (exception) {
    console.log("Error during request");
  }
}
