import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  initialAPICall,
  setupInitialState,
} from "../../../lib/api/initial-api-call";
import {
  applicationBrowserStates,
  setIsInitAPILoading,
  setLastSocialCommerceUrl,
  setToastMessage,
} from "../../../store/reducers/appSlice";
import {
  decrementItemNumber,
  incrementItemNumber,
  setCart,
  setFastcheckoutCartItems,
} from "../../../store/reducers/cartSlice";
import store, { RootState } from "../../../store/store";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { addShopperEvent } from "../../../lib/api/events-methods";
import FastCheckoutPageWrapper from "../../../components/page-wrappers/FastCheckoutPageWrapper";
import { setInteractionsPerSessionCount } from "../../../store/reducers/statisticsSlice";
import ProductDetailsThemeWise from "../../../components/theme-wise-switching/ProductDetailsThemeWise";
import Header from "../../../components/header/Header";
import CartBannerSC from "../../../components/socialCommerce/CartBannerSC";
import CartOperatorSC from "../../../components/socialCommerce/CartOperatorSC";
import ImageSliderSC from "../../../components/socialCommerce/ImageSliderSC";
import IntroBannerSC from "../../../components/socialCommerce/IntroBannerSC";
import ProductImagesSlider from "../../../components/socialCommerce/ProductImagesSlider";
import SplashScreen from "../../../components/splash-screen/SplashScreen";

const AICheckoutMobileLayout1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isDemoActive, setIsDemoActive] = useState(false);
  const [isStateUpdateComplete, setIsStateUpdateComplete] = useState(false);
  const [isHeartBtnActive, setIsHeartBtnActive] = useState(false);
  const [chosenProduct, setChosenProduct] = useState();
  const [isInvalidVariantSelected, setIsInvalidVariantSelected] =
    useState(false);
  const [isNoItemSelected, setIsNoItemSelected] = useState(false);
  const route = window.decodeURI(useLocation().pathname);

  const isInitAPILoading = useSelector(
    (state: RootState) => state.app.UI.isInitAPILoading
  );
  const allProducts: any = useSelector(
    (state: RootState) => state.cart.allProducts[0]
  );
  const demoCheckoutInitData = useSelector(
    (state: RootState) => state.app.demoCheckoutInitData
  );
  const fastcheckoutCartItems: any =
    useSelector((state: RootState) => state.cart.fastcheckoutCartItems) ?? [];
  const shop: any = useSelector((state: RootState) => state.cart.shop);
  const apiAttributeSkuMap: any = useSelector(
    (state: RootState) => state.cart.attributeSkuMap
  );
  const userEmail = useSelector(
    (state: RootState) => state.users.currentUser?.profile?.email
  );
  const lastSessionCallPayload = useSelector(
    (state: RootState) => state.payment?.lastSessionCallPayload
  );
  const settings: any = useSelector(
    (state: RootState) => state.cart.shop.settings
  );
  const showCookieConsent = useSelector(
    (state: RootState) => state.app.UI.showCookieConsent
  );
  const [productId, setProductId] = useState<any>(allProducts?.id);
  const [productName, setProductName] = useState<any>(allProducts?.name);
  const [productDescription, setProductDescription] = useState<any>(
    allProducts?.description
  );
  const [productPrice, setProductPrice] = useState<any>(
    allProducts?.display_price ?? 0.0
  );
  const [productImages, setProductImages] = useState<any>(
    allProducts?.images ?? []
  );
  const [localAttributeSkuMap, setLocalAttributeSkuMap] = useState<any>();
  const [defaultSelectedAttributes, setDefaultSelectedAttributes] =
    useState<any>();
  const [cartItemNumber, setCartItemNumber] = useState(
    fastcheckoutCartItems
      ?.find((v: any) => v.includes(chosenProduct))
      ?.split("[")[1]
      ?.split("]")[0] ?? 1
  );

  const productCurrency = allProducts?.currency;
  const productType = allProducts?.type;
  const attributes = allProducts?.attributes;
  let cartTotal: number = 0.0;
  let delivery: any = ""; //TODO

  /**
   *  increments the number of units of an item to purchase
   * @param { React.MouseEvent } event
   */
  function onIncrement(event: React.MouseEvent) {
    setCartItemNumber((prev: number) => prev + 1);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    store.dispatch(incrementItemNumber({ id: productId }));
  }

  /**
   *  decrements the number of units of an item to purchase
   * @param { React.MouseEvent } event
   */
  function onDecrement(event: React.MouseEvent) {
    if (cartItemNumber > 1) {
      setCartItemNumber((prev: number) => prev - 1);
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      store.dispatch(decrementItemNumber({ id: productId }));
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (route === "/demo/social-commerce") {
      if (
        demoCheckoutInitData?.shop?.name &&
        demoCheckoutInitData?.shop?.name !== "" &&
        demoCheckoutInitData.products[0]["demo-sku"].name &&
        demoCheckoutInitData.products[0]["demo-sku"].name !== "" &&
        demoCheckoutInitData.products[0]["demo-sku"].description &&
        demoCheckoutInitData.products[0]["demo-sku"].description !== "" &&
        demoCheckoutInitData.products[0]["demo-sku"].display_price &&
        demoCheckoutInitData.products[0]["demo-sku"].display_price !== "" &&
        demoCheckoutInitData.products[0]["demo-sku"].images
      ) {
        store.dispatch(setIsInitAPILoading(true));
        setIsDemoActive(true);
        setupInitialState(
          demoCheckoutInitData,
          applicationBrowserStates.NEW_BROWSER,
          route,
          undefined,
          true
        );
        store.dispatch(setLastSocialCommerceUrl(route));
        setTimeout(() => {
          store.dispatch(setIsInitAPILoading(false));
          setIsStateUpdateComplete(true);
        }, 1500);
      } else {
        navigate("/demo");
      }
    } else {
      //* Triggering Events API on user actions
      addShopperEvent("view_page", {
        url: `${window.location.href}`,
        page: "social_commerce",
        email: userEmail && userEmail !== "" ? userEmail : undefined,
      });

      setIsDemoActive(false);
      initialAPICall(route);
      store.dispatch(setLastSocialCommerceUrl(route));
      setIsStateUpdateComplete(true);
    }
  }, [route]);

  useEffect(() => {
    if (!allProducts) {
      setProductId(null);
      setProductName(null);
      setProductDescription(null);
      setProductPrice("");
      setProductImages([]);
      setChosenProduct(undefined);
    }
  }, []);

  useEffect(() => {
    console.log("UI > attribute_sku_map  : ", localAttributeSkuMap);
    console.log("API > attribute_sku_map  : ", apiAttributeSkuMap);
    if (!localAttributeSkuMap) {
      console.log("No combination selected :", localAttributeSkuMap);
      setChosenProduct(undefined);
    } else {
      for (let i of apiAttributeSkuMap) {
        let selectedVariant = i[localAttributeSkuMap];
        if (selectedVariant) {
          console.log(
            "selectedVariant : ",
            selectedVariant,
            "=",
            localAttributeSkuMap
          );
          setIsInvalidVariantSelected(false);
          setIsNoItemSelected(false);
          setChosenProduct(selectedVariant);
          break;
        } else {
          console.log(
            "Selected combination is not available :",
            localAttributeSkuMap
          );
          setIsInvalidVariantSelected(true);
          setChosenProduct(undefined);
        }
      }
    }
  }, [localAttributeSkuMap, allProducts]);

  const addToFastcheckoutCart = () => {
    if (chosenProduct) {
      //? Checking if the variant is already in the Cart with a different QTY
      if (
        fastcheckoutCartItems.filter((i: any) =>
          i.includes(`${chosenProduct}`)
        )[0] &&
        !fastcheckoutCartItems.includes(`${chosenProduct}[${cartItemNumber}]`)
      ) {
        let tempData = fastcheckoutCartItems.filter(
          (i: any) =>
            i !==
            fastcheckoutCartItems.filter((i: any) =>
              i.includes(`${chosenProduct}`)
            )[0]
        );
        store.dispatch(
          setFastcheckoutCartItems([
            ...tempData,
            `${chosenProduct}[${cartItemNumber}]`,
          ])
        );
        store.dispatch(
          setToastMessage({
            text: t("UpdatedCart"),
            type: "SUCCESS",
          })
        );
        let url = `/fastcheckout/${shop?.slug}/${[
          `${chosenProduct}[${cartItemNumber}]`,
          ...tempData,
        ].join()}`;

        //* Triggering Events API on user actions
        addShopperEvent("add_to_cart", {
          url: `${window.location.href}`,
          product: allProducts?.variant[chosenProduct]
            ? {
                id: allProducts?.variant[chosenProduct]?.id,
                sku: allProducts?.variant[chosenProduct]?.sku,
                name: allProducts?.variant[chosenProduct]?.name,
                price: allProducts?.variant[chosenProduct]?.price,
              }
            : undefined,
          quantity: cartItemNumber,
          email: userEmail && userEmail !== "" ? userEmail : undefined,
        });

        navigate(url);
      }
      //? Checking if the variant is already in the Cart with the same QTY
      else if (
        !fastcheckoutCartItems.includes(`${chosenProduct}[${cartItemNumber}]`)
      ) {
        store.dispatch(
          setFastcheckoutCartItems([
            ...fastcheckoutCartItems,
            `${chosenProduct}[${cartItemNumber}]`,
          ])
        );
        store.dispatch(
          setToastMessage({
            text: t("AddedToCart"),
            type: "SUCCESS",
          })
        );
        let url = `/fastcheckout/${shop?.slug}/${[
          `${chosenProduct}[${cartItemNumber}]`,
          ...fastcheckoutCartItems,
        ].join()}`;

        //* Triggering Events API on user actions
        addShopperEvent("add_to_cart", {
          url: `${window.location.href}`,
          product: allProducts?.variant[chosenProduct]
            ? {
                id: allProducts?.variant[chosenProduct]?.id,
                sku: allProducts?.variant[chosenProduct]?.sku,
                name: allProducts?.variant[chosenProduct]?.name,
                price: allProducts?.variant[chosenProduct]?.price,
              }
            : undefined,
          quantity: cartItemNumber,
          email: userEmail && userEmail !== "" ? userEmail : undefined,
        });

        navigate(url);
      } else {
        store.dispatch(
          setToastMessage({
            text: t("UpdatedCart"),
            type: "SUCCESS",
          })
        );
        let url = `/fastcheckout/${shop?.slug}/${fastcheckoutCartItems.join()}`;

        //* Triggering Events API on user actions
        addShopperEvent("add_to_cart", {
          url: `${window.location.href}`,
          product: allProducts?.variant[chosenProduct]
            ? {
                id: allProducts?.variant[chosenProduct]?.id,
                sku: allProducts?.variant[chosenProduct]?.sku,
                name: allProducts?.variant[chosenProduct]?.name,
                price: allProducts?.variant[chosenProduct]?.price,
              }
            : undefined,
          quantity: cartItemNumber,
          email: userEmail && userEmail !== "" ? userEmail : undefined,
        });

        navigate(url);
      }
    }
  };

  useEffect(() => {
    if (
      allProducts &&
      (allProducts?.variant.length > 0 ||
        Object.keys(allProducts?.variant).length > 0)
    ) {
      if (productType === "simple") {
        const simpleProductVariant = allProducts?.variant[allProducts?.key];
        if (simpleProductVariant) {
          setDefaultSelectedAttributes(undefined);
          setChosenProduct(allProducts?.key);
        } else {
          setDefaultSelectedAttributes(undefined);
          setChosenProduct(undefined);
          store.dispatch(
            setToastMessage({
              text: t("ProductsError"),
              type: "ERROR",
            })
          );
        }
      } else if (productType === "variable") {
        const variant = allProducts?.variant[allProducts?.key];
        if (variant) {
          setDefaultSelectedAttributes(variant?.attributes);
          setChosenProduct(allProducts?.key);
        } else {
          //? changed below due to new requirement :> https://app.asana.com/0/0/1203438269462334/f
          // setIsNoItemSelected(true);
          // setDefaultSelectedAttributes(undefined);
          // setChosenProduct(undefined);
          let firstVariant: any = Object.values(allProducts?.variant)[0];
          setDefaultSelectedAttributes(firstVariant?.attributes);
          setChosenProduct(firstVariant?.sku);
        }
      }
    }
  }, [allProducts]);

  useEffect(() => {
    if (!isInitAPILoading && isStateUpdateComplete) {
      if (chosenProduct) {
        const variant = allProducts?.variant[chosenProduct];
        if (variant) {
          const id = variant?.id;
          const name = variant?.name;
          const description = variant?.description;
          const price = variant?.price ? (variant?.price).toFixed(2) : 0.0;
          const images =
            allProducts?.type === "simple"
              ? allProducts?.images
              : variant?.images;
          const urlQuantity = fastcheckoutCartItems
            .find((v: any) => v.includes(chosenProduct))
            ?.split("[")[1]
            ?.split("]")[0];

          setCartItemNumber(urlQuantity ? parseInt(urlQuantity) : 1);
          setProductId(id);
          setProductName(name);
          setProductDescription(description);
          setProductPrice(price);
          setProductImages(images);
          cartTotal += variant?.price
            ? Number(variant?.price) * (urlQuantity ? parseInt(urlQuantity) : 1)
            : 0;
          store.dispatch(
            setCart({
              shop,
              cart: [
                {
                  price: variant?.price ? variant?.price : 0,
                  name: variant?.name,
                  currency: allProducts?.currency,
                  image: variant?.image,
                  amount: urlQuantity ? parseInt(urlQuantity) : 1,
                  id: variant?.id,
                  sku: variant?.sku,
                },
              ],
              total: cartTotal,
            })
          );
        } else {
          setProductId(allProducts?.id);
          setProductName(allProducts?.name);
          setProductDescription(allProducts?.description);
          setProductPrice(allProducts?.display_price ?? 0.0);
          setProductImages(allProducts?.images);
          store.dispatch(
            setCart({
              shop,
              cart: [],
              total: cartTotal,
            })
          );
        }
      } else {
        setProductId(allProducts?.id);
        setProductName(allProducts?.name);
        setProductDescription(allProducts?.description);
        setProductPrice(allProducts?.display_price ?? 0.0);
        setProductImages(allProducts?.images);
        store.dispatch(
          setCart({
            shop,
            cart: [],
            total: cartTotal,
          })
        );
      }
    }
  }, [chosenProduct, allProducts, isInitAPILoading, isStateUpdateComplete]);

  if (isInitAPILoading) {
    return (
      <React.Suspense fallback={<div></div>}>
        <AnimatePresence>
          <SplashScreen />
        </AnimatePresence>
      </React.Suspense>
    );
  } else {
    return (
      <React.Suspense fallback={<div className="socialCommerce"></div>}>
        <Header hideCompactCart={true} />
        <div className="socialCommerce">
          <div className="max-width-container">
            <div className="max-width-container__content">
              <IntroBannerSC />
              {
                <>
                  {fastcheckoutCartItems.length > 0 && (
                    <CartBannerSC
                      isHeartFilled={isHeartBtnActive}
                      cartNumber={fastcheckoutCartItems.length}
                      onCartClick={() => {
                        let url = `/fastcheckout/${
                          shop?.slug
                        }/${fastcheckoutCartItems.join()}`;
                        if (!isDemoActive) navigate(url);
                      }}
                      onHeartClick={() => {
                        setIsHeartBtnActive(!isHeartBtnActive);
                      }}
                    />
                  )}
                  <div className="socialCommerce__desktop--container">
                    <div className="socialCommerce__desktop--container-left">
                      {allProducts && !showCookieConsent && (
                        <>
                          <div className="socialCommerce__desktop--container-left-mobileSlider">
                            <ImageSliderSC
                              imageArray={productImages}
                              isDemoActive={isDemoActive}
                            />
                          </div>
                          <div className="socialCommerce__desktop--container-left-desktopSlider">
                            <ProductImagesSlider
                              images={productImages}
                              isDemoActive={isDemoActive}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="socialCommerce__desktop--container-right">
                      {/* {allProducts && (
                        <ProductInfoSC
                          title={productName}
                          currency={
                            getSymbolFromCurrency(productCurrency) ?? ""
                          }
                          price={productPrice}
                          productDetails={productDescription}
                          deliveryDetails={delivery}
                        />
                      )}
                      {isNoItemSelected && !isInvalidVariantSelected && (
                        <p
                          className="socialCommerce__variantNotAvailable"
                          style={{ marginBottom: 0 }}
                        >
                          {t("NoItemsChosen")}
                        </p>
                      )}
                      {isInvalidVariantSelected && (
                        <p
                          className="socialCommerce__variantNotAvailable"
                          style={{ marginBottom: 0 }}
                        >
                          {t("VariantCombinationNotFound")}
                        </p>
                      )}
                      {productType === "variable" && attributes && (
                        <VariantSelecter
                          attributes={attributes}
                          defaultSelectedAttributes={defaultSelectedAttributes}
                          setMap={setLocalAttributeSkuMap}
                        />
                      )} */}

                      <ProductDetailsThemeWise
                        data={{
                          allProducts,
                          productName,
                          productCurrency,
                          productPrice,
                          productDescription,
                          delivery,
                          isNoItemSelected,
                          isInvalidVariantSelected,
                          attributes,
                          defaultSelectedAttributes,
                          productType,
                        }}
                        setLocalAttributeSkuMap={setLocalAttributeSkuMap}
                      />
                      {chosenProduct && (
                        <CartOperatorSC
                          onIncrement={onIncrement}
                          onDecrement={onDecrement}
                          cartItemNumber={cartItemNumber}
                          onAddToCartClick={
                            !isDemoActive ? addToFastcheckoutCart : () => {}
                          }
                          isDisabled={lastSessionCallPayload === "loading"}
                        />
                      )}
                      {!allProducts && (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: 14,
                            color: "red",
                            paddingTop: "2vh",
                          }}
                        >
                          <span> {t("SelectedProductUnavailable")}</span>
                        </div>
                      )}
                      <h3
                        onClick={() => {
                          if (!isDemoActive) navigate(`/${shop?.slug}`);
                          dispatch(setInteractionsPerSessionCount());
                        }}
                        className="socialCommerce__browseMoreLink"
                        style={{ marginBottom: 0 }}
                      >
                        {t("BrowseMore")}
                      </h3>

                      {settings?.webshop_url && (
                        <h3 className="socialCommerce__goToLink">
                          {t("Or-go-to")}{" "}
                          <a
                            target={"_blank"}
                            href={settings?.webshop_url}
                            rel="noreferrer"
                          >
                            {t("Webshop")}
                          </a>
                        </h3>
                      )}
                    </div>
                  </div>
                  <div className="divider">
                    <div className="divider-line"></div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
        {!showCookieConsent && (
          <FastCheckoutPageWrapper showHeader={false} theme="normal" />
        )}
      </React.Suspense>
    );
  }
};

export default AICheckoutMobileLayout1;
