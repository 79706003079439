import { truncate } from "../../lib/utils/helper-functions";
import "../../sass/components/socialCommerce/singleProductSC.scss";
import plusSVG from "../../assets/svg/plus-black-SVG.svg";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

interface Props {
  image: string;
  name: string;
  price: string | number;
  type: string;
  cartItemsNum: number;
  currency: string | undefined;
  onClick: (e: any) => void;
  onAddToCartClick: () => void;
}

const SingleProductSC = ({
  image,
  name,
  price,
  type,
  cartItemsNum,
  currency,
  onClick,
  onAddToCartClick,
}: Props) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  return (
    <div className="singleProductSC">
      <div className="singleProductSC__top">
        <div
          className="singleProductSC__top--img"
          onClick={onClick}
          style={{ marginRight: 7, cursor: "pointer" }}
        >
          {/* <img
            loading="lazy"
            src={image}
            alt={name}
            className={!isImgLoaded ? "singleProductSC__top--placeholder" : ""}
            onLoad={() => {
              setIsImgLoaded(true);
            }}
            onError={() => {
              setIsImgLoaded(true);
            }}
            style={{ cursor: "pointer" }}
          /> */}
          <LazyLoadImage
            loading="lazy"
            src={image}
            alt={name}
            className={!isImgLoaded ? "singleProductSC__top--placeholder" : ""}
            onLoad={() => {
              setIsImgLoaded(true);
            }}
            onError={() => {
              setIsImgLoaded(true);
            }}
            style={{ cursor: "pointer" }}
          />
        </div>
        {/* //* Only Simple products can be directly added to Cart via Overview Page */}
        {type === "simple" && (
          <button
            className="singleProductSC__top--buttons-plusBtn"
            onClick={onAddToCartClick}
          >
            <LazyLoadImage loading="lazy" src={plusSVG} alt="add" />
          </button>
        )}
      </div>
      <div
        className="singleProductSC__bottom"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <div className="singleProductSC__bottom--textHolder">
          <h3>{truncate(name, 38)}</h3>
        </div>
        <span>
          {currency} {price}
        </span>
      </div>
    </div>
  );
};

export default SingleProductSC;
