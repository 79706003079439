import { AdupUserAddress } from "../../../interfaces/AdupUserAddress";
import { updateExistingAddressById } from "../../../store/reducers/userSlice";
import store from "../../../store/store";
import { SHOPPER_ADDRESS_URL } from "../../constants/urls";
import { buildHeadersURLEncoded } from "../../utils/build-headers";
import { setToastMessage } from "../../../store/reducers/appSlice";
import { toastHandler } from "../../utils/toast-handler";
import { t } from "i18next";
import { submitLog } from "../log";

export async function patchUserAddress(
  address: AdupUserAddress
): Promise<boolean> {
  // send request
  const addressId = await sendPatchUserAddressRequest(address);

  // save state
  if (addressId) {
    store.dispatch(updateExistingAddressById({ ...address, id: addressId }));
    return true;
  }

  return false;
}
async function sendPatchUserAddressRequest(
  address: AdupUserAddress
): Promise<string | null> {
  const key = store.getState().users.currentUser.token;
  const shopId = store.getState().cart?.shop?.id;

  const params = `/${address.id}?address=${JSON.stringify({
    house_number: address.number,
    street: address.street,
    street2: address.street2,
    city: address.city,
    province: address.province,
    postcode: address.postcode,
    country: address.country?.country ?? address.country,
  })}&shop_id=${shopId}`;

  if (key) {
    try {
      const response = await fetch(SHOPPER_ADDRESS_URL + params, {
        method: "PATCH",
        headers: buildHeadersURLEncoded(key),
      });

      // trigger API Errors as Toasts
      const res = await response.json();
      if (res) {
        toastHandler(res, store.dispatch, setToastMessage);
      }
      if (res?.success) {
        const {
          result: { id },
        } = res;

        return id;
      }
      return null;
    } catch (e) {
      console.log(e);
      await submitLog("error", t("UpdateAddressError"));
      store.dispatch(
        setToastMessage({
          text: t("UpdateAddressError"),
          type: "ERROR",
        })
      );
      return null;
    }
  } else {
    return null;
  }
}
