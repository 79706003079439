import React, { ReactElement } from "react";

type Props = {
  button: ReactElement | null;
  headingText: string;
  closeCartFunction?: () => void;
};

const HeadingWithRightButton: React.FC<Props> = ({
  button,
  headingText,
  closeCartFunction,
  children,
}) => {
  return (
    <span className="heading-with-button-to-right">
      <div className="heading-with-button-to-right__left">
        {children}
        <h2 onClick={closeCartFunction}>{headingText}</h2>
      </div>
      <div className="heading-with-button-to-right__right">{button}</div>
    </span>
  );
};

export default HeadingWithRightButton;
