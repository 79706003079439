import { useState, useEffect } from "react";
import arrowDark from "../../assets/svg/arrowDark.svg";
import "../../sass/components/profilePage/listNavigation.scss";
import OrderDetails from "./OrderDetails";
import { getAllOrders } from "../../lib/api/orders-methods";
import store from "../../store/store";
import { setToastMessage } from "../../store/reducers/appSlice";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { submitLog } from "../../lib/api/log";

const OrderList = () => {
  const { t } = useTranslation();
  const [orderData, setOrderData] = useState([]);
  const [isOrderPopupOpen, setIsOrderPopupOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();
  const [loading, setLoading] = useState("pending");

  /* ----------- pagination state start ------------ */
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalOrderRecords, setTotalOrderRecords] = useState(0);
  const itemsPerPage = 8;
  /* ----------- pagination state end -------------- */

  const orderPopupToggle = () => {
    setIsOrderPopupOpen(!isOrderPopupOpen);
  };

  /* ----------------API Call Start -----------------*/

  const fetchAllOrders = async () => {
    try {
      const res = await getAllOrders(currentPageNumber, itemsPerPage);

      if (!res) {
        setLoading("error");
      }
      if (res?.data?.length === 0) {
        setLoading("empty");
      }
      if (res) {
        setLoading("success");
        setTotalOrderRecords(res?.total);
        setOrderData(res?.data);
      }
    } catch (error) {
      console.log("Orders Get All API Error :", error);
      await submitLog("error", "Get All Orders API Error");
      store.dispatch(
        setToastMessage({
          text: t("OrderError"),
          type: "ERROR",
        })
      );
    }
  };

  useEffect(() => {
    fetchAllOrders();
  }, [currentPageNumber]);

  /* ----------------API Call End -------------------*/

  /* ----------- pagination stuf start ------------ */
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(totalOrderRecords / itemsPerPage));
  }, [itemOffset, itemsPerPage, orderData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setCurrentPageNumber(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % totalOrderRecords;
    setItemOffset(newOffset);
  };
  /* ----------- pagination stuf end   ------------ */

  return (
    <>
      <div className="listNavigation-main">
        {loading === "pending" && (
          <div className="loading-status">{t("Loading")}</div>
        )}
        {loading === "error" && (
          <div className="loading-status">{t("ErrorFetchingData")}</div>
        )}
        {loading === "empty" && (
          <div className="loading-status">{t("NoOrdersYet")}</div>
        )}
        {loading === "success" && (
          <div className="listNavigation">
            <h2 className="title">{t("PastOrders")}</h2>
            <div className="listContainer">
              {/* --- */}
              {orderData?.map((item: any, i: any) => {
                return (
                  <div
                    onClick={() => {
                      orderPopupToggle();
                      setSelectedOrder(item);
                    }}
                    key={i}
                    className="listItem"
                  >
                    <div className="listItem-top">
                      <div className="listItem__title">
                        <h2>{`${item?.shop_name} (${t("OrderId")}: ${
                          item?.id
                        })`}</h2>
                        {/* <span>{item?.id }</span> */}
                      </div>
                      <div className="listItem__button">
                        <LazyLoadImage
                          loading="lazy"
                          src={arrowDark}
                          alt="arrow-dark"
                        />
                      </div>
                    </div>
                    <div className="listItem-bottom" />
                  </div>
                );
              })}
              {/* --- */}
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel={t(`${"Next"} >`)}
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              previousLabel={t(`< ${"Previous"}`)}
              renderOnZeroPageCount={undefined}
              marginPagesDisplayed={2}
              containerClassName="pagination"
              pageLinkClassName="pagination__page-num"
              previousLinkClassName="pagination__page-num"
              nextLinkClassName="pagination__page-num"
              activeLinkClassName="pagination__active"
              /* breakClassName="page-item"
            breakLinkClassName="page-link" */
            />
          </div>
        )}
      </div>

      {isOrderPopupOpen && (
        <OrderDetails selectedOrder={selectedOrder} hide={orderPopupToggle} />
      )}
    </>
  );
};

export default OrderList;
