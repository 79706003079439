/* eslint-disable camelcase */
import { PAYMENT_PROCESS_URL } from "../constants/urls";
import store from "../../store/store";
import {
  SelectedPaymentMethodSavedStatus,
  setEncryptedBuckarooCardData,
  setPaymentErrorOccurred,
  setSelectedPaymentMethodOption,
} from "../../store/reducers/paymentSlice";
import {
  applicationUserType,
  disableFastCheckoutUISegment,
  enableFastCheckoutUISegment,
  initialHiddenSegments,
  setIsPayButtonLoading,
  setToastMessage,
  updateUserType,
} from "../../store/reducers/appSlice";
import { toastHandler } from "../utils/toast-handler";
import {
  setUserToken,
  setUserVerified,
  verificationStates,
} from "../../store/reducers/userSlice";
import { t } from "i18next";
import { getKeyByValue } from "../utils/helper-functions";
import updateStateFromShopperObject from "../utils/shopper-object-to-state";
import updateStateFromPaymentMethodsBlock from "../utils/payment-methods-to-state";
import { submitLog } from "./log";
import { setGift } from "../../store/reducers/cartSlice";

/**
 *@param { any } params
 */
export async function handlePaymentRequest() {
  const verificationState = store.getState().users.currentUser.verified;
  if (verificationState?.state === verificationStates.pending) {
    console.log("Phone Number verification Pending");
    store.dispatch(
      setToastMessage({
        text: t("VerifyPrompt"),
        type: "INFO",
      })
    );
  } else {
    try {
      const processResponse = await processUserPayment();

      if (
        processResponse?.session?.action === "frontend" ||
        processResponse?.action === "frontend" ||
        processResponse?.session === "frontend" ||
        processResponse?.session?._links?.checkout ||
        typeof processResponse?.session?.redirect_url === "string"
      ) {
        if (
          processResponse?.session?.return_url ||
          processResponse?.return_url ||
          processResponse?.session?._links?.checkout?.href ||
          processResponse?.session?.redirect_url
        ) {
          let redirectURL =
            processResponse?.session?.return_url ??
            processResponse?.return_url ??
            processResponse?.session?._links?.checkout?.href ??
            processResponse?.session?.redirect_url;

          // Clearing encrypted Card details from state after payment is successful
          store.dispatch(
            setEncryptedBuckarooCardData({
              value: "",
              issuer: "",
            })
          );

          // Clearing selected gift from state after payment is successful
          store.dispatch(setGift("null"));

          window.parent.location.replace(redirectURL);

          // if (window.top !== window.self) {
          //   console.log("PAYMENT : inside an iframe");
          //   store.dispatch(setIsPaymentOnPopUpViaIframe(true));
          //   //* open a pop-up window for payment handling since app is loaded on an iFrame & payments are not allowed in iFrames
          //   // window.open(
          //   //   redirectURL,
          //   //   "_blank",
          //   //   "toolbar=no,location=no,top=80, left=420, status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=700"
          //   // );
          //   window.open(redirectURL, "_blank");
          // } else {
          //   console.log("PAYMENT : NOT inside an iframe");
          //   store.dispatch(setIsPaymentOnPopUpViaIframe(false));
          //   //* simply redirect to payment page since app is not loaded on an iFrame
          //   window.location.replace(redirectURL);
          // }
        }
      }
      // else {
      //   await submitLog("error", t("ProcessError"));
      //   store.dispatch(
      //     setToastMessage({
      //       text: t("ProcessError"),
      //       type: "ERROR",
      //     })
      //   );
      // }
      store.dispatch(setIsPayButtonLoading(false));
      store.dispatch(setPaymentErrorOccurred(false));
    } catch (e: any) {
      console.log(e);
      store.dispatch(setIsPayButtonLoading(false));
      store.dispatch(setPaymentErrorOccurred(false));
      await submitLog("error", t("ProcessError"));
      store.dispatch(
        setToastMessage({
          text: e ? e.toString() : t("ProcessError"),
          type: "ERROR",
        })
      );
    }
  }
}

async function processUserPayment() {
  try {
    store.dispatch(setIsPayButtonLoading(true));
    const { token, accountPhone, profile } = store.getState().users.currentUser;
    const verificationState = store.getState().users.currentUser.verified;
    const { paymentMethodOptionSelected, createAccountFlag } =
      store.getState().payment;
    const shippingAddress =
      store.getState().users?.currentUser?.shipping?.shippingAddress;
    const billingAddress =
      store.getState().users?.currentUser?.shipping?.billingAddress;
    const countryCode = accountPhone.country.dialCode.replace("+", "");
    const cartItems = store.getState().cart.items;
    const grandTotal = store.getState().cart.grandTotal;
    const savedPaymentAttributes =
      store.getState().payment?.savedPaymentAttributes;
    const cart: any = [];

    const shop_id = store.getState().cart.shop.id;

    for (const item of cartItems) {
      cart.push({ sku: item.sku, qty: item.amount });
    }

    // set gift in cart if selected
    if (store.getState().cart.gift !== "null") {
      cart.push({ sku: store.getState().cart.gift, qty: 1, gift: 1 });
    }

    const cartString = JSON.stringify(cart);
    let paymentMethodType = "";
    let bankOptions: any;
    let paymentMethodGateway = "";
    const {
      selectedPaymentMethodSaveState,
      encryptedBuckarooCardData,
      encryptedMollieCardToken,
      bicNumber,
      ibanNumber,
    } = store.getState().payment;

    if (
      selectedPaymentMethodSaveState ===
      SelectedPaymentMethodSavedStatus.SAVED_METHOD
    ) {
      paymentMethodType =
        store.getState().payment.savedPaymentMethodSelected.type;
      bankOptions = store.getState().payment.savedPaymentMethodSelected.banks;
      paymentMethodGateway = store
        .getState()
        .payment.savedPaymentMethodSelected.serviceProvider.toLocaleLowerCase();
    } else {
      paymentMethodType =
        store.getState().payment.unsavedPaymentMethodSelected.type;
      bankOptions = store.getState().payment.unsavedPaymentMethodSelected.banks;
      paymentMethodGateway = store
        .getState()
        .payment.unsavedPaymentMethodSelected.serviceProvider.toLocaleLowerCase();
    }

    const saveShopperObject = {
      country_code: countryCode,
      phone_number: accountPhone?.number,
      first_name: profile?.name?.first,
      last_name: profile?.name?.second,
      shipping_address: {
        house_number: shippingAddress?.number,
        street: shippingAddress?.street,
        street2: shippingAddress?.street2,
        city: shippingAddress?.city,
        province: shippingAddress?.province,
        postcode: shippingAddress?.postcode,
        country: shippingAddress?.country,
      },
      billing_address: {
        house_number:
          billingAddress?.number !== ""
            ? billingAddress?.number
            : shippingAddress?.number,
        street:
          billingAddress?.street !== ""
            ? billingAddress?.street
            : shippingAddress?.street,
        street2:
          billingAddress?.street2 !== ""
            ? billingAddress?.street2
            : shippingAddress?.street2,
        city:
          billingAddress?.city !== ""
            ? billingAddress?.city
            : shippingAddress?.city,
        province:
          billingAddress?.province !== ""
            ? billingAddress?.province
            : shippingAddress?.province,
        postcode:
          billingAddress?.postcode !== ""
            ? billingAddress?.postcode
            : shippingAddress?.postcode,
        country:
          billingAddress?.country !== ""
            ? billingAddress?.country
            : shippingAddress?.country,
      },
      email: profile?.email,
    };

    const updateShopperObject = {
      country_code: countryCode,
      phone_number: accountPhone?.number,
      first_name: profile?.name?.first,
      last_name: profile?.name?.second,
      shipping_address_id:
        shippingAddress?.id !== "" ? shippingAddress?.id : undefined,
      billing_address_id:
        billingAddress?.id !== ""
          ? billingAddress?.id
          : shippingAddress?.id !== ""
          ? shippingAddress?.id
          : undefined,
      email: profile?.email,
    };

    let paymentAttributes;
    let paymentDetailsObject;
    const requireFields =
      cartString &&
      countryCode &&
      accountPhone?.number &&
      profile?.name?.first &&
      profile?.name?.second &&
      shippingAddress?.number &&
      shippingAddress?.street &&
      shippingAddress?.city &&
      shippingAddress?.province &&
      shippingAddress?.postcode &&
      shippingAddress?.country &&
      profile?.email &&
      paymentMethodType &&
      paymentMethodGateway;

    if (!requireFields) {
      // Prevent Process API execution without required fields
      store.dispatch(setIsPayButtonLoading(false));
      store.dispatch(setPaymentErrorOccurred(false));
      return null;
    } else {
      if (paymentMethodGateway === "buckaroo") {
        console.log("paymentMethodGateway : ", paymentMethodGateway);
        if (paymentMethodType === "ideal") {
          // For iDeal ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            ServiceList: [
              {
                Name: "ideal",
                Action: "Pay",
                Parameters: [
                  {
                    Name: "issuer",
                    Value:
                      paymentMethodOptionSelected &&
                      paymentMethodOptionSelected !== ""
                        ? paymentMethodOptionSelected
                        : savedPaymentAttributes?.ideal_bank
                        ? bankOptions[savedPaymentAttributes?.ideal_bank]
                        : bankOptions
                        ? bankOptions[Object.keys(bankOptions)[0]]
                        : "",
                  },
                ],
              },
            ],
          };
        } else if (paymentMethodType === "card") {
          // For Card ( buckaroo )👇🏼
          if (
            encryptedBuckarooCardData.issuer &&
            encryptedBuckarooCardData.issuer !== "" &&
            encryptedBuckarooCardData.value &&
            encryptedBuckarooCardData.value !== ""
          ) {
            paymentDetailsObject = {
              ServiceList: [
                {
                  Name: encryptedBuckarooCardData.issuer,
                  Action: "PayEncrypted",
                  Version: 0,
                  Parameters: [
                    {
                      Name: "EncryptedCardData",
                      GroupType: "", // ??
                      GroupID: "", // ??
                      Value: encryptedBuckarooCardData.value,
                    },
                  ],
                },
              ],
            };
          } else {
            store.dispatch(
              setToastMessage({
                text: t("EmptyPaymentDetailsError"),
                type: "ERROR",
              })
            );
            return;
          }
        } else if (paymentMethodType === "giropay") {
          // For Giropay ( buckaroo )👇🏼
          if (
            bicNumber &&
            bicNumber !== "" &&
            ibanNumber &&
            ibanNumber !== ""
          ) {
            paymentDetailsObject = {
              ServiceList: [
                {
                  Name: "giropay",
                  Action: "Pay",
                  Parameters: [
                    {
                      Name: "bic",
                      Value: bicNumber,
                    },
                    {
                      Name: "CustomerIBAN",
                      Value: ibanNumber,
                    },
                  ],
                },
              ],
            };
          } else {
            store.dispatch(
              setToastMessage({
                text: t("EmptyPaymentDetailsError"),
                type: "ERROR",
              })
            );
            return;
          }
        } else if (paymentMethodType === "sofort") {
          // For sofortueberweisung ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            ServiceList: [
              {
                Name: "sofortueberweisung",
                Action: "Pay",
              },
            ],
          };
        } else if (paymentMethodType === "eps") {
          // For eps ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            ServiceList: [
              {
                Name: "eps",
                Action: "Pay",
              },
            ],
          };
        } else if (paymentMethodType === "p24") {
          // For przelewy24 ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            ServiceList: [
              {
                Name: "Przelewy24",
                Action: "Pay",
                Parameters: [
                  {
                    Name: "CustomerEmail",
                    Value: profile?.email,
                  },
                  {
                    Name: "CustomerFirstName",
                    Value: profile?.name?.first,
                  },
                  {
                    Name: "CustomerLastName",
                    Value: profile?.name?.second,
                  },
                ],
              },
            ],
          };
        } else if (paymentMethodType === "bancontact") {
          // For bancontactmrcash ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            ServiceList: [
              {
                Name: "bancontactmrcash",
                Action: "Pay",
                Parameters: [
                  {
                    Name: "savetoken",
                    Value: "true",
                  },
                ],
              },
            ],
          };
        } else if (paymentMethodType === "klarna") {
          // For klarnakp ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            ServiceList: [
              {
                Name: "klarnakp",
                Action: "Pay",
                Parameters: [
                  {
                    Name: "ArticleNumber",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "12345", // ?? //TODO
                  },
                  {
                    Name: "ArticleQuantity",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "1", // ?? //TODO
                  },
                  {
                    Name: "ReservationNumber",
                    Value: "2377577xxx", // ?? //TODO
                  },
                  {
                    Name: "Street",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value:
                      billingAddress?.street !== ""
                        ? billingAddress?.street
                        : shippingAddress?.street,
                  },
                  {
                    Name: "VatPercentage",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "7", // ?? //TODO
                  },
                  {
                    Name: "Identifier",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "Articlenumber12345", // ?? //TODO
                  },
                  {
                    Name: "FirstName",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: profile?.name?.first,
                  },
                  {
                    Name: "LastName",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: profile?.name?.second,
                  },
                  {
                    Name: "StreetNumber",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: 90, // ?? //TODO
                  },
                  {
                    Name: "City",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value:
                      billingAddress?.city !== ""
                        ? billingAddress?.city
                        : shippingAddress?.city,
                  },
                  {
                    Name: "Country",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value:
                      billingAddress?.country !== ""
                        ? billingAddress?.country?.iso2
                        : shippingAddress?.country?.iso2,
                  },
                ],
              },
            ],
          };
        } else if (paymentMethodType === "billink") {
          // For billink ( buckaroo ) 👇🏼
          paymentDetailsObject = {
            ServiceList: [
              {
                Name: "Billink",
                Action: "Pay",
                Parameters: [
                  {
                    Name: "Trackandtrace",
                    Value: "TR0F123456789", // ?? //TODO
                  },
                  {
                    Name: "VATNumber",
                    Value: "2", // ?? //TODO
                  },
                  {
                    Name: "Quantity",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "1", // ?? //TODO
                  },
                  {
                    Name: "Identifier",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "Articlenumber12345", // ?? //TODO
                  },
                  {
                    Name: "Description",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "Blue Toy Car", // ?? //TODO
                  },
                  {
                    Name: "VatPercentage",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "7", // ?? //TODO
                  },
                  {
                    Name: "GrossUnitPriceIncl",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "10", // ?? //TODO
                  },
                  {
                    Name: "GrossUnitPriceExcl",
                    GroupType: "Article",
                    GroupID: "1",
                    Value: "5", // ?? //TODO
                  },
                  {
                    Name: "Category",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: "B2C", // ?? //TODO
                  },
                  {
                    Name: "CareOf",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: "John Smith", // ?? //TODO
                  },
                  {
                    Name: "ChamberOfCommerce",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: "Kvk123456789", // ?? //TODO
                  },
                  {
                    Name: "FirstName",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: profile?.name?.first,
                  },
                  {
                    Name: "Initials",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: "T", // ?? //TODO
                  },
                  {
                    Name: "LastName",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: profile?.name?.second,
                  },
                  {
                    Name: "Salutation",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: "Male", // ?? //TODO
                  },
                  {
                    Name: "Street",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value:
                      billingAddress?.street !== ""
                        ? billingAddress?.street
                        : shippingAddress?.street,
                  },
                  {
                    Name: "StreetNumber",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: 90, // ?? //TODO
                  },
                  {
                    Name: "PostalCode",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value:
                      billingAddress?.postcode !== ""
                        ? billingAddress?.postcode
                        : shippingAddress?.postcode,
                  },
                  {
                    Name: "City",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value:
                      billingAddress?.city !== ""
                        ? billingAddress?.city
                        : shippingAddress?.city,
                  },
                  {
                    Name: "Country",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value:
                      billingAddress?.country !== ""
                        ? billingAddress?.country?.iso2
                        : shippingAddress?.country?.iso2,
                  },
                  {
                    Name: "MobilePhone",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: accountPhone?.number,
                  },
                  {
                    Name: "Email",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: profile?.email,
                  },
                  {
                    Name: "BirthDate",
                    GroupType: "BillingCustomer",
                    GroupID: "",
                    Value: "01-01-1990", // ?? //TODO
                  },
                  {
                    Name: "Street",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: shippingAddress?.street,
                  },
                  {
                    Name: "StreetNumber",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: 80, // ?? //TODO
                  },
                  {
                    Name: "PostalCode",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: shippingAddress?.postcode,
                  },
                  {
                    Name: "City",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: shippingAddress?.city,
                  },
                  {
                    Name: "CareOf",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: "John Smith", // ?? //TODO
                  },
                  {
                    Name: "FirstName",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: profile?.name?.first,
                  },
                  {
                    Name: "LastName",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: profile?.name?.second,
                  },
                  {
                    Name: "Country",
                    GroupType: "ShippingCustomer",
                    GroupID: "",
                    Value: shippingAddress?.country?.iso2,
                  },
                ],
              },
            ],
          };
        }
      } else if (
        paymentMethodGateway === "mollie" ||
        paymentMethodGateway === "aduppay"
      ) {
        console.log("paymentMethodGateway : ", paymentMethodGateway);
        if (paymentMethodType === "card") {
          // For Card ( mollie , aduppay ) 👇🏼
          paymentDetailsObject = {
            cardToken: encryptedMollieCardToken,
          };
        } else if (paymentMethodType === "ideal") {
          // For iDeal ( mollie , aduppay ) 👇🏼
          paymentDetailsObject = {
            issuer:
              paymentMethodOptionSelected && paymentMethodOptionSelected !== ""
                ? paymentMethodOptionSelected
                : savedPaymentAttributes?.ideal_bank
                ? bankOptions[savedPaymentAttributes?.ideal_bank]
                : bankOptions
                ? bankOptions[Object.keys(bankOptions)[0]]
                : "",
          };
        }
      } else if (paymentMethodGateway === "paynl") {
        console.log("paymentMethodGateway : ", paymentMethodGateway);
        if (paymentMethodType === "ideal") {
          // For iDeal ( paynl ) 👇🏼
          paymentDetailsObject = {
            issuer:
              paymentMethodOptionSelected && paymentMethodOptionSelected !== ""
                ? paymentMethodOptionSelected
                : savedPaymentAttributes?.ideal_bank
                ? bankOptions[savedPaymentAttributes?.ideal_bank]
                : bankOptions
                ? bankOptions[Object.keys(bankOptions)[0]]
                : "",
          };
        }
      }

      const checkBankAvailability = (paymentMethod: string) => {
        if (bankOptions) {
          let bankAvailable = getKeyByValue(
            bankOptions,
            paymentMethodOptionSelected
          );
          if (!bankAvailable) {
            if (savedPaymentAttributes?.[paymentMethod]) {
              console.log(
                paymentMethodOptionSelected,
                ": Bank is not available",
                ", therefore previously saved bank is selected by default : ",
                savedPaymentAttributes?.[paymentMethod]
              );
              store.dispatch(
                setSelectedPaymentMethodOption({
                  key: savedPaymentAttributes?.[paymentMethod] ?? "",
                  optionData:
                    bankOptions[savedPaymentAttributes?.[paymentMethod]] ?? "",
                })
              );
              return savedPaymentAttributes?.[paymentMethod];
            } else {
              console.log(
                paymentMethodOptionSelected,
                ": Bank is not available",
                ", therefore 1st bank is selected by default : ",
                Object.keys(bankOptions)[0]
              );
              store.dispatch(
                setSelectedPaymentMethodOption({
                  key: Object.keys(bankOptions)[0] ?? "",
                  optionData: bankOptions[Object.keys(bankOptions)[0]] ?? "",
                })
              );
              return Object.keys(bankOptions)[0];
            }
          } else {
            console.log("Bank is available : ", paymentMethodOptionSelected);
            return bankAvailable;
          }
        }
      };

      if (paymentMethodType === "ideal") {
        paymentAttributes = JSON.stringify({
          ideal_bank: checkBankAvailability("ideal_bank"),
        });
      } else if (bicNumber && ibanNumber && paymentMethodType === "giropay") {
        paymentAttributes = JSON.stringify({
          giropay_bic_number: bicNumber,
          giropay_iban_number: ibanNumber,
        });
      }

      const saveShopperString: any = JSON.stringify(saveShopperObject);
      const updateShopperString: any = JSON.stringify(updateShopperObject);
      const paymentDetailsString: any = paymentDetailsObject
        ? JSON.stringify(paymentDetailsObject)
        : null;

      const response = await fetch(PAYMENT_PROCESS_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
        body: JSON.stringify({
          shopper: !token ? saveShopperString : updateShopperString,
          shop_id: shop_id,
          payment_method: paymentMethodType,
          cart: cartString,
          cart_total: grandTotal ?? 0.0,
          payment_details: paymentDetailsString ?? undefined,
          payment_attributes: paymentAttributes ?? undefined,
          create_account: token ? true : createAccountFlag,
        }),
      });

      // trigger API Errors as Toasts
      const res = await response.json();
      if (res) {
        toastHandler(res, store.dispatch, setToastMessage);
      }

      const { result } = res;
      const key = result?.authKey;

      if (key && createAccountFlag === true) {
        store.dispatch(setUserToken(key));
      }

      if (createAccountFlag !== true) {
        store.dispatch(setUserToken(undefined));
      }

      //* NEW User saving in state
      if (
        result?.shopper &&
        verificationState?.state !== verificationStates.verified
      ) {
        console.log("NEW User saving in state...");
        updateStateFromShopperObject(result?.shopper);
        updateStateFromPaymentMethodsBlock(result?.shopper);
        for (const segment of Object.keys(initialHiddenSegments)) {
          store.dispatch(disableFastCheckoutUISegment(segment));
        }

        store.dispatch(updateUserType({ user: applicationUserType.OLD_USER }));
        store.dispatch(
          setUserVerified({
            ...verificationState,
            state: verificationStates.verified,
            _: true,
            loggedInMethod: "otp",
            firstPayment: "pending",
          })
        );
        store.dispatch(enableFastCheckoutUISegment("ACCOUNT_SEGMENT"));
        store.dispatch(enableFastCheckoutUISegment("DETAILS_SEGMENT"));
        store.dispatch(enableFastCheckoutUISegment("SHIPPING_ADDRESS_SEGMENT"));
        store.dispatch(enableFastCheckoutUISegment("BILLING_ADDRESS_SEGMENT"));
        store.dispatch(enableFastCheckoutUISegment("PAYMENT_METHODS_SEGMENT"));
        store.dispatch(
          enableFastCheckoutUISegment("CREATE_ADUP_ACCOUNT_SEGMENT")
        );
        console.log("NEW User saved in state ✅");
      }
      store.dispatch(setPaymentErrorOccurred(false));
      return result;
    }
  } catch (error) {
    console.log("Catch @ processUserPayment :", error);
    store.dispatch(
      setToastMessage({
        text: t("ProcessError"),
        type: "ERROR",
      })
    );
    store.dispatch(setPaymentErrorOccurred(false));
    store.dispatch(setIsPayButtonLoading(false));
  }
}
