import { debounce } from "lodash";
import { setupOTPId } from "../../lib/api/otp-methods";
import { getShopperDetails } from "../../lib/api/shopper-methods";
import { logOutUser } from "../../lib/state/clear-user-data";
import {
  setUserPhone,
  setUserVerified,
  setUserWebAuthn,
  verificationStates,
} from "../../store/reducers/userSlice";
import store from "../../store/store";

// these listeners are all run after the onchange event for the phone number input, sequentially
export const phoneNumberChangeListeners: ((event: any) => void)[] = [
  async (event: any, meta?: any) => {
    // update the global state for the phone number
    const { country, number } = meta;
    const accountPhone = store.getState().users.currentUser.accountPhone;

    if (
      number &&
      country &&
      (accountPhone.country !== country || accountPhone.number !== number)
    ) {
      // If the phone number is changed, current verification status should be set to "unverified"
      const verificationState = store.getState().users.currentUser.verified;
      store.dispatch(
        setUserVerified({
          ...verificationState,
          state: verificationStates.unverified,
          firstPayment: "pending",
        })
      );

      if (number && country && number.length > 3) {
        // the phone number string may vary
        // if the first values of the phone number match the dial code
        phoneNumberChangeDebounce(country, number);
      } else {
        store.dispatch(
          setUserPhone({
            country: { countryCode: "", dialCode: "", flag: "", name: "" },
            number: "",
          })
        );
      }
    }
    // if (number.length <= 3) {
    //   store.dispatch(updateUserType({ user: applicationUserType.NO_USER }));
    // }
  },
];

export const phoneNumberCheck = async (country: any, number: any) => {
  // User cleared on phone number change, because phone is tied to a user
  logOutUser();
  store.dispatch(
    setUserPhone({
      country: country,
      number: number,
    })
  );
  const verificationState = store.getState().users.currentUser.verified;
  const result = await getShopperDetails(country.dialCode.substring(1), number);
  if (result) {
    store.dispatch(
      setUserVerified({
        ...verificationState,
        state: verificationStates.trying,
        firstPayment: "pending",
      })
    );
    store.dispatch(
      setUserPhone({
        country: country,
        number: number,
      })
    );
    setupOTPId(country.dialCode.substring(1), number);
    if (result?.webauthn) {
      store.dispatch(setUserWebAuthn(Boolean(result?.webauthn)));
    }
  }
};

const phoneNumberChangeDebounce = debounce(
  (country, number) => phoneNumberCheck(country, number),
  400
);
