import { t } from "i18next";
import { debounce } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import HeadingWithRightButton from "../../components/heading/HeadingWithRightButton";
import { getPaymentIntentForProcessing } from "../../lib/api/get-payment-intent";
import { setIbanNumber } from "../../store/reducers/paymentSlice";
import store from "../../store/store";
import executeScrollOn from "../../lib/utils/executeScrollOn";

const SepaDebitContainer: React.FC = () => {
  const paymentState = store.getState().payment;
  const isWalletPaymentMethodInUse =
    store.getState().payment?.isWalletPaymentMethodInUse;
  const ibanNumber = paymentState.ibanNumber;
  const [iban, setIban] = useState<string>(ibanNumber);
  const dispatch = useDispatch();

  const handleInputChange = (e: any) => {
    setIban(e.target?.value);
    makeSessionCall();
  };

  useEffect(() => {
    if (iban) {
      dispatch(setIbanNumber(iban));
    }
  }, [iban]);

  useEffect(() => {
    // executeScrollOnFastCheckoutPaymentMount();
    executeScrollOn("fastCheckoutPaymentMountRef001");
  }, []);

  return (
    <>
      {!isWalletPaymentMethodInUse && (
        <>
          <HeadingWithRightButton
            headingText={t("PaymentDetailsTitle")}
            button={<Fragment></Fragment>}
          />
          <div id="PaymentForm" style={{ paddingBottom: 20 }}>
            <form>
              <div className="flex-row">
                <div className={"animated-input-regular "}>
                  <input
                    type="text"
                    name="iban"
                    value={iban}
                    className="animated__input"
                    style={{ fontFamily: "var(--main-font-secondary)" }}
                    required
                    onChange={handleInputChange}
                  />
                  <label className="animated__input--label">
                    IBAN {t("Number")}
                  </label>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

const makeSessionCall = debounce(
  async () => await getPaymentIntentForProcessing(),
  1000
);

export default SepaDebitContainer;
