import { AdupUserAddress } from "../../../interfaces/AdupUserAddress";

import {
  addNewUserAddress,
  setBillingAddress,
  setShippingAddress,
} from "../../../store/reducers/userSlice";
import store from "../../../store/store";
import { SHOPPER_ADDRESS_URL } from "../../constants/urls";
import { applicationWWWXRLEncodedBodyBuilder } from "../../utils/body-builder";
import { buildHeadersURLEncoded } from "../../utils/build-headers";
import { setToastMessage } from "../../../store/reducers/appSlice";
import { toastHandler } from "../../utils/toast-handler";
import { t } from "i18next";
import { submitLog } from "../log";

export async function putUserAddress(
  address: AdupUserAddress,
  addressType: string
): Promise<boolean> {
  // send request
  const result: any = await sendPutUserAddressRequest(address);

  // save state
  if (result?.address_id) {
    store.dispatch(
      addNewUserAddress({
        ...address,
        id: result?.address_id,
        shipping_rate: result?.shipping_rate,
      })
    );
    if (address && addressType === "shipping") {
      store.dispatch(
        setShippingAddress({
          ...address,
          id: result?.address_id,
          shipping_rate: result?.shipping_rate,
        })
      );
    } else if (address && addressType === "billing") {
      store.dispatch(
        setBillingAddress({
          ...address,
          id: result?.address_id,
          shipping_rate: result?.shipping_rate,
        })
      );
    }

    return true;
  }

  return false;
}
async function sendPutUserAddressRequest(
  address: AdupUserAddress
): Promise<string | null> {
  const key = store.getState().users.currentUser.token;
  const shopId = store.getState().cart?.shop?.id;

  if (key) {
    try {
      const response = await fetch(SHOPPER_ADDRESS_URL, {
        method: "POST",
        body: applicationWWWXRLEncodedBodyBuilder({
          address: JSON.stringify({
            house_number: address.number,
            street: address.street,
            street2: address.street2,
            city: address.city,
            province: address.province,
            postcode: address.postcode,
            country: address.country,
          }),
          shop_id: shopId,
        }),
        headers: buildHeadersURLEncoded(key),
      });

      // trigger API Errors as Toasts
      const res = await response.json();
      if (res) {
        toastHandler(res, store.dispatch, setToastMessage);
      }

      const { result } = await res;

      return result;
    } catch (e) {
      console.log(e);
      await submitLog("error", t("AddAddressError"));
      store.dispatch(
        setToastMessage({
          text: t("AddAddressError"),
          type: "ERROR",
        })
      );
      return null;
    }
  } else {
    return null;
  }
}
